(function () {
    angular.module('mainApp')

        .controller('FollowersController', function ($routeParams, $scope, followersService, toolsService, usersManager, $log, serviceRanks) {



            var user = $routeParams.user,
                selectedList = $routeParams.type;

            $scope.list = [];
            $scope.followingList = [];
            $scope.followersList = [];
            var myFollowingList = [];
            $scope.followersFlag = true;
            $scope.ranks = null;

            serviceRanks.getRanks()
                .then(function (data) {
                    $scope.ranks = data;
                });

            followersService.getList(user).then(function (data) {

                $scope.followersCount = data.followersCount;
                $scope.followingCount = data.followingCount;

                $scope.followingList = data.following;
                $scope.followersList = data.followers;

                if (selectedList === "following") {
                    $scope.followersFlag = false;
                    $scope.listusers = data.following;
                } else {
                    $scope.followersFlag = true;
                    $scope.listusers = data.followers;
                }

            });

            $scope.$watch("currentUser.niceName", function () {

                if ($scope.currentUser) {

                    followersService.getList(user).then(function (data) {

                        $scope.isFollowing = data.followers.find(function (e) {
                            return e.niceName === $scope.currentUser.niceName;
                        }) ? true : false;

                    }, function (error) {
                        toolsService.manageError('RecipesByAuthor -> getListFollowers  > ERROR', error, true);
                    });

                    //mis propios followers y following
                    followersService.getList($scope.currentUser.niceName).then(function (data) {
                        myFollowingList = data.following;
                    }, function (error) {
                        toolsService.manageError('RecipesByAuthor -> getListFollowers  > ERROR', error, true);
                    });
                }
            });

            $scope.checkFollowers = function (user) {
                return myFollowingList.find(function (e) {
                    return e.niceName == user;
                }) ? true : false;
            };

            $scope.addFollowing = function (currentUser, niceName) {
                followersService.addFollowing(currentUser, niceName).then(function (data) {

                    $scope.isFollowing = true;

                    followersService.getList(user).then(function (data) {

                        $scope.followersCount = data.followersCount;
                        $scope.followingCount = data.followingCount;

                        $scope.followingList = data.following;
                        $scope.followersList = data.followers;

                        $scope.listusers = $scope.followersFlag ? data.followers : data.following;

                    }, function (error) {
                        toolsService.manageError('RecipesByAuthor -> getListFollowers  > ERROR', error, true);
                    });

                    followersService.getList($scope.currentUser.niceName).then(function (data) {
                        myFollowingList = data.following;
                    }, function (error) {
                        toolsService.manageError('RecipesByAuthor -> getListFollowers  > ERROR', error, true);
                    });

                }, function (error) {
                    toolsService.manageError('RecipesByAuthor -> addFollowing  > ERROR', error, true);
                });
            };
            $scope.removeFollowing = function (currentUser, niceName) {
                followersService.removeFollowing(currentUser, niceName).then(function (data) {

                    $scope.isFollowing = false;

                    followersService.getList(user).then(function (data) {

                        $scope.followersCount = data.followersCount;
                        $scope.followingCount = data.followingCount;

                        $scope.followingList = data.following;
                        $scope.followersList = data.followers;

                        $scope.listusers = $scope.followersFlag ? data.followers : data.following;

                    }, function (error) {
                        toolsService.manageError('RecipesByAuthor -> getListFollowers  > ERROR', error, true);
                    });

                    followersService.getList($scope.currentUser.niceName).then(function (data) {
                        myFollowingList = data.following;
                    }, function (error) {
                        toolsService.manageError('RecipesByAuthor -> getListFollowers  > ERROR', error, true);
                    });

                }, function (error) {
                    toolsService.manageError('RecipesByAuthor -> removeFollowing  > ERROR', error, true);
                });
            };

            $scope.changeToFollowers = function () {
                $scope.listusers = $scope.followersList;
                $scope.followersFlag = true;
            };

            $scope.changeToFollowing = function () {
                $scope.listusers = $scope.followingList;
                $scope.followersFlag = false;
            };

            $scope.translateRank = function (rankNiceName) {

                var my_rank = $scope.ranks ? $scope.ranks.find(function (r) {

                    return r.niceName === rankNiceName;

                }) : null;

                return my_rank ? my_rank.name : "";

            };

            $scope.limit = 10; // initial value for limit
            $scope.loadMore = function (last, inview) {
                if (last && inview) {
                    $scope.limit += 10
                }
            };

            var authorNiceName = $routeParams.user;

            usersManager.getUserInfo(authorNiceName, 'public').then(function (data) {
                $log.debug("FavoriteRecipesController > getUserInfo DATA: ", data);
                $scope.currentAuthor = data;
            });

            $scope.goListFollows = function (type) {

                if (type === "followers" && !$scope.followersFlag) {
                    $scope.changeToFollowers();
                } else if (type === "following" && $scope.followersFlag) {
                    $scope.changeToFollowing();
                }
            };
        });
})();
