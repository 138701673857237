(function () {
  angular.module('mainApp')
    .controller('HelpDownloadController', ['$scope', '$rootScope', 'appConfig',
      function ($scope, $rootScope, appConfig) {

        $scope.mycook = false;
        $scope.mycooktouch = false;
        $scope.before_selection = true;

        if (!appConfig.webSettings.mycook) {
          $scope.mycooktouch = true;
        } else if (!appConfig.webSettings.mycooktouch) {
          $scope.mycook = true;
        }

        $scope.openDownloads = function (machine) {
          $scope.before_selection = false;
          if (machine == 'mycooktouch') {
            $scope.mycooktouch = true;
            $scope.mycook = false;
          } else {
            $scope.mycook = true;
            $scope.mycooktouch = false;
          }
        };
      }
    ]);
})();