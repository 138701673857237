(function () {
  angular.module('mainApp').controller('RatingController', [
    function (
      $scope,
      $route,
      $rootScope,
      $timeout,
      $log,
      $filter,
      appConfig,
      toolsService,
      recipesFactory
    ) {
      // COMMENTS
      $scope.initBox = function (idBox, editInfo) {
        $scope.replies.currentLevel = idBox;
        $scope.replies.boxes[idBox] = {};
        $scope.replies.boxes[idBox].isSent = 0;
        $scope.replies.boxes[idBox].type = 'new';
        $scope.replies.boxes[idBox].image = {
          file: null,
          rights: false,
          url: '',
        };

        if (editInfo) {
          $scope.replies.boxes[idBox].msg = editInfo.text;
          $scope.replies.boxes[idBox].rate = editInfo.rate;
          $scope.replies.boxes[idBox].type = 'edit';
          $scope.replies.boxes[idBox].id = editInfo._id;
          $scope.replies.boxes[idBox].image.url = editInfo.haveImage
            ? $scope.mycookTouchDomain +
              '/image/recipe/' +
              $scope.recipeContent.niceName +
              '/185x134/' +
              editInfo._id
            : '';
          $scope.replies.boxes[idBox].image.gotImage = editInfo.haveImage;
        }
      };

      $scope.showReplyDialog = function (idBox) {
        if ($scope.replies.boxes[idBox] === undefined) {
          $scope.replies.boxes[idBox] = {};
        }
        return $scope.replies.boxes[idBox].isSent != 1 && $scope.currentUser;
      };

      $scope.showReplyBox = function (idBox) {
        var replies = $scope.replies;
        if (replies.boxes[idBox] === undefined) {
          replies.boxes[idBox] = {};
        }
        var toShow =
          replies.currentLevel == idBox &&
          replies.boxes[idBox].isSent != 1 &&
          $scope.currentUser;
        return toShow;
      };

      /*$scope.showPreviewBox = function (idBox) {
        var toShow = $scope.showReplyBox(idBox);
        var replies = $scope.replies;
        return toShow && replies.boxes[idBox].msg !== '';
      };*/

      $scope.cancelReply = function (idBox) {
        $scope.replies.currentLevel = 'root';
        $scope.replies.boxes[idBox].msg = '';
        $scope.replies.boxes[idBox].type = '';
        $scope.replies.boxes[idBox].id = null;
        $scope.replies.boxes[idBox].image = {
          file: null,
          rights: false,
          url: '',
        };
      };

      $scope.commentIsValid = function (c) {
        var emtpyComment = {
          date: '',
          comments: [
            {
              date: '',
            },
          ],
        };

        var _c = angular.copy(c);
        return c && c != null && !angular.equals(c, emtpyComment);
      };

      $scope.sendRate = function (arrayOfIds) {
        sendToApiRate(arrayOfIds);
      };

      $scope.sendReply = function (arrayOfIds) {
        sendToApiReply(arrayOfIds);
      };

      // initialize
      $scope.replies = {};
      $scope.replies.boxes = [];
      $scope.initBox('root');

      function sendToApiReply(arrayOfIds) {
        var idComment = arrayOfIds.join('.');
        var level = arrayOfIds.length > 0 ? idComment : 'root';
        var ref = $scope.replies.boxes[level];

        if (
          !toolsService.isEmpty(ref.msg) &&
          !toolsService.isEmpty($scope.currentUser)
        ) {
          ref.msg = stripTags(ref.msg);

          recipesFactory
            .sendRate(
              $scope.recipeContent.niceName,
              ref.rate,
              ref.msg,
              arrayOfIds,
              ref.type,
              $scope.currentUser
            )
            .then(
              function (msgResponse) {
                if (ref.image.file) {
                  if (ref.image.rights) {
                    promise = recipesFactory.uploadChildImage(
                      $scope.recipeContent.niceName,
                      ref.id || msgResponse.data.response,
                      ref.image.file[0]
                    );
                  } else if (ref.image.file === 'deleted') {
                    promise = recipesFactory.deleteChildImage(
                      $scope.recipeContent.niceName,
                      ref.id || msgResponse.data.response
                    );
                  } else {
                    promise = Promise.reject();
                  }
                } else {
                  promise = Promise.resolve();
                }

                promise.then(
                  function (imgResponse) {
                    ref.isSent = 1;
                    $scope.replies.currentLevel = 'root';
                    $route.reload();
                  },
                  function (error) {
                    $scope.responseMsg =
                      $rootScope.translations.single_recipe.rating.rate_message.error;

                    $log.error(
                      'RecipeController > sendReply > uploadChildImage > ERROR: ',
                      error
                    );
                  }
                );
              },
              function (error) {
                $scope.responseMsg =
                  $rootScope.translations.single_recipe.rating.rate_message.error;
                $log.error('RecipeController > sendReply >  ERROR: ', error);
              }
            );
        }
      }

      function sendToApiRate(arrayOfIds) {
        var idComment = arrayOfIds.join('.');
        var level = arrayOfIds.length > 0 ? idComment : 'root';
        var ref = $scope.replies.boxes[level];

        if (
          (!toolsService.isEmpty(ref.rate) || !toolsService.isEmpty(ref.msg)) &&
          !toolsService.isEmpty($scope.currentUser)
        ) {
          ref.msg = stripTags(ref.msg);

          recipesFactory
            .sendRate(
              $scope.recipeContent.niceName,
              ref.rate,
              ref.msg,
              arrayOfIds,
              ref.type,
              $scope.currentUser
            )
            .then(
              function (msgResponse) {
                var promise;

                if (ref.image.file) {
                  if (ref.image.rights) {
                    promise = recipesFactory.uploadChildImage(
                      $scope.recipeContent.niceName,
                      ref.id || msgResponse.data.response,
                      ref.image.file[0]
                    );
                  } else if (ref.image.file === 'deleted') {
                    promise = recipesFactory.deleteChildImage(
                      $scope.recipeContent.niceName,
                      ref.id || msgResponse.data.response
                    );
                  } else {
                    promise = Promise.reject();
                  }
                } else {
                  promise = Promise.resolve();
                }

                promise.then(
                  function (imgResponse) {
                    console.log('MSG: ', msgResponse, 'IMG: ', imgResponse);
                    ref.isSent = 1;
                    $scope.replies.currentLevel = 'root';
                    $route.reload();
                  },
                  function (error) {
                    $scope.responseMsg =
                      $rootScope.translations.single_recipe.rating.rate_message.error;

                    $log.error(
                      'RecipeController > sendRating > uploadChildImage > ERROR: ',
                      error
                    );
                  }
                );
              },
              function (error) {
                $scope.responseMsg =
                  $rootScope.translations.single_recipe.rating.rate_message.error;
                $log.error('RecipeController > sendRating >  ERROR: ', error);
              }
            );
        }
      }

      function stripTags(txt) {
        // TO-IMPROVE: validar que solo haya enlaces a urls internas, por ahora se filtran todos los casos que no sean admins
        if (
          !(
            $scope.currentUser &&
            $scope.adminRoles.indexOf($scope.currentUser.role) > -1
          )
        ) {
          return $filter('stripTags')(txt);
        } else return txt;
      }

      $scope.discardImage = function (idBox) {
        if ($scope.replies.boxes[idBox].image.url)
          URL.revokeObjectURL($scope.replies.boxes[idBox].image.url);

        $scope.replies.boxes[idBox].image = {
          file: null,
          rights: false,
          url: '',
        };

        //repasar
        document.getElementById(idBox + '-imageFile').value = '';
      };

      $scope.setVirtualUrl = function (event, idBox) {
        if ($scope.replies.boxes[idBox].image.file) {
          if ($scope.replies.boxes[idBox].image.url)
            URL.revokeObjectURL($scope.replies.boxes[idBox].image.url);

          $scope.replies.boxes[idBox].image.url = URL.createObjectURL(
            $scope.replies.boxes[idBox].image.file[0]
          );
        }
      };

      $scope.removeImageEdit = function (idBox) {
        if ($scope.replies.boxes[idBox].image.url)
          URL.revokeObjectURL($scope.replies.boxes[idBox].image.url);

        $scope.replies.boxes[idBox].image = {
          file: $scope.replies.boxes[idBox].image.gotImage ? 'deleted' : null,
          rights: false,
          url: '',
        };
      };

      $scope.imageEditAccepted = function (idBox) {
        if ($scope.replies.boxes[idBox].image.rights) {
          $scope.setVirtualUrl(null, idBox);
        }
      };

      var ts = new Date().getTime();

      $scope.getTs = function () {
        return ts;
      };
    },
  ]);
})();
