(function () {
  'use strict';

  angular
    .module('ngCart', ['ngCart.directives'])

    .provider('$ngCart', function () {
      this.$get = function () {};
    })

    .run([
      '$rootScope',
      '$log',
      'ngCart',
      'NgCartItem',
      'store',
      function ($rootScope, $log, ngCart, NgCartItem, store) {
        $rootScope.$on('ngCart:change', function () {
          ngCart.$save();
        });

        if (angular.isObject(store.get('cart'))) {
          ngCart
            .$restore(store.get('cart'), true)
            .then(function () {
              ngCart.__ready = true;
              $rootScope.$emit('ngCart:ready');
            })
            .catch(function (err) {
              $log.error('Error restoring cart: ' + err);
            });
        } else {
          ngCart.init();
          ngCart.__ready = true;
        }
      },
    ])

    .service('ngCart', [
      '$rootScope',
      '$cookies',
      'serviceApiAffiliates',
      'storeFactory',
      'NgCartItem',
      'store',
      'AffiliatesFactory',
      function (
        $rootScope,
        $cookies,
        serviceApiAffiliates,
        storeFactory,
        NgCartItem,
        store,
        AffiliatesFactory
      ) {
        this.init = function () {
          this.$cart = {
            coupon: null,
            customerValue: {},
            shipping: null,
            shippingAddress: null,
            taxRate: null,
            tax: null,
            items: [],
          };
        };

        this.addItem = function (
          niceName,
          name,
          price,
          oprice,
          quantity,
          data,
          landing,
          showOriginal
        ) {
          if (data.isBuyable) {
            //TO prevent mismatch add product conditions from different browser folders.
            this.$refresh();

            var inCart = this.getItemByNiceName(niceName);
            var totalQty = quantity;

            if (inCart) {
              totalQty += inCart.getQuantity();
              this.removeItemByNiceName(niceName);
            }

            var newItem = new NgCartItem(
              niceName,
              name,
              price,
              oprice,
              totalQty,
              data,
              landing,
              showOriginal
            );
            this.$cart.items.push(newItem);

            $rootScope.$broadcast('ngCart:itemAdded', newItem);
            $rootScope.$broadcast('ngCart:change', {});
          }
        };

        //this.getItemById = function (itemId) {
        this.getItemByNiceName = function (itemNiceName) {
          if (!itemNiceName) {
            return false;
          }

          var items = this.getCart().items;
          var build = false;

          angular.forEach(items, function (item) {
            if (item.getNiceName() === itemNiceName) {
              build = item;
            }
          });
          return build;
        };

        this.setShipping = function (shipping) {
          this.$cart.shipping = shipping;
          return this.getShipping();
        };

        this.getShipping = function () {
          if (this.getCart().items.length === 0) {
            return 0;
          }
          return this.getCart().shipping;
        };

        this.setShippingAddress = function (shippingAddress) {
          this.$cart.shippingAddress = shippingAddress;
          $rootScope.$broadcast('ngCart:change', {});
          return this.getShippingAddress();
        };

        this.getShippingAddress = function () {
          return this.$cart.shippingAddress;
        };

        this.setMycookSerialNumber = function (mycook_serial) {
          this.$cart.customerValue.mycook_serial = mycook_serial;
          return this.getMycookSerialNumber();
        };

        this.getMycookSerialNumber = function () {
          return this.getCart().customerValue;
        };

        this.setSendAsGift = function (gift) {
          this.$cart.sendAsGift = gift;
          return this.getSendAsGift();
        };

        this.getSendAsGift = function () {
          return this.getCart().sendAsGift;
        };

        this.setComments = function (comments) {
          this.$cart.comments = comments;
          return this.getComments();
        };

        this.getComments = function () {
          return this.getCart().comments;
        };

        this.setTaxRate = function (taxRate) {
          this.$cart.taxRate = +parseFloat(taxRate).toFixed(2);
          return this.getTaxRate();
        };

        this.getTaxRate = function () {
          return this.$cart.taxRate;
        };

        this.getTax = function () {
          return +parseFloat(
            (this.getSubTotal() / 100) * this.getCart().taxRate
          ).toFixed(2);
        };

        this.setCart = function (cart) {
          this.$cart = cart;
          return this.getCart();
        };

        this.getCart = function () {
          return this.$cart;
        };

        this.getItems = function () {
          return this.getCart().items;
        };

        this.setOrderId = function (orderId) {
          this.$cart.orderId = orderId;
          $rootScope.$broadcast('ngCart:change', {});
          return this.getOrderId();
        };

        this.getOrderId = function () {
          return this.getCart().orderId;
        };

        this.setCoupon = function (coupon) {
          this.$cart.coupon = coupon;
          if (!this.$cart.coupon) {
            this.getItems().forEach(function (item) {
              item.unsetVoucherPrice();
            });

            this.unsetDiscountType();
          }
          $rootScope.$broadcast('ngCart:change', {});
          return this.getCoupon();
        };

        this.replaceCoupon = function (coupon) {
          if (this.$cart.coupon && coupon) {
            this.$cart.coupon = coupon;

            this.getItems().forEach(function (item) {
              item.unsetVoucherPrice();
            });

            this.unsetDiscountType();

            $rootScope.$broadcast('ngCart:change', { fromReplace: true });
            return this.getCoupon();
          } else return this.setCoupon(coupon);
        };

        this.getCoupon = function () {
          return this.getCart().coupon;
        };

        this.setDiscount = function (number) {
          this.$cart.discount = number;
          return this.getDiscount();
        };

        this.getDiscount = function () {
          return this.getCart().discount;
        };

        this.setTotalDiscount = function (number) {
          this.$cart.totalDiscount = number;
          return this.getTotalDiscount();
        };

        this.getTotalDiscount = function () {
          return this.getCart().totalDiscount;
        };

        this.setIsOrderDiscount = function (value) {
          return (this.$cart.isOrderDiscount = value);
        };

        this.isOrderDiscount = function () {
          return (
            this.getCoupon() &&
            this.getCart().isOrderDiscount &&
            this.getDiscount() > 0
          );
        };

        this.unsetDiscountType = function () {
          this.$cart.isOrderDiscount = undefined;
          this.$cart.discountType = undefined;
        };

        this.getTotalItems = function () {
          var count = 0;
          var items = this.getItems();
          angular.forEach(items, function (item) {
            count += item.getQuantity();
          });
          return count;
        };

        this.getTotalUniqueItems = function () {
          return this.getCart().items.length;
        };

        this.getSubTotal = function () {
          var total = 0;
          var couponType = this.getDiscountType();
          //var opp = ['mycook-touch', 'mycook-touch-black-edition', 'mycook-easy', 'mycook-one', 'roner-clip', 'nature', 'tasty-n-cream', 'brownie-co', 'cakepop-co', 'envasadora-vac6000', 'bolsas-vac6000-30x35cm', 'bolsas-vac6000-20x30cm', 'rollos-vac6000-22cmx6m', 'robot-aspirador-lucid-i10', 'cafetera-espresso-automatic-coffeemaker', 'aspirador-escoba-ultimate-digital-fuzzy', 'aspirador-escoba-ultimate-digital', 'batidora-bapi-1200-premium-complet', 'batidora-bapi-1200-inox', 'crepera-s163', 'gofrera-g594', 'fondue-ff2', 'palomitera-pop-corn', 'pichurrin']; /* Ñapote a arreglar */
          angular.forEach(this.getCart().items, function (item) {
            total += item.getTotal(couponType);
          });
          return +parseFloat(total).toFixed(2);
        };

        this.totalCost = function () {
          var totalDiscount = this.getTotalDiscount();

          if (totalDiscount && totalDiscount > 0) {
            return totalDiscount;
          } else {
            return +parseFloat(this.getSubTotal()).toFixed(2);
          }
        };

        this.removeItem = function (index) {
          var removedItem = this.$cart.items[index];
          this.$cart.items.splice(index, 1);
          $rootScope.$broadcast('ngCart:itemRemoved', removedItem);
          $rootScope.$broadcast('ngCart:change', {});
        };

        this.removeItemByNiceName = function (niceName) {
          var cart = this.getCart();
          var removedItem;

          angular.forEach(cart.items, function (item, index) {
            if (item.getNiceName() === niceName) {
              removedItem = cart.items[index];
              cart.items.splice(index, 1);
            }
          });

          this.setCart(cart);
          $rootScope.$broadcast('ngCart:itemRemoved', removedItem);
          $rootScope.$broadcast('ngCart:change', {});
        };

        this.setDiscountMultiplier = function (mult) {
          this.$cart.discountMultiplier = mult;
        };

        this.setDiscountType = function (type) {
          this.$cart.discountType = type;
        };

        this.getDiscountType = function () {
          return this.$cart.discountType;
        };

        this.getDiscountMultiplier = function (mult) {
          return this.getCart().discountMultiplier;
        };

        this.empty = function () {
          $rootScope.$broadcast('ngCart:change', {});
          this.$cart.orderId = null;
          this.$cart.items = [];
          localStorage.removeItem('cart');
        };

        this.isEmpty = function () {
          return this.$cart.items.length > 0 ? false : true;
        };

        this.toObject = function () {
          if (this.getItems().length === 0) {
            return false;
          }

          var items = [];
          angular.forEach(this.getItems(), function (item) {
            items.push(item.toObject());
          });

          return {
            shipping: this.getShipping(),
            shippingAddress: this.getShippingAddress(),
            tax: this.getTax(),
            taxRate: this.getTaxRate(),
            subTotal: this.getSubTotal(),
            totalCost: this.totalCost(),
            items: items,
          };
        };

        function _updateItems(items) {
          return new Promise(function (resolve, reject) {
            var promises = items.map(function (item) {
              return storeFactory.getProduct(item._niceName);
            });

            var affiliate = AffiliatesFactory.getActive();

            if (affiliate) {
              promises.push(
                serviceApiAffiliates.getAffiliateProducts(affiliate)
              );
            }

            Promise.all(promises).then(function (response) {
              var affiliateProducts = affiliate ? response.pop() : null;

              for (var i = 0; i < response.length; i++) {
                if (items[i]._landing) {
                  items[i]._price =
                    response[i].landingPrice || response[i].price;
                } else {
                  if (
                    affiliateProducts &&
                    affiliateProducts.includes(items[i]._niceName)
                  ) {
                    items[i]._price =
                      response[i].affiliatePrice || response[i].price;
                  } else {
                    items[i]._price = response[i].price;
                  }
                }

                items[i]._oprice = response[i].originalPrice;
                items[i]._landing = items[i]._landing ? true : false;
                items[i]._data = response[i];
              }

              resolve(items);
            }, reject);
          });
        }

        function _syncRestore(storedCart, updatedItems, ref) {
          var _self = ref || this;
          var newItems = updatedItems || storedCart.items;

          _self.$cart.coupon = storedCart.coupon;
          _self.$cart.shipping = storedCart.shipping;
          _self.$cart.shippingAddress = storedCart.shippingAddress;
          _self.$cart.tax = storedCart.tax;

          angular.forEach(newItems, function (item) {
            _self.$cart.items.push(
              new NgCartItem(
                item._niceName,
                item._name,
                item._price,
                item._oprice,
                item._quantity,
                item._data,
                item._landing,
                item._showOriginal
              )
            );
          });

          $rootScope.$emit('ngCart:change', {
            fromRestore: true,
            async: updatedItems ? true : false,
          });
        }

        this.$restore = function (storedCart, updateItems) {
          var _self = this;
          _self.init();

          return updateItems
            ? new Promise(function (resolve, reject) {
                _updateItems(storedCart.items).then(function (updatedItems) {
                  resolve(_syncRestore(storedCart, updatedItems, _self));
                }, reject);
              })
            : _syncRestore(storedCart, null, _self);
        };

        this.$save = function () {
          return store.set('cart', JSON.stringify(this.getCart()));
        };

        this.$refresh = function () {
          this.$restore(store.get('cart'));
        };
      },
    ])

    .factory('NgCartItem', [
      '$rootScope',
      '$log',
      'AffiliatesFactory',
      function ($rootScope, $log, AffiliatesFactory) {
        var item = function (
          niceName,
          name,
          price,
          oprice,
          quantity,
          data,
          landing,
          showOriginal
        ) {
          this.setNiceName(niceName);
          this.setName(name);
          this.setPrice(price);
          this.setOriginalPrice(oprice);
          this.setQuantity(quantity);
          this.setData(data);
          if (landing) this.setPriceLanding(landing);
          if (showOriginal) this.setShowOriginal(showOriginal);
        };

        item.prototype.setNiceName = function (niceName) {
          if (niceName) {
            this._niceName = niceName;
          } else {
            $log.error('An NICENAME must be provided');
          }
        };

        item.prototype.setVoucherPrice = function (price) {
          this._voucherPrice = price;
        };

        item.prototype.getVoucherPrice = function () {
          return this._voucherPrice;
        };

        item.prototype.getDiscountedPrice = function () {
          return this._voucherPrice || this._price;
        };

        item.prototype.getEffectivePrice = function (couponType) {
          //Voy a necesitar saber si hay un afiliado aquí!
          var price = this.getDiscountedPrice();
          var activeAffiliate = AffiliatesFactory.getActive();

          if (couponType) {
            switch (couponType) {
              case 'affiliate':
                {
                  price = this._data.affiliatePrice;
                }
                break;
              case 'voucher':
                {
                  if (!this._voucherPrice) price = this._data.price;
                }
                break;
              default:
                break;
            }
          } else if (!activeAffiliate && !this._landing) {
            price = this._data.price;
          }

          // console.log(
          //   'getEffectivePrice: ',
          //   price,
          //   couponType,
          //   activeAffiliate
          // );
          return price;
        };

        item.prototype.unsetVoucherPrice = function () {
          if (this._voucherPrice) this._voucherPrice = null;
        };

        item.prototype.getItemDiscount = function (
          isAffiliateItem,
          couponType
        ) {
          var priceBase = this._data.originalPrice || this._data.price;
          var priceDiscounted = this.getDiscountedPrice();

          if (!couponType || couponType === 'affiliate') {
            if (this._landing)
              priceDiscounted = this._data.landingPrice || this._price;
            else if (isAffiliateItem)
              priceDiscounted = this._data.affiliatePrice || this._price;
          } else {
            priceDiscounted = this._voucherPrice || this._data.price;
          }

          return priceBase && priceDiscounted ? priceBase - priceDiscounted : 0;
        };

        item.prototype.getNiceName = function () {
          return this._niceName;
        };

        item.prototype.getPriceLanding = function () {
          return this._landing;
        };

        item.prototype.setPriceLanding = function (landing) {
          this._landing = landing;
        };

        item.prototype.getShowOriginal = function () {
          return this._showOriginal;
        };

        item.prototype.setShowOriginal = function (original) {
          this._showOriginal = original || false;
        };

        item.prototype.setName = function (name) {
          if (name) {
            this._name = name;
          } else {
            $log.error('A name must be provided');
          }
        };

        item.prototype.getName = function () {
          return this._name;
        };

        item.prototype.setPrice = function (price) {
          var priceFloat = parseFloat(price);
          if (priceFloat) {
            if (priceFloat <= 0) {
              $log.error('A price must be over 0');
            } else {
              this._price = priceFloat;
            }
          } else {
            $log.error('A price must be provided');
          }
        };

        item.prototype.getPrice = function () {
          return this._price;
        };

        item.prototype.setOriginalPrice = function (oprice) {
          var priceFloat = parseFloat(oprice);
          if (!isNaN(priceFloat)) {
            if (priceFloat <= 0) {
              $log.error('A price must be over 0');
            } else {
              this._oprice = priceFloat;
            }
          } else {
            $log.error('Price must be a number');
          }
        };

        item.prototype.getOriginalPrice = function () {
          return this._oprice;
        };

        item.prototype.setQuantity = function (quantity, relative) {
          var quantityInt = parseInt(quantity);
          if (quantityInt % 1 === 0) {
            if (relative === true) {
              this._quantity += quantityInt;
            } else {
              this._quantity = quantityInt;
            }

            if (this._quantity < 1) {
              this._quantity = 1;
            }
          } else {
            this._quantity = 1;
            $log.info('Quantity must be an integer and was defaulted to 1');
          }

          $rootScope.$broadcast('ngCart:change', {});
        };

        item.prototype.getQuantity = function () {
          return this._quantity;
        };

        item.prototype.setData = function (data) {
          if (data) {
            this._data = data;
          }
        };

        item.prototype.getData = function () {
          if (this._data) {
            return this._data;
          } else {
            $log.info('This item has no data');
          }
        };

        item.prototype.isDiscounted = function (isAffiliateItem, couponType) {
          return this.getItemDiscount(isAffiliateItem, couponType) > 0;
        };

        item.prototype.isBadDiscount = function (isAffiliateItem, couponType) {
          return (
            this._voucherPrice &&
            this.getItemDiscount(isAffiliateItem, couponType) <= 0
          );
        };

        item.prototype.getTotal = function (couponType) {
          return +parseFloat(
            this.getQuantity() * this.getEffectivePrice(couponType)
          ).toFixed(2);
        };

        item.prototype.toObject = function () {
          return {
            niceName: this.getNiceName(),
            name: this.getName(),
            price: this.getPrice(),
            quantity: this.getQuantity(),
            data: this.getData(),
            total: this.getTotal(),
          };
        };

        return item;
      },
    ])

    .service('store', [
      '$window',
      '$log',
      function ($window, $log) {
        return {
          get: function (key) {
            if (typeof $window.localStorage[key] !== 'undefined') {
              var cart = angular.fromJson($window.localStorage[key]);
              if (cart !== null) {
                return JSON.parse(cart);
              } else {
                $log.info('Cart is null.');
              }
            } else {
              $log.info('Undefined key. No cart in localStorage.');
            }

            return false;
          },

          set: function (key, val) {
            if (val === undefined) {
              $window.localStorage.removeItem(key);
            } else {
              $window.localStorage[key] = angular.toJson(val);
            }

            return $window.localStorage[key];
          },
        };
      },
    ]);
})();
