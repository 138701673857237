
(function(){

  'use strict';
  
  angular.module('mainApp')
  
    .service('serviceLandings', function($http, $q, $log, sessionService, toolsService, timestamp, appConfig) {
      var getEbookDescriptions = function() {
        var content = {
          "Dietas/Sin lactosa": {
            "img": "sin-lactosa",
            "link": "ebook-gratis-de-recetas-sin-lactosa-preparadas-con-mycook",
            "title": "Buenas ideas para ayudarte con tu intolerancia a la lactosa"
          },
          "Dietas/Sin gluten": {
            "img": "sin-gluten",
            "link": "ebook-gratis-recetas-sin-gluten-mycook",
            "title": "Ricos postres aptos para celíac@s"
          },
          "Dietas/Sin huevo": {
            "img": "sin-huevo",
            "link": "ebook-gratis-con-recetas-dulces-y-saladas-sin-huevo-para-mycook",
            "title": "Recetas perfectas para intolerantes al huevo"
          },
          "Dietas/Vegetariana": {
            "img": "vegetarianas-y-veganas",
            "link": "ebook-gratis-de-recetas-vegetarianas-y-veganas-con-mycook",
            "title": "Sabrosas recetas vegetarianas y veganas"
          },
          "Dietas/Baja en calorías": {
            "img": "bajas-en-calorias",
            "link": "ebook-gratis-de-recetas-ligeras-y-bajas-en-calorias-con-mycook",
            "title": "Come bien con menos calorías"
          },
          "Gastronomia/Norteamericana/Mexicana": {
            "img": "mexico",
            "link": "ebook-gratis-de-recetas-de-mexico-con-mycook",
            "title": "Bocaditos de México"
          },
          "Ocasiones/Navidad": {
            "img": "navidad",
            "link": "ebook-gratis-de-menus-con-recetas-de-navidad-para-mycook",
            "title": "Edición especial Navidad en la mesa"
          },
          "category/arroces": {
            "img": "arroces",
            "link": "ebook-gratis-con-recetas-de-arroces-para-todos-los-dias-con-mycook",
            "title": "Arroces para todos los días"
          },
          "category/salsas-y-guarniciones": {
            "img": "sofritos",
            "link": "ebook-gratis-con-recetas-de-sofritos-para-todos-los-gustos-con-mycook",
            "title": "La salsa de todos tus platos"
          },
          "category/potajes-legumbres": {
            "img": "potajes",
            "link": "ebook-gratis-con-recetas-de-potajes-de-legumbres-con-mycook",
            "title": "Potajes que alimentan"
          },
          "category/sorbetes-y-helados": {
            "img": "helados",
            "link": "ebook-gratis-de-recetas-de-helados-sorbetes-y-granizados-con-mycook",
            "title": "Los frescos del verano"
          },
          "category/masas": {
            "img": "pan",
            "link": "ebook-gratis-de-recetas-de-pan-con-mycook",
            "title": "El pan de cada día"
          },
          "category/alimentacion-infantil": {
            "img": "infantil",
            "link": "ebook-gratis-de-recetas-para-bebes-con-mycook",
            "title": "Los reyes de la casa"
          },
          "accessories/steamer": {
            "img": "3-niveles",
            "link": "ebook-gratis-de-recetas-cocinadas-a-3-niveles-con-mycook",
            "title": "La cocina más eficiente"
          },
          "category/bebidas-y-cocktails": {
            "img": "zumos-batidos-smoothies",
            "link": "ebook-gratis-de-recetas-de-batidos-zumos-y-smoothies-con-mycook",
            "title": "Recetas que se beben"
          },
          "category/dulces-y-reposteria": {
            "img": "postres-sin-horno",
            "link": "ebook-gratis-de-recetas-de-postres-sin-horno-con-mycook",
            "title": "Postres sin horno"
          },
          "category/aperitivos-tapas": {
            "img": "aperitivos-tapas",
            "link": "ebook-gratis-de-recetas-de-aperitivos-y-tapas-con-mycook",
            "title": "Aperitivos y tapas que triunfan"
          },
        };
  
        return content;
      };
  
      var getRegalos = function() {
        var defer = $q.defer();
  
        getJson().then(function(response) {
          defer.resolve(response['regalos-club']);
        });
        return defer.promise;
      };
  
      var getJson = function() {
        var defer = $q.defer();
        var landingsBundle = sessionService.getValue('landings');
  
        if (!toolsService.isEmpty(landingsBundle)) {
          $log.debug("Service API > reaprovecho landingsBundle de sessionService: ", landingsBundle);
          defer.resolve(landingsBundle);
        } else {
          $log.debug("Service API > Cargando Landings");
          var ruta = "config/landings.json?rev=" + timestamp;
          $http({
            method: 'GET',
            url: ruta
          }).then(function(landings) {
            $log.debug("Service API > Landings loaded", landings);
            landingsBundle = landings.data;
            sessionService.setValue('landings', landingsBundle);
            defer.resolve(landingsBundle);
          });
        }
        return defer.promise;
      };
  
      var getLandingByNiceName = function(niceName, projection) {
        console.log('getLandingByNiceName', niceName, projection);
        var defer = $q.defer();
        projection = projection || {json: 1, _id: 0};

        var bodyData = {
          projection: projection
        };

        var ruta = appConfig.localApiBasePath + '/getLanding/' + niceName;

        $http({
          method: 'POST',
          url: ruta,
          data: bodyData,
          headers: {
            'Authorization': 'Bearer ' + sessionService.getUserToken()
          }
        }).then(function(landing) {
          $log.debug("Service API > Landings loaded", landing);
          defer.resolve(landing.data);
        }, function(error) {
          defer.reject(error);
        });
  
        return defer.promise;
      }

      var getLandingsByType = function(type, queryParams, projection) {
        var defer = $q.defer();
        projection = projection || {_id: 0};

        queryParams = toolsService.fromObjToQuery(queryParams, []);
        if(queryParams != '') queryParams = '?' + queryParams;

        var ruta = appConfig.localApiBasePath + "/getLandings" + queryParams;

        var bodyData = {
          projection: projection,
          type: type
        }

        $http({
          method: 'POST',
          url: ruta,
          data: bodyData,
          headers: {
            'Authorization': 'Bearer ' + sessionService.getUserToken()
          }
        }).then(function(landings) {
          $log.debug("Service API > Landings loaded", landings);
          defer.resolve(landings.data);
        });

        return defer.promise;
      }
  
      var getLandingsByTerm = function(term, termType, projection) {
        var defer = $q.defer();
        projection = projection || {_id: 0};
        var ruta = appConfig.localApiBasePath + "/getLandings/" + termType;
        var bodyData = {
          projection: projection,
          term: term
        }

        $http({
          method: 'POST',
          url: ruta,
          data: bodyData,
          headers: {
            'Authorization': 'Bearer ' + sessionService.getUserToken()
          }
        }).then(function(landings) {
          $log.debug("Service API > Landings loaded", landings);
          var landingArray = landings.data;
          defer.resolve(landingArray);
        });
  
        return defer.promise;
      }

      return {
        getRegalos: getRegalos,
        getEbookDescriptions: getEbookDescriptions,
        getLandingsByType: getLandingsByType,
        getLandingsByTerm: getLandingsByTerm,
        getLandingByNiceName: getLandingByNiceName
      };
    });
  })();