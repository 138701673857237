(function () {

    'use strict';

    angular.module('mainApp')

    .directive('viewNote', function ($templateCache) {
        return {
          template: function() {
            return $templateCache.get('template/view-note.html');
          },
          link: function (scope, element, attrs) {}
        };
    })

    .directive('newIngredient', function ($templateCache) {
        return {
          template: function() {
            return $templateCache.get('template/new-ingredient.html');
          },
          link: function (scope, element, attrs) {}
        };
    })

    .controller("shoppingList", ['$rootScope', '$scope', '$routeParams', '$log', 'toolsService', 'sessionService', '_', 'appConfig', '$q', '$http',
        function ($rootScope, $scope, $routeParams, $log, toolsService, sessionService, _, appConfig, $q, $http) {
            $scope.shoppingList = [];
            $scope.showNote = 'deactivated';
            $scope.addNewIngredient = 'deactivated';
            $scope.typeForm = "newIngredient";

            $scope.newIngredient = {
                "ingredient": "",
                "qty": "",
                "unit": "",
                "note": "",
                "checked": false
            };

            $scope.oldIngredient = {
                "ingredient": "",
                "qty": "",
                "unit": "",
                "note": "",
                "checked": false
            };

            $scope.defaultMeasurement = [
                $rootScope.translations.common.measures.tur,
                $rootScope.translations.common.measures.slc,
                $rootScope.translations.common.measures.lea,
                $rootScope.translations.common.measures.brch,
                $rootScope.translations.common.measures.bch,
                $rootScope.translations.common.measures.stk,
                $rootScope.translations.common.measures.clv,
                $rootScope.translations.common.measures.gra,
                $rootScope.translations.common.measures.mc,
                $rootScope.translations.common.measures.u,
                $rootScope.translations.common.measures.cp,
                $rootScope.translations.common.measures.scht,
                $rootScope.translations.common.measures.pch,
                $rootScope.translations.common.measures.pkt,
                $rootScope.translations.common.measures.ml,
                $rootScope.translations.common.measures.coy,
                $rootScope.translations.common.measures.l,
                $rootScope.translations.common.measures.gr,
                $rootScope.translations.common.measures.drp,
                $rootScope.translations.common.measures.tbsp,
                $rootScope.translations.common.measures.tsp,
                $rootScope.translations.common.measures.kg
              ];

            $scope.getShoppingList = function () {
                var url = appConfig.mycookTouchDomain + '/shoppinglist/';

                $http({
                    method: 'get',
                    url: url
                }).then(function (res) {
                    $scope.shoppingList = res.data;
                    console.log($scope.shoppingList);
                }, function (error) {
                });          
            };

            $scope.addIngredient = function () {
                var url = appConfig.mycookTouchDomain + '/shoppinglist';
                var body = [$scope.newIngredient];
                    console.log($scope.newIngredient);
                $http({
                    method: 'post',
                    url: url,
                    data: body,
                }).then(function () {
                    $scope.getShoppingList();
                    $scope.addNewIngredient = 'deactivated';
                }, function (error) {
                });          
            };

            $scope.editIngredient = function (newIngredient, oldIngredient) {
                var url = appConfig.mycookTouchDomain + '/shoppinglist/';
                var body = { "new": newIngredient, "old": oldIngredient };

                $http({
                    method: 'put',
                    url: url,
                    data: body,
                }).then(function () {
                    $scope.getShoppingList();
                    $scope.addNewIngredient = 'deactivated';
                }, function (error) {
                });          
            };

            $scope.removeIngredient = function (ingredient) {
                var url = appConfig.mycookTouchDomain + '/shoppinglist/';
                $http({
                    method: 'DELETE',
                    url: url,
                    headers: { 
                        'Content-type': 'application/json;charset=utf-8',
                    },
                    data: ingredient,
                }).then(function () {
                    $scope.getShoppingList();
                }, function (error) {
                });          
            };

            $scope.removeAll = function () {
                var url = appConfig.mycookTouchDomain + '/shoppinglist/all';

                $http({
                    method: 'delete',
                    url: url,
                    data: {}
                }).then(function () {
                    $scope.getShoppingList();
                }, function (error) {
                });          
            };

            $scope.updateIngredientState = function (ingredient) {
                var url = appConfig.mycookTouchDomain + '/shoppinglist/update/ingredient';
                var updateIngredient = {
                    "ingredient": ingredient.ingredient,
                    "qty": ingredient.qty,
                    "unit": ingredient.unit,
                    "checked": (!ingredient.checked).toString()
                };
                $http({
                    method: 'post',
                    url: url,
                    data: updateIngredient,
                }).then(function () {
                    $scope.getShoppingList();
                }, function (error) {
                });
            };
            $scope.viewNote = function(item) {
                $scope.note = item.note;
                $scope.showNote = 'activated';
            };

            $scope.closeNote = function() {
                $scope.showNote = 'deactivated';
            };

            $scope.closeFormNewIngredient = function() {
                $scope.addNewIngredient = 'desactivated';
            };

            $scope.formIngredient = function(item) {
                $scope.addNewIngredient = 'activated';
                if (item != null) {
                    $scope.typeForm = "editIngredient";
                    $scope.newIngredient.ingredient = item.ingredient;
                    $scope.newIngredient.qty = item.qty;
                    $scope.newIngredient.unit = item.unit;
                    $scope.newIngredient.note = item.note;
                    $scope.oldIngredient = $scope.newIngredient; 
                } else {
                    $scope.typeForm = "newIngredient";
                    $scope.newIngredient.ingredient = "";
                    $scope.newIngredient.qty = "";
                    $scope.newIngredient.unit = "u";
                    $scope.newIngredient.note = "";
                }
            };

            $scope.getShoppingList();
        }
    ]);
})();