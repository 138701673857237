(function(){
    'use strict';

    angular.module('mainApp')

        .controller('StoreLoginController', ['$scope','$rootScope','$location','serviceStoreAnalytics',
            function ($scope, $rootScope, $location,serviceStoreAnalytics) {

                serviceStoreAnalytics.checkoutProcess(2);

                $scope.$watch('currentUser.niceName', function(currentUserNiceName) {
                    if(currentUserNiceName) {
                        serviceStoreAnalytics.checkoutOptions(2, 'user');
                        $location.path($rootScope.url('store-shipping-address'));
                    }
                }, true);

                $scope.loginAsGuest = function() {
                    serviceStoreAnalytics.checkoutOptions(2, 'guest');
                    $location.path($rootScope.url('store-shipping-address'));
                }
        }]);
})();
