(function () {
  'use strict';

  angular
    .module('mainApp')

    .controller('EbookController', [
      '$scope',
      '$routeParams',
      '$filter',
      '$log',
      'serviceMautic',
      'serviceLandings',
      'legalTerms',
      'seoService',
      function (
        $scope,
        $routeParams,
        $filter,
        $log,
        serviceMautic,
        serviceLandings,
        legalTerms,
        seoService
      ) {
        var userData;
        var projection = {
          title: 1,
          img: 1,
          textDownload: 1,
          mauticFormID: 1,
        };

        serviceLandings
          .getLandingByNiceName('ebook-' + $routeParams.ebookName, projection)
          .then(function (response) {
            response.niceName_noebook = $routeParams.ebookName;
            $scope.ebook = response;

            var seoInfo = seoService.searchSeoAtTranslationsLandings();

            if (seoInfo) {
              seoService.setTitle(
                $filter('translateVariables')(seoInfo.title, response.title)
              );
              seoService.setDescription(seoInfo.description);
              seoService.setRobots(false, true);
            }
          })
          .catch(function (error) {
            toolsService.manageError(
              'EbookController > getLandingByNiceName error',
              error,
              true
            );
          });

        $scope.signature_contract = 'deactivated';
        $scope.userLegalData = {};
        $scope.anonymousUserData = true;

        $scope.cancelContract = function (userLegalData) {
          $scope.signature_contract = 'deactivated';
          $scope.contract_not_accepted = true;
        };

        $scope.acceptContract = function (validForm, userLegalData) {
          $scope.signature_contract = 'loading';
          $scope.contract_not_accepted = false;
          var sendToApi = new Array();
          for (var k in userLegalData) {
            if (userLegalData[k]) {
              if (k !== 'newsletterConditions') sendToApi.push(k);
            }
          }

          legalTerms
            .acceptLegalTerms('multi', sendToApi, userData.email)
            .then(function (result) {
              serviceMautic.sendToMautic(userData, $routeParams.ebookName);
            });
        };

        $scope.submitForm = function (user_data) {
          userData = user_data;
          $log.debug(
            'EbookController > submitForm',
            userData,
            $scope.currentUser
          );

          userData.formId = $scope.ebook.mauticFormID;

          $scope.signature_contract = 'activated';

          legalTerms.getLegalTerms(['ebookConditions']).then(function (legal) {
            legal.ebookConditions.forceValidationNewsletter = true;
            $scope.legal = legal;
            $scope.signature_contract = 'activated';
          });
        };
      },
    ])

    .controller('EbookGraciasController', [
      '$scope',
      '$rootScope',
      '$routeParams',
      '$location',
      '$log',
      'serviceLandings',
      'serviceMautic',
      'toolsService',
      'usersManager',
      'webStorage',
      'seoService',
      function (
        $scope,
        $rootScope,
        $routeParams,
        $location,
        $log,
        serviceLandings,
        serviceMautic,
        toolsService,
        usersManager,
        webStorage,
        seoService
      ) {
        var location = $location.protocol() + '://' + $location.host();

        if ($location.host().indexOf('localhost') > -1) {
          // Development URL (add port number)
          location += ':' + $location.port();
        }

        var seoInfo = seoService.searchSeoAtTranslationsLandings();

        if (seoInfo) {
          seoService.setTitle(seoInfo.title);
          seoService.setDescription(seoInfo.description);
          seoService.setRobots(false, false);
        }

        $scope.ebookNicename = $routeParams.ebookName;
        $scope.pinterestMsg = encodeURIComponent($routeParams.ebookName);
        $scope.pinterestImage =
          location +
          '/img/landings/' +
          $routeParams.ebookName +
          '/taurus-mycook-pinterest-ebook-' +
          $routeParams.ebookName +
          '.jpg';

        var projection = {
          title: 1,
          mauticFormID: 1,
        };

        $scope.ebookLandingUrl =
          location + $rootScope.url('ebook-' + $routeParams.ebookName);

        serviceLandings
          .getLandingByNiceName('ebook-' + $routeParams.ebookName, projection)
          .then(function (ebook) {
            var twMsg =
              '?url=' +
              $scope.ebookLandingUrl +
              '&text=' +
              encodeURIComponent(ebook.title);
            if ($rootScope.translations.config.social_media.twitter != '')
              twMsg +=
                '&via=' + $rootScope.translations.config.social_media.twitter;
            $scope.twMsg = twMsg;

            $log.debug(
              'EbookGraciasController currentUser',
              $scope.currentUser
            );

            $scope.$watch(
              'currentUser.niceName',
              function (currentUserNiceName) {
                $log.debug(
                  'TENEMOS CURRENT USER!!!',
                  currentUserNiceName,
                  $scope.currentUser
                );

                if ($scope.currentUser) {
                  var name =
                    $scope.currentUser.name.firstName +
                    ' ' +
                    $scope.currentUser.name.lastName;

                  if (name === '') name = $scope.currentUser.name.displayName;

                  var data = {
                    nombre: name,
                    email: $scope.currentUser.contact.mail,
                    formId: ebook.mauticFormID,
                  };

                  ////////////////////////////////////
                  var machineTranslator = {
                    S1: 'mycook',
                    'S1.1': 'mycooktouch',
                    S2: 'mycooknext',
                  };

                  var currentUserMachine = webStorage.get('userMachine');

                  if (!toolsService.isEmpty(currentUserMachine)) {
                    $log.debug(
                      'Landings get currentUserInfo > reaprovecho currentUserMachine de sessionService: ',
                      machineTranslator[currentUserMachine] || 'mycook'
                    );
                    data.modelo_mycook =
                      machineTranslator[currentUserMachine] || 'mycook';
                    serviceMautic.sendToMautic(data, $routeParams.ebookName);
                  } else {
                    usersManager
                      .getSupportedClouds()
                      .then(function (bundleData) {
                        $log.debug(
                          'Landings > userMachine > data: ',
                          bundleData
                        );

                        var userMachine = 'S1';
                        if (bundleData.length > 0) {
                          userMachine = bundleData[0];
                        }

                        data.modelo_mycook =
                          machineTranslator[userMachine] || 'mycook';
                        serviceMautic.sendToMautic(
                          data,
                          $routeParams.ebookName
                        );
                        webStorage.set('userMachine', userMachine);
                      });
                  }
                  ////////////////////////////////////
                }
              },
              true
            );
          })
          .catch(function (error) {
            toolsService.manageError(
              'EbookGraciasController > getLandingByNiceName error',
              error,
              true
            );
          });

        if ($scope.deviceDetector.raw.userAgent.indexOf('FBAV') === -1) {
          // descargar ebook
          var xhr = new XMLHttpRequest();
          xhr.open(
            'GET',
            '/downloads/ebook/' +
              $routeParams.ebookName +
              '/taurus-mycook-ebook-' +
              $routeParams.ebookName +
              '.pdf',
            true
          );
          xhr.responseType = 'blob';

          xhr.onload = function (e) {
            if (this.status == 200) {
              var blob = new Blob([this.response], { type: 'application/pdf' });
              saveAs(
                blob,
                'taurus-mycook-ebook-' + $routeParams.ebookName + '.pdf'
              );
            }
          };
          xhr.send();
        }
      },
    ]);
})();
