(function () {
  'use strict';

  angular
    .module('mainApp')

    .controller('OrderResponseController', [
      '$filter',
      '$location',
      '$scope',
      '$routeParams',
      '$rootScope',
      'orderSvc',
      'ngCart',
      'TPVerrors',
      function (
        $filter,
        $location,
        $scope,
        $routeParams,
        $rootScope,
        orderSvc,
        ngCart,
        TPVerrors
      ) {
        $scope.init = false;

        $scope.orderId = $routeParams.id;
        $scope.voucherIsApplied = false;

        var signature = $routeParams.Ds_Signature || '';
        var merchant = $routeParams.Ds_MerchantParameters || '';

        console.log('OrderResponseController', $scope.orderId, signature);

        if ($scope.orderId) {
          orderSvc.get($scope.orderId, signature, merchant).then(
            function (order) {
              console.log('order: ', order);
              if (!Object.prototype.hasOwnProperty.call(order, 'data')) {
                order.data = order;
              }
              // SEO
              var seo_title =
                $rootScope.translations.shop.seo['store-payment-response']
                  .title;
              seo_title +=
                ' ' +
                $rootScope.translations.seo.separator +
                ' ' +
                $rootScope.translations.seo.website_name;
              $rootScope.pageTitle = $filter('translateVariables')(
                seo_title,
                order.data.id
              );

              if (order.data) {
                $scope.voucherIsApplied =
                  order.data.voucher &&
                  order.data.voucher.status &&
                  (order.data.voucher.status === 'applied' ||
                    order.data.voucher.type === 'affiliate');

                $scope.order = order.data;
                order = order.data;
                // if (order.payment === 'ScalaPay') {
                //   order.state = 'Paid';
                // }
                
                if (order.state && order.state == 'Paid') {
                  $scope.status = true;

                  ngCart.empty();
                } else if (order.paymentInfo && order.paymentInfo.dsResponse) {
                  //ngCart.setOrderId(order.id);

                  var dsr = order.paymentInfo.dsResponse;
                  var codeNum = parseInt(dsr, 10);
                  $scope.errMsg = '';
                  $scope.status = false;

                  if (dsr && !isNaN(parseInt(codeNum, 10))) {
                    var resKey = '';

                    if (codeNum >= 181 && codeNum <= 182) {
                      resKey = '0181 - 0182';
                    } else if (codeNum >= 208 && codeNum <= 209) {
                      resKey = '0208 - 209';
                    } else if (codeNum >= 501 && codeNum <= 503) {
                      resKey = '0501 - 0503';
                    } else {
                      resKey = dsr;
                    }

                    if (TPVerrors.hasOwnProperty(resKey)) {
                      $scope.errMsg =
                        '<strong>' +
                        TPVerrors[resKey].msg +
                        ' (' +
                        $filter('translateVariables')(
                          $rootScope.translations.shop.response_payment.messages
                            .code,
                          order.paymentInfo.dsResponse
                        ) +
                        ')</strong><br />' +
                        TPVerrors[resKey].description;
                    } else {
                      $scope.errMsg =
                        '<strong>' +
                        $filter('translateVariables')(
                          $rootScope.translations.shop.response_payment.messages
                            .error_code,
                          order.paymentInfo.dsResponse
                        ) +
                        '</strong>';
                    }
                  }
                } else if (order.state && order.state == 'Error') {
                  $scope.errMsg =
                    '<strong>' +
                    $rootScope.translations.shop.response_payment.messages
                      .payment_error +
                    '</strong>';
                  $scope.status = false;

                  //ngCart.setOrderId(order.id);
                } else if (order.state && order.state == 'WaitingPayment') {
                  $scope.errMsg =
                    '<strong>' +
                    $rootScope.translations.shop.response_payment.messages
                      .payment_error +
                    '</strong>';
                  $scope.status = false;

                  //ngCart.setOrderId(order.id);
                }
              } else {
                $scope.status = false;
                $scope.errMsg =
                  '<strong>' +
                  $rootScope.translations.shop.response_payment.messages
                    .incorrect_order +
                  '</strong>';
              }

              $scope.init = true;
            },
            function (data) {
              $location.path($rootScope.url('notFound'));
            }
          );
        } else {
          $scope.init = true;
          $scope.status = false;
          $scope.errMsg =
            $rootScope.translations.shop.response_payment.messages.incorrect_order;
        }
      },
    ]);
})();
