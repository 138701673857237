(function () {
  angular
    .module('mainApp')
    .factory('DietPlanFactory', [
      '$http',
      'appConfig',
      function ($http, appConfig) {
        var _dpFactory = {};

        _dpFactory.getConfig = function () {
          return new Promise(function (resolve) {
            $http({
              method: 'get',
              url: appConfig.mycookTouchDomain + '/dietPlanCfg',
            }).then(
              function (res) {
                resolve(res.data.response);
              },
              function (err) {
                resolve({
                  meals: [
                    {
                      name: 'breakfast',
                      courses: [],
                    },
                    {
                      name: 'morning_snack',
                      courses: [],
                    },
                    {
                      name: 'lunch',
                      courses: [],
                    },
                    {
                      name: 'afternoon_snack',
                      courses: [],
                    },
                    {
                      name: 'dinner',
                      courses: [],
                    },
                  ],
                  mealsPerDay: 5,
                  usePrefs: true,
                });
              }
            );
          });
        };

        _dpFactory.setConfig = function (meals, mealsPerDay, usePrefs) {
          return new Promise(function (resolve, reject) {
            $http({
              method: 'post',
              url: appConfig.mycookTouchDomain + '/dietPlanCfg',
              data: {
                meals: meals,
                mealsPerDay: parseInt(mealsPerDay),
                usePrefs: usePrefs,
              },
            }).then(
              function (res) {
                resolve(res.data.response);
              },
              function (err) {
                reject(err);
              }
            );
          });
        };

        _dpFactory.getWeek = function (monday) {
          return new Promise(function (resolve, reject) {
            var my_monday = monday.format('YYYY-MM-DD');

            $http({
              method: 'get',
              url: appConfig.mycookTouchDomain + '/dietPlan/week/' + my_monday,
            }).then(
              function (res) {
                resolve(res.data.response);
              },
              function (err) {
                reject(err);
              }
            );
          });
        };

        _dpFactory.generateNewWeek = function (monday) {
          return new Promise(function (resolve, reject) {
            var my_monday = monday.format('YYYY-MM-DD');

            $http({
              method: 'post',
              url:
                appConfig.mycookTouchDomain + '/dietPlan/newWeek/' + my_monday,
            }).then(
              function (res) {
                resolve(res.data.response);
              },
              function (err) {
                reject(err);
              }
            );
          });
        };

        _dpFactory.generateEmptyWeek = function (monday) {
          return new Promise(function (resolve, reject) {
            var my_monday = monday.format('YYYY-MM-DD');

            $http({
              method: 'post',
              url:
                appConfig.mycookTouchDomain +
                '/dietPlan/newEmptyWeek/' +
                my_monday,
            }).then(
              function (res) {
                resolve(res.data.response);
              },
              function (err) {
                reject(err);
              }
            );
          });
        };

        _dpFactory.removeWeek = function (monday) {
          return new Promise(function (resolve, reject) {
            var my_monday = monday.format('YYYY-MM-DD');

            $http({
              method: 'post',
              url:
                appConfig.mycookTouchDomain +
                '/dietPlan/emptyWeek/' +
                my_monday,
            }).then(
              function (res) {
                resolve(res.data.response);
              },
              function (err) {
                reject(err);
              }
            );
          });
        };

        _dpFactory.addDish = function (date, meal, dish) {
          return new Promise(function (resolve, reject) {
            $http({
              method: 'post',
              url:
                appConfig.mycookTouchDomain + '/dietPlan/' + date + '/' + meal,
              data: dish,
            }).then(
              function (res) {
                resolve(res.data.response);
              },
              function (err) {
                reject(err);
              }
            );
          });
        };

        _dpFactory.generateDish = function (date, meal, idx) {
          return new Promise(function (resolve, reject) {
            $http({
              method: 'post',
              url:
                appConfig.mycookTouchDomain +
                '/dietPlan/' +
                date +
                '/' +
                meal +
                '/' +
                idx +
                '/random',
            }).then(
              function (res) {
                resolve(res.data.response);
              },
              function (err) {
                reject(err);
              }
            );
          });
        };

        _dpFactory.generateMeal = function (date, meal) {
          return new Promise(function (resolve, reject) {
            $http({
              method: 'post',
              url:
                appConfig.mycookTouchDomain +
                '/dietPlan/random/' +
                date +
                '/' +
                meal,
            }).then(
              function (res) {
                resolve(res.data.response);
              },
              function (err) {
                reject(err);
              }
            );
          });
        };

        _dpFactory.deleteDish = function (date, meal, idx) {
          return new Promise(function (resolve, reject) {
            $http({
              method: 'delete',
              url:
                appConfig.mycookTouchDomain +
                '/dietPlan/' +
                date +
                '/' +
                meal +
                '/' +
                idx,
            }).then(
              function (res) {
                resolve(res.data.response);
              },
              function (err) {
                reject(err);
              }
            );
          });
        };

        _dpFactory.setDish = function (date, meal, idx, dish) {
          return new Promise(function (resolve, reject) {
            $http({
              method: 'put',
              url:
                appConfig.mycookTouchDomain +
                '/dietPlan/' +
                date +
                '/' +
                meal +
                '/' +
                idx,
              data: dish,
            }).then(
              function (res) {
                resolve(res.data.response);
              },
              function (err) {
                reject(err);
              }
            );
          });
        };

        _dpFactory.addDayToShoppingList = function (day) {
          return new Promise(function (resolve, reject) {
            $http({
              method: 'post',
              url:
                appConfig.mycookTouchDomain +
                '/dietPlan/addDayToShoppingList/' +
                day,
            }).then(
              function (res) {
                resolve(res.data.response);
              },
              function (err) {
                reject(err);
              }
            );
          });
        };

        _dpFactory.addWeekToShoppingList = function (day) {
          return new Promise(function (resolve, reject) {
            $http({
              method: 'post',
              url:
                appConfig.mycookTouchDomain +
                '/dietPlan/addWeekToShoppingList/' +
                day,
            }).then(
              function (res) {
                resolve(res.data.response);
              },
              function (err) {
                reject(err);
              }
            );
          });
        };

        return _dpFactory;
      },
    ])
    .controller('DietPlanController', [
      '$scope',
      'DietPlanFactory',
      'toolsService',
      '$rootScope',
      'appConfig',
      '$timeout',
      'ngDialog',
      'unitsService',
      '$mdToast',
      function (
        $scope,
        DietPlanFactory,
        toolsService,
        $rootScope,
        appConfig,
        $timeout,
        ngDialog,
        unitsService,
        $mdToast
      ) {
        function getMomentLocale(region) {
          switch (region) {
            case 'CN-MYCOOKTOUCH':
            case 'CN-INNOCHEF-VN':
            case 'CN-INNOCHEF-MY':
              return 'zh-cn';
            case 'DA-MYCOOKWITT':
              return 'da';
            case 'EN-TEST':
            case 'EN-MYCOOKWITT':
            case 'EN-MYCOOKTOUCH':
            case 'EN-INNOCHEF-MY':
            case 'EN-INNOCHEF-VN':
              return 'en-gb';
            case 'FI-MYCOOKWITT':
              return 'fi';
            case 'IT-ICOOKIN':
              return 'it';
            case 'NO-MYCOOKWITT':
              return 'nb';
            case 'PT-MYCOOKTOUCH':
              return 'pt';
            case 'RU-MYCOOKTOUCH':
              return 'ru';
            case 'SV-MYCOOKWITT':
              return 'sv';
            default:
              return 'es';
          }
        }

        moment.locale(getMomentLocale(appConfig.region));

        if ($scope.$parent.fromDirective) {
          $scope.url = $rootScope.url;
          $scope.mediaDomain = appConfig.mycookTouchDomain + '/image';
        }
        $scope.url = $rootScope.url;

        $scope.my_monday = moment().subtract(moment().isoWeekday() - 1, 'days');
        var adding = {};
        var editing = {};

        $scope.weekShown = 0;

        Promise.all([
          DietPlanFactory.getWeek($scope.my_monday),
          DietPlanFactory.getConfig(),
        ]).then(
          function (response) {
            $scope.week = response[0];
            $scope.days = Object.keys($scope.week);

            $scope.conf = response[1];
            $scope.usePrefs = $scope.conf.usePrefs;
          },
          function (not_found) {
            if (not_found.status === 404) {
              Promise.all([
                DietPlanFactory.generateEmptyWeek($scope.my_monday),
                DietPlanFactory.getConfig(),
              ]).then(function (response) {
                $scope.week = response[0];
                $scope.days = Object.keys($scope.week);

                $scope.conf = response[1];
                $scope.usePrefs = $scope.conf.usePrefs;
              }, errorHandler);
            } else {
              errorHandler(not_found);
            }
          }
        );

        function translateMeal(meal) {
          switch (meal) {
            case 'breakfast':
              return ['Breakfast'];
            case 'morning_snack':
              return ['Morning snack'];
            case 'lunch':
              return ['Lunch starter', 'Lunch main', 'Lunch desert'];
            case 'afternoon_snack':
              return ['Afternoon snack'];
            case 'dinner':
              return ['Diner starter', 'Diner main', 'Diner desert'];
          }
        }

        function getDay(day) {
          var daysToAdd = -1;

          switch (day) {
            case 'Mon':
              daysToAdd = 0;
              break;
            case 'Tue':
              daysToAdd = 1;
              break;
            case 'Wed':
              daysToAdd = 2;
              break;
            case 'Thu':
              daysToAdd = 3;
              break;
            case 'Fri':
              daysToAdd = 4;
              break;
            case 'Sat':
              daysToAdd = 5;
              break;
            case 'Sun':
              daysToAdd = 6;
              break;
          }

          if (daysToAdd >= 0)
            return $scope.my_monday
              .clone()
              .add(daysToAdd, 'days')
              .format('YYYY-MM-DD');
          else return 'Invalid Date';
        }

        var changePrefsId;

        $scope.changePrefs = function (newVal) {
          if (changePrefsId) $timeout.cancel(changePrefsId);

          changePrefsId = $timeout(
            function (prefs) {
              DietPlanFactory.setConfig(
                $scope.conf.meals,
                $scope.conf.mealsPerDay,
                prefs
              ).then(function (response) {}, errorHandler);
            },
            250,
            true,
            newVal
          );
        };

        $scope.getDayNum = function (day) {
          var daysToAdd = -1;

          switch (day) {
            case 'Mon':
              daysToAdd = 0;
              break;
            case 'Tue':
              daysToAdd = 1;
              break;
            case 'Wed':
              daysToAdd = 2;
              break;
            case 'Thu':
              daysToAdd = 3;
              break;
            case 'Fri':
              daysToAdd = 4;
              break;
            case 'Sat':
              daysToAdd = 5;
              break;
            case 'Sun':
              daysToAdd = 6;
              break;
          }

          if (daysToAdd >= 0)
            return $scope.my_monday
              .clone()
              .add(daysToAdd, 'days')
              .format('DD/MM/YYYY');
          else return 'Invalid Date';
        };

        $scope.getWeekHeader = function () {
          return (
            $scope.my_monday.format('D MMM') +
            ' - ' +
            $scope.my_monday.clone().add(6, 'days').format('D MMM')
          );
        };

        $scope.toWeekDay = function (day) {
          switch (day) {
            case 'Mon':
              return $rootScope.translations.diet_plan.days.monday;
            case 'Tue':
              return $rootScope.translations.diet_plan.days.tuesday;
            case 'Wed':
              return $rootScope.translations.diet_plan.days.wednesday;
            case 'Thu':
              return $rootScope.translations.diet_plan.days.thursday;
            case 'Fri':
              return $rootScope.translations.diet_plan.days.friday;
            case 'Sat':
              return $rootScope.translations.diet_plan.days.saturday;
            case 'Sun':
              return $rootScope.translations.diet_plan.days.sunday;
          }
        };

        function changeWeek(dir) {
          $scope.weekShown += dir;
          $scope.my_monday =
            dir > 0
              ? $scope.my_monday.add(7, 'days')
              : $scope.my_monday.subtract(7, 'days');

          DietPlanFactory.getWeek($scope.my_monday).then(
            function (newWeek) {
              $scope.week = newWeek;
            },
            function (not_found) {
              if (not_found.status === 404) {
                DietPlanFactory.generateEmptyWeek($scope.my_monday).then(
                  function (newWeek) {
                    $scope.week = newWeek;
                  }
                );
              } else {
                errorHandler(not_found);
              }
            }
          );
        }

        $scope.prevWeek = function () {
          if ($scope.weekShown > 0) changeWeek(-1);
        };

        $scope.nextWeek = function () {
          if ($scope.weekShown < 3) changeWeek(1);
        };

        /* No va el ng-model sin esto! */
        $scope.changeNote = function (addingNote) {
          $scope.addingNote = addingNote;
        };

        /* No va el ng-model sin esto! */
        $scope.changeNoteEdit = function (editingNote) {
          $scope.editingNote = editingNote;
        };

        $scope.editDialog = function (day, meal, idx) {
          editing.day = day;
          editing.meal = meal;
          editing.idx = idx;

          var dish = $scope.week[day][meal].dishes[idx];
          $scope.dishOnEdit = dish.type == 'note' ? dish.text : dish.niceName;
          $scope.addingGroup = translateMeal(meal);

          $scope.editingDish = true;
        };

        $scope.canGenerate = function () {
          var mealCfg = $scope.conf.meals.find(function (c) {
            return c.name === editing.meal;
          });

          return mealCfg ? mealCfg.courses[editing.idx] : false;
        };

        $scope.addRecipe = function (day, meal) {
          adding.day = day;
          adding.meal = meal;

          addDish({
            type: 'mycook',
            niceName: $scope.$parent.recipe.niceName,
            title: $scope.$parent.recipe.title,
          });
        };

        $scope.generateMeal = function (day, meal) {
          $scope.generating = true;
          DietPlanFactory.generateMeal(getDay(day), meal).then(
            function (dayInfo) {
              $scope.week[day][meal] = dayInfo[meal];
              $scope.week[day].nutritional = dayInfo.nutritional;
              $scope.generating = false;
            },
            function (err) {
              $scope.generating = false;
              errorHandler(err);
            }
          );
        };

        $scope.openDialog = function (
          element,
          nutri,
          templateID,
          className,
          day,
          meal
        ) {
          var array = [
            ['Energy'],
            ['Lipid Tot', ['FA Sat']],
            ['Carbohydrt', ['Sugar Tot']],
            ['Protein'],
            ['Fiber TD'],
            /*['Colesterol'],*/ ['Sodium'] /*,['Water']*/,
          ];
          $scope.nutritionalTable = unitsService.getNutritionalTable(
            nutri,
            array
          );
          $scope.recipe_item = element;
          $scope.day = day;
          $scope.nutritionalDay = true;

          switch (meal) {
            case 'breakfast':
              $scope.maxKcal = $scope.conf.mealsPerDay === 3 ? 600 : 500;
              $scope.meal = $rootScope.translations.diet_plan.meals.breakfast;
              break;
            case 'morning_snack':
              $scope.meal =
                $rootScope.translations.diet_plan.meals.morning_snack;
              $scope.maxKcal = 250;
              break;
            case 'lunch':
              $scope.meal = $rootScope.translations.diet_plan.meals.lunch;
              $scope.maxKcal = $scope.conf.mealsPerDay === 3 ? 1125 : 875;
              break;
            case 'afternoon_snack':
              $scope.meal =
                $rootScope.translations.diet_plan.meals.afternoon_snack;
              maxKcal = 250;
              break;
            case 'dinner':
              $scope.maxKcal = $scope.conf.mealsPerDay === 3 ? 775 : 625;
              $scope.meal = $rootScope.translations.diet_plan.meals.dinner;
              break;
            default:
              $scope.maxKcal = 2500;
              $scope.nutritionalDay = false;
              break;
          }

          ngDialog.open({
            template: templateID,
            className: className,
            scope: $scope,
          });
        };

        $scope.openInfoDialog = function (templateID, className) {
          ngDialog.open({
            template: templateID,
            className: className,
            scope: $scope,
          });
        };

        $scope.generateDish = function () {
          DietPlanFactory.generateDish(
            getDay(editing.day),
            editing.meal,
            editing.idx
          ).then(function (dayInfo) {
            $scope.week[editing.day][editing.meal] = dayInfo[editing.meal];
            $scope.week[editing.day].nutritional = dayInfo.nutritional;
            editing = {};
            $scope.editingNote = '';
            $scope.editingDish = false;
          }, errorHandler);
        };

        $scope.deleteDish = function (day, meal, idx) {
          DietPlanFactory.deleteDish(getDay(day), meal, idx).then(function (
            dayInfo
          ) {
            $scope.week[day][meal] = dayInfo[meal];
            $scope.week[day].nutritional = dayInfo.nutritional;
          },
          errorHandler);
        };

        function addDish(dish) {
          DietPlanFactory.addDish(getDay(adding.day), adding.meal, dish).then(
            function (dayInfo) {
              $scope.week[adding.day][adding.meal] = dayInfo[adding.meal];
              $scope.week[adding.day].nutritional = dayInfo.nutritional;
              adding = {};
              $scope.addingNote = '';
              $scope.addingDish = false;
              $scope.addingGroup = [];
            },
            errorHandler
          );
        }

        function setDish(dish) {
          DietPlanFactory.setDish(
            getDay(editing.day),
            editing.meal,
            editing.idx,
            dish
          ).then(function (dayInfo) {
            $scope.week[editing.day][editing.meal] = dayInfo[editing.meal];
            $scope.week[editing.day].nutritional = dayInfo.nutritional;
            editing = {};
            $scope.editingNote = '';
            $scope.editingDish = false;
            $scope.addingGroup = [];
          }, errorHandler);
        }

        /* Para la directiva que se llama des de los listados y vista receta */
        $scope.addRecipe = function (day, meal) {
          adding.day = day;
          adding.meal = meal;

          addDish({
            type: 'mycook',
            niceName: $scope.$parent.recipe.niceName,
            title: $scope.$parent.recipe.title,
          });
        };

        $scope.replaceNote = function () {
          setDish({ type: 'note', text: $scope.editingNote });
        };

        $scope.replaceMycook = function (niceName) {
          setDish({ type: 'mycook', niceName: niceName });
        };

        $scope.addNote = function () {
          addDish({ type: 'note', text: $scope.addingNote });
        };

        $scope.addMycook = function (niceName) {
          addDish({ type: 'mycook', niceName: niceName });
        };

        $scope.closeAddDish = function () {
          $scope.addingDish = false;
        };

        $scope.closeEditDish = function () {
          $scope.editingDish = false;
        };

        $scope.addDish = function (day, meal) {
          adding = { day: day, meal: meal };
          $scope.addingGroup = translateMeal(meal);
          $scope.addingDish = true;
        };

        $scope.generateWeek = function () {
          $scope.generating = true;
          DietPlanFactory.generateNewWeek($scope.my_monday).then(
            function (new_week) {
              $scope.week = new_week;
              $scope.generating = false;
            },
            function (err) {
              $scope.generating = false;
              errorHandler(err);
            }
          );
        };

        $scope.removeWeek = function () {
          DietPlanFactory.removeWeek($scope.my_monday).then(function (
            new_week
          ) {
            $scope.week = new_week;
          },
          errorHandler);
        };

        function errorHandler(err) {
          toolsService.manageError('DietPlanController: ', err);
        }

        $scope.addDayToShoppingList = function (day) {
          formatDay = getDay(day);
          DietPlanFactory.addDayToShoppingList(formatDay);
          var el = document.getElementById('header');
          console.log(el);
          $mdToast.show(
            $mdToast
              .simple()
              .textContent(
                $rootScope.translations.diet_plan.table
                  .notification_day_shopping
              )
              .position('right')
              .hideDelay(3000)
              .parent(el)
          );
        };

        $scope.addWeekToShoppingList = function () {
          DietPlanFactory.addWeekToShoppingList(
            $scope.my_monday.format('YYYY-MM-DD')
          );
          var el = document.getElementById('header');
          $mdToast.show(
            $mdToast
              .simple()
              .textContent(
                $rootScope.translations.diet_plan.table
                  .notification_day_shopping
              )
              .position('right')
              .hideDelay(3000)
              .parent(el)
          );
        };

        $scope.calculateNutritionalColor = function (kcal, maxKcal) {
          if (kcal <= maxKcal / 3) return 'green';
          else if (kcal <= (maxKcal / 3) * 2) return 'yellow';
          else return 'red';
        };
      },
    ])
    .directive('mealDisplay', [
      '$templateCache',
      '_',
      '$rootScope',
      function ($templateCache, _, $rootScope, $timeout) {
        return {
          restrict: 'E',
          template: '',
          scope: {
            day: '=',
            meal: '=',
            dishes: '=',
            popup: '=',
            dialog: '&',
            delete: '&',
            mediaDomain: '=',
            translations: '=',
          },
          template: function (element, attrs) {
            return $templateCache.get(
              'template/user/diet-plan-meal-display.html'
            );
          },
          link: function ($scope, elem, attrs) {
            var threshold = 5;

            $scope.editingIdx = -1;
            $scope._ = _;
            $scope.url = $rootScope.url;

            function getTextWidth(text, font) {
              //console.log('GOT CANVAS: ', getTextWidth.canvas, text);
              var canvas =
                getTextWidth.canvas ||
                (getTextWidth.canvas = document.createElement('canvas'));
              var context = canvas.getContext('2d');
              context.font = font;
              var metrics = context.measureText(text);
              return metrics.width;
            }

            $scope.editDish = function (index) {
              $scope.dialog()($scope.day, $scope.meal, index);
            };

            $scope.deleteDish = function (index) {
              $scope.delete()($scope.day, $scope.meal, index);
            };

            function computeTextsWidth() {
              $scope.dishes = $scope.dishes.map(function (dish, idx) {
                var textCont = elem
                  .find('.textCont-' + idx)[0]
                  .getBoundingClientRect().width;
                var bucket = elem.find('.textBucket-' + idx);
                var font =
                  bucket.css('font-size') + ' ' + bucket.css('font-family');

                var text =
                  dish.type == 'mycook'
                    ? dish.title
                    : dish.type == 'note'
                    ? dish.text
                    : null;
                var textWidth = getTextWidth(text, font);

                var suppChars = 1;

                while (textWidth > textCont - threshold) {
                  textWidth = getTextWidth(text.slice(0, -suppChars), font);
                  //   console.log(
                  //     'textWidth:',
                  //     textWidth,
                  //     'container:',
                  //     textCont - threshold,
                  //     'text:',
                  //     text.slice(0, -suppChars)
                  //   );
                  suppChars++;
                }

                suppChars--;

                dish.widthChars = text.length - suppChars;

                // console.log(
                //   'CONTAINER: ',
                //   textCont,
                //   'TEXT: ',
                //   textWidth,
                //   ' (' + text + ')',
                //   'FONT: ' + font,
                //   'WITHCHARS: ' + dish.widthChars
                // );

                return dish;
              });
            }

            var toId;

            function resizeHandler() {
              //console.log('EVENT resize!' + $scope.day, $scope.meal);
              if (toId) $timeout.cancel(toId);

              toId = $timeout(computeTextsWidth, 50, true);
            }

            window.addEventListener('resize', resizeHandler);

            $scope.$watch('dishes', function (value, oldValue) {
              //   console.log(
              //     'EVENT watch!',
              //     $scope.day,
              //     $scope.meal,
              //     value,
              //     oldValue
              //   );

              if (value && value.length && !value[0].widthChars) {
                if (toId) $timeout.cancel(toId);

                toId = $timeout(computeTextsWidth, 50, true);
              }
            });

            $scope.$on('$destroy', function () {
              window.removeEventListener('resize', resizeHandler);
            });
          },
        };
      },
    ])
    .directive('mealDisplayInfo', [
      '_',
      '$rootScope',
      '$timeout',
      function (_, $rootScope, $timeout) {
        return {
          restrict: 'E',
          template: '',
          scope: {
            day: '=',
            element: '=',
            nutritionalTable: '=',
            templateID: '=',
            className: '=',
            meal: '=',
            nutri: '=',
            add: '&',
            generate: '&',
            addRecipe: '&',
            mediaDomain: '=',
            open: '&',
            nutritional: '=',
            over: '=',
            translations: '=',
            dishes: '=',
            canGenerate: '=',
          },
          templateUrl: 'template/user/diet-plan-meal-info.html?hola=test',
          link: function ($scope, elem, attrs) {
            var toID;

            $scope._ = _;
            $scope.url = $rootScope.url;

            $scope.addDish = function (day, meal) {
              $scope.add()(day, meal);
            };

            $scope.generateMeal = function (day, meal) {
              if ($scope.canGenerate) $scope.generate()(day, meal);
            };

            $scope.addRecipeToDietPlan = function (day, meal) {
              $scope.addRecipe()(day, meal);
            };

            $scope.openDialog = function (
              element,
              nutri,
              templateID,
              className,
              day,
              meal
            ) {
              $scope.open()(element, nutri, templateID, className, day, meal);
            };
          },
        };
      },
    ])
    .directive('recipeChooser', [
      'recipesFactory',
      'appConfig',
      'toolsService',
      '$rootScope',
      function (appConfig, recipesFactory, toolsService, $rootScope) {
        return {
          restrict: 'E',
          template: '',
          scope: {
            add: '&',
            type: '=',
            group: '=',
            mediaDomain: '=',
            url: '=',
            translations: '=',
          },
          templateUrl: 'template/user/diet-plan-recipe-chooser.html',

          link: function ($scope, elem, attrs) {
            $scope.isDietPlan = true;
            $scope.url = $rootScope.url;
          },
          controller: function ($scope) {
            $scope.currentPage = 1;

            var offSetItems = 0;
            var criteria = {};

            criteria['status.idParent'] = '';
            criteria['sortBy'] = 'recent';
            criteria['course'] = { $in: $scope.group };

            $scope.pageQuery = {};
            $scope.criteria = criteria;
            $scope.firstPage = 1;

            recipesFactory
              .getRecipesByCriteria(criteria, $scope.currentPage, offSetItems)
              .then(function (recipes) {
                $scope.contentData = recipes.result;

                // Paginacion (... por si acaso ...)
                var _maxCount = recipes.status.count + offSetItems;
                $scope.maxCount = _maxCount;
                $scope.itemsByPage = appConfig.itemsByPage;
                $scope.lastPage =
                  _maxCount > appConfig.itemsByPage
                    ? Math.ceil(_maxCount / appConfig.itemsByPage)
                    : 1;
              })
              .catch(function (error) {
                toolsService.manageError(
                  'DietPlanController > getRecipesByCriteria > ERROR1: ',
                  error
                );
              });

            $scope.addRecipe = function (recipe) {
              $scope.add()(recipe.niceName);
            };

            $scope.changeSearch = function (search) {
              $scope.search = search;
            };

            $scope.performSearch = function (restore) {
              if (restore) $scope.currentPage = 1;

              recipesFactory
                .searchRecipes(
                  $scope.search,
                  $scope.criteria,
                  $scope.currentPage,
                  offSetItems
                )
                .then(function (recipes) {
                  $scope.contentData = recipes.result;

                  // Paginacion (... por si acaso ...)
                  var _maxCount = recipes.status.count + offSetItems;
                  $scope.maxCount = _maxCount;
                  $scope.itemsByPage = appConfig.itemsByPage;
                  $scope.lastPage =
                    _maxCount > appConfig.itemsByPage
                      ? Math.ceil(_maxCount / appConfig.itemsByPage)
                      : 1;

                  /* Para que no descuadren los elementos en los listados con n%3 != 0 elementos */
                  var toFit = 3 - (recipes.result.length % 3);

                  if (toFit) {
                    for (var i = 0; i < toFit; i++) {
                      $scope.contentData.push({ mustHide: true });
                    }
                  }
                })
                .catch(function (error) {
                  toolsService.manageError(
                    'DietPlanController > searchRecipes > ERROR1: ',
                    error
                  );
                });
            };

            $scope.primPage = function () {
              $scope.currentPage = 1;
              $scope.performSearch(false);
            };

            $scope.nextPage = function () {
              $scope.currentPage++;
              $scope.performSearch(false);
            };

            $scope.prevPage = function () {
              $scope.currentPage--;
              $scope.performSearch(false);
            };

            $scope.ultPage = function () {
              $scope.currentPage = $scope.lastPage;
              $scope.performSearch(false);
            };
          },
        };
      },
    ]);
})();
