(function () {
  angular.module('mainApp')

    .factory('affiliatesForgotPasswordFactory', ['$http', '$q', 'appConfig', 'toolsService', 'saRouter', function ($http, $q, appConfig, toolsService, saRouter) {

      var _forgotPasswordFactory = {};

      _forgotPasswordFactory.sendChangePasswordRequest = function (userEmail) { //User identificator may be userNiceName or user email

        deferredResponse = $q.defer();

        var urlDestino = appConfig.localApiBasePath + '/affiliates/recoverPassword';

        route = saRouter.getRoute('affiliatesRecoverPassword');
        url = saRouter.replaceUrlParams(route.url, { user: '-', token: '-' });
        url = url.replace("/-/-", "");

        var data = {
          "login": userEmail,
          "redirectUrl": url
        };

        $http({
          method: 'post',
          data: data,
          url: urlDestino
        }).then(function (res) {
          deferredResponse.resolve(true);
        }, function errorCallback(response) {
          deferredResponse.resolve(false);
        });

        return deferredResponse.promise;
      }

      _forgotPasswordFactory.checkToken = function (token) {

        deferredResponse = $q.defer();

        var urlDestino = appConfig.localApiBasePath + '/affiliates/checkToken/' + token;
        $http({
          method: 'get',
          url: urlDestino
        }).then(function (res) {
          $log.debug('Response of token validation', res);
          deferredResponse.resolve(true);
        }, function errorCallback(response) {
          deferredResponse.resolve(false);
        });

        return deferredResponse.promise;
      }

      _forgotPasswordFactory.changePassword = function (affiliateNiceName, newPassword, token) {

        deferredResponse = $q.defer();

        var urlDestino = appConfig.localApiBasePath + '/affiliate/' + affiliateNiceName;
        var params = {
          "password": newPassword
        };

        $http({
          method: 'put',
          url: urlDestino,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          },
          data: params
        }).then(function (res) {
          deferredResponse.resolve(true);
        }, function errorCallback(response) {
          deferredResponse.resolve(false);
        });

        return deferredResponse.promise;
      }

      return _forgotPasswordFactory;
    }])

    .controller('AffiliatesRecoverPasswordController', [function ($scope, $rootScope, $window, $route, $routeParams, affiliatesForgotPasswordFactory) {
      var userNiceName = $routeParams.user;
      var token = $routeParams.token;

      $scope.recoverPassword = {
        recoverPassword: ""
      };

      affiliatesForgotPasswordFactory.checkToken(token).then(function (response) {
        if (response == false) { //redirigir a la home
          var urlToRedirect = $rootScope.url('home');
          $window.location.href = urlToRedirect;
        }
      });

      $scope.submitForm = function (newPassword) {

        affiliatesForgotPasswordFactory.changePassword(userNiceName, newPassword, token).then(function (response) {
          if (response) {
            $scope.successChangePassword = true;
          } else {
            $scope.successChangePassword = false;
          }
        });
      }
    }])

    .controller('AffiliatesForgotPasswordController', [function ($scope, $rootScope, $window, $route, $routeParams, affiliatesForgotPasswordFactory) {
      $scope.submitForgotPassword = function (email) {
        $scope.error = false;

        affiliatesForgotPasswordFactory.sendChangePasswordRequest(email).then(function (response) {
          if (response) { 
            $scope.success = true;
            $scope.error = false;
          } else { 
            $scope.success = false;
            $scope.error = true;
          }
        });
      }
    }])

    .controller('AffiliatesChangePasswordController', [function ($scope, $rootScope, affiliatesForgotPasswordFactory, sessionService, webStorage, $window) {
      $scope.submitForm = function (newPassword) {
        var affiliateNiceName = webStorage.get('userNiceNameAffiliates');
        var token = sessionService.getUserToken(true);
        affiliatesForgotPasswordFactory.changePassword(affiliateNiceName, newPassword, token).then(function (response) {
          if (response) {
            $scope.successChangePassword = true;
            setTimeout(function () {
              var urlToRedirect = $rootScope.url('affiliates-profile');
              $window.location.href = urlToRedirect;
            }, 3000);
          } else {
            $scope.successChangePassword = false;
          }
        });
      }

      $scope.cancelChangePassword = function () {
        var urlToRedirect = $rootScope.url('affiliates-profile');
        $window.location.href = urlToRedirect;
      }
    }])
})();
