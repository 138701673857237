(function () {

    angular.module('mainApp')
        .factory("prefsFactory", [
            "$http",
            "appConfig",
            "usersManager",
            "webStorage",
            "$log",
            function ($http, appConfig, usersManager, webStorage, $log) {
                var _prefsFactory = {};

                _prefsFactory.getSettings = function () {

                    return new Promise(function (resolve, reject) {

                        $http({
                            method: "get",
                            url: appConfig.mycookTouchDomain + "/ingredientsSettings"
                        }).then(
                            function (response) {
                                console.log(response.data);
                                resolve(response.data["Nutritional Enabled"]);
                            },
                            function errorCallback(response) {
                                $log.error("Error getting Settings");
                                reject(response);
                            }
                        );
                    });
                }

                _prefsFactory.getFoodGroups = function () {

                    return new Promise(function (resolve, reject) {

                        $http({
                            method: "get",
                            url: appConfig.mycookTouchDomain + "/recipes/food_groups"
                        }).then(
                            function (response) {
                                resolve(response.data);
                            },
                            function errorCallback(response) {
                                $log.error("Error getting FoodGroups");
                                reject(response);
                            }
                        );
                    });
                };

                _prefsFactory.getDiets = function () {

                    return new Promise(function (resolve, reject) {

                        $http({
                            method: "get",
                            url: appConfig.mycookTouchDomain + "/recipes/diets"
                        }).then(
                            function (response) {
                                resolve(response.data);
                            },
                            function errorCallback(response) {
                                $log.error("Error getting Diets");
                                reject(response);
                            }
                        );
                    });
                };

                _prefsFactory.getNutritionalPrefs = function () {

                    return new Promise(function (resolve, reject) {

                        usersManager.getCurrentUserInfo(webStorage.get("userNiceName"))
                            .then(function (user) {

                                resolve(user.nutritionalPreferences || null);

                            }, reject)
                    });
                };

                _prefsFactory._saveDiets = function (diets) {

                    return new Promise(function (resolve, reject) {
                        $http({
                            method: "post",
                            url: appConfig.mycookTouchDomain + "/user/" + webStorage.get("userNiceName") + "/dietPreferences",
                            data: { dietPreference: diets.map(function(d) { return d.niceName}) }
                        }).then(
                            function (response) {
                                if (response.status === 200)
                                    resolve(true);
                                else
                                    resolve(false);
                            },
                            function errorCallback(response) {
                                $log.error("Error saving Diets");
                                reject(response);
                            }
                        );
                    });
                };

                _prefsFactory._saveFoodGroups = function (foodGroups) {

                    return new Promise(function (resolve, reject) {
                        $http({
                            method: "post",
                            url: appConfig.mycookTouchDomain + "/user/" + webStorage.get("userNiceName") + "/addFoodGroups",
                            data: { foodGroups: foodGroups.map(function(f) { return f.niceName}) }
                        }).then(
                            function (response) {
                                if (response.status === 200)
                                    resolve(true);
                                else
                                    resolve(false);
                            },
                            function errorCallback(response) {
                                $log.error("Error saving FoodGroups");
                                reject(response);
                            }
                        );
                    });
                };

                _prefsFactory._saveIngredients = function (ingredients) {

                    return new Promise(function (resolve, reject) {

                        $http({
                            method: "post",
                            url: appConfig.mycookTouchDomain + "/user/" + webStorage.get("userNiceName") + "/addIngredients",
                            data: { ingredients: ingredients }
                        }).then(
                            function (response) {
                                if (response.status === 200)
                                    resolve(true);
                                else
                                    resolve(false);
                            },
                            function errorCallback(response) {
                                $log.error("Error saving Ingredients");
                                reject(response);
                            }
                        );

                    });
                };

                _prefsFactory._saveTags = function (tags) {

                    return new Promise(function (resolve, reject) {

                        $http({
                            method: "post",
                            url: appConfig.mycookTouchDomain + "/user/addTags",
                            data: { tags: tags }
                        }).then(
                            function (response) {
                                if (response.status === 200)
                                    resolve(true);
                                else
                                    resolve(false);
                            },
                            function errorCallback(response) {
                                $log.error("Error saving Ingredients");
                                reject(response);
                            }
                        );

                    });
                };

                _prefsFactory.savePreferences = function (diets, foodGroups, ingredients, tags) {
         
                    return new Promise(function (resolve, reject) {

                        Promise.all(

                            [
                                _prefsFactory._saveDiets(diets),
                                _prefsFactory._saveFoodGroups(foodGroups),
                                _prefsFactory._saveIngredients(ingredients),
                                _prefsFactory._saveTags(tags)
                            ]

                        ).then(function (responses) {

                            resolve(responses.find(function (response) { return !response }) ? false : true)

                        }, reject);
                    }
                    );
                };

                return _prefsFactory;

            }])

        .controller('PreferencesController', ['$scope', 'prefsFactory', 'ingredientsManager', "$sce", "$window", "$rootScope",
            function ($scope, prefsFactory, ingredientsManager, $sce, $window, $rootScope) {

                $scope.step = 0;
                var nutritionalEnabled;
                $scope.input_ingredient = {};

                Promise.all([prefsFactory.getDiets(), prefsFactory.getFoodGroups(), prefsFactory.getNutritionalPrefs(), prefsFactory.getSettings()])
                    .then(function (response) {
                    
                        [diets, fg, prefs, settings] = response;
                        
                        nutritionalEnabled = settings;

                        fg = fg.filter(function (f) {

                            return f.mustShow;

                        });

                        $scope.diets = prefs ? diets.map(function (d) {
                            d.selected = prefs.dietPreferences.includes(d.niceName);
                            return d;
                        }) : diets;

                        $scope.foodGroups = prefs ? fg.map(function (f) {
                            f.selected = prefs.dislikedFoodGroups.includes(f.niceName);
                            return f;
                        }) : fg;

                        $scope.dislikedIngredients = prefs ? prefs.dislikedIngredients : [];
                    });

                $scope.prevStep = function () {

                    $scope.step -= nutritionalEnabled ? 1 : 2;
                
                };

                $scope.nextStep = function () {

                    $scope.step += nutritionalEnabled ? 1 : 2;

                };

                $scope.clickDiet = function (diet) {

                    diet.selected = !diet.selected;

                    if (diet.foodGroups && diet.foodGroups.length) {
                        
                        var dietFg = diet.foodGroups.map(function(f) { return  f.niceName });

                        $scope.foodGroups = $scope.foodGroups.map(function (fg) {

                            if (dietFg.includes(fg.niceName))
                                fg.selected = diet.selected;

                            return fg;

                        });
                    }

                };

                $scope.clickFoodGroup = function (fg) {

                    fg.selected = !fg.selected;

                };

                $scope.removeIngredient = function (ing) {
                    $scope.dislikedIngredients = $scope.dislikedIngredients.filter(function (i) { return i != ing });
                };

                function add_ingredient(selected) {
                    $scope.dislikedIngredients.push(selected.value);
                    $scope.input_ingredient.selected = undefined;
                }

                $scope.ac_options = {
                    suggest: suggest_ingredients_remote,
                    on_select: add_ingredient
                };

                function suggest_ingredients(term, ingredients) {
                    var fuzzySearch = new Fuse(ingredients, {
                        shouldSort: true,
                        caseSensitive: false,
                        threshold: 0.4,
                    });

                    var q = term.toLowerCase().trim();

                    return fuzzySearch
                        .search(q)
                        .slice(0, 10)
                        .map(function (i) {
                            var val = ingredients[i];
                            return {
                                value: val,
                                label: $sce.trustAsHtml(highlight(val, term))
                            };
                        });
                }

                function highlight(str, term) {
                    var highlight_regex = new RegExp('(' + term + ')', 'gi');
                    return str.replace(highlight_regex, '<strong>$1</strong>');
                }

                function suggest_ingredients_remote(term) {
                    if (term.length < 2)
                        return;

                    return new Promise(function (resolve, reject) {
                        ingredientsManager.getIngredients().then(function (ingredients) {
                            resolve(suggest_ingredients(term, ingredients));
                        }, reject);
                    })

                }

                $scope.savePrefs = function () {
                    prefsFactory.savePreferences(
                        $scope.diets.filter(function (d) { return d.selected }),
                        $scope.foodGroups.filter(function (f) { return f.selected }),
                        $scope.dislikedIngredients,
                        $scope.diets.filter(function (d) { return d.selected }).reduce(function (p, n) { return n.tags ? p.concat(n.tags) : p }, [])    
                        ).then(function (result) {

                            if (result) $window.location.replace('/?suggestions=true');

                        }, function (err) {
                            console.error(err);
                        })
                }
            }]
        )
})();