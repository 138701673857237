(function (){
	angular.module('mainApp')
		.controller('StoreProfitsController', [function($scope, $rootScope, storeFactory) {

			$scope.extraContact = $rootScope.translations.help.contact.telesales.enabled_store;

			storeFactory.getProfits().then(function (result) {
        		$scope.profits = result;
      		});

		}]);
})();