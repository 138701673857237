/**
 *  - SearchController
 */

(function () {

  'use strict';

  angular.module('mainApp')

    .controller("SearchController", ['$scope', '$rootScope', '$location', '$filter', '_', 'appConfig', 'toolsService', 'recipeCategoriesFactory', 'recipesFactory', '$log',
      function ($scope, $rootScope, $location, $filter, _, appConfig, toolsService, recipeCategoriesFactory, recipesFactory, $log) {

        $scope.sendSearch = function (criteria) {
          if(typeof criteria.terms !== 'undefined') {
            $location.path($rootScope.url('searchResult', {term: criteria.terms.toLowerCase()}))
            .search('verified', null)
            .search('hasVideo', null);
          } else {
            $log.info('Input search empty');
          }
          
        };

        var searchCriteria = {};
        searchCriteria = _.clone($location.$$search, true);
        $scope.searchForm = searchCriteria;

      }
    ]);

})();
