/**
 *  - ExtraContactController
 */

(function () {

    'use strict';
  
    angular.module('mainApp')
  
      .controller("ExtraContactController", ['$scope', '$rootScope', '$location',
        function ($scope, $rootScope, $location) {
  
          $scope.openContact = function () {
              $location.path($rootScope.url('contact'))
              .search('extra', 'true')
          };
        }
      ]);
  
  })();