(function () {
  'use strict';

  angular
    .module('mainApp')

    .factory('authInterceptor', [
      '$q',
      '$window',
      '$rootScope',
      '$injector',
      '$timeout',
      '$log',
      'appConfig',
      function ($q, $window, $rootScope, $timeout, $injector, $log, appConfig) {
        return {
          request: function (config) {
            config.headers = config.headers || {};

            var $route = $injector.get('$route');
            var toolsService = $injector.get('toolsService');
            var sessionService = $injector.get('sessionService');
            var mcUserToken;

            if (!angular.isUndefined($route.current)) {
              $log.debug(
                'Interceptor current route',
                $route,
                'name',
                $route.current.name
              );
            }

            if (
              !angular.isUndefined($route.current) &&
              !angular.isUndefined($route.current.$$route) &&
              !angular.isUndefined($route.current.$$route.pageType) &&
              $route.current.$$route.pageType == 'affiliates'
            ) {
              mcUserToken = sessionService.getUserToken(true);
            } else {
              mcUserToken = sessionService.getUserToken();
            }

            if (!toolsService.isEmpty(mcUserToken)) {
              if (
                config.url &&
                (config.url.indexOf(appConfig.mycookTouchDomain) > -1 ||
                  config.url.indexOf(appConfig.localApiBasePath) > -1)
              ) {
                if (
                  config.url.indexOf('checkToken') == -1 &&
                  angular.isUndefined(config.headers.token)
                ) {
                  config.headers.token = mcUserToken;
                  config.url =
                    config.url +
                    (config.url.indexOf('?') == -1 ? '?' : '&') +
                    'token=' +
                    mcUserToken;
                }
              }
            }

            return config;
          },
          // On request failure
          requestError: function (rejection) {
            $log.error('requestError > rejection: ', rejection);
            return $q.reject(rejection);
          },
          response: function (response) {
            return response || $q.when(response);
          },
          // On response failure
          responseError: function (rejection) {
            if (rejection.status === 401) {
              //not allowed
              var route = $injector.get('$route');
              $log.error(
                'authResponseInterceptor > token rejection:',
                rejection,
                ' config: ',
                rejection.config
              );

              if (route.current.$$route.pageType == 'affiliates') {
                $rootScope.$emit('SESSION_EXPIRED_AFFILIATES');
              } else if (route.current.$$route.name != 'editPassword') {
                $rootScope.$emit('SESSION_EXPIRED');
              }
            }
            return $q.reject(rejection);
          },
        };
      },
    ]);
})();
