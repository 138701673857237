(function () {
  angular.module('mainApp')

    .controller('AffiliatesIncomeController', ['$scope', '$rootScope', 'serviceApiAffiliates', 'webStorage', 'appConfig', 'sessionService',
      function ($scope, $rootScope, $filter, serviceApiAffiliates, webStorage, appConfig, sessionService) {
        var userNiceName = webStorage.get('userNiceNameAffiliates');

        serviceApiAffiliates.getIncomes(userNiceName).then(function (data) {
          $scope.totalOrders = 0;
          $scope.totalIncomes = 0;

          data.forEach(function (element) {
            $scope.totalOrders += element.total;
            $scope.totalIncomes += element.income;
          }, this);
          $scope.incomes = data;
        });

        $scope.filterDate = function (date) {
          return $filter('date')(date, "dd-MM-yyyy");
        };

        $scope.calculate = function (items, month, year, years) {
          $scope.totalOrders = 0;
          $scope.totalIncomes = 0;
          var result = $scope.filtered();
          result.forEach(function (element) {
            $scope.totalOrders += element.total;
            $scope.totalIncomes += element.income;
          }, this);
        };

        $scope.filtered = function () {
          var result = [];
          var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
          var items = $scope.incomes;
          var year = $scope.selectedYear;
          var month = $scope.selectedMonth;
          if ($scope.years.indexOf(year) > -1) {
            if (months.indexOf(month) > -1) {
              for (var i = 0; i < items.length; i++) {
                if (new Date(items[i].date).getFullYear() == year && (new Date(items[i].date).getMonth() + 1) == month) {
                  result.push(items[i]);
                }
              }
              return result;
            } else {
              for (var i = 0; i < items.length; i++) {
                if (new Date(items[i].date).getFullYear() === year) {
                  result.push(items[i]);
                }
              }
              return result;
            }
          } else {
            return items;
          }
        };

        $scope.export = function (month, year) {
          var token = sessionService.getUserToken(true);
          var url = appConfig.localApiBasePath + '/excelAffiliats?token=' + token;

          if (month)
            url += '&month=' + month;
          
          if(year)
            url += '&year=' + year;

          window.location = url;
        };
      },
    ])

    .directive('monthOptions', function () {
      return {
        restrict: 'AE',
        replace: true,
        template:
          '<select id="month" name="month" ng-model="selectedMonth">' +
          '<option value="">Mes</option>' +
          '<option value="01">Enero</option>' +
          '<option value="02">Febrero</option>' +
          '<option value="03">Marzo</option>' +
          '<option value="04">Abril</option>' +
          '<option value="05">Mayo</option>' +
          '<option value="06">Junio</option>' +
          '<option value="07">Julio</option>' +
          '<option value="08">Agosto</option>' +
          '<option value="09">Septiembre</option>' +
          '<option value="10">Octubre</option>' +
          '<option value="11">Noviembre</option>' +
          '<option value="12">Diciembre</option>' +
          '</select>'
      }
    })

    .directive('yearSelect', function () {
      var currentYear = new Date().getFullYear();
      return {
        restrict: 'AE',
        replace: true,
        template: '<select id="year" name="year" ng-model="selectedYear" ng-options="y for y in years">' +
          '<option value="" selected>Año</option></select>',
        controller: function ($scope, $element, $attrs) {

          $scope.years = [];
          for (var i = ($attrs.offset * 1); i < ($attrs.range * 1) + 1; i++) {
            $scope.years.push(currentYear - i);
          }

        }
      }
    })

    .directive('test', ["$filter", function ($filter) {
      return {
        restrict: 'E',
        template: '<div style="font-size: 9px;"> Created at {{date2}}<div>',
        scope: {
          date: '@'
        },
        link: function (scope, elem, attrs) {
          var dateFormat = 'dd/MM/yy HH:mm:ss';
          scope.date2 = $filter('date')(attrs.date, dateFormat);
        }
      }
    }])

    .filter('affiliatesFilter', function () {

      return function (items, month, year, years) {
        var result = [];
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        if (years.indexOf(year) > -1) {
          if (months.indexOf(month) > -1) {
            for (var i = 0; i < items.length; i++) {
              if (new Date(items[i].date).getFullYear() == year && (new Date(items[i].date).getMonth() + 1) == month) {
                result.push(items[i]);
              }
            }
            return result;
          } else {
            for (var i = 0; i < items.length; i++) {
              if (new Date(items[i].date).getFullYear() === year) {
                result.push(items[i]);
              }
            }
            return result;
          }
        } else {
          return items;
        }
      }
    });
})();