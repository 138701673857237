(function () {
  'use strict';

  angular
    .module('mainApp')

    .run([
      'serviceStoreAnalytics',
      function (serviceStoreAnalytics) {
        serviceStoreAnalytics.init();
      },
    ])

    .service('serviceStoreAnalytics', [
      '$rootScope',
      'ngCart',
      'storeFactory',
      '$cookies',
      '$http',
      '$window',
      'AffiliatesFactory',
      function (
        $rootScope,
        ngCart,
        storeFactory,
        $cookies,
        $http,
        $window,
        AffiliatesFactory
      ) {
        var self = this;
        var dataLayer;

        self.init = function () {
          dataLayer = window.dataLayer = window.dataLayer || [];

          $rootScope.$on('ngCart:itemAdded', function (event, element) {
            self.addRemoveFromCart('add', element);
          });

          $rootScope.$on('ngCart:itemRemoved', function (event, element) {
            self.addRemoveFromCart('remove', element);
          });
        };

        function sendToAnalytics(obj) {
          dataLayer.push(obj);
        }

        function sendGetRequest(url) {
          return $http.get(url);
        }

        function getEffectivePrice(product, voucher) {
          if (product.unitPrice) {
            if (voucher && voucher.status && voucher.status == 'applied') {
              return product._landing || product._affiliate
                ? product.unitPriceWithDiscount || product.productValue.price
                : product.unitPriceWithDiscount || product.unitPrice;
            } else {
              return product.unitPrice;
            }
          } else {
            var price = product._voucherPrice || product._price;

            if (voucher && !product._voucherPrice) price = product._data.price;

            return price;
          }
        }

        function setCartProduct(product, voucher) {
          var qty;
          var _price = getEffectivePrice(product, voucher);

          if (product._data) {
            qty = product._quantity;
            product = product._data;
          } else if (product.productValue) {
            qty = product.quantity;
            product = product.productValue;
          }

          var categoryList = getProductCategories(product.category);

          var obj = {
            name: product.name,
            id: product.reference,
            // 'price': product.price,
            //'brand': 'Google',
            category: categoryList,
            //'variant': 'Gray',
            quantity: qty,
          };

          // check if price is from landingPrice or regular price.
          // variable "_price" can be landing price, afiliate_price or regular price
          // variable "product.price" is always regular price

          if (_price != null) {
            obj.price = _price;
          } else obj.price = product.price; //Este caso no debería saltar nunca.

          return obj;
        }

        function getProductCategories(categories) {
          var categoryList = '';

          angular.forEach(categories, function (cat, key) {
            categoryList += cat.text;

            if (key < categories.length - 1) {
              categoryList += '|';
            }
          });

          return categoryList;
        }

        function cartProductItems(products, voucher) {
          var productArray = [];

          if (products._name) {
            productArray.push(setCartProduct(products, voucher));
          } else {
            angular.forEach(products, function (value, key) {
              productArray.push(setCartProduct(value, voucher));
            });
          }

          return productArray;
        }

        function listProductItems(products, list) {
          var productArray = [];

          angular.forEach(products, function (value, key) {
            var obj = {
              name: value.name,
              id: value.reference || value.niceName,
              price: value.price,
              category: getProductCategories(value.category),
              list: list,
              position: value.positionImpression
                ? value.positionImpression
                : key + 1,
            };
            //console.log("Object 150 forEach: ", obj);
            productArray.push(obj);
          });

          return productArray;
        }
        //get all the value
        function getValue(products){
          var value = 0;

          angular.forEach(products, function (product, key) {
              value = value+ product.price;
            });
          return value;
        }

        //get all the value
        function transformNames(products){
          var items = [];

          angular.forEach(products, function (product, key) {
            var prod = {}
            prod.item_id = product.id;
            prod.item_name = product.name;
            prod.price = product.price;
            prod.item_category = product.category;
            prod.quantity = product.quantity;
            items.push(prod);
          });
          return items;
        }

        // state: add or remove
        // products: is one product
        self.addRemoveFromCart = function (state, products, voucher) {
          products = cartProductItems(products, voucher);

          var object = {
            
          };

          if (state == 'remove') {
            object.event = 'remove_from_cart';
            storeFactory.getConfiguration().then(function (store_config) {
              object.items = [];
              var currency = store_config.displayCurrency || 'EUR';
              object.currency = currency;
              object.value = getValue(products);
              object.items = transformNames(products);
              sendToAnalytics(object);
            });
            

            //sendToAnalytics(object);
          } else if (state == 'add') {
            //object.event = 'addToCart';
            object.event = 'add_to_cart';

            storeFactory.getConfiguration().then(function (store_config) {
              var currency = store_config.displayCurrency || 'EUR';

              object.items = [];
              object.currency = currency;
              object.value = getValue(products);
              
              object.items = transformNames(products);
              sendToAnalytics(object);
            });

            if ($window.sessionStorage.getItem('EXTERNAL_CAMPAIGN')) {
              var black = products.find(function (p) {
                return p.id === '923117000';
              });

              if (black) {
                sendToAnalytics({ event: 'externalCampaignAddToCart' });
              }
            }
          }
        };

        self.checkoutProcess = function (step, checkoutOption) {
          var products = cartProductItems(
            ngCart.getItems(),
            ngCart.getCoupon()
          );

          
          storeFactory.getConfiguration().then(function (store_config) {
            
            var currenc = store_config.displayCurrency || 'EUR';

            var it = transformNames(products);
            var object = {
              event: 'begin_checkout',
              currency: currenc,
              value: getValue(products),
              items: it
            };
            
            //if (checkoutOption) object.ecommerce.checkout.actionField.option = checkoutOption;

            sendToAnalytics(object);
            /// tipo de pago (stripe)
            /// direccion de envio igual a facturacion
            /// guest / usuario
          });          
        };

        self.checkoutOptions = function (step, checkoutOption) {
          var object = {
            event: 'checkoutOption',
            ecommerce: {
              checkout_option: {
                actionField: {
                  step: step,
                  option: checkoutOption,
                },
              },
            },
          };

          sendToAnalytics(object);
        };

        function get_utm_src() {
          var utm_src = '';
          var lts = $cookies.get('initialTrafficSource');

          if (lts) {
            lts.split('|').forEach(function (param) {
              if (param.startsWith('utmcsr')) utm_src = param.split('=')[1];
            });
          }

          return utm_src;
        }

        self.purchase = function (order) {
          var currency = '';
          storeFactory.getConfiguration().then(function (store_config) {
            currency = store_config.displayCurrency || 'EUR';
          });
          var affiliation = '';
          var AFFILIATE_MYCOOK = AffiliatesFactory.getActive();
          var UTM_SOURCE = get_utm_src();
          var products = cartProductItems(order.products, order.voucher);

          if (AFFILIATE_MYCOOK) {
            affiliation = AFFILIATE_MYCOOK;
          } else if (UTM_SOURCE) {
            affiliation = UTM_SOURCE;
          }
          var it = transformNames(products);
          var object = {
            //event: 'purchaseCompleted',
            event: 'purchase',
            currency: currency,
            transaction_id: order.id,
            value: order.total,
            shipping: order.total - order.totalWithoutShipping,
            items: it
          };

          if (
            order.voucher &&
            order.voucher.code &&
            order.voucher.status == 'applied'
          ) {
            object.coupon = order.voucher.code;
          }

          if ($window.sessionStorage.getItem('EXTERNAL_CAMPAIGN')) {
            var black = products.find(function (p) {
              return p.id === '923117000';
            });

            if (black) {
              sendToAnalytics({
                event: 'externalCampaignPurchase',
                externalCampaignOID: order.id,
                externalCampaignRevenue: (black.price * black.quantity) / 1.21,
              });
            }
          }

          sendToAnalytics(object);
        };

        this.addProductImpression = function (products, page) {
          storeFactory.getConfiguration().then(function (store_config) {
            var currency = store_config.displayCurrency || 'EUR';

            var object = {
              event: 'productImpressions',
              ecommerce: {
                currencyCode: currency,
                impressions: listProductItems(products, page),
              },
            };

            sendToAnalytics(object);
          });
        };

        this.addProductClick = function (product, position, list) {
          var object = {
            event: 'productClick',
            ecommerce: {
              click: {
                actionField: { list: list },
                products: [
                  {
                    name: product.name,
                    id: product.reference || product.niceName,
                    price: product.price,
                    category: getProductCategories(product.category),
                    position: position,
                  },
                ],
              },
            },
          };

          sendToAnalytics(object);
        };

        this.singleProductView = function (product, related) {
          var object = {
            event: 'productDetailView',
            ecommerce: {
              detail: {
                //'actionField': {'list': 'Apparel Gallery'}  // 'detail' actions have an optional list property.
                products: [
                  {
                    name: product.name,
                    id: product.reference || product.niceName,
                    price: product.price,
                    category: getProductCategories(product.category),
                  },
                ],
              },
            },
          };

          //if(related.length > 0) object.ecommerce.impressions = listProductItems(related, 'single-product-related');

          sendToAnalytics(object);
        };
      },
    ]);
})();
