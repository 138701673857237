(function () {
  'use strict';

  angular.module('mainApp')
    .controller('LoginController', [function ($scope, $location, $window, $rootScope, $routeParams, $timeout, _, usersManager) {

      $scope.login = function (user, pass, redirect, redirectParams) {
        usersManager.loginUser(user, pass).then(function (userInfo) {
          $scope.errorLogin = false;
          $rootScope.$emit('userLoggedIn', {
            'niceName': userInfo.niceName
          });

          $rootScope.$emit('legalTerms', {
            'data': userInfo
          });

          if (typeof (redirect) !== 'undefined') {
            if (typeof (redirectParams) === 'undefined') redirectParams = '';
            $location.path($rootScope.url(redirect, redirectParams));
          } else if($routeParams.redirect) {
            $window.location.href = $routeParams.redirect;
          }
        }, function (e) {
          $scope.errorLogin = true;

          $timeout(function () {
            $scope.errorLogin = false;
          }, 1500);
        });
      }
    }])
})();