(function () {
  'use strict';

  angular
    .module('mainApp')

    .controller('RecipesByTagsController', [
      '$rootScope',
      '$scope',
      '$filter',
      '$q',
      '$routeParams',
      'toolsService',
      'appConfig',
      'recipesFactory',
      'seoService',
      'recipeListService',
      '_',
      'sessionService',
      'serviceLandings',
      'richSearchService',
      '$timeout',
      function (
        $rootScope,
        $scope,
        $filter,
        $q,
        $routeParams,
        toolsService,
        appConfig,
        recipesFactory,
        seoService,
        recipeListService,
        _,
        sessionService,
        serviceLandings,
        richSearchService,
        $timeout
      ) {
        var criteria = {};
        var pageQuery = '?';
        var promises = [];
        var dieta = $routeParams.tipoDieta;
        var lastTag = $routeParams.tipoDieta;
        var currentPage = $routeParams.currentPage
          ? $routeParams.currentPage
          : 1;

        $scope.allowedCompats = appConfig.allowedCompats || ['S1'];

        //Allowed Compats
        var registeredWatch = $scope.$watch(
          'currentUser',
          function (currentUser) {
            if (currentUser) {
              if (!currentUser.role.includes('admin')) {
                $scope.allowedCompats = appConfig.allowedCompats || ['S1'];
              } else {
                $scope.allowedCompats = appConfig.allowedCompatsAdmin || [
                  'S1',
                  'S2',
                ];
              }
            }
          }
        );

        $scope.$on('$destroy', function () {
          registeredWatch();
        });

        $scope.currentPage = parseInt(currentPage);

        // Dieta deep linking
        if (!toolsService.isEmpty($routeParams.tipoDietaParam2)) {
          dieta += appConfig.itemSeparator + $routeParams.tipoDietaParam2;
          lastTag = $routeParams.tipoDietaParam2;
        }

        if (!toolsService.isEmpty($routeParams.tipoDietaParam3)) {
          dieta += appConfig.itemSeparator + $routeParams.tipoDietaParam3;
          lastTag = $routeParams.tipoDietaParam3;
        }

        $scope.showDeleteDialog = function (niceName, title) {
          recipeListService.showDeleteDialog(niceName, title);
        };

        $scope.showPublishDialog = function (recipe, title) {
          recipeListService.showPublishDialog(
            recipe,
            title,
            $scope.deviceDetector.raw.userAgent
          );
        };

        promises.push(recipesFactory.getTags());

        if (appConfig.webSettings.richSearch)
          promises.push(richSearchService.getByTerm(lastTag, 'tag'));

        $q.all(promises).then(function (results) {
          var tagsBundle = results[0];
          var richSearch = results[1];

          $scope.tags = tagsBundle.tags;
          $scope.tagsDietas = tagsBundle.tagsDietas;

          // ÑAPA (aunque es mejor que ir paseando el '/' por las urls...) -> hablar con Juan para que lo cambie
          var strRgx = new RegExp('\\' + appConfig.itemSeparator, 'gi');
          criteria.tags = [
            $scope.tags[dieta].replace(strRgx, appConfig.decorator),
          ];

          var tagLandingsCounter = sessionService.getValue(
            'tag-' + criteria.tags[0],
            'session'
          );
          console.log(
            'tagLandingsCounter ',
            tagLandingsCounter,
            'tag-' + criteria.tags[0]
          );
          var offSetItems = tagLandingsCounter ? tagLandingsCounter : 0;
          console.log('offSetItems at ' + dieta, offSetItems);

          if (
            ($scope.currentPage == 1 || !tagLandingsCounter) &&
            appConfig.webSettings.landingsAtRecipesList
          ) {
            // get Landings & ebooks
            var landingProjection = {
              title: 1,
              img: 1,
              niceName: 1,
              type: 1,
              shortDescription: 1,
              _id: 0,
            };

            serviceLandings
              .getLandingsByTerm(criteria.tags[0], 'tags', landingProjection)
              .then(function (landingsBundle) {
                loadRecipes(offSetItems, richSearch, landingsBundle);
              });
          } else loadRecipes(offSetItems, richSearch);
        });

        function loadRecipes(offSetItems, richSearch, landingsBundle) {
          var orderInfo = recipeListService.setOrderFilters(
            criteria,
            pageQuery
          );
          pageQuery = orderInfo.pageQuery;
          criteria = orderInfo.criteria;

          $scope.pageQuery = pageQuery;
          $scope.criteria = criteria;

          if (
            $scope.currentPage == 1 &&
            landingsBundle &&
            landingsBundle.status.count > 0
          ) {
            landingsBundle.results = toolsService.getRandomValuesFromArray(
              landingsBundle.results,
              3
            );
            offSetItems = landingsBundle.results.length;

            _.map(landingsBundle.results, function (obj) {
              if (obj.type == 'ebook')
                obj.niceName_noebook = obj.niceName.replace('ebook-', '');
              return obj;
            });
          }

          // Save counter in session
          sessionService.setValue(
            'tag-' + criteria.tags[0],
            offSetItems,
            'session'
          );

          $scope.$emit('LOAD');

          recipesFactory
            .getRecipesByCriteria(criteria, $scope.currentPage, offSetItems)
            .then(function (recipes) {
              var cTags = criteria.tags[0];
              var pageTitle = _.replace(cTags, new RegExp('/', 'g'), ' - ');
              var pageDescription = $filter('translateVariables')(
                $rootScope.translations.seo.tagsRecipeList.description,
                pageTitle
              );

              setSeo(
                pageTitle,
                pageDescription,
                richSearch,
                recipes.status.count
              );

              if (richSearch) {
                $scope.recipesTitle = richSearch.title;
                $scope.richSearch = richSearch;
              } else {
                $scope.recipesTitle = pageTitle;
              }

              var bundleRecipesAndLandings = recipes.result;

              // SET LANDING DATA TO VIEW
              if (
                $scope.currentPage == 1 &&
                landingsBundle &&
                landingsBundle.status.count > 0
              ) {
                bundleRecipesAndLandings = toolsService.pushElementsAtPositions(
                  recipes.result,
                  landingsBundle.results
                );
              }

              $scope.contentData = bundleRecipesAndLandings;

              // Paginacion
              var _maxCount = recipes.status.count;
              if (offSetItems > 0) _maxCount += offSetItems;
              $scope.maxCount = _maxCount;
              $scope.itemsByPage = appConfig.itemsByPage;
              $scope.lastPage =
                _maxCount > appConfig.itemsByPage
                  ? Math.ceil(_maxCount / appConfig.itemsByPage)
                  : 1;

              seoService.setPrevPage($scope.currentPage, $scope.pageQuery);
              seoService.setNextPage(
                $scope.currentPage,
                $scope.pageQuery,
                $scope.lastPage
              );

              $scope.$emit('UNLOAD');

              function setSeo(
                pageTitle,
                pageDescription,
                richSearch,
                recipesCount
              ) {
                if (richSearch && richSearch.seo) {
                  if (richSearch.seo.title)
                    pageTitle = $filter('translateVariables')(
                      richSearch.seo.title,
                      recipesCount
                    );
                  if (richSearch.seo.description)
                    pageDescription = richSearch.seo.description;
                }

                seoService.setTitle(pageTitle, $scope.currentPage);
                seoService.setDescription(pageDescription, $scope.currentPage);
              }
            })
            .catch(function (error) {
              toolsService.manageError(
                'RecipesByTagsController > ERROR: ',
                error
              );
            });
        }

        $scope.showDietPlanDialog = function (recipe) {
          $scope.recipeToDietPlan = recipe;
          $scope.showDietPlan = true;
        };

        $scope.closeDietPlanDialog = function () {
          $scope.recipeToDietPlan = null;
          $scope.showDietPlan = false;
        };
      },
    ]);
})();
