(function () {
  'use strict';

  angular.module('mainApp')
    .controller('DraftController', [function ($scope, $rootScope, $filter, usersManager, webStorage) {

      var userNiceName = webStorage.get('userNiceName');
      $scope.totalDraftRecipes = 0;
      $scope.draftWarning = true;

      getDraftRecipes();

      $scope.$watch('currentUser.niceName', function (currentUserNiceName) {
        userNiceName = currentUserNiceName;
        getDraftRecipes();
      });

      $rootScope.$on('updateDraftRecipes', function (e) {
        getDraftRecipes();
      });

      function getDraftRecipes() {
        if (userNiceName) {
          usersManager.getDraftRecipes(userNiceName).then(function (result) {
            $scope.totalDraftRecipes = result.draftCount;
            $rootScope.$broadcast('updatedDraftRecipes', result.draftCount);
            $scope.txt = $filter('translateVariables')($rootScope.translations.recipe_list.draft_warning, result.draftCount);
          });
        } else {
          $scope.totalDraftRecipes = 0;
        }
      }

      $scope.closeAlert = function () {
        $scope.draftWarning = false;
      }
    }]);
})();