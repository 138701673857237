(function () {
  'use strict';

  angular
    .module('mainApp')

    .controller('RecipesBySearchController', [
      '$rootScope',
      '$scope',
      '$filter',
      '$q',
      '$routeParams',
      '$location',
      '_',
      'toolsService',
      'appConfig',
      'recipesFactory',
      'seoService',
      'recipeListService',
      'richSearchService',
      'sessionService',
      'serviceLandings',
      '$timeout',
      function (
        $rootScope,
        $scope,
        $filter,
        $q,
        $routeParams,
        $location,
        _,
        toolsService,
        appConfig,
        recipesFactory,
        seoService,
        recipeListService,
        richSearchService,
        sessionService,
        serviceLandings,
        $timeout
      ) {
        var promises = [];
        var criteria = {};
        var term = $routeParams.term ? $routeParams.term : '';
        var currentPage = $routeParams.currentPage
          ? $routeParams.currentPage
          : 1;

        $scope.allowedCompats = appConfig.allowedCompats || ['S1'];

        //Allowed Compats
        var registeredWatch = $scope.$watch(
          'currentUser',
          function (currentUser) {
            if (currentUser) {
              if (!currentUser.role.includes('admin')) {
                $scope.allowedCompats = appConfig.allowedCompats || ['S1'];
              } else {
                $scope.allowedCompats = appConfig.allowedCompatsAdmin || [
                  'S1',
                  'S2',
                ];
              }
            }
          }
        );

        $scope.$on('$destroy', function () {
          registeredWatch();
        });

        $scope.currentPage = parseInt(currentPage);

        $scope.showDeleteDialog = function (niceName, title) {
          recipeListService.showDeleteDialog(niceName, title);
        };

        $scope.showPublishDialog = function (recipe, title) {
          recipeListService.showPublishDialog(
            recipe,
            title,
            $scope.deviceDetector.raw.userAgent
          );
        };

        promises.push(recipesFactory.getTags());
        if (appConfig.webSettings.richSearch)
          promises.push(richSearchService.getByTerm(term, 'search'));

        var searchLandingsCounter = sessionService.getValue(
          'terms-' + term,
          'session'
        );
        var offSetItems = searchLandingsCounter ? searchLandingsCounter : 0;

        if (
          ($scope.currentPage == 1 || !searchLandingsCounter) &&
          appConfig.webSettings.landingsAtRecipesList
        ) {
          // get Landings & ebooks
          var landingProjection = {
            title: 1,
            img: 1,
            niceName: 1,
            type: 1,
            shortDescription: 1,
            _id: 0,
          };

          promises.push(
            serviceLandings.getLandingsByTerm(term, 'terms', landingProjection)
          );
        }

        criteria = _.clone($location.$$search, true);
        criteria['status.idParent'] = '';
        $scope.criteria = criteria;

        $q.all(promises).then(function (results) {
          var tagsBundle = results[0];
          var richSearch = results[1];
          if (results[2]) var landingsBundle = results[2];

          $scope.tags = tagsBundle.tags;
          $scope.tagsDietas = tagsBundle.tagsDietas;

          if (
            $scope.currentPage == 1 &&
            landingsBundle &&
            landingsBundle.status.count > 0
          ) {
            landingsBundle.results = toolsService.getRandomValuesFromArray(
              landingsBundle.results,
              3
            );
            offSetItems = landingsBundle.results.length;

            _.map(landingsBundle.results, function (obj) {
              if (obj.type == 'ebook')
                obj.niceName_noebook = obj.niceName.replace('ebook-', '');
              return obj;
            });
          }

          // Save counter in session
          sessionService.setValue('terms-' + term, offSetItems, 'session');

          $scope.$emit('LOAD');

          recipesFactory
            .searchRecipes(term, criteria, $scope.currentPage, offSetItems)
            .then(function (recipes) {
              var pageTitle = $filter('translateVariables')(
                $rootScope.translations.seo.searchResult.title,
                term
              );
              var pageDescription =
                $rootScope.translations.seo.searchResult.description;

              // change custom contents by rich search
              setSeo(
                pageTitle,
                pageDescription,
                richSearch,
                recipes.status.count
              );

              if (richSearch) {
                $scope.recipesTitle = richSearch.title;
                $scope.richSearch = richSearch;
              } else {
                $scope.recipesTitle = pageTitle;
              }

              var bundleRecipesAndLandings = recipes.result;

              // SET LANDING DATA TO VIEW
              if (landingsBundle && landingsBundle.status.count > 0) {
                bundleRecipesAndLandings = toolsService.pushElementsAtPositions(
                  recipes.result,
                  landingsBundle.results
                );
              }

              $scope.contentData = bundleRecipesAndLandings;

              // Paginacion
              var _maxCount = recipes.status.count;
              if (offSetItems > 0) _maxCount += offSetItems;
              $scope.maxCount = _maxCount;
              $scope.itemsByPage = appConfig.itemsByPage;
              $scope.lastPage =
                _maxCount > appConfig.itemsByPage
                  ? Math.ceil(_maxCount / appConfig.itemsByPage)
                  : 1;

              seoService.setPrevPage($scope.currentPage);
              seoService.setNextPage($scope.currentPage, null, $scope.lastPage);

              $scope.$emit('UNLOAD');
            })
            .catch(function (error) {
              toolsService.manageError(
                'RecipesBySearchController > getRecipesByCriteria > ERROR1: ',
                error
              );
            });

          function setSeo(
            pageTitle,
            pageDescription,
            richSearch,
            recipesCount
          ) {
            if (richSearch && richSearch.seo) {
              if (richSearch.seo.title)
                pageTitle = $filter('translateVariables')(
                  richSearch.seo.title,
                  recipesCount
                );
              if (richSearch.seo.description)
                pageDescription = richSearch.seo.description;
            }

            seoService.setTitle(pageTitle, $scope.currentPage);
            seoService.setDescription(pageDescription, $scope.currentPage);
          }
        });

        $scope.showDietPlanDialog = function (recipe) {
          $scope.recipeToDietPlan = recipe;
          $scope.showDietPlan = true;
        };

        $scope.closeDietPlanDialog = function () {
          $scope.recipeToDietPlan = null;
          $scope.showDietPlan = false;
        };
      },
    ]);
})();
