
(function(){

  'use strict';
  
  angular.module('mainApp')
  
    .controller("LandingsComprarRonerController", ['$scope', '$route', '$log', 'storeFactory', 'seoService', 'serviceStoreAnalytics',
      function ($scope, $route, $log, storeFactory, seoService, serviceStoreAnalytics) {
        $log.debug("LandingsComprarRonerController", $route.current.name, $route.current.originalPath);

        var seoInfo = seoService.searchSeoAtTranslationsLandings();

        if (seoInfo) {
          seoService.setTitle(seoInfo.title);
          seoService.setDescription(seoInfo.description);
        }

        $scope.email_stock = '';

        $scope.submitStockForm = function(email, niceName, price) {
          var data = {
            "email": email,
            "formId": '29',
            "modelo_mycook": niceName,
            "precio": price
          };

          var productUrl = $location.path();
          serviceMautic.sendToMautic(data, '', productUrl).then(function(response) {
            $scope.email_stock = '';
            $scope.formMessageOK = true;
            $scope.formMessageKO = false;
          }, function(error) {
            $log.debug(error);
            $scope.formMessageKO = true;
            $scope.formMessageOK = false;
          });
        };

        storeFactory.getProduct('roner-clip').then(function (result) {
          $scope.product = result;
  
          $scope.slickConfig = {
            method: {},
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  centerMode: true,
                  slidesToShow: 3,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          };
        });
    }]);
  })();