(function () {
  angular.module('mainApp')

    .service('followersService', function ($q, $http, appConfig) {

      var getListFollowers = function (niceName) {
        var urlDestino = appConfig.mycookTouchDomain + '/user/' + niceName + '/followers';
        var deferred = $q.defer();

        $http({
          method: 'get',
          url: urlDestino
        }).then(function (res) {
          var result = res.data;

          deferred.resolve(result);
        }, function (error) {
          deferred.reject(error);
        });

        return deferred.promise;
      };

      var addFollowing = function (niceName, followingNiceName) {
        var urlDestino = appConfig.mycookTouchDomain + '/user/' + niceName + '/following/' + followingNiceName;
        var deferred = $q.defer();

        $http({
          method: 'post',
          url: urlDestino
        }).then(function (res) {
          var result = res.data;

          deferred.resolve(result);
        }, function (error) {
          deferred.reject(error);
        });

        return deferred.promise;
      };

      var removeFollowing = function (niceName, followingNiceName) {
        var urlDestino = appConfig.mycookTouchDomain + '/user/' + niceName + '/following/' + followingNiceName;
        var deferred = $q.defer();

        $http({
          method: 'delete',
          url: urlDestino
        }).then(function (res) {
          var result = res.data;

          deferred.resolve(result);
        }, function (error) {
          deferred.reject(error);
        });

        return deferred.promise;
      };

      return {
        getList: getListFollowers,
        addFollowing: addFollowing,
        removeFollowing: removeFollowing
      };
    });
})();
