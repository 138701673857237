(function () {
  angular.module('mainApp').controller('DietPlanCfgController', [
    '$scope',
    'DietPlanFactory',
    'toolsService',
    '$rootScope',
    '$location',
    function ($scope, DietPlanFactory, toolsService, $rootScope, $location) {
      var emptyMeals = [
        {
          name: 'breakfast',
          courses: [],
        },
        {
          name: 'morning_snack',
          courses: [],
        },
        {
          name: 'lunch',
          courses: [],
        },
        {
          name: 'afternoon_snack',
          courses: [],
        },
        {
          name: 'dinner',
          courses: [],
        },
      ];

      $scope.title = 'Diet Plan Configuration';

      $scope.dishesOpt = [
        {
          name: $rootScope.translations.diet_plan.meals.breakfast,
          id: 'Breakfast',
        },
        {
          name: $rootScope.translations.diet_plan.meals.morning_snack,
          id: 'Morning snack',
        },
        {
          name: $rootScope.translations.diet_plan.meals.starter_dish,
          id: 'Starter dish',
        },
        {
          name: $rootScope.translations.diet_plan.meals.main_dish,
          id: 'Main dish',
        },
        {
          name: $rootScope.translations.diet_plan.meals.desert,
          id: 'Desert',
        },
        {
          name: $rootScope.translations.diet_plan.meals.afternoon_snack,
          id: 'Afternoon snack',
        },
      ];

      $scope.selects = [];
      var usePrefs;

      DietPlanFactory.getConfig().then(function (cfg) {
        $scope.conf = cfg.meals;
        $scope.mealsPerDay = cfg.mealsPerDay.toString();
        usePrefs = cfg.usePrefs;
      }, errorHandler);

      $scope.translateCourse = function (course) {
        var elem = $scope.dishesOpt.find(function (d) {
          return d.id == course;
        });
        return elem ? elem.name : 'null';
      };

      $scope.setMealsPerDay = function (mpd) {
        $scope.mealsPerDay = mpd;
      };

      $scope.changeSelect = function (value, index) {
        $scope.selects[index] = value;
      };

      $scope.removeCfg = function (name, idx) {
        var cfg = $scope.conf.find(function (c) {
          return c.name == name;
        });

        if (cfg) cfg.courses.splice(idx, 1);
      };

      $scope.addCfg = function (name, index) {
        if ($scope.selects[index]) {
          var cfg = $scope.conf.find(function (c) {
            return c.name == name;
          });

          if (cfg)
            cfg.courses.push({ type: 'mycook', course: $scope.selects[index] });
        }
      };

      $scope.removeAll = function () {
        $scope.conf = emptyMeals;
      };

      $scope.save = function () {
        DietPlanFactory.setConfig(
          $scope.conf,
          $scope.mealsPerDay,
          usePrefs
        ).then(function (resp) {
          $location.path($rootScope.url('dietPlan'));
        }, errorHandler);
      };

      function errorHandler(err) {
        toolsService.manageError('DietPlanCfgController: ', err);
      }
    },
  ]);
})();
