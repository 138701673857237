(function () {

  'use strict';

  angular.module('mainApp')

    .controller("AdvancedSearchController", ['$scope', '$rootScope', '$sce', '$location', '$timeout', '$q', '$log', '$document', '$window', '_', 'appConfig', 'toolsService', 'recipeCategoriesFactory', 'recipesFactory', 'ingredientsManager',
      function ($scope, $rootScope, $sce, $location, $timeout, $q, $log, $document, $window, _, appConfig, toolsService, recipeCategoriesFactory, recipesFactory, ingredientsManager) {

        $scope.pageQuery = pageQuery;
        $scope.currentPage = 1;
        $scope.maxCount = 1;
        $scope.itemsByPage = 20;
        $scope.items = [];
        $scope.loading = true;
        $scope.buttonLoadMore = false;

        // assignar get query to searchForm
        var searchCriteria = {};
        searchCriteria = _.clone($location.$$search, true);

        if (!toolsService.isEmpty(searchCriteria.tags)) {
          searchCriteria.tags = searchCriteria.tags.split(appConfig.filterSeparator);
        } else searchCriteria.tags = [];

        if (!toolsService.isEmpty(searchCriteria.ingredients) && searchCriteria.ingredients.length > 0) {
          searchCriteria.ingredients = searchCriteria.ingredients.split(appConfig.filterSeparator);
        } else searchCriteria.ingredients = [];

        if(searchCriteria.verify =='true') {
          searchCriteria.verified = 'true';
        } else {
          searchCriteria.verified = '';
        }
        
        if (!toolsService.isEmpty(searchCriteria.compatibility)) {
          searchCriteria.compatibility = searchCriteria.compatibility.split(appConfig.filterSeparator);
        } else {
          searchCriteria.compatibility = [];
        }

        $log.debug("AdvancedSearchController > SEARCHCRITERIA: ", searchCriteria);
        $scope.searchForm = searchCriteria;

        var criteria = {};
        var pageQuery = "?";
        var searchTimer;

        pageQuery += toolsService.fromObjToQuery($location.$$search, []);
        // recibo parametros de busqueda
        criteria = _.clone($location.$$search, true);

        $scope.$watch('searchForm', function () {
          $log.debug('--------> searchForm HAS CHANGED!');

          // reset variables before search
          $scope.buttonLoadMore = false;
          $scope.currentPage = 1;
          $scope.maxCount = 1;
          $scope.loading = true;
          $scope.items = [];

          manageCriteria();

          // Go to top
          $('html, body').animate({
            scrollTop: 0
          }, 500);
        }, true);

        $scope.loadMore = function () {
          $scope.loading = true;
          $scope.currentPage++;

          manageCriteria();
        };

        function manageCriteria() {
          criteria = _.cloneDeep($scope.searchForm);

          $timeout.cancel(searchTimer); // cancelo timer anterior

          searchTimer = $timeout(function () {
            // clean url when it's empty
            if (criteria.tags.length == 0) _.unset(criteria, 'tags');
            if (criteria.ingredients.length == 0) _.unset(criteria, 'ingredients');

            $log.debug('AdvancedSearchController > Empiezo a buscar!', criteria);

            var terms = criteria.terms;
            if(terms) terms = terms.toLowerCase();

            // change URL
            $location.path($rootScope.url('advancedSearchResult'))
              .search('terms', terms)
              .search('category', criteria.category)
              .search('tags', criteria.tags ? criteria.tags.join(',') : '')
              .search('difficulty', criteria.difficulty)
              .search('price', criteria.price)
              .search('temperature', criteria.temperature)
              .search('sortBy', criteria.sortBy)
              .search('ingredients', criteria.ingredients ? criteria.ingredients.join(',') : '')
              .search('timeLength', criteria.timeLength)
              .search('verify', criteria.verified);

            // send tags with format XXX/XXX/XXX
            if (criteria.tags) {
              var strRgx2 = new RegExp("\\" + appConfig.itemSeparator, "gi");
              criteria.tags.forEach(function (tag, index) {
                criteria.tags[index] = _.replace($scope.originalTags[tag], strRgx2, appConfig.decorator);
              });
            }

            // search
            getRecipesByCriteria(criteria, $scope.currentPage, 0, $scope.itemsByPage);
          }, 1000);
        }

        function getRecipesByCriteria(criteria, currentPageNum) {
          $scope.$emit('LOAD');

          // NUM PAX (default: recipes without PAX)
          criteria['status.idParent'] = "";

          if (criteria.compatibility.includes('S0')) {
            criteria.compatibility.splice(criteria.compatibility.indexOf('S0'), 1);

            if (!criteria.compatibility.includes('S1')) {
              criteria.compatibility.push('S1');
            }
          }

          var promise;

          if (criteria['ingredients'] && criteria['ingredients'].length > 0) {
            //delete criteria["terms"];
            promise = recipesFactory.getRecipesByCriteria(criteria, currentPageNum, 0 ,$scope.itemsByPage);
          } else promise = recipesFactory.searchRecipes(criteria["terms"], criteria, currentPageNum, 0, $scope.itemsByPage);

          promise.then(function (responseBundle) {
              $log.debug("AdvancedSearchController > getRecipesByCriteria SUCCESS: ", responseBundle, " Current page: ", currentPageNum, " Total pages: ", $scope.maxCount);
              // Aplicamos modificaciones a la respuesta para darle formato
              var _items = responseBundle.result;
              if (currentPageNum == 1) $scope.items = [];
              for (var i = 0; i < _items.length; i++) {
                $scope.items.push(_items[i]);
              }

              // Paginacion
              var _maxCount = responseBundle.status.count;
              $scope.maxCount = Math.ceil(_maxCount / $scope.itemsByPage); // usado en la ficha de autor
              $log.debug("AdvancedSearchController > getRecipesByCriteria - Paginacion", responseBundle.status.count, $scope.itemsByPage, $scope.maxCount, $scope.currentPage);

              if ($scope.currentPage < $scope.maxCount) {
                $scope.buttonLoadMore = true;
              } else {
                $scope.buttonLoadMore = false;
              }
              $scope.$emit('UNLOAD');
              $scope.loading = false;

            }).catch(function (error) {
              $log.error("AdvancedSearchController > getRecipesByCriteria > ERROR1: ", error);
              $scope.loading = false;
            });
        }

        // mass autocomplete
        $scope.input_ingredient = {
          selected: undefined
        };

        $scope.removeIngredient = function (ingredient) {
          _.pull($scope.searchForm.ingredients, ingredient);
        };

        function add_ingredient(selected) {
          $scope.searchForm.ingredients.push(selected.value);
          $scope.input_ingredient.selected = "";
        }

        $scope.ac_options = {
          suggest: suggest_ingredients_remote,
          on_select: add_ingredient
        };

        $scope.ac_option_highlight = {
          suggest: suggest_ingredients_remote,
          on_error: console.log
        };

        function suggest_ingredients(term, ingredients) {
          var fuzzySearch = new Fuse(ingredients, {
            shouldSort: true,
            caseSensitive: false,
            threshold: 0.4,
          });

          var q = term.toLowerCase().trim();

          return fuzzySearch
            .search(q)
            .slice(0, 10)
            .map(function (i) {
              var val = ingredients[i];
              return {
                value: val,
                label: $sce.trustAsHtml(highlight(val, term))
              };
            });
        }

        function highlight(str, term) {
          var highlight_regex = new RegExp('(' + term + ')', 'gi');
          return str.replace(highlight_regex, '<strong>$1</strong>');
        }

        function suggest_ingredients_remote(term) {
          if (term.length < 2)
            return;

          var deferred = $q.defer();
          ingredientsManager.getIngredients().then(function (ingredients) {
            deferred.resolve(suggest_ingredients(term, ingredients));
          });
          return deferred.promise;
        }

        // Sort by criteria
        $scope.sortBy = function (value) {
          $scope.searchForm.sortBy = value;
          $scope.searchForm['verified'] = '';
        };
        // End Sort by criteria

        // filter by criteria
        $scope.filterBy = function (value) {
          $scope.searchForm[value] = 'true';
          $scope.searchForm.sortBy = 'recent';
        };
        // End filter by criteria

        // Get Price - Difficulty recipe text
        $scope.currentPriceDifficultyTitle = function (current, type) {
          switch (current) {
            case 1:
              current = 'low';
              break;
            case 2:
              current = 'medium';
              break;
            case 3:
              current = 'high';
              break;
          }
          return $rootScope.translations.common[type + '_' + current];
        };

        // FEATURE: pasar a filterSvc
        $scope.difficultyRank = [{
            k: '1',
            v: $rootScope.translations.common.difficulty_low
          },
          {
            k: '2',
            v: $rootScope.translations.common.difficulty_medium
          },
          {
            k: '3',
            v: $rootScope.translations.common.difficulty_high
          }
        ];

        $scope.priceRank = [{
            k: '1',
            v: $rootScope.translations.common.price_low
          },
          {
            k: '2',
            v: $rootScope.translations.common.price_medium
          },
          {
            k: '3',
            v: $rootScope.translations.common.price_high
          }
        ];

        $scope.timeLengthRank = [{
            k: '1',
            v: $rootScope.translations.advanced_search.timelength_15m
          },
          {
            k: '2',
            v: $rootScope.translations.advanced_search.timelength_30m
          },
          {
            k: '3',
            v: $rootScope.translations.advanced_search.timelength_1h
          },
          {
            k: '4',
            v: $rootScope.translations.advanced_search.timelength_plus1h
          }
        ];

        recipeCategoriesFactory.getCategories().then(function (cats) {
          $scope.categories = cats;
        });

        $scope.triggerTag = function (tag) {
          var foundTag = $scope.searchForm.tags.indexOf(tag.path);
          if (foundTag === -1) {
            $scope.searchForm.tags.push(tag.path);
            tag.checked = true;
          } else {
            $scope.searchForm.tags.splice(foundTag, 1);
            tag.checked = false;
          }
        };

        // recupero els tags
        recipesFactory.getTags().then(function (tagsBundle) {
          $scope.originalTags = tagsBundle.tags;
          var tags = arrayToAngular2(tagsBundle.tags);
          $log.debug("AdvancedSearchController > getTags: ", tags);

          // when tags does not have a parent
          var tagsCopy = _.cloneDeep(tags);
          var emptyTag = {
            "name": $rootScope.translations.common.tags,
            "checked": false,
            "children": []
          };
          tags.forEach(function (tag, index) {
            if (tag.children.length == 0) {
              tagsCopy = tagsCopy.splice(index, 1);
              emptyTag.children.push(tags[index]);
            }
          });
          if (emptyTag.children.length > 0) tagsCopy.push(emptyTag);
          $scope.tags = tagsCopy;
          $log.debug("AdvancedSearchController > getTags After manage: ", $scope.tags);
          // when tags does not have a parent
        });

        function arrayToAngular2(array, check) {
          var op = 'uncheck';
          if (check !== undefined) {
            op = check;
          }

          var tree = [];
          var strRgx2 = new RegExp("\\" + appConfig.itemSeparator, "gi");
          for (var i in array) {
            var path = array[i];
            _angularToggle2(tree, path.split(appConfig.itemSeparator), 0, op, null, i);
          }
          return tree;
        }

        function findNode2(array, name) {
          for (var i = 0; i < array.length; i++) {
            var node = array[i];
            if (node.name == name) {
              return i;
            }
          }
          return -1;
        }

        function _angularToggle2(tree, segments, level, op, param, key) {
          var pos;
          var node;

          if (segments.length - 1 == level) {
            pos = findNode2(tree, segments[level]);
            if (pos == -1) {
              node = {};
              node.name = segments[level];
              node.checked = ($scope.searchForm.tags.indexOf(key) === -1) ? false : true;
              node.path = key;
              node.children = [];
              tree.push(node);
              pos = tree.length - 1;
            }
            tree[pos].checked = ($scope.searchForm.tags.indexOf(key) === -1) ? false : true;
            if (op === 'get') {
              return tree[pos].children;
            } else if (op === 'put') {
              tree[pos].children = param;
            }
          } else {
            pos = findNode2(tree, segments[level]);
            if (pos == -1) {
              node = {};
              node.name = segments[level];
              node.checked = false;
              node.children = [];
              node.path = key;
              tree.push(node);
              pos = tree.length - 1;
            }
            _angularToggle2(tree[pos].children, segments, level + 1, op, param, key);
          }
          return tree;
        }

        // En mobile abrir formulario
        $scope.formToggle = true;
        if ($document[0].body.clientWidth < 768) {
          $scope.formToggle = false;
        }

        // resize página para saber cuando mostramos el botón filtrar en mobile
        angular.element($window).bind('resize', function () {
          if ($document[0].body.clientWidth >= 768) {
            $scope.formToggle = true;
          }
        });

        $scope.triggerForm = function (action) {
          if (action == 'open') {
            $scope.formToggle = true;
            $('html, body').animate({
              scrollTop: 0
            }, 500);
          } else { // close
            $scope.formToggle = false;
          }
        };

        $scope.selectMachineFilter = function (machine) {
          if ($scope.searchForm.compatibility.includes(machine)) {
            $scope.searchForm.compatibility.splice($scope.searchForm.compatibility.indexOf(machine), 1);
          } else {
            $scope.searchForm.compatibility.push(machine);
          }
        };

        $scope.checkMachineSelected= function (machine) {
          return $scope.searchForm.compatibility.includes(machine);
        };

        $scope.showDietPlanDialog = function(recipe) {

          $scope.recipeToDietPlan = recipe;
          $scope.showDietPlan = true;

        }

        $scope.closeDietPlanDialog = function() {

          $scope.recipeToDietPlan = null;
          $scope.showDietPlan = false;

        }
      }
    ]);
})();