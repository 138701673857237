(function () {
  angular.module('mainApp')
    .controller('BlogController', [function ($scope, $routeParams, $rootScope, $filter, $location, appConfig, serviceApiBlog, saRouter, toolsService, _) {

      var currentDomain = $location.path();
      currentDomain = currentDomain.replace(/\//, "");
      currentDomain = currentDomain.split("/");

      var category = $routeParams.categoryNiceName;
      var searchText = $routeParams.search;

      $scope.currentDomain = (category && category != '') ? currentDomain[0] + "/" + currentDomain[1] + '/' + category : 'blog';

      if (searchText) {
        $scope.pageQuery = '?search=' + searchText;
      }

      $scope.absolut = appConfig.templatesPrefix;
      $routeParams.pageNum == undefined || $routeParams.pageNum < 1 || isNaN($routeParams.pageNum) ? $scope.currentPage = 1 : $scope.currentPage = parseInt($routeParams.pageNum);
      $scope.itemsByPageBlog = 12;
      $scope.firstPage = 1;

      serviceApiBlog.getPosts(category, searchText, ($scope.currentPage - 1) * $scope.itemsByPageBlog, $scope.itemsByPageBlog).then(function (data) {

        var totalPosts = data.status.search_count;
        var result = data.result;

        //Pagination
        var _maxCount = totalPosts;
        $scope.maxCount = _maxCount;

        //TODO: posar el itemsByPageBlog com a element dels settings a la consola
        $scope.lastPage = (_maxCount > $scope.itemsByPageBlog) ? Math.ceil(_maxCount / $scope.itemsByPageBlog) : 1;
        $scope.showPosts = result;

        var prevPageNum = ($scope.currentPage - 1 > 0) ? $scope.currentPage - 1 : null;
        var nextPageNum = ($scope.currentPage + 1 <= $scope.lastPage) ? $scope.currentPage + 1 : null;
        var locationUrl = $location.protocol() + '://' + $location.host();

        if (category) {
          if (prevPageNum) $rootScope.prevPage = locationUrl + $rootScope.url('blogPaginationCategory', {
            categoryNiceName: category,
            pageNum: prevPageNum
          });
          if (nextPageNum) $rootScope.nextPage = locationUrl + $rootScope.url('blogPaginationCategory', {
            categoryNiceName: category,
            pageNum: nextPageNum
          });
        } else {
          if (prevPageNum) $rootScope.prevPage = locationUrl + $rootScope.url('blogPagination', {
            pageNum: prevPageNum
          }) + $scope.pageQuery;
          if (nextPageNum) $rootScope.nextPage = locationUrl + $rootScope.url('blogPagination', {
            pageNum: nextPageNum
          }) + $scope.pageQuery;

          if ($scope.currentPage > 1) {
            var seo_title = $filter('translateVariables')($rootScope.translations.seo['blogPagination']['title'], $scope.currentPage);
            seo_title += ' ' + $rootScope.translations.seo.separator + ' ' + $rootScope.translations.common.page + ' ' + $scope.currentPage;
            seo_title += ' ' + $rootScope.translations.seo.separator + ' ' + $rootScope.translations.seo.website_name;
            $rootScope.pageTitle = seo_title;
            $rootScope.pageDescription += ' ' + $rootScope.translations.common.page + ' ' + $scope.currentPage;
          }
        }

        for (var i = 0; i < $scope.showPosts.length; i++) {
          $scope.showPosts[i].url = getPostUrl($scope.showPosts[i].cms.url.slug);

          // replace spaces by %20 of image url
          if ($scope.showPosts[i].imagePreview) $scope.showPosts[i].imagePreview = $scope.showPosts[i].imagePreview.replace(/ /g, '%20');

          if (!$scope.showPosts[i].social || !$scope.showPosts[i].social.comments) {
            $scope.showPosts[i].social = {
              "comments": 0
            };
          }
          for (var j = 0; j < $scope.showPosts[i].category.length; j++) {
            $scope.showPosts[i].category[j].url = getCategoryUrl($scope.showPosts[i].category[j]);
          }
        }
      });

      $scope.searchPosts = function (text) {
        serviceApiBlog.searchPosts(text);
      }

      serviceApiBlog.getCategories().then(function (result) {
        $scope.categoriesBlog = result;

        if (category) {
          var categoryDisplayName;
          var categoryDescription;

          var category_info = _.find(result, {'cms': {'url': {'slug': category}}});
          
          if (category_info) {
            categoryDisplayName = category_info.text;

            $scope.blogSubtitle = categoryDisplayName;

            if (!toolsService.isEmpty(category_info.cms.seo.description)) {
              categoryDescription = category_info.cms.seo.description;
            } else {
              categoryDescription = $filter('translateVariables')($rootScope.translations.seo.blogCategory.description, category_info.text);
            }

            var seo_title = $filter('translateVariables')($rootScope.translations.seo.blogCategory.title, categoryDisplayName);

            if ($scope.currentPage > 1) {
              seo_title += ' ' + $rootScope.translations.seo.separator + ' ' + $rootScope.translations.common.page + ' ' + $scope.currentPage;
              categoryDescription += ' ' + $rootScope.translations.common.page + ' ' + $scope.currentPage;
            }

            seo_title += ' ' + $rootScope.translations.seo.separator + ' ' + $rootScope.translations.seo.website_name;
            $rootScope.pageTitle = seo_title;
            $rootScope.pageDescription = categoryDescription;

          }

          $rootScope.pageDescription = categoryDescription;
        }
      });

      function getPostUrl(post) {
        var route = saRouter.getRoute('blogPost');
        return saRouter.replaceUrlParams(route.url, {
          postNiceName: post
        });
      }

      function getCategoryUrl(category) {
        var route = saRouter.getRoute('blogCategory');
        return saRouter.replaceUrlParams(route.url, {
          categoryNiceName: category.cms.url.slug
        });
      }
    }])

    .directive('genericPost', function (timestamp) {

      var directive = {};

      directive.restrict = 'E';

      directive.scope = {
        item: '=',
        translations: '='
      };

      directive.templateUrl = function (elem, attrs) {
        return 'template/blog/' + attrs.templateType + '.html?rev=' + timestamp;
      };

      return directive;
    });
})();