(function () {
  "use strict";

  angular
    .module("mainApp")

    .filter("maximumsteps", [
      "_",
      function (_) {
        return function (object, number) {
          return _.slice(object, 0, number);
        };
      },
    ])

    .filter("accessoriesOutsideMycook", [
      "filtersManager",
      "_",
      function (filtersManager, _) {
        return function (object) {
          var filtersBundle = filtersManager.getFilters();
          var availableAccessories = filtersBundle.accessoriesNoMycook;

          var filteredAccessories = _.filter(object, function (n) {
            return availableAccessories.indexOf(n.key) > -1 ? true : false;
          });

          return filteredAccessories;
        };
      },
    ])

    .filter("functionAccesories", [
      "filtersManager",
      "_",
      function (filtersManager, _) {
        return function (object, func) {
          console.log("FUNC: ", func);

          var filtersBundle = filtersManager.getFilters();
          var availableAccessories =
            filtersBundle.accessoriesNoFunctions[func] || [];

          var filteredAccessories = _.filter(object, function (n) {
            return availableAccessories.indexOf(n.key) === -1 ? true : false;
          });

          return filteredAccessories;
        };
      },
    ])

    .filter("FilterSplashCover", [
      "filtersManager",
      "_",
      function (filtersManager, _) {
        return function (object, data) {
          var filteredAccessories = _.filter(object, function (n) {
            if(n.key == "splashCover"){
              return ( data.machineS2 || data.type == 'adapt' ) ? true : false;
            }else{
              return true
            }
            // return !data.machineS2  && n.key == "splashCover" ? false : true;
          });

          return filteredAccessories;
        };
      },
    ]);
})();
