(function () {

  'use strict';

  angular.module('mainApp')

    .controller("FavoriteRecipesController", ['$rootScope', '$scope', '$routeParams', '$log', 'toolsService', '_', 'recipeCategoriesFactory', 'seoService', 'usersManager', 'followersService','$location',
      function ($rootScope, $scope, $routeParams, $log, toolsService, _, recipeCategoriesFactory, seoService, usersManager, followersService, $location) {
        $scope.recipesTitle = $rootScope.translations.menu.user_logged.my_favorite_recipes;
        seoService.setTitle($rootScope.translations.menu.user_logged.my_favorite_recipes);

        recipeCategoriesFactory.getCategories().then(function (cats) {
          $scope.categories = cats;
        });

        $scope.badgeIsDeactivate = function (bdgCode, userBadges) {
          var isDeactivate = (toolsService.isEmpty(bdgCode) || toolsService.isEmpty(userBadges) || userBadges.indexOf(bdgCode) === -1);
          return isDeactivate;
        };

        $scope.getTotal = function () {
          return _.sumBy(_.map($scope.categories, 'favoritesCount'));
        }

        var authorNiceName = $routeParams.user;

        usersManager.getUserInfo(authorNiceName, 'public').then(function (data) {
          $log.debug("FavoriteRecipesController > getUserInfo DATA: ", data);
          $scope.currentAuthor = data;
        });

        $scope.badgeIsDeactivate = function (bdgCode, userBadges) {
          var isDeactivate = (toolsService.isEmpty(bdgCode) || toolsService.isEmpty(userBadges) || userBadges.indexOf(bdgCode) === -1);
          return isDeactivate;
        };

        followersService.getList(authorNiceName).then(function (data) {

          $scope.followersCount = data.followersCount;
          $scope.followingCount = data.followingCount;

        }, function (error) {
          toolsService.manageError('RecipesByAuthor -> getListFollowers  > ERROR', error, true);
        });

        $scope.goListFollows = function (type) {
          $location.path($rootScope.url('followers', { type: type, user: authorNiceName }));
        };

      }
    ]);
})();