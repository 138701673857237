(function () {
  angular.module('mainApp')
    .controller('HelpFaqsController', [function ($scope, $rootScope, $location, _, toolsService) {

      var faqs = _.cloneDeep($rootScope.translations.help.faqs.questions);
      for (var i = 0; i < faqs.length; i++) {
        for (var j = 0; j < faqs[i].content.length; j++) {
          faqs[i].content[j].show = false;
        }
      }

      $scope.faqs = faqs;

      $scope.$on('my-accordion:onReady', function () {
        if (!toolsService.isEmpty($location.$$search.current)) {
          var current = $location.$$search.current;
          current = current.split('-');
          $scope.faqs[current[1]].content[current[2]].show = true;
          $scope.accordion.toggle(parseInt(current[1]));
        } else {
          if (!toolsService.isEmpty($scope.faqs)) {
            $scope.accordion.toggle(0);
            $scope.faqs[0].content[0].show = true;
          }
        }
      });

      $scope.showTab = function (parent, child) {

        for (var i = 0; i < faqs.length; i++) {
          for (var j = 0; j < faqs[i].content.length; j++) {
            faqs[i].content[j].show = false;
          }
        }
        $scope.faqs[parent].content[child].show = true;

        // change URL
        $location.path($rootScope.url('faqs'))
          .search('current', 'tab-' + parent + '-' + child);
      }
    }]);
})();