(function () {

    'use strict';

    angular.module('mainApp')

        .factory('bannersFactory', ['$http', 'appConfig', '$filter', 'storeFactory',
            function ($http, appConfig, $filter, storeFactory) {

                var _bannersFactory = {};

                _bannersFactory.getBanners = function (user) {

                    var urlDestino = appConfig.localApiBasePath + '/banners/';
                    var data = user ? { query: { logged: { "$in": ["both", "logged"] } } } : { query: { logged: { "$in": ["both", "no_logged"] } } };

                    return $http({
                        method: 'post',
                        url: urlDestino,
                        headers: { 'Content-type': 'application/json' },
                        data: data
                    }).then(function (banners) {

                        _bannersFactory.banners = banners.data.result;
                        var today = $filter('date')(new Date(), 'yyyy-MM-dd hh:mm:ss');

                        angular.forEach(_bannersFactory.banners, function (value, key) {
                            value.from = $filter('date')(value.availability.from, 'yyyy-MM-dd hh:mm:ss');
                            value.to = $filter('date')(value.availability.to, 'yyyy-MM-dd hh:mm:ss');
                            value.visible = value.from < today && today < value.to;
                        });

                    });
                }

                _bannersFactory.getBanner = function (position) {
                    return _bannersFactory.banners.find(function (banner) {
                        return banner.position == position && banner.visible;
                    });
                }

                return _bannersFactory;
            }])

        .factory('bannerShop', ['$http', 'appConfig', '$filter', '$q', 'storeFactory',
            function ($http, appConfig, $filter, $q, storeFactory) {

                var deferred = $q.defer();
                var _bannerShop = {}

                _bannerShop.getBannerShop = function () {

                    var url = appConfig.localApiBasePath + '/featuredProducts';

                    $http({
                        method: 'get',
                        url: url
                    }).then(function (bannerShopFeatured) {

                        //console.log(bannerShopFeatured);

                        var bannerShopFeatured = bannerShopFeatured.data.result;
                        var today = $filter('date')(new Date(), 'yyyy-MM-dd hh:mm:ss');

                        angular.forEach(bannerShopFeatured, function (value, key) {
                            value.from = $filter('date')(value.availability.from, 'yyyy-MM-dd hh:mm:ss');
                            value.to = $filter('date')(value.availability.to, 'yyyy-MM-dd hh:mm:ss');
                            value.visible = value.from < today && today < value.to;
                        });

                        var bannerVisible = bannerShopFeatured.find(function (bannerShop) {
                            return bannerShop.visible == true;
                        });

                        if (bannerVisible) {
                            
                            storeFactory.getProduct(bannerVisible.product.niceName)
                                .then(function(prod) {
                                    //console.log(bannerVisible);
                                    bannerVisible.product = prod;
                                    deferred.resolve(bannerVisible);

                                }
                            ); 

                        } else {
                            deferred.reject(bannerVisible);
                        }

                    });

                    return deferred.promise;

                }

                return _bannerShop;
            }])
}
)();