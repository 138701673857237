(function () {

  const maxDays = function (geststocks) {

    if (geststocks) {

      if (geststocks.currentState == 'ENT') {

        var entState = geststocks.history.reverse().find(function (tracking) {
          return tracking.state === 'ENT';
        });

        if (entState) {

          var diff = (new Date().getTime() - new Date(entState.date).getTime()) / (1000 * 3600 * 24);

          return diff <= 14;

        } else return false;

      } else return false;

    } else return false;
  };

  angular
    .module('mainApp')
    // Enable logs in production mydomain.com?showLogs=true
    .config(function ($logProvider, $provide) {
      $provide.decorator('$log', function ($delegate) {
        var debugFunction = $delegate.debug;

        $delegate.debug = function () {
          if (localStorage.getItem('showLogs') != false) {
            debugFunction.apply($delegate, arguments);
          }
        };
        return $delegate;
      });
    })

    .config(function ($locationProvider) {
      $locationProvider.html5Mode(true).hashPrefix('!');
    })

    .service('configService', function ($log, $window) {
      this.debugEnabled = function (flag) {
        if (flag) {
          $log.info('Setting debugEnabled to ' + flag);
        }
        try {
          $window.localStorage.setItem('showLogs', flag ? flag.toString() : '');
        } catch (e) {
          $log.error(
            'localStorage not working because user is in private mode (browser) ',
            e
          );
        }
      };
    })

    // End Enable logs in production
    .service(
      'toolsService',
      function ($rootScope, $location, $log, $sce, moment, _, $cookies) {
        var randomQuery = '';

        var self = this;
        // Check if value is undefined, null or empty
        self.isEmpty = function (x) {
          return angular.isUndefined(x) || x === null || x === '' || x == '';
        };
        // Return showLogs from query or by default
        self.showLogs = function () {
          return !self.isEmpty($location) &&
            !self.isEmpty($location.search()) &&
            !self.isEmpty($location.search().showLogs)
            ? $location.search().showLogs
            : false;
        };

        this.getRandomQuery = function () {
          /*
         IMPROVEMENT:
         - generate new random on init app & on edit recipe
         - ask for rndm to storage service.
         */
          return '?rndm=' + randomQuery;
        };

        this.newRandomQuery = function () {
          randomQuery = Math.floor(Math.random() * 1000 + 1);
          return '?rndm=' + randomQuery;
        };

        // returns random array of "numValues" positions
        this.getRandomValuesFromArray = function (array, numValues) {
          var shuffled = array.sort(function () {
            return 0.5 - Math.random();
          });
          var selected = shuffled.slice(0, numValues);
          return selected;
        };

        this.canRma = function (order) {
          
          return order.products.find(function (p) {

            return (p.quantity - (p.returned || 0)) > 0;

          }) && maxDays(order.gestStocks);
          
        }

        // given array push elements to position "n"
        /*this.pushElementsAt = function (array, elements, n) {
        var initArray = _.slice(array, 0, n);
        var endArray = _.slice(array, n, array.length);
        var newArray = _.concat(initArray, elements, endArray);
        return newArray;
      };*/

        // given array push elements to positions given by variable positions
        this.pushElementsAtPositions = function (array, elements, positions) {
          positions = positions || [3, 5, 7];
          var newArray = new Array();

          var counterArray = 0;
          var counterElements = 0;

          for (var i = 0; i < array.length + elements.length; i++) {
            if (
              positions.indexOf(i) > -1 &&
              elements.length > counterElements
            ) {
              // afegeixo Element
              newArray.push(elements[counterElements]);
              counterElements++;
            } else {
              newArray.push(array[counterArray]);
              counterArray++;
            }
          }
          return newArray;
        };

        this.trustSrc = function (src) {
          return $sce.trustAsResourceUrl(src);
        };

        this.getObjValues = function (obj) {
          var vals = [];
          for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
              vals.push(obj[key]);
            }
          }
          return vals;
        };

        this.specialflattenObj = function (ob) {
          var toReturn = {};

          for (var i in ob) {
            if (!ob.hasOwnProperty(i)) {
              continue;
            }
            if (Object.prototype.toString.call(ob[i]) === '[object Array]') {
              toReturn[i] = ob[i];
            } else if (typeof ob[i] == 'object') {
              var flatObject = this.flattenObj(ob[i]);

              for (var x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) {
                  continue;
                }
                if (Object.prototype.toString.call(x) === '[object Array]') {
                  toReturn[i] = x;
                } else {
                  toReturn[i + '.' + x] = flatObject[x];
                }
              }
            } else {
              // si es un tipo de datos basico, se devuelve tal cual
              toReturn[i] = ob[i];
            }
          }
          return toReturn;
        };

        // thx to https://gist.github.com/penguinboy/762197
        this.flattenObj = function (ob) {
          var toReturn = {};

          for (var i in ob) {
            if (!ob.hasOwnProperty(i)) {
              continue;
            }

            if (typeof ob[i] == 'object') {
              var flatObject = this.flattenObj(ob[i]);
              for (var x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) {
                  continue;
                }
                toReturn[i + '.' + x] = flatObject[x];
              }
            } else {
              toReturn[i] = ob[i];
            }
          }
          return toReturn;
        };

        this.setMonths = function () {
          var m = moment();
          var months = [];

          for (var i = 0; i < 12; i++) {
            months.push({
              val: i + 1,
              name: m.months(i).format('MMMM'),
            });
          }

          return months;
        };

        this.setDays = function () {
          var days = [];
          for (var i = 1; i <= 31; i++) {
            days.push({
              val: i,
              name: i,
            });
          }
          return days;
        };

        this.setYears = function () {
          var years = [];
          var d = new Date();
          var yearActual = d.getFullYear();
          for (var i = 0; i < 100; i++) {
            years.push({
              val: yearActual - i,
              name: yearActual - i,
            });
          }
          return years;
        };

        this.getStringDate = function (year, month, day) {
          //returns a String in format yyyy-mm-dd

          var date = '';

          if (String(month).length == 1) {
            month = '0' + String(month);
          }

          if (String(day).length == 1) {
            day = '0' + String(day);
          }

          date = year + '-' + month + '-' + day;
          return date;
        };

        this.dateToArray = function (date) {
          var dates = date.split('-');

          //in case that date is with the format 2012-02-02T00:00:00.000Z remove the timestamp value
          if (dates[2].length > 2) {
            dates[2] = dates[2].substring(0, 2);
          }

          return [parseInt(dates[0]), parseInt(dates[1]), parseInt(dates[2])];
        };

        this.arrayToTimestamp = function (dateArray) {
          if (
            this.isEmpty(dateArray[0]) ||
            this.isEmpty(dateArray[1]) ||
            this.isEmpty(dateArray[2])
          ) {
            return '';
          } else {
            if (dateArray[2] < 10) {
              dateArray[2] = '0' + String(dateArray[2]);
            }

            if (dateArray[1] < 10) {
              dateArray[1] = '0' + String(dateArray[1]);
            }

            var dateFormatted =
              dateArray[0] +
              '-' +
              dateArray[1] +
              '-' +
              dateArray[2] +
              'T00:00:00.000Z';

            return dateFormatted;
          }
        };

        this.has_duplicates = function (arr) {
          var x = {},
            len = arr.length;
          for (var i = 0; i < len; i++) {
            if (x[arr[i]] === true) {
              return true;
            }
            x[arr[i]] = true;
          }
          return false;
        };

        this.fromObjToQuery = function (queryObj, blackList) {
          var oKeys = Object.keys(queryObj);
          var newQueryString = '';
          for (var i = 0; i < oKeys.length; i++) {
            var k = oKeys[i];
            if (blackList === undefined || blackList.indexOf(k) == -1) {
              newQueryString += (i === 0 ? '' : '&') + k + '=' + queryObj[k];
            }
          }
          return newQueryString;
        };

        this.extractYoutubeCode = function (str) {
          var youtubeCandidate = str;
          var youtubeCode = '';

          // youtube url
          if (
            youtubeCandidate.indexOf('youtu') > -1 &&
            youtubeCandidate.indexOf('//') > -1
          ) {
            // extraer el ID con regex
            // Thx to: http://stackoverflow.com/questions/3452546/javascript-regex-how-to-get-youtube-video-id-from-url
            var codeMatches = youtubeCandidate.match(
              /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/i
            );

            if (codeMatches && codeMatches.length > 1) {
              // ok
              if (codeMatches[1].length == 11) {
                youtubeCode = codeMatches[1];
              }
            }
          } else if (!this.isEmpty(youtubeCandidate)) {
            youtubeCode = youtubeCandidate;
          }
          return youtubeCode;
        };

        this.cookiePermission = function (wanted) {

          var consent = $cookies.get("OptanonConsent");
          var groupPermission;

          if (consent) {

            var groupParts = consent.split("&").find(function (part) {
              return part.startsWith("groups=");
            });

            var groups = groupParts ? groupParts.split("=").pop() : null;

            groupPermission = groups ? groups.split(",").map(function (group) {
              var parts = group.split(":");
              return {
                gid: parts[0],
                value: parts[1]
              };
            }).find(function (parsed) {
              return parsed.gid === wanted;
            }) : false;

            groupPermission = groupPermission && groupPermission.value === '1';

          } else {

            groupPermission = true;

          }

          return groupPermission;
        }

        this.getEmbedVideo = function (videoId, queryParams) {

          var permission = this.cookiePermission("C0004");
          var video = 'template/no-video-permission.html';

          if (permission) {

            if ($rootScope.translations.config.videoService.youtube) {
              queryParams = queryParams || {};
              queryParams.autoplay = 0; //force disable autoplay

              queryParams = queryParams
                ? '?' + this.fromObjToQuery(queryParams, [])
                : '';

              video = '//www.youtube.com/embed/' + videoId + queryParams;
            }

            if ($rootScope.translations.config.videoService.tencent_qq) {
              if (!queryParams) queryParams = {};
              queryParams.vid = videoId; // add video ID to query
              queryParams = queryParams
                ? '?' + this.fromObjToQuery(queryParams, [])
                : '';
              video = '//v.qq.com/txp/iframe/player.html' + queryParams;
            }

          }

          return video;
        };

        this.manageError = function (logMessage, errorMessage, to404) {
          $log.error(logMessage, errorMessage);
          if (to404) $location.path('/404');
        };
      }
    )

    .filter('getLastItem', [
      'appConfig',
      'toolsService',
      function (appConfig, toolsService) {
        return function (input, separatorCase) {
          var output = input;
          var token = appConfig.itemSeparator;
          if (separatorCase) {
            token = appConfig[separatorCase];
          }
          if (!toolsService.isEmpty(input) && input.indexOf(token) > -1) {
            output = input.split(token);
            if (output.length > 0) {
              output = output[output.length - 1];
            }
          }

          return output;
        };
      },
    ])

    // Para poner enlaces en texto - ng-bind-html="sampleText | parseUrlFilter:'_blank':'otherPropertie'"
    .filter('parseUrlFilter', function () {
      var urlPattern = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/gi;
      return function (text, target) {
        return text.replace(
          urlPattern,
          '<a target="' + target + '" href="$&">$&</a>'
        );
      };
    })

    // Sustituir variable en textos
    .filter('translateVariables', [
      '$log',
      'toolsService',
      function ($log, toolsService) {
        return function (text, obj) {
          var newText = text;

          if (typeof obj == 'object') {
            var arr = text.match(/{{[\s\S]*?}}/gi);

            if (!arr) {
              return newText;
            }

            arr.forEach(function (key) {
              function replacerdots(match, p1, p2, p3, offset, string) {
                return p2.trim();
              }

              var position = key.replace(/({{)([\s\S]*?)(}})/, replacerdots);

              if (obj[position]) {
                newText = newText.replace(key, obj[position]);
              } else {
                $log.warn(
                  'translateVariables: not found "' +
                  position +
                  '" in cadena "' +
                  text +
                  '"'
                );
              }
            });
          } else {
            if (obj !== undefined) obj = obj.toString(); // in case obj is 0

            if (!toolsService.isEmpty(text) && !toolsService.isEmpty(obj)) {
              newText = newText.replace(/{{[\s\S]*?}}/, obj);
            }
          }

          return newText;
        };
      },
    ])

    // ng-src en iframe
    .filter('trustAsResourceUrl', [
      '$sce',
      function ($sce) {
        return function (val) {
          return $sce.trustAsResourceUrl(val);
        };
      },
    ])

    // Validate all html
    .filter('unsafe', function ($sce) {
      return $sce.trustAsHtml;
    })

    .filter('getFirstItem', [
      'appConfig',
      'toolsService',
      function (appConfig, toolsService) {
        return function (input) {
          var output = input;
          var token = appConfig.itemSeparator;
          if (!toolsService.isEmpty(input) && input.indexOf(token) > -1) {
            output = input.split(token);
            if (output.length > 0) {
              output = output[0];
            }
          }

          return output;
        };
      },
    ])

    .filter('filterAt', [
      'appConfig',
      'toolsService',
      function (appConfig, toolsService) {
        return function (input) {
          return /^@/.test(input) ? input.split('@')[1] : input;
        };
      },
    ])

    .filter('useDecorator', [
      'appConfig',
      'toolsService',
      function (appConfig, toolsService) {
        return function (input) {
          var myregex = new RegExp('\\' + appConfig.itemSeparator, 'g');
          return input.replace(myregex, appConfig.decorator);
        };
      },
    ])

    .filter('stripDecorator', [
      'appConfig',
      'toolsService',
      function (appConfig, toolsService) {
        return function (input) {
          var myregex = new RegExp('\\' + appConfig.decorator, 'g');
          return input.replace(myregex, appConfig.itemSeparator);
        };
      },
    ])

    .filter('slugify', [
      function (appConfig) {
        return function (input) {
          var replaceStringChar = function (cadena) {
            var chars = {
              á: 'a',
              é: 'e',
              í: 'i',
              ó: 'o',
              ú: 'u',
              à: 'a',
              è: 'e',
              ì: 'i',
              ò: 'o',
              ù: 'u',
              ñ: 'n',
              Á: 'A',
              É: 'E',
              Í: 'I',
              Ó: 'O',
              Ú: 'U',
              À: 'A',
              È: 'E',
              Ì: 'I',
              Ò: 'O',
              Ù: 'U',
              Ñ: 'N',
            };

            var expr = /[áàéèíìóòúùñ]/gi;

            var res = cadena.replace(expr, function (e) {
              return chars[e];
            });

            return res;
          };

          if (!input) {
            return input;
          }

          if (appConfig.region && appConfig.region == 'ES-MYCOOKTOUCH') {
            input = input.toLowerCase().replace(/ /g, '-');

            input = replaceStringChar(input);
          } else {
            input = slugify(input);
          }

          return input;
        };
      },
    ])

    .filter('urlEncode', [
      'appConfig',
      function (appConfig) {
        return function (url) {
          var urlified = '';
          if (url instanceof Array) {
            urlified = '';
            var newUrl = [];
            var urlSZ = url.length;
            for (var i = 0; i < urlSZ; i++) {
              newUrl[i] = encodeURIComponent(url[i]);
            }
            urlified = newUrl.join(appConfig.filterSeparator);
          } else {
            urlified = url !== undefined ? encodeURIComponent(url) : '';
          }

          return urlified;
        };
      },
    ])

    .filter('formatUrl', [
      'toolsService',
      function (toolsService) {
        return function (input) {
          if (!toolsService.isEmpty(input) && !/^http/.test(input)) {
            input = 'http://' + input;
          }
          return input;
        };
      },
    ])

    .filter('userWebName', [
      'toolsService',
      function (toolsService) {
        return function (website_url, website_name) {
          if (website_url && website_url !== '') {
            website_url = website_url.replace('http://', '');
            website_url = website_url.replace('https://', '');

            // remove / at the end of an url
            website_url = website_url.replace(new RegExp('/*$'), '');

            if (website_url.length < 30 || toolsService.isEmpty(website_name)) {
              return website_url;
            } else return website_name;
          } else return website_url;
        };
      },
    ])

    .filter('capitalize', [
      '_',
      function (_) {
        return function (input) {
          return !!input ? _.upperFirst(input) : '';
        };
      },
    ])

    .filter('removeDoubleQuotes', function () {
      return function (input) {
        if (typeof input !== 'undefined') {
          input = input.replace(/['"]+/g, '');
          return input;
        }
      };
    })

    .filter('stripTags', function () {
      return function (str, tags, disallow) {
        if (!str) {
          return str;
        }

        tags = tags ? tags.split(',') : '';

        var regexp = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;

        var stripped = str.replace(regexp, function ($0, $1) {
          var found = ~tags.indexOf($1.toLowerCase());
          var replace = disallow ? found : !found;
          var replacement = !replace ? $0 : '';
          return replacement;
        });
        return stripped;
      };
    })

    .filter('floatToFraction', function () {
      return function (input) {
        var f;

        if (
          input &&
          (parseFloat(input) === 0.25 ||
            parseFloat(input) === 0.5 ||
            parseFloat(input) === 0.75)
        ) {
          f = new Fraction(parseFloat(input));
          input = f.toFraction(true);
        }

        return input;
      };
    })

    .filter('fractionToFloat', function () {
      return function (input) {
        var f = new Fraction(input);
        var decimal = 2;
        return Number(Math.round(f.n / f.d + 'e' + decimal) + 'e-' + decimal); //f.numerator / f.denominator;
      };
    })

    .filter('setDecimal', function () {
      return function (input, places) {
        if (isNaN(input)) return input;
        // If we want 1 decimal place, we want to mult/div by 10
        // If we want 2 decimal places, we want to mult/div by 100, etc
        // So use the following to create that factor
        var factor = '1' + Array(+(places > 0 && places + 1)).join('0');
        input = Math.round(input * factor) / factor;
        input = input.toString().replace('.', ',');
        return input;
      };
    })

    .filter('removeEmptyDecimals', function () {
      return function (input, decimal) {
        if (!decimal) decimal = ',';
        if (input.indexOf(decimal) > -1) {
          input = input.toString().split(decimal);
          if (Number(input[1]) == 0) input = input[0];
        }

        return input;
      };
    })

    .filter('grToKg', [
      '$filter',
      function ($filter) {
        return function (input, unit) {
          if (input > 999) {
            input = input / 1000;
            unit = 'kg';
          }
          input = $filter('setDecimal')(input, 1);
          return input + '&nbsp;' + unit;
        };
      },
    ])

    .filter('setGallerySize', [
      function () {
        return function (input, size) {
          if (input) {
            var path = input;
            var split = path.split('/');

            var result =
              split.slice(0, split.length - 1).join('/') +
              '/' +
              size +
              '/' +
              split[split.length - 1];

            return result;
          } else return input;
        };
      },
    ])

    .filter('encodeURI', [
      function ($window) {
        return function (path) {
          try {
            return $window.encodeURI(path);
          } catch (e) {
            return path;
          }
        };
      },
    ])

    .directive('svgStore', [
      function () {
        return {
          restrict: 'EA',
          replace: true,
          template:
            '<div ng-class="className" class="svg-element">' +
            '<div class="scaling-svg-container" ng-attr-style="padding-bottom: {{ 100 * h/w }}%;">' +
            "<svg class=\"scaling-svg\" ng-attr-viewBox=\"{{ x + ' ' + y + ' ' + w + ' ' + h }}\">" +
            '<use xlink:href="{{ \'#\' + name }}"></use>' +
            '</svg>' +
            '</div>' +
            '</div>',
          scope: {
            name: '=?',
            x: '=?',
            y: '=?',
            w: '=?',
            h: '=?',
            className: '=?',
          },
          link: function (scope, element, attributes) {
            if (scope.x == undefined) {
              scope.x = 0;
            }

            if (scope.y == undefined) {
              scope.y = 0;
            }
          },
        };
      },
    ])

    .directive('remoteSvg', [
      "appConfig",
      "$http",
      "$sce",
      "timestamp",
      function (appConfig, $http, $sce, timestamp) {

        return {
          restrict: 'EA',
          replace: true,
          template: '<server-svg ng-bind-html="trustedHtml(rawSvg)" ng-style="{\'visibility\':rawSvg ? \'visible\' : \'hidden\'}" />',
          scope: {
            picto: '=',
            color: '=?',
            w: '=?',
            h: '=?'
          },
          link: function ($scope, element, attributes) {

            $scope.trustedHtml = function (plainText) {
              return $sce.trustAsHtml(plainText);
            }

            $scope.mediaDomain = appConfig.mycookTouchDomain + '/image/picto/';

            $scope.$watch('picto', function () {

              $scope.rawSvg = null;

              $http.get($scope.mediaDomain + $scope.picto + '?' + timestamp, { cache: true }).then(function (svg) {
                var rawSvg = svg.data.match(/(<svg)[\s\S]*(<\/svg>)/i);

                if (rawSvg) {

                  rawSvg = rawSvg[0];
                  var html;

                  if ($scope.color) {
                    if (!html)
                      html = jQuery.parseHTML(rawSvg);

                    html[0].style.fill = $scope.color;
                  }

                  if ($scope.w) {
                    if (!html)
                      html = jQuery.parseHTML(rawSvg);

                    html[0].style.width = $scope.w;
                  }

                  if ($scope.h) {
                    if (!html)
                      html = jQuery.parseHTML(rawSvg);

                    html[0].style.height = $scope.h;
                  }


                  $scope.rawSvg = html ? html[0].outerHTML : rawSvg;

                } else {
                  $scope.rawSvg = ''; //'<img src="{{ mediaDomain + picto + \'?\' + timestamp }}" />';
                }

              }, function (err) {
                $scope.rawSvg = ''; //'<img src="{{ mediaDomain + picto + \'?\' + timestamp }}" />';
              });

            });
          }
        };
      },
    ])

    .directive('dynamicSvgStore', [
      function () {
        return {
          restrict: 'EA',
          replace: false,
          template:
            '<div ng-class="className" class="svg-element" style="width: inherit; height: inherit">' +
            '<div class="scaling-svg-container" ng-attr-style="padding-bottom: {{ 100 * h/w }}%;">' +
            "<svg class=\"scaling-svg\" ng-attr-viewBox=\"{{ x + ' ' + y + ' ' + w + ' ' + h }}\">" +
            '<use xlink:href="{{ \'#\' + name }}"></use>' +
            '</svg>' +
            '</div>' +
            '</div>',
          scope: {
            name: '=?',
            x: '=?',
            y: '=?',
            w: '=?',
            h: '=?',
            className: '=?',
            dynamicName: '=?',
          },
          link: function (scope, element, attributes) {
            if (scope.x == undefined) {
              scope.x = 0;
            }

            if (scope.y == undefined) {
              scope.y = 0;
            }

            if (scope.dynamicName) {
              scope.name = scope.dynamicName;
              scope.$watch('dynamicName', function (newName, oldName) {
                if (oldName !== newName) scope.name = newName;
              });
            }
          },
        };
      },
    ])

    .directive('ngXlinkHref', function () {
      return {
        priority: 99,
        restrict: 'A',
        link: function (scope, element, attr) {
          var attrName = 'xlink:href';
          attr.$observe('ngXlinkHref', function (value) {
            if (!value) return;

            attr.$set(attrName, value);
          });
        },
      };
    })

    .directive('scrollToItem', function () {
      return {
        restrict: 'A',
        scope: {
          scrollTo: '@',
        },
        link: function (scope, elm, attr) {
          elm.on('click', function () {
            $('html, body').animate(
              {
                scrollTop:
                  $(scope.scrollTo).offset().top -
                  angular.element('#header').height(),
              },
              'slow'
            );
          });
        },
      };
    })

    .directive('a', function ($location, $timeout, $route) {
      return {
        restrict: 'E',
        link: function (scope, elem, attrs) {
          if (attrs.ngClick || attrs.href === '' || attrs.href === '#') {
            elem.on('click', function (e) {
              e.preventDefault();
            });
          }

          var domain = $location.host();
          if ($route.current) {
            if ($route.current.routeName != 'blogPost') {
              $timeout(function () {
                $('a[href^="http://"]')
                  .not('[href*="' + domain + '"]')
                  .attr('target', '_blank')
                  .attr('rel', 'nofollow');

                $('a[href^="https://"]')
                  .not('[href*="' + domain + '"]')
                  .attr('target', '_blank')
                  .attr('rel', 'nofollow');
              }, 0);
            } else {
              $timeout(function () {
                $('a[href^="http://"]')
                  .not('[href*="' + domain + '"]')
                  .attr('target', '_blank');

                $('a[href^="https://"]')
                  .not('[href*="' + domain + '"]')
                  .attr('target', '_blank');
              }, 0);
            }
          }
        },
      };
    })
    .directive('videoFull', function ($window, toolsService, $rootScope, $timeout) {
      var template = '<iframe ng-if="!permissions" src="template/no-video-permission.html" id="iframe-player-{{id}}" height="400" style="width:100%;border:none;"></iframe>';
      template +=
        '<div ng-if="permissions" class="module-full-video {{videoSize}} {{class}}" id="video-full-{{id}}">';
      template += '<div class="video-height closed">';
      template += '<div class="icon">';
      template +=
        '<span ng-if="playImage == \'\'" class="play-button"></span>';
      template +=
        '<img ng-if="playImage !== \'\'" ng-src="{{ playImage }}" alt="" />';
      template += '</div>';
      template +=
        '<div class="bg-video" style="background-image: url({{bgImage}}); background-color: {{bgColor}};"></div>';
      template += '<youtube id="player-{{id}}" player-options="options"></youtube>';
      template += '</div>';
      template += '</div>';

      return {
        template: template,
        scope: true,
        link: function ($scope, element, attrs) {
          var _id = Math.floor(Math.random() * 100);

          $scope.player;

          $scope.options = {
            videoId: attrs.video,
            playerVars: {
              controls: 0,
              autoplay: '0',
              autohide: '1',
              color: 'white',
              rel: '0',
              showinfo: '0',
              theme: 'light',
            }
          }

          $scope.permissions = toolsService.cookiePermission("C0004");
          $scope.id = _id;
          $scope.class = attrs.class;
          $scope.bgImage = attrs.image;
          $scope.bgColor =
            typeof attrs.color !== 'undefined'
              ? attrs.color
              : 'rgb(200, 200, 200)';
          $scope.videoSize = attrs.smallVideo == 'true' ? 'small-height' : '';
          $scope.playImage =
            typeof attrs.play !== 'undefined' ? attrs.play : '';

          $scope.$on('ngYoutubePlayer:onPlayerReady', function (event, player, id) {
            $scope.player = player.target;

            resizeVideoTouch();

            //$scope.player.playVideo();

            angular
              .element('#player-' + _id)
              .delay(50)
              .fadeIn();

          });

          $scope.$on('ngYoutubePlayer:onStateChange', function (event, data, id) {
            if (data == 0) {
              // video end
              scrollTouch(null);
            }
          });

          $scope.$on('ngYoutubePlayer:onPlayerError', function (event, data, id) {
            console.error("Video error");
          });

          if (!$rootScope.translations.config.videoService.tencent_qq)
            angular.element($window).bind('resize', resizeVideoTouch);

          element.bind('click', function () {
            if (!$rootScope.translations.config.videoService.tencent_qq) {

              if ($scope.player) {
                $scope.player.playVideo();
              }

              var videoSize = getVideoSize();

              $('#video-full-' + _id + ' .video-height')
                .delay(200)
                .height(videoSize.height + 'px');
              $(
                '#video-full-' +
                _id +
                ' .bg-video, #video-full-' +
                _id +
                ' .icon'
              )
                .delay(200)
                .fadeOut();
              $('html, body').animate(
                {
                  scrollTop:
                    $('#video-full-' + _id).offset().top -
                    $('#header').height() +
                    20,
                },
                400,
                function () {
                  $timeout(function () {
                    $(window).on('scroll', scrollTouch);
                  }, 200);
                }
              );
            } else {
              var query = {
                rel: 0,
                showinfo: 0,
                autohide: 1,
                autoplay: 1,
                enablejsapi: 1,
                cc_load_policy: 1,
                modestbranding: 1,
              };

              scope.video = toolsService.getEmbedVideo(attrs.video, query);

              ngDialog.open({
                template: 'dialog-video-fullscreen.html',
                className: 'overlay-funciones',
                scope: scope,
              });
            }
          });

          function getVideoSize() {
            return {
              width: jQuery('body').width(),
              height: (315 * jQuery('body').width()) / 560,
            };
          }

          function resizeVideoTouch() {
            if ($scope.player)
              jQuery('#player-' + _id)
                .height((jQuery('body').width() * 315) / 560)
                .width('100%');
          }

          function scrollTouch(e) {
            if ($scope.player) {
              $scope.player.pauseVideo();
              angular
                .element('#video-full-' + _id + ' .video-height')
                .removeAttr('style')
                .addClass('closed');
              $(
                '#video-full-' +
                _id +
                ' .bg-video, #video-full-' +
                _id +
                ' .icon'
              ).fadeIn();
            }
            $(window).off('scroll', scrollTouch);
          }
        }
      };
    })
    .directive('customTag', function ($compile, $sce) {
      return {
        restrict: 'E',
        //replace:true,
        scope: {
          id: '=',
          class: '=',
          html: '=' /*,
          tag: '='*/,
        },
        transclude: true,
        link: function (scope, element, attrs, ctrl, transclude) {
          //, transclude, xx

          var tag_title = attrs.tag; // || 'h1';
          var template =
            '<' +
            tag_title +
            ' id="{{ id }}" class="{{ class }}" ng-bind-html="html"></' +
            tag_title +
            '>';

          if (!attrs.html) {
            template =
              '<' +
              tag_title +
              ' id="{{ id }}" class="{{ class }}"></' +
              tag_title +
              '>';
          }

          var linkFn = $compile(template);
          var content = linkFn(scope);

          if (!attrs.html) {
            transclude(function (clone) {
              content.append(clone);
            });
          }

          element.replaceWith(content);
        },
      };
    })

    .directive('youtubeSubscribe', function ($window, $log) {
      return {
        link: function (scope, element, attrs) {
          scope.channel = attrs.channel;
          scope.layout =
            typeof attrs.layout !== 'undefined' ? attrs.layout : 'default';
          scope.count =
            typeof attrs.count !== 'undefined' ? attrs.count : 'hidden';

          if (document.readyState == 'complete') {
            loadWindowYoutube();
          } else {
            $window.addEventListener('load', loadWindowYoutube, false);
          }

          function loadWindowYoutube() {
            if (!$window.gapi) {
              $log.error(
                'youtubeSubscribe -> its necessary to load google API'
              );
            } else {
              renderSubscribeButton();
            }
          }

          function renderSubscribeButton() {
            var container = document.getElementById(
              'yt-button-container-render'
            );

            var options = {
              channel: scope.channel,
              layout: scope.layout,
              count: scope.count,
            };

            gapi.ytsubscribe.render(container, options);
          }
        },
      };
    })

    .directive('fbLike', function ($window, $log) {
      return {
        link: function (scope, element, attrs) {
          scope.url = attrs.facebookUrl;
          scope.action = attrs.action;
          scope.layout = attrs.layout;
          scope.share =
            typeof attrs.share !== 'undefined' ? attrs.share : false;
          scope.showFaces =
            typeof attrs.showFaces !== 'undefined' ? attrs.showFaces : false;

          if (document.readyState == 'complete') {
            loadWindowFacebook();
          } else {
            $window.addEventListener('load', loadWindowFacebook, false);
          }

          function loadWindowFacebook() {
            if (!$window.FB) {
              $log.error('fbLike -> its necessary to load facebook SDK');
            } else {
              renderLikeButton();
            }
          }

          function renderLikeButton() {
            element.html(
              '<div class="fb-like"' +
              (!!scope.url ? ' data-href="' + scope.url + '"' : '') +
              ' data-layout="' +
              scope.layout +
              '" data-action="' +
              scope.action +
              '" data-show-faces="' +
              scope.showFaces +
              '" data-share="' +
              scope.share +
              '"></div>'
            );
            $window.FB.XFBML.parse(element.parent()[0]);
          }
        },
      };
    })

    .directive('tinyNav', function ($window, $timeout) {
      return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
          var toggle = angular.element(elem).children('.tiny-toggle');
          var menu = angular.element(elem).children('.tiny-nav');
          var menu_links = angular.element(elem).find('.tiny-nav a');

          if (attrs.tinyNav) {
            menu.addClass(attrs.tinyNav);
          } else menu.addClass('mobile');

          resizeTiny();

          scope.$watch(
            function () {
              return menu_links.attr('class');
            },
            function (newValue) {
              if (menu_links.hasClass('current')) {
                updateCurrentValue();
              }
            }
          );

          function updateCurrentValue() {
            var current_link = angular
              .element(elem)
              .find('.tiny-nav a.current');

            toggle.text(current_link.text());
            toggle.attr('title', current_link.text());
          }

          toggle.on('click', function () {
            $(this).siblings('.tiny-nav').toggleClass('in');
          });

          angular.element($window).bind('resize', resizeTiny);

          function resizeTiny() {
            var width_attr = 480;

            switch (attrs.tinyNav) {
              case 'portrait':
                width_attr = 768;
                break;

              case 'landscape':
                width_attr = 979;
                break;

              case 'large':
                width_attr = 1200;
                break;
            }

            if ($window.innerWidth >= width_attr) {
              // version desktop
              toggle.addClass('hide');
              menu.removeClass('show');
            } else {
              // version mobile
              toggle.removeClass('hide');
              menu.addClass('show');
            }
          }
        },
      };
    })
    .directive('resize', function ($window) {
      return function (scope, element) {
        var w = angular.element($window);
        scope.getWindowDimensions = function () {
          return {
            h: w.height(),
            w: w.width(),
          };
        };

        scope.$watch(
          scope.getWindowDimensions,
          function (newValue, oldValue) {
            scope.windowHeight = newValue.h;
            scope.windowWidth = newValue.w;
          },
          true
        );

        w.bind('resize', function () {
          scope.$apply();
        });
      };
    })

    .directive('expandableImage', function ($rootScope, timestamp) {
      return {
        restrict: 'EA',
        replace: true,
        scope: {
          title: '=',
          subtitle: '=',
          closeEvent: '=',
          paths: '=',
          mediaDomain: '=',
        },
        templateUrl: '/template/expandable-image.html?ver=' + timestamp,
        controller: function ($scope) {
          $scope.closeAmplifiedImage = function () {
            $rootScope.$emit($scope.closeEvent);
          };
        },
      };
    });

  //TODO: remember que hay un modulo inyectado para añadir los names creados dinamicamente - necesario para la validacion de los forms con campos dinamicos
  //IMPORTANTE: añade "names" dinámicos a los inputs creados de manera dinámica, sino no se pueden validar!!
  angular
    .module('mainApp.formUtils', [])

    .config(function ($provide) {
      $provide.decorator('ngModelDirective', function ($delegate) {
        var ngModel = $delegate[0],
          controller = ngModel.controller;
        ngModel.controller = [
          '$scope',
          '$element',
          '$attrs',
          '$injector',
          function (scope, element, attrs, $injector) {
            var $interpolate = $injector.get('$interpolate');
            attrs.$set('name', $interpolate(attrs.name || '')(scope));
            $injector.invoke(controller, this, {
              $scope: scope,
              $element: element,
              $attrs: attrs,
            });
          },
        ];
        return $delegate;
      });

      $provide.decorator('formDirective', function ($delegate) {
        var form = $delegate[0],
          controller = form.controller;
        form.controller = [
          '$scope',
          '$element',
          '$attrs',
          '$injector',
          function (scope, element, attrs, $injector) {
            var $interpolate = $injector.get('$interpolate');
            attrs.$set(
              'name',
              $interpolate(attrs.name || attrs.ngForm || '')(scope)
            );
            $injector.invoke(controller, this, {
              $scope: scope,
              $element: element,
              $attrs: attrs,
            });
          },
        ];
        return $delegate;
      });
    });
})();