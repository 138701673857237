(function () {
  'use strict';

  angular
    .module('mainApp')
    .factory('AffiliateProductsFactory', [
      '$http',
      'AffiliatesFactory',
      'appConfig',
      function ($http, AffiliatesFactory, appConfig) {
        var affiliateProds = null;

        var _affiliateProd = {};

        _affiliateProd.get = function () {
          if (Array.isArray(affiliateProds))
            return Promise.resolve(affiliateProds);
          else {
            return new Promise(function (resolve, reject) {
              var activeAffiliate = AffiliatesFactory.getActive();

              var url = activeAffiliate
                ? appConfig.localApiBasePath +
                  '/affiliateProducts/' +
                  activeAffiliate
                : appConfig.localApiBasePath + '/affiliateProducts';

              $http({
                method: 'GET',
                url: url,
              }).then(function (res) {
                affiliateProds = res.data.response;
                resolve(res.data.response || []);
              }, reject);
            });
          }
        };

        return _affiliateProd;
      },
    ])
    .factory('AffiliatesFactory', [
      '$cookies',
      '$http',
      'appConfig',
      function ($cookies, $http, appConfig) {
        var _affiliates = {};

        _affiliates.getActive = function () {
          if ($cookies.get('AFFILIATE_MYCOOK'))
            return $cookies.get('AFFILIATE_MYCOOK');
          else if (sessionStorage.getItem('AFFILIATE_MYCOOK'))
            return sessionStorage.getItem('AFFILIATE_MYCOOK');
          else return null;
        };

        _affiliates.removeActive = function () {
          var removed = false;

          if ($cookies.get('AFFILIATE_MYCOOK')) {
            $cookies.remove('AFFILIATE_MYCOOK');
            removed = true;
          }
          if (sessionStorage.getItem('AFFILIATE_MYCOOK')) {
            sessionStorage.removeItem('AFFILIATE_MYCOOK');
            removed = true;
          }

          return removed;
        };

        _affiliates.setActive = function (affiliate) {
          return new Promise(function (resolve, reject) {
            $http({
              method: 'GET',
              url: appConfig.localApiBasePath + '/config/AFFILIATE_MYCOOK',
            }).then(function (res) {
              var expires = new Date();
              expires.setDate(expires.getDate() + res.data.cookie_time);

              $cookies.put('AFFILIATE_MYCOOK', affiliate, {
                expires: expires,
              });

              sessionStorage.setItem('AFFILIATE_MYCOOK', affiliate);
              resolve();
            }, reject);
          });
        };

        _affiliates.getExternal = function () {
          if ($cookies.get('EXTERNAL_AFFILIATE'))
            return $cookies.get('EXTERNAL_AFFILIATE');
          else if (sessionStorage.getItem('EXTERNAL_AFFILIATE'))
            return sessionStorage.getItem('EXTERNAL_AFFILIATE');
          else return null;
        };

        _affiliates.removeExternal = function () {
          var removed = false;

          if ($cookies.get('EXTERNAL_AFFILIATE')) {
            $cookies.remove('EXTERNAL_AFFILIATE');
            removed = true;
          }
          if (sessionStorage.getItem('EXTERNAL_AFFILIATE')) {
            sessionStorage.removeItem('EXTERNAL_AFFILIATE');
            removed = true;
          }

          return removed;
        };

        _affiliates.setExternal = function (affiliate) {
          return new Promise(function (resolve, reject) {
            $http({
              method: 'GET',
              url: appConfig.localApiBasePath + '/config/AFFILIATE_MYCOOK',
            }).then(function (res) {
              var expires = new Date();
              expires.setDate(expires.getDate() + res.data.cookie_time);

              $cookies.put('EXTERNAL_AFFILIATE', affiliate, {
                expires: expires,
              });

              sessionStorage.setItem('EXTERNAL_AFFILIATE', affiliate);
              resolve();
            }, reject);
          });
        };

        return _affiliates;
      },
    ])
    .controller('StorePaymentController', [
      '$scope',
      '$rootScope',
      '$location',
      '$cookies',
      'ngCart',
      'paymentSvc',
      'toolsService',
      'webStorage',
      '_',
      'serviceStoreAnalytics',
      'legalTerms',
      '$log',
      'AffiliatesFactory',
      'storeFactory',
      'appConfig',
      '$http',
      function (
        $scope,
        $rootScope,
        $location,
        $cookies,
        ngCart,
        paymentSvc,
        toolsService,
        webStorage,
        _,
        serviceStoreAnalytics,
        legalTerms,
        $log,
        AffiliatesFactory,
        storeFactory,
        appConfig,
        $http
      ) {
        serviceStoreAnalytics.checkoutProcess(4);

        $scope.freeShippingCost = true;
        $scope.showTaxes = true;
        $scope.paymentType = 'tpv';

        $scope.address = ngCart.getShippingAddress();
        $scope.orderComments = ngCart.getComments();
        $scope.errMsg = null;
        var processing = false;

        $scope.voucher = null;
        $scope.voucherMessage = '';
        $scope.voucherIsApplied = false;
        $scope.sendAsGift = false;

        /* Hay que revisar esto! */
        //$scope.originalPriceProds = ['mycook-touch', 'mycook-touch-black-edition', 'mycook-easy', 'mycook-one', 'roner-clip', 'nature', 'tasty-n-cream', 'brownie-co', 'cakepop-co', 'envasadora-vac6000', 'bolsas-vac6000-30x35cm', 'bolsas-vac6000-20x30cm', 'rollos-vac6000-22cmx6m', 'robot-aspirador-lucid-i10', 'cafetera-espresso-automatic-coffeemaker', 'aspirador-escoba-ultimate-digital-fuzzy', 'aspirador-escoba-ultimate-digital', 'batidora-bapi-1200-premium-complet', 'batidora-bapi-1200-inox', 'crepera-s163', 'gofrera-g594', 'fondue-ff2', 'palomitera-pop-corn', 'pichurrin'];
        $scope.discountOrigin = 'mycook.es';

        $scope.scalapay = {
          locale: appConfig.momentJsLanguage
        };

        storeFactory.getConfiguration().then(function (config) {
          $scope.scalapay = Object.assign($scope.scalapay, JSON.parse(JSON.stringify(config.scalapay)));
        });

        // if ($cookies.get('AFFILIATE_MYCOOK')) {
        //   $scope.affiliatePrices = true;
        //   $scope.discountOrigin = $cookies.get('AFFILIATE_MYCOOK');
        // } else {
        //   $scope.affiliatePrices = false;
        // }

        // if ($location.search().landing) {
        //   $scope.criteoPrices = true;
        //   $scope.discountOrigin = '';
        // } else {
        //   $scope.criteoPrices = false;
        // }
        $scope.scalapay.no_of_installment = function (params) {
          return ($scope.order.total + $scope.order.shippingCost) < $scope.scalapay.amount_threshold_installments ? 3 : 4;
        };

        validateOrder();

        var deregisterEvent = $rootScope.$on('ngCart:change', function () {
          if ($scope.errMsg == null && processing == false) {
            validateOrder();
          }
        });
        
        $scope.$on('$destroy', function () {
          deregisterEvent();
        });

        $scope.addVoucher = function (code) {
          ngCart.setCoupon(code);
        };

        $scope.removeVoucher = function () {
          var discountType = ngCart.getDiscountType();
          if (discountType === 'affiliate') AffiliatesFactory.removeActive();

          $scope.voucher = null;
          $scope.voucherMessage = '';
          $scope.voucherIsApplied = false;
          ngCart.setCoupon(null);
        };

        $scope.changeVoucher = function () {
          $scope.voucher = $scope.voucher
            .toUpperCase()
            .replace(/\s/g, '')
            .trim();
        };

        $scope.getTotalItems = function () {
          return ngCart.getTotalItems();
        };

        $scope.doPayment = function () {
          var conditionsArray = ['termsOfSale'];
          var mail = false;

          if (toolsService.isEmpty($scope.currentUser)) {
            mail = $scope.address.mail;
          }

          legalTerms
            .acceptLegalTerms('multi', conditionsArray, mail)
            .then(function (result) {
              continuePayment();
            })
            .catch(function (error) {
              $log.error(
                'CheckoutController -> Error accepting legalTerms',
                error
              );
            });
        };

        function continuePayment() {
          processing = true;
          ngCart.setComments($scope.orderComments);
          ngCart.setSendAsGift($scope.sendAsGift);
          var cart = _.clone(ngCart.getCart());
          var userNiceName = webStorage.get('userNiceName') || 'guest';

          //store-payment-tpv

          if (ngCart.getCoupon()) {
            var coupon = ngCart.getCoupon();
            $scope.voucher = coupon;
            cart.voucher = {
              code: coupon,
            };
          }

          cart.payment = $scope.paymentType;

          paymentSvc.insertOrder(cart, userNiceName).then(
            function (results) {
              if (results.status == 201) {
                $scope.order = results.data;

                //toolsService.changePath($scope.url(paymentType,{id:$scope.order.id}));
                serviceStoreAnalytics.checkoutOptions(4, $scope.paymentType);

                if ($scope.paymentType === 'scalapay') {
                  var orderTempData = {
                    totalAmount: {
                      currency: "EUR",
                      amount: $scope.order.total + ""
                    },
                    consumer: {
                      phoneNumber: $scope.order.customerValue.mobile,
                      givenNames: $scope.order.customerValue.name,
                      surname: $scope.order.customerValue.lastName,
                      email: $scope.order.customerValue.email
                    },
                    billing: {
                      phoneNumber: $scope.order.customerValue.mobile,
                      countryCode: $scope.order.shippingAddress.country,
                      name: $scope.order.customerValue.name + $scope.order.customerValue.lastName
                    },
                    shipping: {
                      countryCode: $scope.order.shippingAddress.country,
                      phoneNumber: $scope.order.customerValue.mobile,
                      name: $scope.order.customerValue.name + $scope.order.customerValue.lastName,
                      postcode: $scope.order.shippingAddress.zip,
                      line1: $scope.order.shippingAddress.address,
                    },
                    items: [],
                    shippingAmount: { 
                      currency: "EUR", 
                      amount: $scope.order.shippingCost + ""
                    },
                    taxAmount: { 
                      currency: "EUR", 
                      amount: $scope.order.totalTaxes.total + ""
                    },
                    type: "online",
                    product: $scope.order.total < $scope.scalapay.amount_threshold_installments ?  "pay-in-3" : "pay-in-4",
                    frequency: { 
                      number: 1, frequencyType: "monthly" 
                    },
                    orderExpiryMilliseconds: 600000,
                    merchantReference: $scope.order.id
                  };

                  $scope.order.products.forEach(function (product) {
                    orderTempData.items.push({
                      price: { 
                        currency: "EUR", 
                        amount: product.total + ""
                      },
                      quantity: product.quantity,
                      name: product.productValue.name,
                      category: product.productValue.category.niceName,
                      sku: product.productValue.reference
                    });
                  });

                  $http({
                    method: 'post',
                    url: appConfig.localApiBasePath + '/' + 'scalapay',
                    data: JSON.stringify({
                      order_id: $scope.order.id,
                      scalapay: orderTempData
                    })
                  }).then(
                    function(res) {
                      window.location.href = res.data.response.checkoutUrl;
                    },
                    function(err) {}
                  );
                } else {
                  $location.path(
                    $rootScope.url('store-payment-' + $scope.paymentType, {
                      id: $scope.order.id,
                    })
                  );
                }
                $scope.errMsg = null;
              }
              processing = false;
            },
            function (results) {
              errorHandling(results.data);
              processing = false;
            }
          );
        }
        function generateScalaOrder(order){

        }

        function errorHandling(response) {
          // 1 - order items undefined or <
          // 2 - item no valid
          // 3 - item does not exist
          // 4 - invalid stock
          // 5 - invalid shipping address
          // 6 - unknown order type
          // 7 - unathorized

          // coupon errors
          // 20 - Discount code applied correctly
          // 21 - date range
          // 22 - Discount exceded valid uses
          // 23 - Discount not match user
          // 24 - Discount not match any product of your cart
          // 25 - Invalid discount code
          // 26 - You can't mix coupon codes
          // 27 - no estan todos los productos de un pack
          // 28 - Orden fuera del rango de precios
          // 29 - Error aplicando el descuento

          var data = response.error;

          if (data) {
            // item does not exist / no stock
            if (data.errCode == 3 || data.errCode == 4) {
              //Get product names to show error
              var products = data.data.map(function (d) {
                return d['name'] + ' (Ref: ' + d['reference'] + ')';
              });

              products = products.toString().replace(/,/g, ', ');

              //Remove Invalid items from the cart
              data.data.forEach(function (p) {
                //If product is not avaliable/valid remove it from the cart
                if (data.errCode == 3) {
                  ngCart.removeItemByNiceName(p._niceName);
                } else {
                  //If stock is not valid update it
                  if (p.stock > 0) {
                    var item = ngCart.getItemByNiceName(p._niceName);
                    data.setQuantity(p.stock, false);
                  } else {
                    ngCart.removeItemByNiceName(p._niceName);
                  }
                }
              });

              var sufix = data.data.length > 1 ? '_plural' : '_singular';
              var v = data.errCode.toString();

              $scope.errMsg =
                $rootScope.translations.shop.order_checkout.errors[
                  v + 'a' + sufix
                ] +
                ' ' +
                products +
                ' ' +
                $rootScope.translations.shop.order_checkout.errors[
                  v + 'b' + sufix
                ];
            } else {
              var v = data.errCode > 5 ? 'other' : data.errCode;

              if ($rootScope.translations.shop.order_checkout.errors[v])
                $scope.errMsg =
                  $rootScope.translations.shop.order_checkout.errors[v];
              else {
                $scope.voucherMessage = '';
                var removeVoucher = false;

                if (data !== null && data.length > 0) {
                  data.forEach(function (item) {
                    // coupon errors
                    if (item.errorCode >= 21 && item.errorCode <= 29) {
                      removeVoucher = true;
                      $scope.voucherMessage +=
                        '<p>' +
                        $rootScope.translations.shop.cart.promotional_code
                          .messages[item.errorCode] +
                        '</p>';
                    }
                  });
                } else {
                  $log.info('data is null');
                }

                if (removeVoucher) {
                  $scope.voucherIsApplied = false;
                  ngCart.setCoupon(null);
                  ngCart.setDiscount(response.failingOrder.totalDiscount);
                  ngCart.setTotalDiscount(null); /* ??? */
                }

                $scope.order = response.failingOrder;
              }
            }
          }
        }

        function validateOrder() {
          var cart = _.clone(ngCart.getCart());
          var userNiceName = webStorage.get('userNiceName') || 'guest';
          processing = true;

          if (ngCart.getCoupon()) {
            var coupon = ngCart.getCoupon();
            $scope.voucher = coupon;
            cart.voucher = {
              code: coupon,
            };
          }

          paymentSvc.validateOrder(cart, userNiceName).then(
            function (results) {
              console.log('validateOrder: ', results);

              ngCart.setDiscount(results.data.totalDiscount);
              ngCart.setTotalDiscount(results.data.total); /* ??? */

              if (results.status == 200) {
                $scope.order = results.data;

                if (
                  $scope.order.voucher &&
                  $scope.order.voucher.type === 'affiliate'
                ) {
                  $scope.voucherIsApplied = true;

                  var currentAffiliate = AffiliatesFactory.getActive();

                  if (currentAffiliate !== results.data.extra.affiliate)
                    AffiliatesFactory.removeActive();

                  AffiliatesFactory.setActive(results.data.extra.affiliate);

                  ngCart.setDiscountType('affiliate');

                  $scope.voucherMessage =
                    '<p>' +
                    $rootScope.translations.shop.cart.promotional_code.messages
                      .valid_discount +
                    '</p>';
                } else {
                  //console.log('ORDER:', $scope.order);

                  if (!toolsService.isEmpty($scope.order.voucher)) {
                    if (
                      !toolsService.isEmpty($scope.order.voucher.status) &&
                      $scope.order.voucher.status == 'applied'
                    ) {
                      $scope.voucherIsApplied = true;

                      ngCart.setCoupon($scope.order.voucher.code);
                      ngCart.setDiscountType('voucher');

                      if ($scope.order.voucher.type == 'fixedPrice') {
                        for (var i in results.data.products) {
                          var responseProduct = results.data.products[i];

                          if (responseProduct.unitPriceWithDiscount) {
                            var my_item = ngCart.getItemByNiceName(
                              responseProduct.niceName
                            );

                            if (my_item)
                              my_item.setVoucherPrice(
                                responseProduct.unitPriceWithDiscount
                              );
                          }
                        }

                        ngCart.setIsOrderDiscount(false);
                      } else {
                        ngCart.setIsOrderDiscount(true);
                      }

                      ngCart.setDiscountMultiplier(
                        $scope.order.voucher.multiplier
                      );
                    } else {
                      $scope.voucherIsApplied = false;
                      ngCart.setCoupon(null);
                    }

                    $scope.voucherMessage =
                      '<p>' +
                      $rootScope.translations.shop.cart.promotional_code
                        .messages.valid_discount +
                      '</p>';
                  }
                }
              }

              $scope.errMsg = null;
              processing = false;
            },
            function (results) {
              errorHandling(results.data);
              processing = false;
            }
          );
        }
      },
    ])

    .factory('paymentSvc', [
      '$rootScope',
      '$http',
      '_',
      'appConfig',
      'sessionService',
      '$cookies',
      'AffiliatesFactory',
      function (
        $rootScope,
        $http,
        _,
        appConfig,
        sessionService,
        $cookies,
        AffiliatesFactory
      ) {
        function insertOrder(order, userNiceName) {
          return handleOrder(order, userNiceName, 'new');
        }

        function validateOrder(order, userNiceName) {
          return handleOrder(order, userNiceName, 'calc');
        }

        function handleOrder(order, userNiceName, type) {
          var leanOrder = cleanCart(order);

          console.log('CLEAN CART: ', leanOrder);

          return $http({
            method: 'post',
            headers: {
              Authorization: 'Bearer ' + sessionService.getUserToken(),
            },
            url:
              appConfig.localApiBasePath +
              '/order/' +
              type +
              '/' +
              userNiceName,
            data: JSON.stringify(leanOrder),
          });
        }

        function cleanCart(fullCart) {
          // if (ngCart.getOrderId()) {
          //   cart.orderId = ngCart.getOrderId();
          // }

          if (AffiliatesFactory.getActive() != null) {
            fullCart.affiliate = AffiliatesFactory.getActive();
          }

          if (AffiliatesFactory.getExternal() != null) {
            fullCart.external_affiliate = AffiliatesFactory.getExternal();
          }

          if ($cookies.get('initialTrafficSource') != null) {
            fullCart.its = $cookies.get('initialTrafficSource');
          }

          if ($cookies.get('lastTrafficSource') != null) {
            fullCart.lts = $cookies.get('lastTrafficSource');
          }

          var leanCart = _.pick(fullCart, [
            'shipping',
            'shippingAddress',
            'taxRate',
            'voucher',
            'comments',
            'sendAsGift',
            'customerValue',
            'orderId',
            'affiliate',
            'external_affiliate',
            'lts',
            'its',
            'payment'
          ]);
          var leanItems = [];

          fullCart.items.forEach(function (item) {
            var leanItem = _.pick(item, [
              '_niceName',
              '_name',
              '_price',
              '_quantity',
              '_data',
              '_landing',
            ]);

            delete leanItem.$$hashKey;
            delete leanItem._data.relatedProducts;
            delete leanItem._data.$$hashKey;

            leanItems.push(leanItem);
          });

          leanCart.items = leanItems;
          return leanCart;
        }

        return {
          insertOrder: insertOrder,
          validateOrder: validateOrder,
        };
      },
    ])

    .factory('orderSvc', [
      '$rootScope',
      '$http',
      '$q',
      '_',
      'appConfig',
      'sessionService',
      function ($rootScope, $http, $q, _, appConfig, sessionService) {
        // Analytics.trackCheckout(1);
        // Analytics.trackEvent('Cart', 'view', 'carrito');

        function get(orderId, signature, merchant) {
          return $http({
            method: 'get',
            url:
              appConfig.localApiBasePath +
              '/order/' +
              orderId +
              '?signature=' +
              signature +
              '&merchant=' +
              merchant,
          });
        }

        function markAsSent(orderId) {
          return new Promise(function (resolve, reject) {
            $http({
              method: 'post',
              url: appConfig.localApiBasePath + '/order/analytics/',
              headers: {
                Authorization: 'Bearer ' + sessionService.getUserToken(),
              },
              data: { order: orderId },
            }).then(
              function (result) {
                resolve(result.data.response);
              },
              function (err) {
                reject(err);
              }
            );
          });
        }

        return {
          get: get,
          markAsSent: markAsSent,
        };
      },
    ])

    .directive('orderSummary', [
      'timestamp',
      function (timestamp, $cookies, $rootScope) {
        return {
          restrict: 'E',
          scope: false,
          templateUrl: function (element, attrs) {
            if (typeof attrs.templateUrl == 'undefined') {
              return (
                '/template/store/checkout/order-summary.html?rev=' + timestamp
              );
            } else {
              return attrs.templateUrl;
            }
          },
          link: function ($scope) {
            $scope.isDiscounted = function (product) {
              return $scope.getProductDiscount(product) > 0;
            };

            $scope.isBadDiscount = function (product) {
              return (
                $scope.order.voucher &&
                $scope.order.voucher.status &&
                $scope.order.voucher.status == 'applied' &&
                product.unitDiscount &&
                $scope.getProductDiscount(product) <= 0
              );
            };

            $scope.getDiscountOrigin = function (product) {
              var productDiscountOrigin =
                $rootScope.translations.shop.cart.discount_origin.shop;

              if (
                $scope.order.voucher &&
                $scope.order.voucher.status &&
                $scope.order.voucher.status == 'applied' &&
                product.unitDiscount
              ) {
                productDiscountOrigin = $scope.order.voucher.code;
              } else if (
                !(
                  $scope.order.voucher &&
                  $scope.order.voucher.status &&
                  $scope.order.voucher.status == 'applied'
                )
              ) {
                if (product._landing) {
                  productDiscountOrigin =
                    $rootScope.translations.shop.cart.discount_origin.landing;
                } else if (product._affiliate) {
                  productDiscountOrigin =
                    $rootScope.translations.shop.cart.discount_origin.affiliate;
                }
              }

              return productDiscountOrigin;
            };

            $scope.getDiscountString = function () {
              return $scope.order && $scope.order.voucher
                ? $scope.order.voucher.multiplier > 1
                  ? $rootScope.translations.shop.cart.discount +
                    ' ' +
                    $scope.order.voucher.code +
                    ' x' +
                    $scope.order.voucher.multiplier
                  : $rootScope.translations.shop.cart.discount +
                    ' ' +
                    $scope.order.voucher.code
                : '';
            };

            $scope.getProductDiscount = function (product) {
              var priceBase =
                product.productValue.originalPrice ||
                product.productValue.price;
              var priceDiscounted =
                product.unitPrice || product.productValue.price;

              if (
                $scope.order.voucher &&
                $scope.order.voucher.status &&
                $scope.order.voucher.status == 'applied'
              ) {
                if (!product.unitDiscount) {
                  priceDiscounted = product.productValue.price;
                } else {
                  priceDiscounted = product.unitPriceWithDiscount;
                }
              }

              return priceBase && priceDiscounted
                ? priceBase - priceDiscounted
                : 0;
            };

            /* Retornará el total de la orden sin descuento. */
            $scope.getSubTotal = function () {
              return $scope.order
                ? $scope.order.products.reduce(function (acum, prod) {
                    return (
                      acum + $scope.getEffectivePrice(prod) * prod.quantity
                    );
                  }, 0)
                : 0;
            };

            $scope.getEffectivePrice = function (product) {
              if (
                $scope.order.voucher &&
                $scope.order.voucher.status &&
                $scope.order.voucher.status == 'applied'
              ) {
                return product._landing || product._affiliate
                  ? product.unitPriceWithDiscount || product.productValue.price
                  : product.unitPriceWithDiscount || product.unitPrice;
              } else {
                return product.unitPrice;
              }
            };

            $scope.isOrderDiscount = function () {
              return $scope.order
                ? $scope.order.voucher &&
                    $scope.order.voucher.status &&
                    $scope.order.voucher.status == 'applied' &&
                    $scope.order.voucher.type != 'fixedPrice' &&
                    $scope.order.voucher.type != 'affiliate' &&
                    $scope.order.totalDiscount > 0
                : false;
            };
          },
        };
      },
    ]);
})();
