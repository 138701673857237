(function () {
  'use strict';

  angular
    .module('mainApp')

    .directive('totalMinutesCheck', [
      'toolsService',
      function (toolsService) {
        //Check speed - time
        return {
          require: 'ngModel',
          link: function (scope, elem, attrs, ctrl) {
            scope.$watch(attrs.ngModel, function (newValue, oldValue) {
              var isValidTime = true;
              var recipe = scope.recipe;
              if (!toolsService.isEmpty(scope.recipe)) {
                if (newValue == '0') {
                  isValidTime =
                    !toolsService.isEmpty(recipe) &&
                    !toolsService.isEmpty(recipe.totalTimeH) &&
                    recipe.totalTimeH != '0' &&
                    !isNaN(recipe.totalTimeH);
                } else if (!isNaN(newValue) && !isNaN(recipe.totalTimeH)) {
                  scope.forms.basicInformationForm.cf_duracion_total.$setValidity(
                    'totalhourserror',
                    true
                  );
                }
                ctrl.$setValidity('totalminuteserror', isValidTime);
              }
            });
          },
        };
      },
    ])

    .directive('totalHoursCheck', [
      'toolsService',
      function (toolsService) {
        //Check speed - time
        return {
          require: 'ngModel',
          link: function (scope, elem, attrs, ctrl) {
            scope.$watch(attrs.ngModel, function (newValue, oldValue) {
              var isValidTime = true;
              var recipe = scope.recipe;
              if (!toolsService.isEmpty(scope.recipe)) {
                if (newValue == '0') {
                  isValidTime =
                    !toolsService.isEmpty(recipe) &&
                    !toolsService.isEmpty(recipe.totalTimeM) &&
                    recipe.totalTimeM != '0' &&
                    !isNaN(recipe.totalTimeM);
                } else if (!isNaN(newValue) && !isNaN(recipe.totalTimeM)) {
                  scope.forms.basicInformationForm.cf_mincombo_0.$setValidity(
                    'totalminuteserror',
                    true
                  );
                }
                ctrl.$setValidity('totalhourserror', isValidTime);
              }
            });
          },
        };
      },
    ])

    .directive('stepTimeCheck', [
      'toolsService',
      function (toolsService) {
        //Check speed - time
        return {
          require: 'ngModel',
          link: function (scope, elem, attrs, ctrl) {
            scope.$watchGroup(
              [
                'step.auxFields.time_hours',
                'step.auxFields.time_min',
                'step.auxFields.time_sec',
              ],
              function (newValues, oldValues, scope) {
                var isValidTime = true;

                // valida que en caso de que min sea 0, haya al menos 5 seg
                var times = scope.step.auxFields;

                if (times) {
                  var total_step_time =
                    parseInt(times.time_hours, 10) * 3600 +
                    parseInt(times.time_min, 10) * 60 +
                    parseInt(times.time_sec, 10);

                  if (total_step_time == 0) isValidTime = false;
                  ctrl.$setValidity('mintimeerror', isValidTime);
                }
              }
            );
          },
        };
      },
    ])

    .directive('stepTimeMycookCheck', [
      'toolsService',
      function (toolsService) {
        //Check speed - time
        return {
          require: 'ngModel',
          link: function (scope, elem, attrs, ctrl) {
            scope.$watchGroup(
              [
                'step.auxFields.time_hours',
                'step.auxFields.time_min',
                'step.auxFields.time_sec',
                'step.auxFields.step_speed',
              ],
              function (newValues, oldValues, scope) {
                var isValidTime = true;

                // valida que el tiempo esté en el rango adecuado.
                var times = scope.step.auxFields;
                var params = scope.step.params;

                if (times && params.time) {
                  if (scope.step.func !== 'turbo') {
                    var total_seconds =
                      parseInt(times.time_hours, 10) * 3600 +
                      parseInt(times.time_min, 10) * 60 +
                      parseInt(times.time_sec, 10);

                    isValidTime =
                      total_seconds >= params.time.min &&
                      total_seconds <= params.time.max;
                  }

                  //console.log(total_seconds, params, times);
                  ctrl.$setValidity('timerangeerror', isValidTime);
                }
              }
            );
          },
        };
      },
    ])

    // .directive('dynamicName', function ($compile, $parse) {
    //   return {
    //     restrict: 'A',
    //     terminal: true,
    //     priority: 1000000,
    //     link: function (scope, elem) {
    //       var backup = elem.attr('dynamic-name');
    //       var name = $parse(elem.attr('dynamic-name'))(scope);
    //       // console.log('INterpolated:', name);
    //       // console.log('parsed:', $parse(elem.attr('dynamic-name'))(scope));
    //       // $interpolate() will support things like 'skill'+skill.id where parse will not
    //       elem.removeAttr('dynamic-name');
    //       elem.attr('name', "'" + name + "'");
    //       $compile(elem)(scope);
    //     },
    //   };
    // })

    // .directive('stepTimeNoMycookCheck', [
    //   'toolsService',
    //   function (toolsService) {
    //     //Check speed - time
    //     return {
    //       require: 'ngModel',
    //       link: function (scope, elem, attrs, ctrl) {
    //         scope.$watchGroup(
    //           [
    //             'step.auxFields.time_hours',
    //             'step.auxFields.time_min',
    //             'step.auxFields.time_sec',
    //           ],
    //           function (newValues, oldValues, scope) {
    //             ctrl.$setValidity(
    //               'timeerror',
    //               scope.step.auxFields.time_hours ||
    //                 scope.step.auxFields.time_min ||
    //                 scope.step.auxFields.time_sec
    //             );
    //           }
    //         );
    //       },
    //     };
    //   },
    // ])

    .directive('ensureMax', [
      function () {
        return {
          require: 'ngModel',
          link: function (scope, ele, attrs, ctrl) {
            // If you want to watch all the inner properties on the object, you need to add true as the second parameter to the watch. This sets up a deep watch.
            scope.$watch(attrs.ngModel, function (newValue, oldValue) {
              var maxExceeded =
                scope.step &&
                scope.step.accessories &&
                scope.step.accessories.length < attrs.ensureMax;
              ctrl.$setValidity('maxexceeded', maxExceeded); // si es un error -> false
            });
          },
        };
      },
    ])

    .directive('customOnChange', function () {
      return {
        restrict: 'A',
        link: function (scope, element, attrs) {
          var onChangeHandler = scope.$eval(attrs.customOnChange);
          var onChangeArguments = scope.$eval(attrs.customOnChangeArgs);
          element.bind('change', function (event) {
            onChangeHandler(event, onChangeArguments);
          });
        },
      };
    });
})();
