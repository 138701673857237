(function () {
  'use strict';

  var validators = {
    "ES-MYCOOKTOUCH": function (value, ctrl) {

      var validRegex = /^[XYZ]?([0-9]{7,8})([A-Z])$/i;
      var dniLetters = 'TRWAGMYFPDXBNJZSQVHLCKE';

      var valid = false;
      var original_document = value.toUpperCase().replace(/\s/, '');

      if (value && value.length === 9) {
        value = value.toUpperCase().replace(/\s/, '');
        var niePrefix = value.charAt(0);

        switch (niePrefix) {
          case 'X':
            niePrefix = 0;
            break;
          case 'Y':
            niePrefix = 1;
            break;
          case 'Z':
            niePrefix = 2;
            break;
        }
        value = niePrefix + value.substr(1);
        var valid = false;
        if (validRegex.test(value)) {
          valid =
            value.charAt(8) ===
            dniLetters.charAt(parseInt(value, 10) % 23);
        }
      }

      //4594359v
      //X0243228A - NIE
      ctrl.$setValidity('validnif', valid);
      return valid ? original_document : undefined;
    },
    default: function (value) {
      return value;
    }
  }

  angular
    .module('mainApp')  
    .directive('validnif', ["appConfig", function (appConfig) {
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attr, ctrl) {

          var validator = validators[appConfig.region] || validators.default;

          ctrl.$parsers.unshift(function(value) { return validator(value, ctrl) });
        },
      };
    }])

    .directive('postalCodeCheck', [
      'toolsService',
      function (toolsService) {
        // Postal code concords
        return {
          require: 'ngModel',
          link: function (scope, elem, attrs, ctrl) {
            scope.$watchGroup(
              ['userData.state', 'userData.zip', 'states'],
              function (newValues, oldValues, scope) {

                if (scope.states) {

                  var isValidPostalCode = false;

                  if (scope.userData.zip && scope.userData.state && scope.states[scope.userData.state] != "00") {
                    if (scope.subDomain === 'pt') {
                      if(scope.userData.zip.substr(0, 2) == scope.states[scope.userData.state]) {
                        isValidPostalCode = true;
                      }
                    } else {
                      var start_stateCode = scope.states[scope.userData.state] + '000';
                    
                      var end_stateCode = parseInt(start_stateCode) + 1000;

                      if (
                        parseInt(scope.userData.zip) >= parseInt(start_stateCode) &&
                        parseInt(scope.userData.zip) < end_stateCode
                      ) {
                        isValidPostalCode = true;
                      }
                    }
                    
                    ctrl.$setValidity('postalcodeerror', isValidPostalCode);
                  } else {
                    ctrl.$setValidity('postalcodeerror', true);
                  }
                }
              }
            );
          },
        };
      },
    ])

    .controller('StoreShippingController', [
      '$scope',
      '$rootScope',
      '$http',
      '$anchorScroll',
      '$location',
      'webStorage',
      'toolsService',
      'legalTerms',
      'ngCart',
      'appConfig',
      'usersManager',
      'storeFactory',
      'serviceStoreAnalytics',
      'ngDialog',
      function (
        $scope,
        $rootScope,
        $http,
        $anchorScroll,
        $location,
        webStorage,
        toolsService,
        legalTerms,
        ngCart,
        appConfig,
        usersManager,
        storeFactory,
        serviceStoreAnalytics,
        ngDialog
      ) {
        serviceStoreAnalytics.checkoutProcess(3);

        // get state list
        storeFactory.getStates().then(function (states) {
          $scope.states = states;
        });

        var regions;
        // get region list
        storeFactory.getRegions().then(function (rs) {
          regions = rs;
        });

        var userInfo;
        $scope.showShippingForm = false;
        $scope.userData = {};
        $scope.userData.privacy = false;
        $scope.selectedShippingAddress = {};

        var currentAddress = ngCart.getShippingAddress();
        if (currentAddress) {
          $scope.userData = currentAddress;
          $scope.userData.privacy = true;
          $scope.showShippingForm = true;
        }

        var subdomain = window.location.host.split('.')[0].toLowerCase();
        $scope.subDomain = subdomain;
        $scope.zipPattern = subdomain === 'pt' ? /^([0-9]{4,4})([-]{1,1})([0-9]{3,3})$/ : /^[0-9]*$/;
        $scope.zipMinLength = subdomain === 'pt' ? 8 : 5;
        $scope.zipMaxLength = subdomain === 'pt' ? 8 : 5;

        $scope.$watch(
          'currentUser.niceName',
          function (currentUserNiceName) {
            if (currentUserNiceName) {
              var url =
                appConfig.localApiBasePath + '/customer/' + currentUserNiceName;

              $http({
                method: 'GET',
                url: url,
              }).then(function (res) {
                $scope.addresses = res.data.address;
                console.log('ADDRESSES: ', $scope.addresses);
                userInfo = res.data;
              });

              usersManager.getMachineList().then(function (result) {
                if (result.length > 0) {
                  ngCart.setMycookSerialNumber(result[0].niceName);
                }
              });
            }
          },
          true
        );

        $scope.loadAddressAsGuest = function () {
          $scope.showShippingForm = true;
        };

        $scope.addNewShippingAddress = function () {
          $scope.showShippingForm = true;
          $scope.userData = {};
          $scope.userData.privacy = true;

          $scope.selectedShippingAddress = $scope.userData;
        };

        $scope.userShippingAddress = function (address) {
          $scope.showShippingForm = true;
          $scope.userData = address;
          $scope.userData.privacy = true;

          $scope.selectedShippingAddress = address;
          $scope.userData.mail = userInfo.email;
        };

        $scope.acceptContract = function (validForm, userLegalData) {
          $scope.signature_contract = 'loading';
          $scope.contract_not_accepted = false;
          var conditionsArray = [];
          for (var k in userLegalData) {
            if (userLegalData[k]) {
              conditionsArray.push(k);
            }
          }
          legalTerms
            .acceptLegalTerms('multi', conditionsArray, $scope.userData.mail)
            .then(function (result) {
              ngCart.setShippingAddress($scope.userData);
              $location.path($rootScope.url('store-payment'));
            })
            .catch(function (error) {
              console.error(
                'NewsletterContractController -> Error accepting legalTerms',
                error
              );
            });
        };

        $scope.cancelContract = function (userLegalData) {
          $scope.signature_contract = 'deactivated';
          $scope.contract_not_accepted = true;
        };

        $scope.next = function () {
          //$scope.userData.country = "España";
          $scope.userData.country = appConfig.countryCode || 'ES';
          $scope.userData.region = regions[$scope.userData.state];

          if (!toolsService.isEmpty($scope.currentUser)) {
            ngCart.setShippingAddress($scope.userData);
            $location.path($rootScope.url('store-payment'));
          } else {
            $scope.userLegalData = {};
            $scope.anonymousUserData = true;
            legalTerms
              .getLegalTerms(['ecommerceGuestConditions'])
              .then(function (legal) {
                $scope.legal = legal;
                $scope.signature_contract = 'activated';
              });
          }
        };
      },
    ]);
})();
