(function () {
  'use strict';

  angular
    .module('mainApp')

    .controller('FavoriteRecipesByCategoryController', [
      '$rootScope',
      '$scope',
      '$routeParams',
      '$log',
      'toolsService',
      'appConfig',
      'recipesFactory',
      'seoService',
      'recipeListService',
      'usersManager',
      'followersService',
      '$location',
      function (
        $rootScope,
        $scope,
        $routeParams,
        $log,
        toolsService,
        appConfig,
        recipesFactory,
        seoService,
        recipeListService,
        usersManager,
        followersService,
        $location
      ) {
        var criteria = {};
        var pageQuery = '?';
        var category = $routeParams.categoryNiceName;

        $scope.allowedCompats = appConfig.allowedCompats || ['S1'];

        //Allowed Compats
        var registeredWatch = $scope.$watch(
          'currentUser',
          function (currentUser) {
            if (currentUser) {
              if (!currentUser.role.includes('admin')) {
                $scope.allowedCompats = appConfig.allowedCompats || ['S1'];
              } else {
                $scope.allowedCompats = appConfig.allowedCompatsAdmin || [
                  'S1',
                  'S2',
                ];
              }
            }
          }
        );

        $scope.$on('$destroy', function () {
          registeredWatch();
        });

        $scope.showDeleteDialog = function (niceName, title) {
          recipeListService.showDeleteDialog(niceName, title);
        };

        $scope.showPublishDialog = function (recipe, title) {
          recipeListService.showPublishDialog(
            recipe,
            title,
            $scope.deviceDetector.raw.userAgent
          );
        };

        $scope.badgeIsDeactivate = function (bdgCode, userBadges) {
          var isDeactivate =
            toolsService.isEmpty(bdgCode) ||
            toolsService.isEmpty(userBadges) ||
            userBadges.indexOf(bdgCode) === -1;
          return isDeactivate;
        };

        var currentPage = $routeParams.currentPage
          ? $routeParams.currentPage
          : 1;
        $scope.currentPage = parseInt(currentPage);

        var authorNiceName = $routeParams.user;
        usersManager
          .getUserInfo(authorNiceName, 'public')
          .then(function (data) {
            $log.debug(
              'FavoriteRecipesByCategoryController > getUserInfo DATA: ',
              data
            );
            $scope.currentAuthor = data;
          });

        if (category != $rootScope.translations.common.category_all.link) {
          criteria.category = category;
        }

        recipesFactory.getTags().then(function (result) {
          var tagsBundle = result;

          $scope.tags = tagsBundle.tags;
          $scope.tagsDietas = tagsBundle.tagsDietas;

          criteria['status.favorites'] = 1;

          var authorNiceName = $routeParams.user;
          usersManager.getUserInfo(authorNiceName, 'public').then(
            function (data) {
              $log.debug(
                'FavoriteRecipesByCategoryController > getUserInfo DATA: ',
                data
              );
              $scope.currentAuthor = data;
            },
            function (error) {
              toolsService.manageError(
                'FavoriteRecipesByCategoryController > getAuthorInfo error',
                error,
                true
              );
            }
          );

          var orderInfo = recipeListService.setOrderFilters(
            criteria,
            pageQuery
          );
          pageQuery = orderInfo.pageQuery;
          criteria = orderInfo.criteria;

          $scope.pageQuery = pageQuery;
          $scope.criteria = criteria;

          $scope.$emit('LOAD');

          recipesFactory
            .getRecipesByCriteria(criteria, $scope.currentPage)
            .then(function (responseBundle) {
              var pageTitle =
                $rootScope.translations.menu.user_logged.my_favorite_recipes;

              seoService.setTitle(pageTitle, $scope.currentPage);
              seoService.setDescription('');
              $scope.recipesTitle = pageTitle;

              // Aplicamos modificaciones a la respuesta para darle formato
              $scope.contentData = responseBundle.result;
              console.log(responseBundle.result);

              // Paginacion
              var _maxCount = responseBundle.status.count;
              $scope.maxCount = _maxCount;
              $scope.itemsByPage = appConfig.itemsByPage;
              $scope.lastPage =
                _maxCount > appConfig.itemsByPage
                  ? Math.ceil(_maxCount / appConfig.itemsByPage)
                  : 1;

              seoService.setPrevPage($scope.currentPage, $scope.pageQuery);
              seoService.setNextPage(
                $scope.currentPage,
                $scope.pageQuery,
                $scope.lastPage
              );

              $scope.$emit('UNLOAD');
            })
            .catch(function (error) {
              toolsService.manageError(
                'CategoryController > getRecipesByCriteria > ERROR1: ',
                error
              );
            });
        });

        followersService.getList(authorNiceName).then(
          function (data) {
            $scope.followersCount = data.followersCount;
            $scope.followingCount = data.followingCount;
          },
          function (error) {
            toolsService.manageError(
              'RecipesByAuthor -> getListFollowers  > ERROR',
              error,
              true
            );
          }
        );

        $scope.goListFollows = function (type) {
          $location.path(
            $rootScope.url('followers', { type: type, user: authorNiceName })
          );
        };

        $scope.showDietPlanDialog = function (recipe) {
          $scope.recipeToDietPlan = recipe;
          $scope.showDietPlan = true;
        };

        $scope.closeDietPlanDialog = function () {
          $scope.recipeToDietPlan = null;
          $scope.showDietPlan = false;
        };
      },
    ]);
})();
