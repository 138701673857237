(function () {
  'use strict';

  angular.module('mainApp')

    .service('serviceBadges', function ($http, $q, appConfig) {

      var getBadges = function () {

        var defer = $q.defer();
        var ruta = appConfig.mycookTouchDomain + "/badges/";

        $http({
          method: 'GET',
          url: ruta,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }).then(function (res) {
          defer.resolve(res.data);
        });
        return defer.promise;
      };

      return {
        getBadges: getBadges
      };
    })

    .service('serviceRanks', function ($http, $q, $log, appConfig, sessionService, toolsService) {

      var getRanks = function () {
        var deferredRanks = $q.defer();
        var ranksBundle = sessionService.getValue('ranks');

        if (!toolsService.isEmpty(ranksBundle)) {
          $log.debug("Service API > reaprovecho ranksBundle de sessionService: ", ranksBundle);
          deferredRanks.resolve(ranksBundle);
        } else {
          $log.debug("Service API > Cargando ranks");
          var urlDestino = appConfig.mycookTouchDomain + '/ranks/';
          $http({
            method: 'get',
            url: urlDestino
          }).then(function (ranks) {
            $log.debug("Service API > Ranks loaded", ranks);
            ranksBundle = ranks.data;
            sessionService.setValue('ranks', ranksBundle);
            deferredRanks.resolve(ranksBundle);
          });
        }

        return deferredRanks.promise;
      };

      return {
        getRanks: getRanks
      };
    })

    .service('serviceApiBlog', function ($rootScope, $http, $q, $location, $route, appConfig, sessionService) {

      var getPosts = function (categoria, searchText, skip, limit) {

        var defer = $q.defer();
        var url = appConfig.localApiBasePath + '/posts' + "?skip=" + skip + "&limit=" + limit;
        var queryData = {};
        if (searchText) {
          queryData.query = {};
          var queryTitle = {
            title: {
              $regex: searchText,
              $options: 'i'
            }
          };
          var queryShortDescription = {
            shortDescription: {
              $regex: searchText,
              $options: 'i'
            }
          };
          var queryDescription = {
            description: {
              $regex: searchText,
              $options: 'i'
            }
          };
          queryData.query.$or = [queryTitle, queryShortDescription, queryDescription];
        }
        if (categoria) {
          queryData.query = {};
          queryData.query.category = {
            $regex: categoria,
            $options: "i"
          };
        }

        var header = {
          'Content-Type': 'application/json'
        };

        if (sessionService.getUserToken()) {
          header.Authorization = 'Bearer ' + sessionService.getUserToken();
        }

        $http({
          method: 'POST',
          url: url,
          headers: header,
          data: queryData
        }).then(function (res) {
          var data = res.data;

          data.result.forEach(function (item, i) {
            if (item.date) {
              item.date = item.date.substring(0, 10);
            }
          });

          defer.resolve(data);
        });

        return defer.promise;

      };

      var getPost = function (niceName) {

        var defer = $q.defer();
        var url = appConfig.localApiBasePath + '/posts?skip=0&limit=1&sortBy=-date';

        var data = {
          query: {
            "cms.url.slug": niceName,
            "language": appConfig.locationLanguage
          }
        };

        var header = {
          'Content-Type': 'application/json'
        };

        if (sessionService.getUserToken()) {
          header.Authorization = 'Bearer ' + sessionService.getUserToken();
        }

        $http({
          method: 'POST',
          url: url,
          headers: header,
          data: data
        }).then(function (res) {

          //fix the date format
          if (res.data && res.data.result[0] && res.data.result[0].date) {
            res.data.result[0].date = res.data.result[0].date.substring(0, 10);
          }

          defer.resolve(res.data.result[0]);
        });

        return defer.promise;
      };

      var getCategories = function (niceName) {

        var defer = $q.defer();
        var url = appConfig.localApiBasePath + '/postcategories';

        $http({
          method: 'GET',
          url: url,
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(function (res) {
          defer.resolve(res.data.result);
        });

        return defer.promise;
      };

      var getLastPosts = function () {

        var defer = $q.defer();
        var url = appConfig.localApiBasePath + '/posts?skip=0&limit=4&sortBy=-date';

        $http({
          method: 'POST',
          url: url,
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(function (res) {
          defer.resolve(res.data.result);
        });

        return defer.promise;

      };

      var searchPosts = function (text) {
        if (text) {
          $location.path($rootScope.url('blog')).search({
            search: text
          });
          $route.reload();
        } else {
          $location.path($rootScope.url('blog'));
        }
      };

      return {
        getPosts: getPosts,
        getPost: getPost,
        getCategories: getCategories,
        getLastPosts: getLastPosts,
        searchPosts: searchPosts
      };

    });

  function filterCategories(data, category) {

    var filteredPosts = [];

    data.forEach(function (item, i) {

      for (var j = 0; j < item.category.length; j++) {
        if ((item.category[j].cms.url.slug == category) || (item.category[j].text == category)) { //el poso a la array filtrada
          filteredPosts.push(item);
          break;
        }
      }
    });

    return filteredPosts;
  }
})();
