(function () {
    angular.module('mainApp')
  
      .factory('affiliatesLegalTermsFactory', ['$http', '$q', 'appConfig', 'toolsService', '$routeParams',
        function ($http, $q, appConfig, toolsService, $routeParams) {
          var _legalTermsFactory = {}
  
          _legalTermsFactory.checkToken = function (token) {

            if(!token) {
              token = $routeParams.token;
            }

            deferredResponse = $q.defer();
    
            var urlDestino = appConfig.localApiBasePath + '/affiliates/checkToken/' + token;
            $http({
              method: 'get',
              url: urlDestino
            }).then(function (res) {
               if(res.statusCode != 400) {
                deferredResponse.resolve(true);
               } else {
                deferredResponse.resolve(false);
               }
            }, function errorCallback(response) {
                deferredResponse.resolve(false);
            });
    
            return deferredResponse.promise;
          }
  
          return _legalTermsFactory
        }])  
  
      .controller('AffiliatesLegalTermsController', [function ($scope, $rootScope, $window, $http, appConfig, sessionService, webStorage, affiliatesLegalTermsFactory, affiliatesUsersManager, $routeParams, $timeout) {

        var token = $routeParams.token;
        var userNiceName = $routeParams.user;
        $scope.changeData = false;

        //webStorage.set('userNiceNameAffiliates', userNiceName);
        sessionService.setUserToken(token, true);

        affiliatesLegalTermsFactory.checkToken(token).then(function (response) {
            if (response == false) { //redirigir a la home
              var urlToRedirect = $rootScope.url('affiliates-home');
              $window.location.href = urlToRedirect;
            } 
        });

        var getLegalTerms = function() {
          var urlDestino = appConfig.localApiBasePath + '/affiliates/legalterms'

            $http({
                method: 'get',
                url: urlDestino
              }).then(function (res) {
                $scope.introductionTitle = res.data.introductionTitle;
                $scope.legalText = res.data.legalText;
                $scope.validationText = res.data.validationText;
                $scope.preChecked = res.data.preChecked;
              }, function errorCallback (response) {
                sessionService.setUserToken("TOKEN", true);
                var urlToRedirect = $rootScope.url('affiliates-home');
                $window.location.href = urlToRedirect;
              })
        }

        var profile;

        affiliatesUsersManager.getAffiliateInfo(userNiceName, profile).then(function (data) {
          $scope.userProfile = data;
        });
        
        $scope.change = function() {
          if ($scope.preChecked) $scope.preChecked = false;
          else $scope.preChecked = true;
        }

        $scope.saveData = function() {
          var urlDestino = appConfig.localApiBasePath + '/affiliates/update'
          $http({
            method: 'post',
            data: $scope.userProfile,
            url: urlDestino,
            headers: {
              'Authorization': 'Bearer ' + sessionService.getUserToken(true)
            }
          }).then(function (res) {
            if (res.status == 200 || res.status == 204) {
              $scope.successChangeData = true;
              $scope.changeData = !$scope.changeData;
            } else {
              $scope.successChangeData = false;
            }
          }, function errorCallback (response) {
            sessionService.setUserToken("TOKEN", true);
            var urlToRedirect = $rootScope.url('affiliates-home');
            $window.location.href = urlToRedirect;
          })
        }

        $scope.changeDataState = function() {
          $scope.changeData = !$scope.changeData;
        }

        $scope.sendLegalTerms = function() {
          if ($scope.preChecked) {
            var urlDestino = appConfig.localApiBasePath + '/affiliates/aceptlegalterms'
  
            var data = {
              'user': userNiceName,  
              'legalTerms': {
                'dateAgreement': new Date().toISOString(),
                'agree': true,
              }
            }
  
            $http({
              method: 'post',
              data: data,
              url: urlDestino,
              headers: {
                'Authorization': 'Bearer ' + sessionService.getUserToken(true)
              }
            }).then(function (res) {
              if (res.status == 200) {
                $scope.successContact = true;
                setTimeout(function(){
                  webStorage.set('userNiceNameAffiliates', userNiceName); 
                  var urlToRedirect = $rootScope.url('affiliates-profile');
                  $window.location.href = urlToRedirect; 
                }, 5000);
              } else {
                $scope.successContact = false;
              }
            }, function errorCallback (response) {
              sessionService.setUserToken("TOKEN", true);
              var urlToRedirect = $rootScope.url('affiliates-home');
              $window.location.href = urlToRedirect;
            })
          } else {
            $scope.successContact = false;
          }
        }

        getLegalTerms();

      }])
  })()
  