(function () {
	'use strict';

	angular.module('mainApp')

		.controller('StoreMyOrdersController', ['$scope', '$rootScope', '$filter', 'toolsService', 'storeFactory', 'webStorage',
			function ($scope, $rootScope, $filter, toolsService, storeFactory, webStorage, $routeParams) {

				var userNiceName = webStorage.get('userNiceName');
				
				//$scope.pageQuery = pageQuery;
				$scope.currentPage = $routeParams.page || 1;
				$scope.maxCount = 1;
				$scope.lastPage = 1;
				$scope.itemsByPage = 50;
				$scope.orders = [];

				storeFactory.getOrders(userNiceName, ($scope.currentPage - 1) * $scope.itemsByPage, $scope.itemsByPage).then(function (data) {

					$scope.maxCount = data.status.search_count;
					$scope.lastPage = Math.ceil($scope.maxCount / $scope.itemsByPage);
					// $scope.orders = $filter('filter')(data.result, { state: '!WaitingPayment' });
					$scope.orders = JSON.parse(JSON.stringify(data.result));

					//console.log("currentPage: ", $scope.currentPage, "maxCount: ", $scope.maxCount, "lastPage: ", $scope.lastPage, "itemsByPage: ", $routeParams)

				});

				$scope.showRma = toolsService.canRma;
			}])

		.controller('StoreOrderController', ['$scope', '$rootScope', '$routeParams', '$location', '$filter', 'appConfig', 'storeFactory', 'webStorage',
			function ($scope, $rootScope, $routeParams, $location, $filter, appConfig, storeFactory, webStorage) {

				$scope.orderId = $routeParams.id;//"63954"

				var userNiceName = webStorage.get('userNiceName');

				var geststockStates = {
					ok: ["SOL", "ENV", "TRA", "ENR", "ENT"],
					ko: {
						"INC": $rootScope.translations.shop.geststocks.incidences.INC, //"Incidencia",
						"AUS": $rootScope.translations.shop.geststocks.incidences.AUS, //"Destinatario ausente",
						"DIR": $rootScope.translations.shop.geststocks.incidences.DIR, //"Dirección incorrecta",
						"MER": $rootScope.translations.shop.geststocks.incidences.MER, //"Falta mercancía",
						"RCH": $rootScope.translations.shop.geststocks.incidences.RCH, //"Rechazado",
						"RTR": $rootScope.translations.shop.geststocks.incidences.RTR, //"Retrasado",
						"NDT": $rootScope.translations.shop.geststocks.incidences.NDT, //"Cambio datos de entrega",
						"RPT": $rootScope.translations.shop.geststocks.incidences.RPT, //"Punto de entrega concertado",
						"CEN": $rootScope.translations.shop.geststocks.incidences.CEN, //"Nueva entrega concertada"
					}
				};

				var trackUrl = "https://taurus.geststocks.com/tracking/seguimiento.php?ctrl=X-TAURUS&ref=";

				function join(t, a, s) {

					function format(m) {
						var f = new Intl.DateTimeFormat('es', m);
						return f.format(t);
					}
					return a.map(format).join(s);
				}

				function getDate(state, order, idx) {

					if (idx) {
						var tracking = order.gestStocks.history.reverse().find(function (info) { return info.state === state });

						return tracking ?
							join(new Date(tracking.date), [{ day: 'numeric' }, { month: 'numeric' }, { year: 'numeric' }], '/') :
							join(new Date, [{ day: 'numeric' }, { month: 'numeric' }, { year: 'numeric' }], '/');

					} else return join(new Date(order.date), [{ day: 'numeric' }, { month: 'numeric' }, { year: 'numeric' }], '/');
				}

				function getSvgInfo(currentStateIdx, stepIdx, rawState) {

					if (stepIdx) {

						if (currentStateIdx === stepIdx)
							return rawState === 'ENT' ? { name: 'ok-tick', size: "50px" } : { name: 'on-delivery', size: "50px" };
						else if (stepIdx < currentStateIdx)
							return { name: 'ok-tick', size: "33px" };
						else if (stepIdx > currentStateIdx)
							return { name: 'order-point', size: "33px" };

					} else return { name: 'ok-tick', size: "33px" };
				}

				if ($scope.orderId) {
					storeFactory.getSingleOrder($scope.orderId, userNiceName).then(function (data) {

						$scope.order = data;

						if ($scope.order) {

							if ($scope.order.gestStocks) {

								var gestData = $scope.order.gestStocks;
								var actualState = $location.search().testState || gestData.currentState;
								var currentStateIdx = geststockStates.ok.indexOf(actualState) + 1;
								var currentIncid = null;

								if (!currentStateIdx) {

									currentIncid = geststockStates.ko[actualState] || null;

									if (currentIncid) {
										currentStateIdx = 4;
									}

								}

								$scope.geststocksInfo = {
									states: [
										{
											name: $rootScope.translations.shop.geststocks.states[0],
											date: getDate("SOL", $scope.order, 0),
											svg: getSvgInfo(currentStateIdx, 0, actualState),
											incid: false
										},
										{
											name: $rootScope.translations.shop.geststocks.states[1],
											date: getDate("SOL", $scope.order, 1),
											svg: getSvgInfo(currentStateIdx, 1, actualState),
											incid: false
										},
										{
											name: $rootScope.translations.shop.geststocks.states[2],
											date: getDate("ENV", $scope.order, 2),
											svg: getSvgInfo(currentStateIdx, 2, actualState),
											incid: false
										},
										{
											name: $rootScope.translations.shop.geststocks.states[3],
											date: getDate("TRA", $scope.order, 3),
											svg: getSvgInfo(currentStateIdx, 3, actualState),
											incid: false
										},
										{
											name: $rootScope.translations.shop.geststocks.states[4],
											date: getDate("ENR", $scope.order, 4),
											svg: getSvgInfo(currentStateIdx, 4, actualState),
											incid: false
										},
										{
											name: $rootScope.translations.shop.geststocks.states[5],
											date: getDate("ENV", $scope.order, 5),
											svg: getSvgInfo(currentStateIdx, 5, actualState),
											incid: false
										}
									],
									currentState: currentStateIdx,
									follow: gestData.clientRef ? trackUrl + gestData.clientRef : null,
									carrier: gestData.carrier,
									rawState: actualState,
									gotInfo: !!gestData.clientRef
								};

								if (currentIncid) {
									$scope.geststocksInfo.states.splice(5, 0, {
										name: currentIncid,
										date: getDate(actualState, $scope.order, 4),
										svg: { name: 'close-small-solid', size: "33px" },
										incid: true
									});
								}
							}

							// SEO
							var seo_title = $rootScope.translations.shop.seo['store-order'].title;
							seo_title += ' ' + $rootScope.translations.seo.separator + ' ' + $rootScope.translations.seo.website_name;
							$rootScope.pageTitle = $filter('translateVariables')(seo_title, $scope.order.id);

						} else {
							$location.path($rootScope.url('notFound'));
						}
					});
				}

			}])

		.controller('StoreRmaController', ['$scope', '$rootScope', '$routeParams', '$location', 'storeFactory', 'webStorage', 'toolsService',
			function ($scope, $rootScope, $routeParams, $location, storeFactory, webStorage, toolsService) {

				var orderId = $routeParams.id;
				var userNiceName = webStorage.get('userNiceName');

				if (orderId) {
					storeFactory.getSingleOrder(orderId, userNiceName).then(function (data) {

						$scope.order = data;

						if ($scope.order) {
							if (!toolsService.canRma($scope.order))
								$location.path($rootScope.url('store-my-orders'));

						} else $location.path($rootScope.url('notFound'));
					});

					var subdomain = window.location.host.split('.')[0].toLowerCase();
        			$scope.subDomain = subdomain;
					$scope.zipPattern = subdomain === 'pt' ? /^([0-9]{4,4})([-]{1,1})([0-9]{3,3})$/ : /^[0-9]*$/;
        			$scope.zipMinLength = subdomain === 'pt' ? 8 : 5;
        			$scope.zipMaxLength = subdomain === 'pt' ? 8 : 5;
				} else $location.path($rootScope.url('store-my-orders'));

			}])

		.directive('rmaForm', ['timestamp', 'storeFactory', 'webStorage', '$rootScope', '$location',
			function (timestamp, storeFactory, webStorage, $rootScope, $location) {
				return {
					restrict: 'E',
					scope: false,
					templateUrl: '/template/store/rma-form.html?rev=' + timestamp,
					link: function ($scope) {

						$scope.toReturn = $scope.order.products.reduce(function (res, p) {

							res[p.productValue.niceName] = { return: false, quantity: 0 };
							return res;

						}, {});

						$scope.userData = $scope.order.shippingAddress || $scope.order.billingAddress;
						$scope.userData.mail = $scope.order.customerValue ? $scope.order.customerValue.email : '';

						storeFactory.getStates().then(function (states) {
							$scope.states = states;
						});

						$scope.setReturn = function (niceName, value) {

							if ($scope.toReturn[niceName]) {
								$scope.toReturn[niceName].return = value;
								$scope.toReturn[niceName].quantity = value ? 1 : 0;
							}
						}

						$scope.canReturn = function () {

							return $scope.form.$valid && Object.keys($scope.toReturn).reduce(function (res, key) {
								return res || $scope.toReturn[key].return
							}, false) && $scope.return_reason;
						}

						$scope.submitForm = function () {

							if ($scope.canReturn()) {

								var address = $scope.userData;
								var mail = address.mail;

								delete address.mail;

								storeFactory.returnProds({
									orderId: $scope.order.id,
									customer: webStorage.get('userNiceName') || "guest",
									products: Object.keys($scope.toReturn)
										.filter(function (key) {

											return $scope.toReturn[key].return;

										})
										.reduce(function (res, key) {

											res.push({ productId: key, quantity: $scope.toReturn[key].quantity });
											return res;

										}, []),
									shippingAddress: address,
									validationMail: $scope.validationMail,
									customerValue: {
										email: mail
									},
									returnReason: $scope.return_reason
								}).then(function () {

									if (webStorage.get('userNiceName'))
										$location.path($rootScope.url('store-my-orders'));
									else
										$location.path('/');

								}, console.error);
							}
						}
					}
				};
			}])

		.directive('noQty', function () {
			return {
				require: 'ngModel',
				link: function (scope, elm, attrs, ctrl) {
					ctrl.$validators.noQty = function (modelValue, viewValue) {
						if (ctrl.$isEmpty(modelValue)) {
							return false;
						}
						return !!modelValue && /^\d+$/.test(viewValue);
					};
				}
			};
		});
})();