/**
 *  - recipeCategoriesFactory
 *  - CategoriesController
 *  - CategoryController
 */

(function () {
  'use strict'

  angular.module('mainApp')
    /**
      Categories Factory
    */

    .factory('recipeCategoriesFactory', ['$http', '$q', '$filter', '$rootScope', '$log', 'appConfig', 'toolsService',
      function ($http, $q, $filter, $rootScope, $log, appConfig, toolsService) {
        var _recipeCategoriesFactory = {}
        var deferredCats

        _recipeCategoriesFactory.getCategories = function () {
          // IMPROVE: faltaría aplicar caché
          if (angular.isUndefined(deferredCats)) {
            deferredCats = $q.defer()
            var urlDestino = appConfig.mycookTouchDomain + '/categories/';

            $http({
              method: 'post',
              headers: {
                'Content-type': 'application/json',
              },
              url: urlDestino,
              data: JSON.stringify({
                "visibility": true,
                "compatibility": appConfig.allowedCompats || []
              })
            }).then(function (res) {
              var cats = res.data
              var totals = res.data[0] ? res.data[0].totals : null;

              console.log("Totals: ", totals);

              // Añadir categoría todas
              var catTodas = {};
              catTodas.name = $rootScope.translations.common.category_all.title;
              catTodas.niceName = $rootScope.translations.common.category_all.link;
              catTodas.active = $rootScope.translations.common.category_all.active;
              catTodas.type = 'all';

              if (totals) {
              
                catTodas.region = res.data[0].region; /* ¿necesario? */

                Object.keys(totals).forEach(function(key) {

                  if (/Count$/.test(key))
                    catTodas[key] = totals[key];

                });
              
              }

              cats.splice(0, 0, catTodas);
              deferredCats.resolve(cats);
            })
          } else {
            $log.debug('recipeCategoriesFactory > getCategories > reaprovecho', deferredCats)
          }

          return deferredCats.promise
        }

        _recipeCategoriesFactory.getCategoryByNiceName = function (catNiceName) {
          if (!toolsService.isEmpty(catNiceName)) {
            return _recipeCategoriesFactory.getCategories().then(function (cats) {
              var found = $filter('filter')(cats, { niceName: catNiceName }, true)

              if (found.length) {
                return found[0]
              }
            })
          } else {
            return $q.when(undefined)
          }
        }

        return _recipeCategoriesFactory
      }
    ])

    .factory('totalReceiptsCategoryFactory', ['$http', '$q', '$log', 'appConfig',
      function ($http, $q, $log, appConfig) {
        var _totalReceiptsCategoryFactory = {}
        var deferredCategory

        _totalReceiptsCategoryFactory.getTotalCategories = function () {
          if (angular.isUndefined(deferredCategory)) {
            deferredCategory = $q.defer()
            var totalUrlRecipients = appConfig.mycookTouchDomain + '/recipes/total'

            $http({
              method: 'get',
              url: totalUrlRecipients
            }).then(function (response) {
              var totalReceipts = response.data
              deferredCategory.resolve(totalReceipts)
            })
          } else {
            $log.debug('recipeCategoriesFactory > getCategories > reaprovecho', deferredCategory)
          }
          return deferredCategory.promise
        }

        return _totalReceiptsCategoryFactory
      }
    ])

    .controller('RecipesCategoryController', ['$scope', 'recipeCategoriesFactory', '_', 'totalReceiptsCategoryFactory', 'recipesFactory',
      function ($scope, recipeCategoriesFactory, _, totalReceiptsCategoryFactory, recipesFactory) {
        console.log('RecipesCategoryController');
        recipeCategoriesFactory.getCategories().then(function (cats) {
          console.log("Categories: ", cats);
          $scope.categories = cats
        });

        recipesFactory.countRecipes().then(function (totalReceipts) {
          $scope.totalReceipts = totalReceipts
        });

        $scope.getTotalReceipts = function () {
          console.log("getTotalReceipts: ", $scope.totalReceipts);
          return $scope.totalReceipts
        };

        $scope.getTotal = function () {
          return _.sumBy(_.map($scope.categories, 'count'))
        };
      }
    ])

})();