(function () {
  angular
    .module('mainApp')

    .factory('postFactory', [
      '$http',
      '$q',
      'appConfig',
      'toolsService',
      function ($http, $q, appConfig, toolsService) {
        var _postFactory = {};

        _postFactory.sendMsg = function (niceName, msg, arrayOfIds) {
          var urlDestino =
            appConfig.localApiBasePath +
            '/post/' +
            niceName +
            '/comment/' +
            (arrayOfIds.length > 0 ? arrayOfIds.join('.') : '');
          var queryData = {};
          queryData = msg;
          return $http({
            method: 'post',
            headers: {
              'Content-type': 'application/json',
            },
            url: urlDestino,
            data: JSON.stringify(queryData),
          });
        };

        return _postFactory;
      },
    ])

    .controller('CommentsController', [
      function (
        $scope,
        $route,
        $rootScope,
        $location,
        $timeout,
        $log,
        $filter,
        appConfig,
        toolsService,
        recipesFactory,
        postFactory
      ) {
        $scope.ignoreClick = false;
        // COMMENTS
        $scope.initBox = function (idBox) {
          $scope.replies.currentLevel = idBox;
          $scope.replies.boxes[idBox] = {};
          $scope.replies.boxes[idBox].isSent = 0;
          $scope.replies.boxes[idBox].msg = '';
          $scope.replies.boxes[idBox].image = {
            file: null,
            rights: false,
            url: '',
          };
        };

        $scope.showReplyDialog = function (idBox) {
          if ($scope.replies.boxes[idBox] === undefined) {
            $scope.replies.boxes[idBox] = {};
          }
          return $scope.replies.boxes[idBox].isSent != 1 && $scope.currentUser;
        };

        $scope.showReplyBox = function (idBox) {
          var replies = $scope.replies;
          if (replies.boxes[idBox] === undefined) {
            replies.boxes[idBox] = {};
          }
          var toShow =
            replies.currentLevel == idBox &&
            replies.boxes[idBox].isSent != 1 &&
            $scope.currentUser;
          return toShow;
        };

        $scope.showPreviewBox = function (idBox) {
          var toShow = $scope.showReplyBox(idBox);
          var replies = $scope.replies;
          return toShow && replies.boxes[idBox].msg !== '';
        };

        $scope.cancelReply = function (idBox) {
          $scope.replies.currentLevel = 'root';
          $scope.replies.boxes[idBox].msg = '';
          $scope.replies.boxes[idBox].image = {
            file: null,
            rights: false,
            url: '',
          };
        };

        $scope.commentIsValid = function (c) {
          var emtpyComment = {
            date: '',
            comments: [
              {
                date: '',
              },
            ],
          };

          var _c = angular.copy(c);
          return c && c != null && !angular.equals(c, emtpyComment);
        };

        $scope.sendReply = function (arrayOfIds) {
          // idBox, id2
          var idComment = arrayOfIds.join('.');
          var level = arrayOfIds.length > 0 ? idComment : 'root';
          var ref = $scope.replies.boxes[level];
          if (
            !toolsService.isEmpty(ref.msg) &&
            !toolsService.isEmpty($scope.currentUser) &&
            !$scope.ignoreClick
          ) {
            // TO-IMPROVE: validar que solo haya enlaces a urls internas, por ahora se filtran todos los casos que no sean admins
            $scope.ignoreClick = true;
            if (
              !(
                $scope.currentUser &&
                $scope.adminRoles.indexOf($scope.currentUser.role) > -1
              )
            ) {
              ref.msg = $filter('stripTags')(ref.msg);
            }
            $log.debug(
              'RecipeController > sendReply! idComment: [',
              idComment,
              '] arrayOfIds usado: ',
              arrayOfIds,
              ' ',
              ref
            );

            if ($rootScope.currentRouteName == 'blogPost') {
              ref.userMail = $scope.currentUser.contact.mail;

              postFactory
                .sendMsg($scope.post.cms.url.slug, ref, arrayOfIds)
                .then(
                  function (msgResponse) {
                    ref.isSent = 1;
                    $scope.replies.currentLevel = 'root';
                    $timeout(function () {
                      if (msgResponse.status == '201') {
                        $scope.responseMsg =
                          $rootScope.translations.blog.comments.comment_message.success;
                        $route.reload();
                      } else {
                        $scope.responseMsg =
                          $rootScope.translations.blog.comments.comment_message.error;
                      }
                    }, appConfig.timeoutDialogues);
                  },
                  function (error) {
                    $log.error(
                      'RecipeController > sendReply >  ERROR: ',
                      error
                    );
                  }
                )
                .finally(function () {
                  $scope.ignoreClick = false;
                });
            } else {
              recipesFactory
                .sendMsg($scope.recipeContent.niceName, ref.msg, arrayOfIds)
                .then(
                  function (msgResponse) {
                    ref.isSent = 1;
                    $scope.replies.currentLevel = 'root';

                    var promise =
                      ref.image.file && ref.image.rights
                        ? recipesFactory.uploadChildImage(
                            $scope.recipeContent.niceName,
                            msgResponse.data.response,
                            ref.image.file[0]
                          )
                        : Promise.resolve();

                    return promise.then(
                      function (imgResponse) {
                        $timeout(function () {
                          if (
                            msgResponse.status == '201' &&
                            imgResponse.status === 200
                          ) {
                            $scope.responseMsg =
                              $rootScope.translations.single_recipe.comment_message.success;
                            $route.reload();
                          } else {
                            $scope.responseMsg =
                              $rootScope.translations.single_recipe.comment_message.error;
                          }
                        }, appConfig.timeoutDialogues);
                      },
                      function (error) {
                        $log.error(
                          'RecipeController > sendReply > uploadChildImage > ERROR: ',
                          error
                        );
                      }
                    );
                  },
                  function (error) {
                    $log.error(
                      'RecipeController > sendReply >  ERROR: ',
                      error
                    );
                  }
                )
                .finally(function () {
                  $scope.ignoreClick = false;
                });
            }
          }
        };

        $scope.discardImage = function (idBox) {
          if ($scope.replies.boxes[idBox].image.url)
            URL.revokeObjectURL($scope.replies.boxes[idBox].image.url);

          $scope.replies.boxes[idBox].image = {
            file: null,
            rights: false,
            url: '',
          };

          document.getElementById(idBox + '-imageFile').value = '';
        };

        $scope.setVirtualUrl = function (event, idBox) {
          if ($scope.replies.boxes[idBox].image.file) {
            if ($scope.replies.boxes[idBox].image.url)
              URL.revokeObjectURL($scope.replies.boxes[idBox].image.url);

            $scope.replies.boxes[idBox].image.url = URL.createObjectURL(
              $scope.replies.boxes[idBox].image.file[0]
            );
          }
        };

        // comments
        $scope.replies = {};
        $scope.replies.boxes = [];
        $scope.initBox('root');
        $scope.oldStyle = false;
      },
    ]);
})();
