(function () {

  'use strict';

  angular.module('mainApp')

    .service("richSearchService", function ($http, $q, appConfig) {
      this.getByTerm = function (term, type) {
        var defer = $q.defer();

        var urlDestino = appConfig.localApiBasePath + '/richsearch/getByTerm';

        $http({
          method: 'post',
          url: urlDestino,
          data: {
            "term": term,
            "type": type
          }
        }).then(function (res) {
          var cats = res.data;
          defer.resolve(cats);
        }, function (error) {
          defer.reject(error);
        });

        return defer.promise;
      };
    });
})();