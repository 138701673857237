(function () {
  'use strict';

  angular
    .module('mainApp')

    .controller('RecipesByAuthorController', [
      '$rootScope',
      '$scope',
      '$filter',
      '$routeParams',
      '$location',
      '_',
      'toolsService',
      'appConfig',
      'recipesFactory',
      'seoService',
      'recipeListService',
      'usersManager',
      'followersService',
      function (
        $rootScope,
        $scope,
        $filter,
        $routeParams,
        $location,
        _,
        toolsService,
        appConfig,
        recipesFactory,
        seoService,
        recipeListService,
        usersManager,
        followersService
      ) {
        $scope.allowedCompats = appConfig.allowedCompats || ['S1'];

        //Allowed Compats
        var registeredWatch = $scope.$watch(
          'currentUser',
          function (currentUser) {
            if (currentUser) {
              if (!currentUser.role.includes('admin')) {
                $scope.allowedCompats = appConfig.allowedCompats || ['S1'];
              } else {
                $scope.allowedCompats = appConfig.allowedCompatsAdmin || [
                  'S1',
                  'S2',
                ];
              }
            }
          }
        );

        $scope.$on('$destroy', function () {
          registeredWatch();
        });

        $scope.followersCount = 0;
        $scope.followingCount = 0;

        var userFollowers = [];

        var criteria = {};
        var pageQuery = '?';
        var currentPage = $routeParams.currentPage
          ? $routeParams.currentPage
          : 1;

        $scope.currentPage = parseInt(currentPage);

        $scope.showDeleteDialog = function (niceName, title) {
          recipeListService.showDeleteDialog(niceName, title);
        };

        $scope.showPublishDialog = function (recipe, title) {
          recipeListService.showPublishDialog(
            recipe,
            title,
            $scope.deviceDetector.raw.userAgent
          );
        };

        $scope.badgeIsDeactivate = function (bdgCode, userBadges) {
          var isDeactivate =
            toolsService.isEmpty(bdgCode) ||
            toolsService.isEmpty(userBadges) ||
            userBadges.indexOf(bdgCode) === -1;
          return isDeactivate;
        };

        var orderInfo = recipeListService.setOrderFilters(criteria, pageQuery);
        pageQuery = orderInfo.pageQuery;
        criteria = orderInfo.criteria;
        criteria.user = $routeParams.user;
        criteria['status.idParent'] = '';
        $scope.pageQuery = pageQuery;
        $scope.criteria = criteria;

        var user = $routeParams.user;

        followersService.getList(user).then(
          function (data) {
            $scope.followersCount = data.followersCount;
            $scope.followingCount = data.followingCount;
          },
          function (error) {
            toolsService.manageError(
              'RecipesByAuthor -> getListFollowers  > ERROR',
              error,
              true
            );
          }
        );

        //REFACTOR Q ALL
        $scope.$watch('currentUser.niceName', function () {
          if ($scope.currentUser) {
            /* Avoid race conditions */
            followersService.getList(user).then(
              function (data) {
                $scope.isFollowing = data.followers.find(function (f) {
                  return f.niceName === $scope.currentUser.niceName;
                })
                  ? true
                  : false;
              },
              function (error) {
                toolsService.manageError(
                  'RecipesByAuthor -> getListFollowers  > ERROR',
                  error,
                  true
                );
              }
            );
          }
        });

        $scope.goListFollows = function (type) {
          $location.path(
            $rootScope.url('followers', { type: type, user: user })
          );
        };

        $scope.addFollowing = function () {
          followersService.addFollowing($scope.currentUser.niceName, user).then(
            function (data) {
              $scope.isFollowing = true;
              $scope.followersCount = $scope.followersCount + 1;
            },
            function (error) {
              toolsService.manageError(
                'RecipesByAuthor -> addFollowing  > ERROR',
                error,
                true
              );
            }
          );
        };
        $scope.removeFollowing = function () {
          followersService
            .removeFollowing($scope.currentUser.niceName, user)
            .then(
              function (data) {
                $scope.isFollowing = false;
                $scope.followersCount = $scope.followersCount - 1;
              },
              function (error) {
                toolsService.manageError(
                  'RecipesByAuthor -> removeFollowing  > ERROR',
                  error,
                  true
                );
              }
            );
        };

        usersManager.getUserInfo(user, 'public').then(
          function (data) {
            $scope.currentAuthor = data;

            console.log('Author data:', data);

            var author = $filter('capitalize')(data.name.displayName);
            $scope.recipesTitle = author;
            seoService.setTitle(
              $filter('translateVariables')(
                $rootScope.translations.seo.authorRecipeList.title,
                author
              )
            );
            seoService.setDescription(
              $filter('translateVariables')(
                $rootScope.translations.seo.authorRecipeList.description,
                author
              )
            );
          },
          function (error) {
            toolsService.manageError(
              'RecipesByAuthor -> getAuthorInfo  > ERROR',
              error,
              true
            );
          }
        );

        recipesFactory.getTags().then(function (tags) {
          var tagsBundle = tags;

          $scope.tags = tagsBundle.tags;
          $scope.tagsDietas = tagsBundle.tagsDietas;

          $scope.$emit('LOAD');

          recipesFactory
            .getRecipesByCriteria(criteria, $scope.currentPage)
            .then(function (responseBundle) {
              $scope.contentData = responseBundle.result;
              // Paginacion
              var _maxCount = responseBundle.status.count;
              $scope.maxCount = _maxCount;
              $scope.itemsByPage = appConfig.itemsByPage;
              $scope.lastPage =
                _maxCount > appConfig.itemsByPage
                  ? Math.ceil(_maxCount / appConfig.itemsByPage)
                  : 1;

              seoService.setPrevPage($scope.currentPage, $scope.pageQuery);
              seoService.setNextPage(
                $scope.currentPage,
                $scope.pageQuery,
                $scope.lastPage
              );

              $scope.$emit('UNLOAD');
            })
            .catch(function (error) {
              toolsService.manageError(
                'RecipesByAuthor > getRecipesByCriteria > ERROR: ',
                error
              );
            });
        });
        $scope.showDietPlanDialog = function (recipe) {
          $scope.recipeToDietPlan = recipe;
          $scope.showDietPlan = true;
        };

        $scope.closeDietPlanDialog = function () {
          $scope.recipeToDietPlan = null;
          $scope.showDietPlan = false;
        };
      },
    ]);
})();
