(function () {
  'use strict';

  angular
    .module('mainApp')

    .filter('filterVel', [
      function () {
        return function (vels, step) {
          var intTemp = parseInt(step.temperature);

          if (intTemp && !isNaN(intTemp)) {
            return vels.filter(function (vel) {
              var floatVel = parseFloat(vel.k);
              if (floatVel && !isNaN(floatVel)) return floatVel <= 5;
              else return true;
            });
          } else return vels;
        };
      },
    ])

    .controller('EditRecipeController', [
      '$sce',
      '$scope',
      '$rootScope',
      '$http',
      '$q',
      '$timeout',
      '$interval',
      '$upload',
      '$location',
      '$window',
      '$routeParams',
      '$filter',
      '_',
      '$log',
      'appConfig',
      'toolsService',
      'recipesFactory',
      'usersManager',
      'sessionService',
      'filtersManager',
      'ngDialog',
      'ingredientsManager',
      'recipeCategoriesFactory',
      function (
        $sce,
        $scope,
        $rootScope,
        $http,
        $q,
        $timeout,
        $interval,
        $upload,
        $location,
        $window,
        $routeParams,
        $filter,
        _,
        $log,
        appConfig,
        toolsService,
        recipesFactory,
        usersManager,
        sessionService,
        filtersManager,
        ngDialog,
        ingredientsManager,
        recipeCategoriesFactory
      ) {
        // Son la key, value es el que cambia por idioma
        $scope.weightUnit = localStorage.getItem('weightUnit') || 'gr';
        $scope.temperatureUnit  = localStorage.getItem('temperatureUnit') || 'C';
        var progressive = 'Progressiva',
          turbo = 'Turbo',
          saveAsDraft = false,
          filtersBundle = filtersManager.getFilters();
        //$scope.hours = filtersBundle.hours;
        // $scope.minutesMycook = filtersBundle.minutesMycook;
        $scope.minutes = filtersManager.getMinutes();
        $scope.seconds = filtersManager.getSeconds();
        $scope.sendingRecipe = false;
        $scope.forms = {}; // forms
        $scope.basicInformationForm_submitted = false; // form validation
        $scope.steps_submitted = false; // form validation
        $scope.selecteds = [];
        $scope.courses = [
          'breakfast',
          'appetizer',
          'snack',
          'first',
          'second',
          'dessert',
        ];
        //TODO Selector maquina
        $scope.selectMachines = appConfig.allowedCompats || ['S1'];
        $scope.listMachines = {};
        $scope.labelMachines = false;

        $scope.ingredientMeasures = ingredientsManager.getMeasurements();

        if($scope.weightUnit && $scope.weightUnit !== 'gr') {
          $scope.ingredientMeasures.forEach(function(element) {
            if(element.key == 'gr') {
              element.key = $scope.weightUnit;
              element.name = $rootScope.translations.common.measures[$scope.weightUnit];
            }
          })
        }

        // INITIAL RECIPE
        var emptyStep = {
          description: '',
          accessories: [],
          temperature: '',
          speed: '',
          ingredients: [
            /*{
              "qty": "",
              "unit": "",
              "name": "",
              "extra": "",
              "reserved": false
            }*/
          ],
          auxFields: {
            time_hours: 0,
            time_min: 0,
            time_sec: 0,
          },
          function: 'normal',
          auxType: 'mycook',
          type: 'mycook',
          params: {},
        };

        var emptyGroup = {
          name:
            $rootScope.translations.new_recipe.step_by_step
              .elaboration_title_value,
          steps: [emptyStep],
        };

        $scope.emptyRecipe = {
          //"totalTimeH": 0,
          //"totalTimeM": 0,
          price: 0,
          difficulty: 0,
          groups: [emptyGroup],
          course: [],
          tags: [],
          videos: [],
          imageRights: false,
        };

        var imagesToRemove = [];

        $scope.recipeStorage = $scope.recipeCase = $location.search()['case'];

        if (!$scope.recipeCase) {
          $scope.recipeStorage = $scope.recipeCase = !$routeParams.recipeNiceName
            ? 'new'
            : null;

          if (!$scope.recipeCase) {
            $window.history.back();
          }
        }

        $scope.functions = filtersBundle.mycookFunctions;
        $scope.noMycookTypes = filtersBundle.noMycookTypes;
        $scope.allowedCompats = appConfig.allowedCompats || ['S1'];

        function initStepsParams() {
          // rellenamos los auxFields de cada step, para poder hacer bien el binding
          for (var i = 0; i < $scope.recipe.groups.length; i++) {
            var g = $scope.recipe.groups[i];
            for (var j = 0; j < g.steps.length; j++) {
              var aStep = g.steps[j];
              if (!aStep.type) aStep.type = 'mycook'; // old recipes without type
              //if (aStep.type && aStep.type !== 'mycook')
              aStep.auxType =
                aStep.type === 'mycook' ? aStep.type : 'no_mycook';

              if (aStep.function) {
                if (!$scope.getCompatibleFunctions().includes(aStep.function))
                  aStep.function = 'normal';
              }

              var info = initStepAuxTags(aStep);
              if(aStep.ingredients && aStep.ingredients.length && $scope.weightUnit) {
                for(var k=0; k < aStep.ingredients.length; k++ ){
                  if(aStep.ingredients[k].unit == 'gr') {
                    aStep.ingredients[k].unit = $scope.weightUnit;
                    aStep.ingredients[k].qty = $rootScope.converWeight(Number(aStep.ingredients[k].qty), $scope.weightUnit);
                  }
                }
              }
              
              aStep.auxFields = info.auxFields;
              aStep.params = info.params;
            }
          } // end formatting process
        }

        function initStepsImages(isEdit) {
          // rellenamos los auxFields de cada step, para poder hacer bien el binding
          for (var i = 0; i < $scope.recipe.groups.length; i++) {
            var g = $scope.recipe.groups[i];
            for (var j = 0; j < g.steps.length; j++) {
              var aStep = g.steps[j];
              if (isEdit) {
                if (aStep.haveImage) {
                  aStep.imageRights = true;
                }
              } else {
                aStep.haveImage = false;
                if (aStep._id) {
                  delete aStep._id;
                }
              }
            }
          } // end formatting process
        }

        // haurien de ser true xk estiguin deshabilitats
        if (['new', 'size'].includes($scope.recipeCase)) {
          $scope.pane_2 = $scope.pane_3 = $scope.pane_4 = $scope.pane_5 = $scope.pane_6 = true;
        } else
          $scope.pane_2 = $scope.pane_3 = $scope.pane_4 = $scope.pane_5 = $scope.pane_6 = false;

        $scope.pane_1_title = $scope.pane_2_title = $scope.pane_4_title = false;

        $rootScope.$broadcast('updateDraftRecipes', {});

        $rootScope.$on('updatedDraftRecipes', function (e, draftRecipes) {
          $scope.currentDraftRecipes = draftRecipes;
        });

        // If Edit fill all the recipe fields
        if (['edit', 'adapt', 'size'].includes($scope.recipeCase)) {
          Promise.all([

            recipeCategoriesFactory.getCategories(),
            recipesFactory.getRecipe($filter('urlEncode')($routeParams.recipeNiceName))

          ]).then(function (response) {

            var cats = response[0];
            var recipe = response[1];

            if ($scope.recipeStorage !== 'adapt') {
              $scope.listMachines.S1 = recipe.compatibility.current.includes(
                'S1'
              );
              $scope.listMachines.S2 = recipe.compatibility.current.includes(
                'S2'
              );
            }

            if($scope.recipeStorage == 'adapt') {
              $scope.listMachines.S1 = recipe.compatibility.current.includes(
                'S2'
              );
              $scope.listMachines.S2 = recipe.compatibility.current.includes(
                'S1'
              );
            }

            var seo_title = $filter('translateVariables')(
              $rootScope.translations.seo.editRecipe.title,
              recipe.title
            );
            seo_title +=
              ' ' +
              $rootScope.translations.seo.separator +
              ' ' +
              $rootScope.translations.seo.website_name;
            $rootScope.pageTitle = seo_title;

            $scope.recipe = recipe;

            if ($scope.recipe.groups.length > 1) {
              $scope.pType = 'group';
            } else {
              $scope.pType = 'single';
            }

            if ($scope.recipeCase === 'size') {
              $scope.sizesArray = filtersManager.getAllowedSizes(
                recipe.size
              );
              $scope.recipe.size.current = '';
            } else {
              $scope.sizesArray = filtersManager.getAllowedSizes();
            }

            var haveCat = cats.find(function (cat) {
              return cat.niceName === recipe.categoryNiceName;
            });

            if (!haveCat) {
              cats.push({
                name: recipe.category,
                niceName: recipe.categoryNiceName
              });
            }

            $scope.categories = cats;

            initStepsParams();

            initStepsImages($scope.recipeCase === 'edit');

            preSelectTags();

            updateTotalSteps();

            registerCourses();

            if ($scope.recipeCase !== 'adapt') {
              $scope.$watchGroup(
                ['listMachines.S1', 'listMachines.S2'],
                function (machines) {
                  initStepsParams();
                }
              );
            }
          }, function (error) {
            $log.error("RecipeController > EDIT recipe doesn't exists");
          });
        } else {

          recipeCategoriesFactory.getCategories().then(function (cats) {
            $scope.categories = cats;
          });

          $scope.sizesArray = filtersManager.getAllowedSizes();
          // If New recipe reset form values
          resetForm();

          if ($scope.recipeCase !== 'adapt') {
            $scope.$watchGroup(
              ['listMachines.S1', 'listMachines.S2'],
              function (machines) {
                initStepsParams();
              }
            );
          }
        }

        $scope.getPanelIndex = function (number) {
          return $scope.recipeCase === 'size' ? number - 1 : number;
        };

        var machineVariant;

        $scope.$watch('userMachine', function (userMachine) {
          $log.debug('Edit recipe, userMachine:', userMachine);

          machineVariant = userMachine == 'S1' ? 'mycook' : null;
        });

        function getMachineVariant() {
          return getEnabledMachine() === 'S1' && machineVariant === 'mycook'
            ? machineVariant
            : null;
        }

        function setEmptyRecipe() {
          $scope.selecteds = [];
          return _.cloneDeep($scope.emptyRecipe);
        }

        // add course to array
        $scope.updateCourse = function (course) {
          if ($scope.recipe.course.indexOf(course) > -1) {
            // remove
            _.pull($scope.recipe.course, course);
          } else {
            $scope.recipe.course.push(course);
          }
        };

        // Step 2: Procesos de elaboracion
        $scope.setProcessType = function (pType) {
          $scope.pType = pType;

          var panelNum = 3;

          $scope['pane_' + (panelNum + 1)] = false;
          $scope.accordion.toggle(panelNum);

          if (pType === 'single' && $scope.recipe.groups.length > 1) {
            $scope.recipe.groups.splice(1, $scope.recipe.groups.length - 1);
          }
        };
        // END Step 2: Procesos de elaboracion

        $scope.removeStepImage = function (step) {
          step.image = ['deleted'];
          step.imageRights = false;
        };

        $scope.stemImageChanged = function (step) {
          step.imageRights = false;
        };

        // Adding elements
        $scope.addNewGroup = function ($event) {
          if ($event) {
            $event.preventDefault();
          }

          $scope.recipe.groups.push({ name: '', steps: [] });

          updateTotalSteps();
          // si creamos un nuevo proceso (no el primero) añadimos un nuevo paso directamente
          var totalGroups = $('.recipe-group').length;
          if (totalGroups > 0) {
            $scope.addNewStep(totalGroups);
            $timeout(function () {
              $('html, body').animate(
                {
                  scrollTop: $('#group' + totalGroups).offset().top - 100,
                },
                500
              );
            }, 0);
          }
        };

        $scope.addNewStep = function (groupID, $event) {
          if ($event) {
            $event.preventDefault();
          }
          var newStep = _.cloneDeep(emptyStep);
          var args = initStepAuxTags(newStep);

          newStep.auxFields = args.auxFields;
          newStep.params = args.params;

          $scope.recipe.groups[groupID].steps.push(newStep);
          var totalSteps = $('#group' + groupID + ' .group-step').length;

          updateTotalSteps();

          setTimeout(function () {
            $('html, body').animate(
              {
                scrollTop:
                  $('#group' + groupID)
                    .find('.group-step')
                    .eq(totalSteps)
                    .offset().top - 100,
              },
              500
            );
          }, 0);
        };

        $scope.addNewIngredient = function (groupID, stepID, reserved, $event) {
          $event.preventDefault();
          $scope.recipe.groups[groupID].steps[stepID].ingredients.push({
            qty: '',
            unit: '',
            name: '',
            extra: '',
            reserved: reserved,
          });
        };

        $scope.removeIngredient = function (array_ing, ingredient, $event) {
          $event.preventDefault();
          _.pull(array_ing, ingredient);
        };
        // END Adding elements

        $scope.removeItem = function (array, hashKey, $event) {
          $event.preventDefault();

          var index = array.findIndex(function (elem) {
            return elem.$$hashKey === hashKey;
          });

          if (index >= 0) {
            var removed = array.splice(index, 1).pop();

            if (removed.steps) {
              imagesToRemove.push.apply(
                imagesToRemove,
                removed.steps
                  .filter(function (step) {
                    return step.haveImage && step._id;
                  })
                  .map(function (step) {
                    return {
                      file: 'deleted',
                      id: step._id,
                    };
                  })
              );
            } else {
              if (removed.haveImage && removed._id)
                imagesToRemove.push({ file: 'deleted', id: removed._id });
            }

            updateTotalSteps();
          }
        };

        $scope.isCompatible = function (compatArray) {
          return compatArray.includes(getEnabledMachine());
        };

        $scope.setVelocityRange = function (step) {
          if (step.params.ranges) {
            step.params.current_range = step.temperature
              ? step.params.ranges.h
              : step.params.ranges.c;

            if (
              step.auxFields.velprog_init &&
              !step.params.current_range
                .map(function (vel) {
                  return vel.k;
                })
                .includes(step.auxFields.velprog_init.toString())
            ) {
              step.auxFields.velprog_init = '';
            }

            if (
              step.auxFields.velprog_end &&
              !step.params.current_range
                .map(function (vel) {
                  return vel.k;
                })
                .includes(step.auxFields.velprog_end.toString())
            ) {
              step.auxFields.velprog_end = '';
            }

            step.auxFields.step_speed =
              step.params.speed && step.params.speed.length === 1
                ? step.params.speed[0].k
                : '';
          }
        };

        function getCurrentTime(step) {
          if (
            !isNaN(parseInt(step.auxFields.time_hours, 10)) &&
            !isNaN(parseInt(step.auxFields.time_min, 10)) &&
            !isNaN(parseInt(step.auxFields.time_sec, 10))
          ) {
            return (
              parseInt(step.auxFields.time_hours, 10) * 3600 +
              parseInt(step.auxFields.time_min, 10) * 60 +
              parseInt(step.auxFields.time_sec, 10)
            );
          } else {
            return 0;
          }
        }

        function getEnabledMachine() {
          return $scope.listMachines.S1 ? 'S1' : 'S2';
        }

        function computeSecondsArray(current_time, params) {
          var descriptor = params.time.s.find(function (slice) {
            return current_time < slice.max_time;
          });

          var seconds = descriptor
            ? descriptor.steps.reduce(function (array, info) {
              var min_s =
                info.min || info.min === 0
                  ? info.min
                  : params.time.secs_range.min;
              var max_s = info.max || params.time.secs_range.max;

              for (var i = min_s; i <= max_s; i += info.step) array.push(i);

              return array;
            }, [])
            : [];

          if (seconds[0]) seconds.unshift(0);

          return seconds;
        }

        $scope.speedRequired = function (step) {
          if (step.params.speed) {
            if (step.params.temp && step.temperature) return true;

            if (step.params.time && getCurrentTime(step)) return true;
          }

          return false;
        };

        $scope.getSecondsArray = function (step) {
          var secs = computeSecondsArray(getCurrentTime(step), step.params);

          //console.log("secs: ", secs);

          if (!secs.includes(step.auxFields.time_sec))
            step.auxFields.time_sec = 0;

          Object.keys(step.auxFields).forEach(function (key) {
            if (key.startsWith('time')) {
              if (!step.auxFields[key]) step.auxFields[key] = 0;
            }
          });

          return secs;
        };

        function resetStep(step) {
          console.log('step to change', step);
          if (step.params.ranges)
            step.params.current_range = step.params.ranges.c;

          if (step.params.speed) {
            console.log('here 1', step.params.speed)
            if (step.params.speed.length === 1)
              step.auxFields.step_speed = step.speed = step.params.speed[0].k;
            else {
              var available = step.params.speed
                .map(function (speed) {
                  return speed.k;
                })
                .includes(step.speed);

              if (!available) step.speed = step.auxFields.step_speed = '';
              else step.auxFields.step_speed = step.speed;
            }
          } else {
            console.log('here 2')
            step.speed = step.auxFields.step_speed = '';
          }

          if (step.params.temp) {
            console.log('here 3', step.params.temp);
            if (step.params.temp.length === 1) {
              step.temperature = step.params.temp[0].k;
            } else {
              var available = step.params.temp
                .map(function (temp) {
                  return temp.k;
                })
                .includes(step.temperature);

              if (!available) step.temperature = '';
            }
          } else {
            console.log('here 4');
            step.temperature = '';
          }

          if (step.outsideTemperature) {
            step.outsideTemperature = '';
          }

          if (step.microwaveWatts) {
            step.microwaveWatts = '';
          }

          step.auxFields.time_hours = 0;
          step.auxFields.time_min = 0;
          step.auxFields.time_sec = 0;
          step.cookTime = step.stepTime = 0;

          step.auxFields.step_turbo = '';

          step.auxFields.velprog_init = '';
          step.auxFields.velprog_end = '';

          //console.log('RESETING STEP: ', step, step.params);
        }

        $scope.toggeFuctionsHelp = function (on) {
          $scope.helpFunctionsShown = on;
        };

        $scope.getCompatibleFunctions = function () {
          return $scope.functions
            .filter(function (func) {
              return $scope.isCompatible(func.for);
            })
            .map(function (func) {
              return func.key;
            });
        };

        $scope.setStepFunction = function (step, func) {
          console.log('setStepFunction', step, func);
          if (step.function !== func) {
            
            step.function = func;

            step.params = filtersManager.getParams(
              getEnabledMachine(),
              step.function,
              getMachineVariant()
            );
            
            resetStep(step);
          }
          
        };

        $scope.getSvgName = function (current, func) {
          return current === func
            ? 'function-' + func + '-solid'
            : 'function-' + func;
        };

        $scope.selectTypeStep = function (step, type, $event) {
          //'mycook', 'bake', 'fry', 'coat', 'manipulation', 'repose', 'stew'
          console.log('selectTypeStep', step, type, $event);
          if (type !== 'mycook') {
            step.auxFields.step_speed = step.speed = step.temperature = '';

            // remove accessories not available
            var availableAccessories = filtersBundle.accessoriesNoMycook;
            var filteredAccessories = _.filter(step.accessories, function (n) {
              return availableAccessories.indexOf(n) > -1 ? true : false;
            });
            step.accessories = filteredAccessories;
          }

          step.auxType = ['no_mycook', 'mycook'].includes(type)
            ? type
            : step.auxType;
          step.type = type !== 'no_mycook' ? type : 'bake';

          resetStep(step);
        };

        $scope.getStepNumber = function (indexGroup, indexStep) {
          var num = 0;

          for (var i = 0; i <= indexGroup; i++) {
            if (indexGroup === i) {
              num += indexStep + 1;
            } else {
              num += $scope.recipe.groups[i]['steps'].length;
            }
          }

          return num;
        };

        // Validations during form
        $scope.mixingPaddleAlert = function (form, step) {
          var allowedRange = [
            '',
            '1',
            '1.5',
            '2',
            '2.5',
            '3',
            '3.5',
            '4',
            '4.5',
            '5',
            '2-3',
            '2-4',
            '2-5',
            '3-4',
            '3-5',
            '4-5',
          ];

          var validPaddle =
            !step.accessories.includes('mixingBlade') ||
            !step.speed ||
            (step.accessories.includes('mixingBlade') &&
              allowedRange.includes(step.speed.toString()) &&
              step.function !== 'turbo' &&
              step.function !== 'knead');
          validPaddle = (step.accessories.includes('mixingBlade') && step.function == 'high-temperature' && step.speed)? true :validPaddle ;
          if (form.step_speed && !validPaddle)
            form.step_speed.$setValidity('paddleerror', validPaddle);

          return !validPaddle;
        };

        $scope.accessoriesAlert = function (form,step){

          var arr=[ 
                'splashCover',
                'steamer'
              ];

          var similar =  step.accessories.filter( function (item) { 
            return arr.includes(item);
          });

          var inValid=  (similar.length>1)? true:false;
          if(form.step_speed){
            form.step_speed.$setValidity('accessorieserror', !inValid);
          }

          return inValid;
          
        }

        $scope.checkMinimumGramQuantity = function(ingredient,indexIngredient,minError) {
          var quantity = ingredient.qty.toString();
          var unit = ingredient.unit;
          if (quantity && quantity.includes(',')) {
            quantity = quantity.replace(',', '.');
          }
          if((unit == 'gr' || unit == 'lb' || unit == 'oz') && isNaN(Number(quantity))){
            minError.minVal=true;
            return true;
          }
          if(unit == 'gr' && Number(quantity) < 5 ) {
            minError.minVal=true;
            return true;
          } else if(unit == 'lb' && Number(quantity) < 0.011){
            minError.minVal=true;
            return true;
          } else if(unit == 'oz' && Number(quantity) < 0.176){
            minError.minVal=true;
            return true;
          }else{
            minError.minVal=false;
            return false;
          }
        }


        $scope.setSpeed = function (step) {
          switch (step.function) {
            case 'progressive':
              {
                if (step.auxFields.velprog_init && step.auxFields.velprog_end) {
                  step.speed =
                    step.auxFields.velprog_init +
                    '-' +
                    step.auxFields.velprog_end;
                } else {
                  step.speed = '';
                }
              }
              break;
            case 'turbo':
              {
                step.speed = step.auxFields.step_turbo;
              }
              break;
            default: {
              step.speed = step.auxFields.step_speed;
            }
          }
        };

        $scope.setRangeMin = function (step) {
          return function (min) {
            var maxLim = 1000;
            if (step.speed === 'A') {
              maxLim = 4; // fins a 3 minuts
            }
            return maxLim > min;
          };
        };

        $scope.greaterThan = function (val) {
          return function (item) {
            return parseInt(item.k) > parseInt(val);
          };
        };

        $scope.lessThan = function (step) {
          return function (item) {
            var val =
              step.auxFields.velprog_end ||
              step.params.current_range.slice(-1).pop().k;
            return parseInt(item.k) < parseInt(val);
          };
        };

        $scope.setTime = function (step, mycook) {
          //???
          if (toolsService.isEmpty(step.auxFields)) {
            step.auxFields = {};
          }

          if (mycook) step.cookTime = getCurrentTime(step);
          else step.stepTime = getCurrentTime(step);
        };

        function initStepAuxTags(aStep) {
          var response = {
            auxFields: {},
            params: {},
          };

          if (!toolsService.isEmpty(aStep)) {
            // speed
            if (/^([1-9]|[1-9][0-1])T$/i.test(aStep.speed)) {
              // Turbo case
              response.auxFields.step_turbo = aStep.speed;
              response.auxFields.step_speed = turbo;
              if (!aStep.function) aStep.function = 'turbo';
            } else if (/(.*)\-(.*)/.test(aStep.speed)) {
              // Progressive case
              var aRange = aStep.speed.split('-');
              response.auxFields.velprog_init = aRange[0];
              response.auxFields.velprog_end = aRange[1];
              response.auxFields.step_speed = progressive;
              if (!aStep.function) aStep.function = 'progressive';
            } else {
              // normal case
              response.auxFields.step_speed = aStep.speed; //Comprovar si es ple abans de retornar

              if (!aStep.function && aStep.speed) {
                switch (aStep.speed) {
                  case 'S':
                  case 'S1':
                  case 'S2':
                    aStep.function = 'saute';
                    if (!aStep.temperature && getEnabledMachine() === 'S2')
                      aStep.temperature = 'MAX';
                    break;
                  case 'A':
                    aStep.function = 'knead';
                    break;
                  default:
                    if (aStep.type === 'mycook') aStep.function = 'normal';
                }
              } else if (!aStep.function && aStep.type === 'mycook') {
                aStep.function = 'normal';
              }
            }

            //Si no hay "func" es un step sin Mycook
            if (aStep.function) {
              response.params = filtersManager.getParams(
                getEnabledMachine(),
                aStep.function,
                getMachineVariant()
              );

              if (aStep.function === 'steam' && getEnabledMachine() === 'S2')
                aStep.temperature = 'VAP';
              if (aStep.function === 'saute' && getEnabledMachine() === 'S2')
                aStep.temperature = 'MAX';
            }

            if (response.params.time || !aStep.function) {
              var time;

              if (
                !toolsService.isEmpty(aStep.cookTime) &&
                !isNaN(parseInt(aStep.cookTime, 10))
              ) {
                time = parseInt(aStep.cookTime, 10);
              } else if (
                !toolsService.isEmpty(aStep.stepTime) &&
                !isNaN(parseInt(aStep.stepTime, 10))
              ) {
                time = parseInt(aStep.stepTime, 10);
              } else {
                time = 0;
              }

              response.auxFields.time_hours =
                (response.params.time && response.params.time.h.length) ||
                  aStep.auxType === 'no_mycook' ||
                  !aStep.function
                  ? Math.floor(time / 3600)
                  : 0;
              time = time - response.auxFields.time_hours * 3600;
              response.auxFields.time_min = Math.floor(time / 60);
              time = time - response.auxFields.time_min * 60;
              response.auxFields.time_sec = time;

              // console.log(
              //   'Setting time for: ',
              //   aStep.function || aStep.type,
              //   response.auxFields
              // );
            }

            if (response.params.temp) {
              response.params.temp = checktemperature(response.params.temp);
              if (aStep.temperature) {
                if (
                  !response.params.temp
                    .map(function (tmp) {
                      return tmp.k;
                    })
                    .includes(aStep.temperature.toString())
                ) {
                  aStep.temperature =
                    response.params.temp && response.params.temp.length === 1
                      ? response.params.temp[0].k
                      : '';
                } else {
                  aStep.temperature = aStep.temperature.toString();
                }
              } else {
                if (response.params.temp && response.params.temp.length === 1) {
                  aStep.temperature = response.params.temp[0].k;
                }
              }
            }

            if (response.params.ranges) {
              response.params.current_range = aStep.temperature
                ? response.params.ranges.h
                : response.params.ranges.c;

              if (
                !response.params.current_range
                  .map(function (r) {
                    return r.k;
                  })
                  .includes(response.auxFields.velprog_end)
              )
                response.auxFields.velprog_end = '';

              if (
                !response.params.current_range
                  .map(function (r) {
                    return r.k;
                  })
                  .includes(response.auxFields.velprog_init)
              )
                response.auxFields.velprog_init = '';
            }

            if (response.params.speed) {
              if (response.auxFields.step_speed) {
                if (
                  !response.params.speed
                    .map(function (sp) {
                      return sp.k;
                    })
                    .includes(response.auxFields.step_speed.toString())
                ) {
                  response.auxFields.step_speed =
                    response.params.speed && response.params.speed.length === 1
                      ? response.params.speed[0].k
                      : '';
                }
              } else {
                if (response.params.speed && response.params.speed.length === 1) {
                  response.auxFields.step_speed = response.params.speed[0].k;
                }
              }
            }

            if (response.params.time) {
              if (
                aStep.function &&
                response.params.time.h.length &&
                !response.params.time.h.includes(response.auxFields.time_hours)
              ) {
                response.auxFields.time_hours = 0;
              }

              if (
                aStep.function &&
                response.params.time.m.length &&
                !response.params.time.m.includes(response.auxFields.time_min)
              ) {
                response.auxFields.time_min = 0;
              }

              var secs_array = computeSecondsArray(
                getCurrentTime(response),
                response.params
              );

              // console.log(
              //   'Secs array: ',
              //   secs_array,
              //   getCurrentTime(response),
              //   response.params
              // );

              if (
                aStep.function &&
                secs_array.length &&
                aStep.auxType !== 'no_mycook' &&
                !secs_array.includes(response.auxFields.time_sec)
              ) {
                response.auxFields.time_sec = 0;
              }
            }
          }

          //console.log("Returning: ", response.auxFields.step_speed, response.params);

          return response;
        }

        function checktemperature(temperatures) {
          if ($scope.temperatureUnit == 'F' ) {
            var arr = [];
            var excludedTemp = ['MAX','VAP','min','max','vap'];
            var inclidetamp = temperatures.filter(function (temp){
              return excludedTemp.includes(temp.k);
            });
            if ( inclidetamp.length > 0 ) {
              return temperatures;
            }
            for(var i= 0; i<temperatures.length; i++){
              for(var j=0; j<$rootScope.translatetemperature.length; j++){
                if(temperatures[i].k == $rootScope.translatetemperature[j].C){
                  arr.push({
                    "k":temperatures[i].k,
                    "v": temperatures[i].v.replace(temperatures[i].k, $rootScope.translatetemperature[j].F )+'F'
                  })
                }
              }
            }
            return arr;
          }else {
            return temperatures;
          }
        }

        function updateTotalSteps() {
          var arr = new Array({
            key: '',
            name: $rootScope.translations.new_recipe.select,
          });

          var counter = 0;

          if ($scope.recipe) {
            $scope.recipe.groups.map(function (group, key_group) {
              group['steps'].map(function (step, key_step) {
                counter++;

                arr.push({
                  key: $filter('translateVariables')(
                    $rootScope.translations.single_recipe.reserved_ingredient,
                    counter
                  ),
                  name:
                    $rootScope.translations.new_recipe.step_by_step.step_title +
                    ' ' +
                    counter,
                });
              });
            });
          }

          $scope.totalSteps = arr;
        }

        // Check if recipe title exist
        $scope.recipeAlreadyExists = function (title) {
          // tiene que recibir el title, no el nicename
          $scope.alreadyExists = false;
          if (
            $scope.recipeCase === 'new' &&
            !toolsService.isEmpty(title) &&
            title.length > 3
          ) {
            var titleNiceName = slugify(title);

            recipesFactory.getRecipe($filter('urlEncode')(titleNiceName)).then(
              function (recipe) {
                $scope.alreadyExists = true;
              },
              function (error) {
                $log.error("RecipeController > recipe doesn't exists");
              }
            );
          }
        };

        ///////////// PUBLISH RECIPE
        // click publish or save as draft button
        $scope.submitGeneralForm = function (forms, draft) {
          saveAsDraft = draft;
          var validForm = true;

          forms.forEach(function (form) {
            validForm = form[1]
              ? $scope.submitFormPart(form[0], form[1], form[2], true) &&
              validForm
              : validForm;
          });

          if (validForm) {
            
            var images = {
              main: {
                file: $scope.imagesRecipeUpload
                  ? $scope.imagesRecipeUpload[0]
                  : null,
                rights: $scope.recipe.imageRights,
              },
              steps: $scope.recipe.groups.reduce(function (acum, group, gIdx) {
                acum.push.apply(
                  acum,
                  group.steps.map(function (step, sIdx) {
                    return {
                      stepIdx: sIdx,
                      groupIdx: gIdx,
                      id: step._id,
                      file: step.image ? step.image[0] : null,
                      rights: step.imageRights,
                    };
                  })
                );
                return acum;
              }, []),
            };
            
              $scope.recipe.groups.forEach(function(group) {
                group.steps.forEach(function(step) {
                  if(step.ingredients && step.ingredients.length) {
                    step.ingredients.forEach(function (ingre) {
                      if(ingre.unit && (ingre.unit == 'gr' || ingre.unit == 'lb' || ingre.unit == 'oz')){
                        ingre.qty = ingre.qty.toString();
                          if (ingre.qty && ingre.qty.includes(',')) {
                            ingre.qty = ingre.qty.replace(',', '.');
                          }
                          ingre.unit = "gr";
                          ingre.qty = $rootScope.roundToFive($rootScope.convertToGrams(Number(ingre.qty),$scope.weightUnit));
                      }
                    })
                  }
                })
              })

            switch ($scope.recipeCase) {
              case 'adapt':
                {
                  var gotFiles =
                    (images.main.file && images.main.rights) ||
                    images.steps.find(function (stepImage) {
                      return stepImage.file && stepImage.rights;
                    });

                  manageCourses();
                  purgeSteps();
                  addingTags($scope.recipe);

                  $scope.sendingRecipe = true;
                  toNewTouch(
                    _.cloneDeep($scope.recipe),
                    _.cloneDeep($scope.recipe.compatibility),
                    !gotFiles
                  ).then(function (response) {
                    if (gotFiles) {
                      postFiles(images, response.niceName).then(
                        function () {
                          $location.path(
                            $rootScope.url('recipe', {
                              recipeNiceName: response.niceName,
                            })
                          );
                        },
                        function () {
                          $location.path(
                            $rootScope.url('recipe', {
                              recipeNiceName: response.niceName,
                            })
                          );
                        }
                      );
                    } else {
                      $location.path(
                        $rootScope.url('recipe', {
                          recipeNiceName: response.niceName,
                        })
                      );
                    }
                  }, $log.error)
                    .finally(function () {
                      $scope.sendingRecipe = false;
                    });
                }
                break;
              case 'size':
                {
                  prepareRecipe($scope.recipeCase, createNewSize, images).then(
                    function (recipeName) {
                      $location.path(
                        $rootScope.url('recipe', { recipeNiceName: recipeName })
                      );
                    },
                    $log.error
                  );
                }
                break;
              default: {
                var preProcess = imagesToRemove.length
                  ? postFiles({ steps: imagesToRemove }, $scope.recipe.niceName)
                  : Promise.resolve();

                preProcess.then(function () {
                  if (!$scope.recipe.compatibility) {
                    $scope.recipe.compatibility = {};
                  }

                  $scope.recipe.compatibility.current = Object.keys(
                    $scope.listMachines
                  ).filter(function (key) {
                    return $scope.listMachines[key];
                  });

                  prepareRecipe($scope.recipeCase, publishRecipe, images).then(
                    function (recipeName) {
                      //localStorage.setItem('recipeCase', 'new');
                      $scope.recipeStorage = 'new'; //tengo que entender, con mucha calma, que se pretende aquí.

                      //console.log($rootScope.url('recipe', { recipeNiceName: recipeName }));
                      $location.path(
                        $rootScope.url('recipe', { recipeNiceName: recipeName })
                      );
                    },
                    $log.error
                  );
                }, $log.error);
              }
            }
          } else {
            console.error('Invalid FORM!');
          }
        };

        var toNewTouch = function (recipe, identifier, copyImage) {
          delete recipe._id;
          var machine = '';

          Object.keys(identifier).forEach(function (item) {
            if (item === 'S1') machine = 'S2';
            if (item === 'S2') machine = 'S1';
          });

          var deferredResponse = $q.defer(),
            url = appConfig.mycookTouchDomain + '/recipe/toNewTouch/' + machine;

          $http({
            method: 'post',
            url: url,
            data: { recipe: recipe, copyImage: copyImage },
            headers: { 'Content-type': 'application/json' },
          }).then(
            function (res) {
              deferredResponse.resolve({
                niceName: res.data.response,
                machine: machine,
              });
            },
            function errorCallback(err) {
              deferredResponse.reject(err);
            }
          );
          return deferredResponse.promise;
        };

        function prepareRecipe(rcase, next, images) {
          var deferred = $q.defer();

          $scope.recipe.title = _.upperFirst($scope.recipe.title);

          var catNames = $scope.categories.filter(function (cat) {
            return cat.niceName === $scope.recipe.categoryNiceName;
          });

          if (catNames.length > 0) {
            $scope.recipe.category = catNames[0].name;
          }

          if ($scope.recipe.videos && $scope.recipe.videos.length > 0) {
            var filteredVideos = [];

            $scope.recipe.videos.forEach(function (vidString) {
              if (!toolsService.isEmpty(vidString)) {
                var videoCode = toolsService.extractYoutubeCode(vidString);
                if (!toolsService.isEmpty(videoCode)) {
                  filteredVideos.push(videoCode);
                }
              }
            });

            $scope.recipe.videos = filteredVideos;
          }

          checkStepsAndIngredients(); // Revisar pasos & ingredientes (vacios, clean name, check qty)

          if (
            !$scope.sendingRecipe &&
            $scope.recipe_general_form.$valid &&
            catNames.length > 0
          ) {
            $scope.recipe.draft = saveAsDraft;
            var showDialog = false;

            // check if show dialog "realmente quieres publicar la receta"
            if (
              !saveAsDraft &&
              (['new', 'size'].includes(rcase) || $scope.recipe.status.draft)
            ) {
              // new o era un draft
              showDialog = true;
            }

            publishWithDialog(showDialog, rcase, deferred, next, images);
          } else {
            deferred.reject('form not valid');
          }

          return deferred.promise;
        }

        // submit part of form
        // @number: number of form part (1: basic info, 2: procesos, 3: elaboracion)
        // @form: form part
        // @is_submit: is form part submitted
        // @is_general_form_submitted: is form submitted
        $scope.submitFormPart = function (
          number,
          form,
          is_submit,
          is_general_form_submitted
        ) {
          // number =
          //   number < 2 && $scope.recipeCase === 'size' ? number + 1 : number;

          $scope.compatibilityCheckbox = [];
          // if($scope.recipeCase=='adapt'){
          //   $scope.listMachines = {};
          // }
          Object.keys($scope.listMachines).forEach(function (key) {
            if ($scope.listMachines[key]) {
              $scope.compatibilityCheckbox.push(key);
            }
          });

          $scope.IsValid = false;

          if (
            $scope.compatibilityCheckbox.length === 0 &&
            $scope.recipeCase !== 'adapt'
          ) {
            $scope.labelMachines = true;
            $scope.IsValid = false;
          } else {
            $scope.labelMachines = false;
            $scope.IsValid = true;
          }

          
          if (form) {
            if(number==4) {
              Object.keys(form.stepForm).forEach(function(item){
                if(form.stepForm[item] && form.stepForm[item].$error && form.stepForm[item].$error.minVal){
                  form.$valid = false;
                }
              }) 
            }
            // validar form
            $scope[is_submit] = true;

            // not valid && not general go to form
            if (!form.$valid && !is_general_form_submitted && !$scope.IsValid) {
              $('html, body').animate(
                {
                  scrollTop: $(
                    '#my-accordion v-pane:nth-child(' + number + ')'
                  ).offset().top,
                },
                300
              );
              // valid but not general
            } else if (
              form.$valid &&
              !is_general_form_submitted &&
              $scope.IsValid
            ) {
              var nextPane =
                number < 2 && $scope.recipeCase === 'size'
                  ? number + 2
                  : number + 1;
              $scope['pane_' + nextPane] = false;
              $scope['pane_' + number + '_title'] = false;
              $scope.accordion.toggle(
                nextPane === number + 1 ? number : number + 1
              );
            } else {
              // general form invalid & submitted
              if (
                $scope.recipe_general_form.$invalid &&
                is_general_form_submitted
              ) {
                if (form.$invalid) $scope['pane_' + number + '_title'] = true;
                $scope.accordion.collapseAll();
                $('html, body').animate({ scrollTop: 0 }, 500);
                return false;
              } else {
                return true;
              }
            }
          } else {
            // sin validar abro siguiente
            var nextPane =
              number < 2 && $scope.recipeCase === 'size'
                ? number + 2
                : number + 1; //No debe pasar

            $scope['pane_' + nextPane] = false;
            $scope.accordion.toggle(
              nextPane === number + 1 ? number : number + 1
            );
          }
        };

        function purgeSteps() {
          $scope.recipe.groups.forEach(function (group) {
            group.steps.forEach(function (step) {
              if (!/^[0-9]+$/.test(step.temperature)) delete step.temperature; //per evitar que casquin màquines antigues
            });
          });
        }

        function createNewSize(rcase, images) {
          return new Promise(function (resolve, reject) {
            $scope.sendingRecipe = true;
            addingTags($scope.recipe);

            $scope.recipe.info = {
              creationDate: new Date(),
              creationSource: $scope.deviceDetector.raw.userAgent,
            };

            // $scope.recipe.grants.search = ['multisize'];
            // $scope.recipe.grants.view =
            //   $scope.recipe.grants && $scope.recipe.grants.view
            //     ? $scope.recipe.grants.view
            //     : ['public'];

            manageCourses();

            purgeSteps();

            recipesFactory
              .publishRecipe(_.cloneDeep($scope.recipe), $scope.recipeCase)
              .then(
                function (res) {
                  if (images) {
                    postFiles(images, res.data.niceName).then(function () {
                      resolve(res.data.niceName);
                    }, reject);
                  } else {
                    // end new img
                    resolve(res.data.niceName);
                  }
                },
                function (e) {
                  if ($scope.recipe_general_form.$invalid) {
                    $('html, body').animate({ scrollTop: 0 }, 500);
                  }

                  $log.error('newSize KO. Error: ', e);
                  reject('formulario no válido');
                }
              )
              .finally(function () {
                $rootScope.$broadcast('updateDraftRecipes', {});

                $timeout(function () {
                  $scope.sendingRecipe = false;
                }, appConfig.timeoutDialogues);
              });
          });
        }

        function publishRecipe(rcase, images) {
          var deferred = $q.defer();
          $scope.sendingRecipe = true;
          addingTags($scope.recipe);
          var gotMain;
          var isMultiSize;

          if (rcase === 'edit') {
            $scope.recipe.info = {
              modificationSource: $scope.deviceDetector.raw.userAgent,
            };

            gotMain =
              $scope.recipe.grants &&
              $scope.recipe.grants.search.includes('main');

            isMultiSize =
              $scope.recipe.grants &&
              $scope.recipe.grants.search.includes('multisize');
          } else {
            // creating recipe
            $scope.recipe.info = {
              creationDate: new Date(),
              creationSource: $scope.deviceDetector.raw.userAgent,
            };

            gotMain = true;
            isMultiSize = false;
          } // end edit/creating recipe

          if (!isMultiSize) {
            //Putting recipe as private, draft or public
            $scope.recipe.grants = {
              search: gotMain ? ['main', 'public'] : ['public'],
              view: ['public'],
            };

            if ($scope.recipe.status && $scope.recipe.status.private) {
              var privateUser = gotMain
                ? ['main', 'user/' + $scope.currentUser.niceName]
                : ['user/' + $scope.currentUser.niceName];
              $scope.recipe.grants = {
                search: privateUser,
                view: ['user/' + $scope.currentUser.niceName],
              };
            } else if ($scope.recipe.draft) {
              var draftUser = gotMain
                ? ['main', 'draft/' + $scope.currentUser.niceName]
                : ['draft/' + $scope.currentUser.niceName];
              $scope.recipe.grants = {
                search: draftUser,
                view: ['draft/' + $scope.currentUser.niceName],
              };
            }
          } else {
            $scope.recipe.grants = {
              search: $scope.recipe.grants.search,
              view:
                $scope.recipe.grants && $scope.recipe.grants.view
                  ? (
                    $scope.recipe.status.private 
                      ? ['user/' + $scope.currentUser.niceName]
                      : ['public']
                  )
                  : ['public'],
                  // view:
                  // $scope.recipe.grants && $scope.recipe.grants.view
                  //   ? (
                  //     !$scope.recipe.grants.view.includes('public') && !$scope.recipe.status.private 
                  //       ? ['public'] 
                  //       : $scope.recipe.grants.view 
                  //   )
                  //   : ['public'],
            };
          }

          manageCourses();

          purgeSteps();

          //console.log('SENDING RECIPE: ', $scope.recipe);

          recipesFactory
            .publishRecipe(_.cloneDeep($scope.recipe), $scope.recipeCase)
            .then(
              function (res) {
                var niceName = res.data.niceName || $scope.recipe.niceName;

                if (images) {
                  postFiles(images, niceName).then(function () {
                    deferred.resolve(niceName);
                  }, deferred.reject);
                } else {
                  // end new img
                  deferred.resolve(niceName);
                }
              },
              function (e) {
                if ($scope.recipe_general_form.$invalid) {
                  $('html, body').animate({ scrollTop: 0 }, 500);
                }

                console.error('CAIGO ERROR:', e);
                $log.error('sendRecipe KO. Error: ', e);
                deferred.reject('formulario no válido');
              }
            )
            .finally(function () {
              $rootScope.$broadcast('updateDraftRecipes', {});

              $timeout(function () {
                $scope.sendingRecipe = false;
              }, appConfig.timeoutDialogues);
            });

          return deferred.promise;
        }
        //////// END PUBLISH

        function publishWithDialog(dialog, rcase, deferred, next, images) {
          if (dialog) {
            ngDialog
              .openConfirm({
                template: 'send-recipe.html',
                className: 'new-recipe',
                scope: $scope,
              })
              .then(
                function (r) {
                  next(rcase, images).then(
                    function (r) {
                      deferred.resolve(r);
                    },
                    function (e) {
                      deferred.reject(e);
                    }
                  );
                },
                function (e) {
                  deferred.reject(e);
                }
              );
          } else {
            next(rcase, images).then(
              function (r) {
                deferred.resolve(r);
              },
              function (e) {
                deferred.reject(e);
              }
            );
          }
        }

        function registerCourses() {
          var newCourses = new Array();
          var coursesObject = filtersBundle.coursesObject;

          _.forEach($scope.recipe.course, function (course) {
            var filteredCourses = _.pickBy(coursesObject, function (value) {
              return value.indexOf(course) > -1;
            });

            _.forEach(filteredCourses, function (value, key) {
              newCourses.push(key);
            });
          });

          newCourses = _.uniq(newCourses);

          $scope.recipe.course = newCourses;
        }

        function manageCourses() {
          var newCourses = new Array();
          var coursesObject = filtersBundle.coursesObject;

          $scope.recipe.course.forEach(function (course) {
            if (!coursesObject[course]) {
              console.error('manageCourses: ' + course);
            }

            coursesObject[course].forEach(function (item) {
              newCourses.push(item);
            });
          });

          newCourses = _.uniq(newCourses);

          $scope.recipe.course = newCourses;
        }

        function checkStepsAndIngredients() {
          $scope.recipe.groups.map(function (group, key_group) {
            group['steps'].map(function (step, key_step) {
              if (step.type === 'mycook') {
                step.outsideTemperature = step.microwaveWatts = null;
                step.auxFields.time_hours = 0;
                step.stepTime = 0;
              } else {
                step.cookTime = 0;
              }

              // check turbo
              if (step.auxFields.step_speed === 'Turbo') {
                step.stepTime = 0;
                step.cookTime = 0;
              }

              step['ingredients'].map(function (ingredient, key_ing) {
                // transform fraction to float
                if (!toolsService.isEmpty(ingredient.qty)) {
                  ingredient.qty = ingredient.qty.toString().replace(/,/g, '.'); // change comma to dot

                  if (ingredient.qty.toString().indexOf('/') !== -1) {
                    // is a fraction
                    ingredient.qty = $filter('fractionToFloat')(ingredient.qty);
                  }
                }
                // trim ingredient
                ingredient.name = ingredient.name.trim();
                ingredient.name = $filter('capitalize')(ingredient.name); //ingredient.name.charAt(0).toUpperCase() + ingredient.name.slice(1);

                if (
                  toolsService.isEmpty(ingredient.qty) &&
                  toolsService.isEmpty(ingredient.unit) &&
                  toolsService.isEmpty(ingredient.name) &&
                  toolsService.isEmpty(ingredient.extra)
                ) {
                  _.pull(
                    $scope.recipe.groups[key_group].steps[key_step].ingredients,
                    $scope.recipe.groups[key_group].steps[key_step].ingredients[
                    key_ing
                    ]
                  );
                }
              });
            });
          });
        }
        

        function resetForm() {
          $scope.myFiles = [];
          $scope.recipe = setEmptyRecipe();
          $scope.pType = undefined;
        }

        // Get TAG num columns
        function tagColumns() {
          if ($scope.currentUser && $scope.currentUser.role) {
            var result = $filter('showTags')(
              $scope.treeTags,
              $scope.currentUser.role
            );
            var num = result.length / 4;

            if (Number.isInteger(num)) {
              $scope.tagCols = 4;
            } else {
              $scope.tagCols = 3;
            }
          }
        }

        // Create TAGS TREE
        var registeredWatch = $scope.$watch(
          'currentUser',
          function (currentUser) {
            if (currentUser) {
              if (!currentUser.role.includes('admin')) {
                $scope.selectMachines = appConfig.allowedCompats || ['S1'];
              } else {
                $scope.selectMachines = appConfig.allowedCompatsAdmin || [
                  'S1',
                  'S2',
                ];
              }
            }

            tagColumns();
          }
        );

        // tree control
        $scope.multiOpts = {
          multiSelection: true,
          //nodeId: "path",
          label: 'name',
          injectClasses: {
            ul: 'a1',
            li: 'a2',
            iExpanded: 'a3',
            iCollapsed: 'a4',
            iLeaf: 'a5',
            label: 'a6',
          },
          equality: function (node1, node2) {
            return (
              node1 !== undefined &&
              node2 !== undefined &&
              node1.path === node2.path
            );
          },
        };

        recipesFactory.getTags().then(function (bundle) {
          $scope.tagsBuenos = toolsService.getObjValues(bundle.tags);
          $scope.treeTags = arrayToAngular2($scope.tagsBuenos);

          // when tags does not have a parent
          var treeTagsCopy = _.cloneDeep($scope.treeTags);
          var emptyTag = { name: '--empty--', checked: false, children: [] };

          $scope.treeTags.forEach(function (tag, index) {
            if (tag.children.length === 0) {
              treeTagsCopy = treeTagsCopy.splice(index, 1);
              emptyTag['children'].push($scope.treeTags[index]);
            }
          });

          if (emptyTag['children'].length > 0) treeTagsCopy.push(emptyTag);
          $scope.treeTags = treeTagsCopy;
          // when tags does not have a parent

          $scope.propagateChanges = function (sel, isSelected) {
            var pathSelected = sel.path.split(appConfig.itemSeparator);
            var hasParent = pathSelected.length > 2;
            if (hasParent) {
              modifyParent(pathSelected);
            }

            // al deseleccionarlo, deseleccionamos hijos
            if (!isSelected && sel.children.length > 0) {
              _.remove($scope.selecteds, function (item) {
                return (
                  item.path.indexOf(sel.path + appConfig.itemSeparator) > -1
                );
              });
            }
          };
        });

        // fill tags checked when edit recipe
        function preSelectTags() {
          // format tag, generate node and add to selecteds
          $scope.recipe.tags.forEach(function (aTag) {
            var tagRegExp = new RegExp('\\' + appConfig.decorator, 'g');
            var tagCorrected = aTag.replace(tagRegExp, appConfig.itemSeparator);

            var selectedNode = {
              name: $filter('getLastItem')(tagCorrected),
              checked: false,
              path: tagCorrected,
              children: [],
            };

            $scope.selecteds.push(selectedNode);
          });
        }

        // add tags from tree directive to model
        function addingTags(recipeModel) {
          recipeModel.tags = [];
          $scope.selecteds.forEach(function (selTag) {
            var tagRegExp = new RegExp('\\' + appConfig.itemSeparator, 'g');
            var tagFormatted = selTag.path.replace(
              tagRegExp,
              appConfig.decorator
            );

            if (recipeModel.tags.indexOf(tagFormatted) === -1) {
              recipeModel.tags.push(tagFormatted);
            }
          });
        }
        // END TAGS TREE

        // mass autocomplete ingredients
        $scope.ac_option_highlight = {
          suggest: suggest_ingredients_remote,
          on_error: console.log,
        };

        function suggest_ingredients(term, ingredients) {
          var fuzzySearch = new Fuse(ingredients, {
            shouldSort: true,
            caseSensitive: false,
            threshold: 0.4,
          });

          var q = term.toLowerCase().trim();

          return fuzzySearch
            .search(q)
            .slice(0, 10)
            .map(function (i) {
              var val = ingredients[i];
              return {
                value: val,
                label: $sce.trustAsHtml(highlight(val, term)),
              };
            });
        }

        function highlight(str, term) {
          var highlight_regex = new RegExp('(' + term + ')', 'gi');
          return str.replace(highlight_regex, '<strong>$1</strong>');
        }

        function suggest_ingredients_remote(term) {
          if (term.length < 2) return;

          var deferred = $q.defer();
          ingredientsManager.getIngredients().then(function (ingredients) {
            deferred.resolve(suggest_ingredients(term, ingredients));
          });
          return deferred.promise;
        }
        // END mass autocomplete ingredients

        // Upload Image recipe
        var editImageSrc =
          $scope.mediaDomain + '/recipe/185x134/' + $routeParams.recipeNiceName;
        $scope.editImageSrc = $routeParams.recipeNiceName ? editImageSrc : null;
        $scope.imagesRecipeUpload = null;

        $scope.removeMainImage = function () {
          $scope.imagesRecipeUpload =
            $scope.recipe.images && $scope.recipe.images.length
              ? ['deleted']
              : null;
          $scope.imageRights = false;
          $scope.editImageSrc = null;
        };

        $scope.photoChanged = function (event) {
          //check if new image is setted and shows it on the screen
          $scope.imageRights = false;
          $scope.fileReaderSupported = window.FileReader != null;
          $scope.imagesRecipeUpload = event.target.files;

          if ($scope.imagesRecipeUpload) {
            var files = $scope.imagesRecipeUpload;
            var file = files[0];
            if ($scope.fileReaderSupported && file.type.indexOf('image') > -1) {
              $timeout(function () {
                var fileReader = new FileReader();
                fileReader.readAsDataURL(file);
                fileReader.onload = function (e) {
                  $timeout(function () {
                    $scope.editImageSrc = e.target.result;
                  });
                };
              });
            }
          }
        };

        function uploadMainFile(niceName, newfile2Send) {
          var destinationUrl = '';
          var method = '';

          if ($scope.recipeCase === 'new') {
            destinationUrl =
              appConfig.mycookTouchDomain + '/recipe/' + niceName + '/image';
            method = 'POST';

            return uploadProcess(destinationUrl, method, newfile2Send);
          } else {
            //es un edit, faig un replace de la imatge a la posicio 0 (elimino la imatge a la posicio 0 i pujo la nova)
            destinationUrl =
              appConfig.mycookTouchDomain + '/recipe/' + niceName + '/image';
            method = 'POST';

            if ($scope.recipe.images.length === 0) {
              //la recepta te la imatge per defecte, no cal fer el delete
              return uploadProcess(destinationUrl, method, newfile2Send);
            } else {
              //sha de borrar la imatge actual de la recepta i pujar la nova

              var urlDestinoDelete =
                appConfig.mycookTouchDomain +
                '/recipe/' +
                niceName +
                '/image/0';

              return $http({
                method: 'delete',
                url: urlDestinoDelete,
              }).then(function (response) {
                return uploadProcess(destinationUrl, method, newfile2Send);
              });
            }
          }
        }

        function uploadProcess(destinationUrl, method, newfile2Send) {
          return new Promise(function (resolve, reject) {
            $upload
              .upload({
                url: destinationUrl,
                method: method,
                file: newfile2Send,
                fileFormDataName: 'image',
              })
              .progress(function (evt) {
                $log.debug('progress1 > evt: ', evt);
                $log.debug(
                  'progress: ' +
                  parseInt((100.0 * evt.loaded) / evt.total, 10) +
                  '% file :' +
                  evt.config.file.name
                );
                $scope.imageProgress =
                  parseInt((100.0 * evt.loaded) / evt.total, 10) +
                  '% file :' +
                  evt.config.file.name;
              })
              .success(function (data, status, headers, config) {
                $log.debug(
                  'que es el que tinc al data: ',
                  data,
                  status,
                  headers,
                  config
                );
                $log.debug(
                  'file ' +
                  config.file.name +
                  ' is uploaded successfully. Response: ',
                  data
                );

                resolve();
              })
              .error(function (e) {
                $log.error('File upload Error', e);
                $scope.errorPhotoUpload = true;

                reject();
              });
          });
        }

        function checkFileValidity(file, niceName) {
          var newfile2Send;

          if (Object.prototype.toString.call(file) === '[object File]') {
            newfile2Send = file;
          } else if (aFile.indexOf(',') > -1) {
            /* santi: No tinc molt clar aixó, penso que mai caurem aquí */
            var blobBin = atob(aFile.split(',')[1]);
            var array = [];
            for (var j = 0; j < blobBin.length; j++) {
              array.push(blobBin.charCodeAt(j));
            }

            newfile2Send = new Blob([new Uint8Array(array)], {
              type: 'image/jpeg',
              name: 'new-image.jpg',
            }); // image/png ??
          }

          return newfile2Send ? uploadMainFile(niceName, newfile2Send) : null;
        }

        function postFiles(myFiles, niceName) {
          var promises = [];

          if (myFiles.main && myFiles.main.file) {
            if (myFiles.main.file === 'deleted') {
              promises.push(
                $http({
                  method: 'delete',
                  url:
                    appConfig.mycookTouchDomain +
                    '/recipe/' +
                    niceName +
                    '/image/0',
                })
              );
            } else {
              if (myFiles.main.rights) {
                var promise = checkFileValidity(myFiles.main.file, niceName);
                if (promise) promises.push(promise);
                else $log.error('Invalid main file');
              } else {
                $log.error(
                  'Avoiding main recipe image upload because of missing rights'
                );
              }
            }
          }

          promises.push.apply(
            promises,
            myFiles.steps
              .map(function (stepFile) {
                if (stepFile.file) {
                  if (stepFile.file === 'deleted') {
                    promises.push(
                      $http({
                        method: 'delete',
                        url:
                          appConfig.mycookTouchDomain +
                          '/recipe/' +
                          niceName +
                          '/imageChild/' +
                          stepFile.id,
                      })
                    );
                  } else {
                    if (stepFile.rights) {
                      var url =
                        appConfig.mycookTouchDomain + '/recipe/' + niceName;
                      url += stepFile.id
                        ? '/imageChild/' + stepFile.id
                        : '/imageChild/groups.steps/' +
                        stepFile.groupIdx +
                        '.' +
                        stepFile.stepIdx;

                      return uploadProcess(url, 'POST', stepFile.file);
                    } else {
                      var actualId =
                        stepFile.id ||
                        stepFile.groupIdx + '_' + stepFile.stepIdx;

                      $log.error(
                        'Avoiding step "' +
                        actualId +
                        '" image upload because of missing rights'
                      );

                      return null;
                    }
                  }
                } else return null;
              })
              .filter(function (promise) {
                return promise;
              })
          );

          return promises.length ? Promise.all(promises) : Promise.resolve();
        }

        // function postFilesOld(myFiles, niceName) {
        //   // FEATURE: [not necessary, just in case to allow multiple files upload] if need to upload multiple images, need to do this process asynchronously
        //   var promises = [];

        //   for (var i = 0; i < myFiles.length; i++) {
        //     var aFile = myFiles[i];
        //     var newfile2Send;

        //     if (Object.prototype.toString.call(aFile) === '[object File]') {
        //       newfile2Send = aFile;
        //     } else if (aFile.indexOf(',') > -1) {
        //       var blobBin = atob(aFile.split(',')[1]);
        //       var array = [];
        //       for (var j = 0; j < blobBin.length; j++) {
        //         array.push(blobBin.charCodeAt(j));
        //       }

        //       newfile2Send = new Blob([new Uint8Array(array)], {
        //         type: 'image/jpeg',
        //         name: 'new-image.jpg',
        //       }); // image/png
        //     }

        //     if (newfile2Send) {
        //       promises.push(uploadFile(niceName, newfile2Send));
        //     }
        //   }

        //   return promises.length ? Promise.all(promises) : Promise.resolve();
        // }
        // END Upload Image recipe

        // Manage Tags
        function modifyParent(pathSelected) {
          var parentPath = pathSelected.slice(0, pathSelected.length - 1);
          var parentPathStr = parentPath.join(appConfig.itemSeparator);

          var nodeChecked = false;
          for (var i = 0; i < $scope.treeTags.length && !nodeChecked; i++) {
            var mainTag = $scope.treeTags[i];
            for (var j = 0; j < mainTag.children.length && !nodeChecked; j++) {
              var parentTag = mainTag.children[j];

              if (parentTag.path === parentPathStr) {
                if (!_.some($scope.selecteds, { path: parentPathStr })) {
                  $scope.selecteds.push(parentTag);
                }
              }
            }
          }
        }

        function arrayToAngular2(array, check) {
          var op = 'uncheck';
          if (check !== undefined) {
            op = check;
          }

          var tree = [];
          for (var i in array) {
            var path = array[i];
            _angularToggle2(tree, path.split(appConfig.itemSeparator), 0, op);
          }
          return tree;
        }

        function findNode2(array, name) {
          for (var i = 0; i < array.length; i++) {
            var node = array[i];
            if (node.name === name) {
              return i;
            }
          }
          return -1;
        }

        function _angularToggle2(tree, segments, level, op, param) {
          var pos;
          var node;
          if (segments.length - 1 === level) {
            pos = findNode2(tree, segments[level]);
            if (pos === -1) {
              node = {};
              node.name = segments[level];
              node.checked = false;
              node.path = segments.join(appConfig.itemSeparator);
              node.children = [];
              tree.push(node);
              pos = tree.length - 1;
            }
            if (op === 'toggle') {
              tree[pos].checked = !tree[pos].checked;
            } else if (op === 'check') {
              tree[pos].checked = true;
            } else if (op === 'uncheck') {
              tree[pos].checked = false;
            } else if (op === 'get') {
              return tree[pos].children;
            } else if (op === 'put') {
              tree[pos].children = param;
            }
          } else {
            pos = findNode2(tree, segments[level]);
            if (pos === -1) {
              node = {};
              node.name = segments[level];
              node.checked = false;
              node.children = [];
              node.path = segments
                .slice(0, level + 1)
                .join(appConfig.itemSeparator);
              tree.push(node);
              pos = tree.length - 1;
            }
            _angularToggle2(tree[pos].children, segments, level + 1, op, param);
          }
          return tree;
        }
        // END Manage Tags
      },
    ]);
})();
