(function () {
	angular.module('mainApp')

		.controller('AffiliatesLoginController', ['$scope', '$rootScope', '$timeout', '_', 'affiliatesUsersManager', 'webStorage',
			function ($scope, $rootScope, $timeout, _, affiliatesUsersManager, webStorage) {

				var last_unaccepted;

				$scope.resend_mail = function () {

					$scope.errorLegalTerms = false;
					$scope.checkMail = false;

					affiliatesUsersManager.resendMail(last_unaccepted)
						.then(function(response) {

							$scope.checkMail = response;

						}, function(err) {
							console.error(err);
							$scope.errorLogin = true;

							$timeout(function () {
								$scope.errorLogin = false;
							}, 1500);

						});
				}

				$scope.login = function (user, pass) {

					$scope.resendMailButton = false;
					$scope.errorLegalTerms = false;
					$scope.checkMail = false;
					
					affiliatesUsersManager.loginUser(user, pass).then(function (userInfo) {

						if (userInfo.legalTerms) {

							$scope.errorLogin = false;
							webStorage.set('userNiceNameAffiliates', userInfo.niceName);
							$rootScope.$emit('userAffiliatesLoggedIn', userInfo);

						}

					}, function (e) {

						if (e.status === 403) {

							last_unaccepted = e.data;
							$scope.resendMailButton = true;
							$scope.errorLegalTerms = true;

						} else {

							$scope.errorLogin = true;

							$timeout(function () {
								$scope.errorLogin = false;
							}, 1500);

						}
					});
				};

			}]);
})();