(function () {
  angular
    .module("mainApp")

    .factory("contactFactory", [
      "$http",
      "$q",
      "$rootScope",
      "appConfig",
      "toolsService",
      "storeFactory",
      function ($http, $q, $rootScope, appConfig, toolsService, storeFactory) {
        var _contactFactory = {};

        _contactFactory.sendContactInformation = function (userData) {
          deferredResponse = $q.defer();
          var urlDestino = appConfig.localApiBasePath + "/contact/";

          var topic = userData.subject;
          var name = userData.name;
          var email = userData.mail;

          var data = {
            contact_email: userData.contact_email,
            topic: topic.name,
            name: name,
            email: email,
            date: new Date().toISOString()
          };

          if (userData.machine) data.serialNumber = userData.machine;
          if (userData.message) data.message = userData.message;
          if (userData.order_id) data.order_id = userData.order_id;
          if (userData.address) data.address = userData.address;
          if (userData.state) data.state = userData.state;
          if (userData.surname_1) data.surname_1 = userData.surname_1;
          if (userData.surname_2) data.surname_2 = userData.surname_2;
          if (userData.town) data.city = userData.town;
          if (userData.zip) data.postal_code = userData.zip;
          if (userData.idcard) data.idcard = userData.idcard;
          if (userData.phone) data.phone = userData.phone;
          if (userData.contact_phone !== undefined) data.contact_phone = userData.contact_phone ? $rootScope.translations.common.yes : $rootScope.translations.common.no;

          $http({
            method: "post",
            data: data,
            url: urlDestino
          }).then(
            function (res) {
              deferredResponse.resolve(true);
            },
            function errorCallback(response) {
              deferredResponse.resolve(false);
            }
          );

          return deferredResponse.promise;
        };

        return _contactFactory;
      }
    ])

    .controller("ContactController", [
      function (
        $scope,
        $rootScope,
        $location,
        $timeout,
        appConfig,
        contactFactory,
        usersManager,
        toolsService,
        legalTerms,
        storeFactory,
        _
      ) {

        $scope.mediaDomain = appConfig.mycookTouchDomain + '/image';

        $scope.userData = {
          newsletter: false,
          policy: false
        };

        var extraQuery = $location.search()["extra"];

        if (extraQuery)
          $scope.isExtra = $rootScope.translations.help.contact.telesales.enabled_home || $rootScope.translations.help.contact.telesales.enabled_store;

        usersManager.getMachineList().then(function (result) {
          if (result.length > 0) {
            $scope.userData.machine = result[0].niceName;
          }
        });

        if (!toolsService.isEmpty($scope.currentUser)) {
          $scope.userData["name"] =
            $scope.currentUser.name.firstName +
            " " +
            $scope.currentUser.name.lastName;
          $scope.userData["mail"] = $scope.currentUser.contact.mail;
        }

        if (appConfig.webSettings.shop) {
          storeFactory.getStates().then(function (states) {
            $scope.states = states;
          });

          if ($location.search()) {
            if ($location.search().order_id) {
              $scope.userData["order_id"] = $location.search().order_id;

              var foundElement = _.find(
                $rootScope.translations.help.contact.form.subject_list,
                function (o) {
                  return o.key == "I_NEED_BILL";
                }
              );
              $scope.userData["subject"] = foundElement;
              $scope.userData["contact_email"] = foundElement.contact_email;

              $timeout(function () {
                $scope.contactForm.subject.$setDirty();
              }, 0);
            }

            if ($location.search().name)
              $scope.userData["name"] = $location.search().name;
            if ($location.search().address)
              $scope.userData["address"] = $location.search().address;
            if ($location.search().state)
              $scope.userData["state"] = $location.search().state;
            if ($location.search().surname)
              $scope.userData["surname_1"] = $location.search().surname;
            if ($location.search().town)
              $scope.userData["town"] = $location.search().town;
            if ($location.search().zip)
              $scope.userData["zip"] = $location.search().zip;
            if ($location.search().idcard)
              $scope.userData["idcard"] = $location.search().idcard;
            if ($location.search().mail)
              $scope.userData["mail"] = $location.search().mail;
          }
        }

        $scope.signature_contract = "deactivated";
        $scope.userLegalData = {};
        $scope.anonymousUserData = true;

        $scope.cancelContract = function (userLegalData) {
          $scope.signature_contract = "deactivated";
          $scope.contract_not_accepted = true;
        };

        $scope.acceptContract = function (validForm, userLegalData) {
          $scope.signature_contract = "loading";
          $scope.contract_not_accepted = false;
          var sendToApi = new Array();

          for (var k in userLegalData) {
            if (userLegalData[k]) {
              sendToApi.push(k);
            }
          }

          legalTerms
            .acceptLegalTerms("multi", sendToApi, $scope.userData.mail)
            .then(function (result) {
              sendContactInformation();

              $scope.signature_contract = "deactivated";
            });
        };

        function sendContactInformation() {
          contactFactory
            .sendContactInformation($scope.userData)
            .then(function (response) {
              if (response === true) {
                $scope.contactForm.$setPristine();
                $scope.userData = {};
                $scope.successContact = true;
              } else {
                $scope.successContact = false;
              }
            });
        }

        $scope.closeReturn = function() {
          $scope.order = null;
        }

        $scope.sendContactForm = function () {
          // check solicitar factura
          if (!toolsService.isEmpty($scope.userData.order_id)) {
              storeFactory
                .checkMailOrder($scope.userData.mail, $scope.userData.order_id)
                .then(function (data) {
                  $scope.error_order_mail = false;
                  $scope.error_order_id = false;
                  $scope.error_order_rma_mail = false;
                  $scope.error_order_rma_id = false;

                  if ($scope.userData.subject.contact_email)
                    signContract();
                  else {
                    storeFactory.getGuestOrder($scope.userData.order_id, $scope.userData.mail)
                      .then(function (data) {

                          if (toolsService.canRma(data)) {
                            
                            $scope.order = data;
                            $scope.validationMail = $scope.userData.mail;

                          } else
                            $scope.error_order_rma_id = true;

                      }, function(err) {

                        $scope.error_order_rma_id = true;

                      });
                  }
                })
                .catch(function (error) {
                  if (error.status == "401") {
                    // email incorrecte
                    if (!$scope.userData.subject.contact_email) {

                      $scope.error_order_rma_mail = true;
                      $scope.error_order_rma_id = false;

                    } else {

                      $scope.error_order_mail = true;
                      $scope.error_order_id = false;

                    }
                    
                  } else {
                    // order not found

                    if (!$scope.userData.subject.contact_email) { 

                      $scope.error_order_rma_mail = false;
                      $scope.error_order_rma_id = true;

                    } else {

                      $scope.error_order_mail = false;
                      $scope.error_order_id = true;

                    }
                  }
                });
          } else {
            if ($scope.telesale())
              accepExtraTerms();
            else
              signContract();
          }
        };

        $scope.telesale = function () {
          return $scope.isExtra || ($scope.userData.subject && $scope.userData.subject.key === 'TELESALES');
        }

        function signContract() {
          legalTerms.getLegalList().then(function (legalList) {
            if (_.indexOf(legalList, "contactConditions") > -1) {
              $scope.signature_contract = "activated";

              legalTerms
                .getLegalTerms(["contactConditions"])
                .then(function (legal) {
                  $scope.legal = legal;
                  $scope.signature_contract = "activated";
                });
            } else sendContactInformation();
          });
        }

        function accepExtraTerms() {

          if ($scope.isExtra) {
            $scope.userData.contact_email = $rootScope.translations.help.contact.telesales.contact_mail;
            $scope.userData.subject = { name: $rootScope.translations.help.contact.telesales.fixed_reason };
          }

          $scope.userData.contact_phone = $scope.userData.contact_phone || false;

          legalTerms.getLegalList().then(function (legalList) {
            if (legalList.includes("telesalesConditions")) {

              legalTerms
                .getLegalTerms(["telesalesConditions"])
                .then(function (legal) {
                  $scope.legal = legal;
                  $scope.signature_contract = "activated";
                });
            } else sendContactInformation();
          });
        }
      }
    ]).directive('phoneValidation', function () {
      return {
        require: 'ngModel',
        link: function (scope, element, attr, mCtrl) {
          function myValidation(value) {
            if (!isNaN(value) && value.length === 9) {
              mCtrl.$setValidity('phone', true);
            } else {
              mCtrl.$setValidity('phone', false);
            }
            return value;
          }
          mCtrl.$parsers.push(myValidation);
        }
      };
    });
})();
