(function () {
  'use strict';

  angular
    .module('mainApp')

    .controller('RecipesByCategoryController', [
      '$rootScope',
      '$scope',
      '$filter',
      '$q',
      '$routeParams',
      '_',
      'toolsService',
      'appConfig',
      'recipesFactory',
      'recipeCategoriesFactory',
      'seoService',
      'recipeListService',
      'serviceLandings',
      'sessionService',
      'richSearchService',
      '$timeout',
      '$location',
      function (
        $rootScope,
        $scope,
        $filter,
        $q,
        $routeParams,
        _,
        toolsService,
        appConfig,
        recipesFactory,
        recipeCategoriesFactory,
        seoService,
        recipeListService,
        serviceLandings,
        sessionService,
        richSearchService,
        $timeout,
        $location
      ) {
        var category = $routeParams.categoryNiceName;
        var promises = [];
        var criteria = {};
        var pageQuery = '?';
        var categoryTitle = '';
        var currentPage = $routeParams.currentPage
          ? $routeParams.currentPage
          : 1;

        $scope.allowedCompats = appConfig.allowedCompats || ['S1'];

        //Allowed Compats
        var registeredWatch = $scope.$watch(
          'currentUser',
          function (currentUser) {
            if (currentUser) {
              if (!currentUser.role.includes('admin')) {
                $scope.allowedCompats = appConfig.allowedCompats || ['S1'];
              } else {
                $scope.allowedCompats = appConfig.allowedCompatsAdmin || [
                  'S1',
                  'S2',
                ];
              }
            }
          }
        );

        $scope.$on('$destroy', function () {
          registeredWatch();
        });

        $scope.currentPage = parseInt(currentPage);

        $scope.showDeleteDialog = function (niceName, title) {
          recipeListService.showDeleteDialog(niceName, title);
        };

        $scope.showPublishDialog = function (recipe, title) {
          recipeListService.showPublishDialog(
            recipe,
            title,
            $scope.deviceDetector.raw.userAgent
          );
        };

        // $scope.goToRecipe = function (userMachine, recipeNiceName) {
        //     console.log('goToRecipe');
        //     localStorage.setItem('machineSelected', userMachine);
        //     $location.path($rootScope.url('recipe', {recipeNiceName: recipeNiceName}));
        // };

        promises.push(recipeCategoriesFactory.getCategoryByNiceName(category)); // get category information
        promises.push(recipesFactory.getTags()); // get Tags
        if (appConfig.webSettings.richSearch)
          promises.push(richSearchService.getByTerm(category, 'category'));

        var categoryLandingsCounter = sessionService.getValue(
          'category-' + category,
          'session'
        );
        var offSetItems = categoryLandingsCounter ? categoryLandingsCounter : 0;

        if (
          ($scope.currentPage == 1 || !categoryLandingsCounter) &&
          appConfig.webSettings.landingsAtRecipesList
        ) {
          // get Landings & ebooks
          var landingProjection = {
            title: 1,
            img: 1,
            niceName: 1,
            type: 1,
            shortDescription: 1,
            _id: 0,
          };

          promises.push(
            serviceLandings.getLandingsByTerm(
              category,
              'categories',
              landingProjection
            )
          );
        }

        $q.all(promises).then(function (results) {
          var categoryInfo = results[0];
          var tagsBundle = results[1];
          var richSearch = results[2];
          if (results[3]) var landingsBundle = results[3];

          if (typeof categoryInfo !== 'undefined') {
            categoryTitle = categoryInfo.name;
          }

          $scope.tags = tagsBundle.tags;
          $scope.tagsDietas = tagsBundle.tagsDietas;

          if (!toolsService.isEmpty(category)) {
            category = category.replace(appConfig.decorator, '');
            if (category != $rootScope.translations.common.category_all.link) {
              criteria.category = category;
            }
          }

          var orderInfo = recipeListService.setOrderFilters(
            criteria,
            pageQuery
          );
          pageQuery = orderInfo.pageQuery;
          criteria = orderInfo.criteria;
          criteria['status.idParent'] = '';
          $scope.pageQuery = pageQuery;
          $scope.criteria = criteria;

          if (landingsBundle && landingsBundle.status.count > 0) {
            landingsBundle.results = toolsService.getRandomValuesFromArray(
              landingsBundle.results,
              3
            );
            offSetItems = landingsBundle.results.length;

            _.map(landingsBundle.results, function (obj) {
              if (obj.type == 'ebook')
                obj.niceName_noebook = obj.niceName.replace('ebook-', '');
              return obj;
            });
          }

          // Save counter in session
          sessionService.setValue(
            'category-' + category,
            offSetItems,
            'session'
          );

          $scope.$emit('LOAD');

          recipesFactory
            .getRecipesByCriteria(criteria, $scope.currentPage, offSetItems)
            .then(function (recipes) {
              var pageTitle =
                categoryTitle ||
                $rootScope.translations.common.category_all.title;
              var pageDescription = $filter('translateVariables')(
                $rootScope.translations.seo.recipeList.description,
                pageTitle
              );

              setSeo(
                pageTitle,
                pageDescription,
                richSearch,
                recipes.status.count
              );

              if (richSearch) {
                $scope.recipesTitle = richSearch.title;
                $scope.richSearch = richSearch;
              } else {
                $scope.recipesTitle = pageTitle;
              }

              var bundleRecipesAndLandings = recipes.result;

              // SET LANDING DATA TO VIEW
              if (
                $scope.currentPage == 1 &&
                landingsBundle &&
                landingsBundle.status.count > 0
              ) {
                bundleRecipesAndLandings = toolsService.pushElementsAtPositions(
                  recipes.result,
                  landingsBundle.results
                );
              }

              $scope.contentData = bundleRecipesAndLandings;

              // Paginacion
              var _maxCount = recipes.status.count + offSetItems;
              $scope.maxCount = _maxCount;
              $scope.itemsByPage = appConfig.itemsByPage;
              $scope.lastPage =
                _maxCount > appConfig.itemsByPage
                  ? Math.ceil(_maxCount / appConfig.itemsByPage)
                  : 1;

              seoService.setPrevPage($scope.currentPage, $scope.pageQuery);
              seoService.setNextPage(
                $scope.currentPage,
                $scope.pageQuery,
                $scope.lastPage
              );

              $scope.$emit('UNLOAD');
            })
            .catch(function (error) {
              toolsService.manageError(
                'CategoryController > getRecipesByCriteria > ERROR1: ',
                error
              );
            });

          function setSeo(
            pageTitle,
            pageDescription,
            richSearch,
            recipesCount
          ) {
            if (richSearch && richSearch.seo) {
              if (richSearch.seo.title)
                pageTitle = $filter('translateVariables')(
                  richSearch.seo.title,
                  recipesCount
                );
              if (richSearch.seo.description)
                pageDescription = richSearch.seo.description;
            }

            seoService.setTitle(pageTitle, $scope.currentPage);
            seoService.setDescription(pageDescription, $scope.currentPage);
          }
        });

        $scope.showDietPlanDialog = function (recipe) {
          $scope.recipeToDietPlan = recipe;
          $scope.showDietPlan = true;
        };

        $scope.closeDietPlanDialog = function () {
          $scope.recipeToDietPlan = null;
          $scope.showDietPlan = false;
        };
      },
    ])
    /* Esta directiva quizas habria que moverla a algun lugar mejor, pero no se donde */
    .directive('addToDietPlan', function () {
      return {
        scope: {
          recipe: '=',
          close: '&',
          translations: '=',
        },
        controller: function ($scope) {
          $scope.fromDirective = true;

          $scope.closeDialog = function () {
            $scope.close()();
          };
        },
        templateUrl: 'template/recipe/add-to-diet-plan.html',
        replace: true,
      };
    });
})();
