(function () {
  angular
    .module('mainApp')

    .service(
      'serviceApiAffiliates',
      function (
        $rootScope,
        $http,
        $q,
        $location,
        $route,
        $filter,
        appConfig,
        sessionService
      ) {
        var getAffiliateProducts = function (affiliate) {
          var defer = $q.defer();

          $http({
            method: 'GET',
            url: appConfig.localApiBasePath + '/affiliateProducts/' + affiliate,
            headers: {
              Authorization: 'Bearer ' + sessionService.getUserToken(true),
            },
          }).then(function (res) {
            var data = res.data.response;
            defer.resolve(data);
          });

          return defer.promise;
        };

        var getBanners = function (affiliate, type) {
          var defer = $q.defer();
          var url =
            appConfig.localApiBasePath +
            '/affiliates/links/' +
            affiliate +
            '/' +
            type;

          $http({
            method: 'GET',
            url: url,
            headers: {
              Authorization: 'Bearer ' + sessionService.getUserToken(true),
            },
          }).then(function (res) {
            var data = res.data;
            defer.resolve(data);
          });

          return defer.promise;
        };

        var getResources = function (type) {
          var defer = $q.defer();
          var url = appConfig.localApiBasePath + '/resources';

          $http({
            method: 'GET',
            url: url,
            headers: {
              Authorization: 'Bearer ' + sessionService.getUserToken(true),
            },
          }).then(function (res) {
            var data = res.data.result[0];
            defer.resolve(data);
          });

          return defer.promise;
        };

        var getIncomes = function (affiliate) {
          var defer = $q.defer();
          var url = appConfig.localApiBasePath + '/conversionsFront';
          //var queryData = {};

          // queryData.query = {
          //   "affiliate.niceName": affiliate,
          //   returned: false
          // }

          $http({
            method: 'GET',
            url: url,
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + sessionService.getUserToken(true),
            },
            // data: queryData
          }).then(function (res) {
            var data = res.data;
            console.log(data);
            defer.resolve(data);
          });

          return defer.promise;
        };

        var getAffiliateCode = function () {
          var defer = $q.defer();
          var url = appConfig.localApiBasePath + '/affiliateCode';
          //var queryData = {};

          // queryData.query = {
          //   "affiliate.niceName": affiliate,
          //   returned: false
          // }

          $http({
            method: 'GET',
            url: url,
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + sessionService.getUserToken(true),
            },
            // data: queryData
          }).then(function (res) {
            defer.resolve(res.data.code);
          }, defer.reject);

          return defer.promise;
        };

        return {
          getBanners: getBanners,
          getResources: getResources,
          getIncomes: getIncomes,
          getAffiliateProducts: getAffiliateProducts,
          getAffiliateCode: getAffiliateCode,
        };
      }
    );
})();
