(function () {
  angular
    .module('mainApp')

    .service(
      'recipeListService',
      function (
        $rootScope,
        $route,
        $routeParams,
        $log,
        recipesFactory,
        ngDialog,
        toolsService,
        $timeout
      ) {
        var showDeleteDialog = function (niceName, title) {
          if (!toolsService.isEmpty(niceName)) {
            var recipeToDelete = {
              niceName: niceName,
              title: title,
            };
            ngDialog
              .openConfirm({
                template: 'remove-recipe.html',
                className: 'new-recipe',
                data: recipeToDelete,
              })
              .then(
                function (r) {
                  recipesFactory.deleteRecipe(niceName).then(
                    function (res) {
                      $route.reload();
                    },
                    function (err) {
                      $log.error(
                        'RecipeListService > showDeleteDialog > deleteRecipe ERROR: ',
                        err
                      );
                    }
                  );
                },
                function (e) {
                  $log.error(
                    'RecipeListService > showDeleteDialog > openConfirm ERROR: ',
                    err
                  );
                }
              );
          }
        };

        var showPublishDialog = function (recipe, title, userAgent) {
          if (typeof recipe !== 'undefined') {
            if (!toolsService.isEmpty(recipe.niceName)) {
              var recipeToPublish = {
                niceName: recipe.niceName,
                title: title,
              };

              ngDialog
                .openConfirm({
                  template: 'send-recipe.html',
                  className: 'publish-recipe',
                  data: recipeToPublish,
                })
                .then(
                  function (r) {
                    //quitar los tags de draft
                    recipe.grants.view = ['public'];

                    recipe.grants.search = recipe.grants.search.filter(
                      function (g) {
                        return !g.startsWith('draft/');
                      }
                    );

                    recipe.grants.search.push('public');

                    recipe.info = {
                      creationDate: new Date(),
                      creationSource: userAgent,
                    };

                    recipesFactory.edit(recipe).then(
                      function (res) {
                        $rootScope.$broadcast('updateDraftRecipes', {});
                        $route.reload();
                      },
                      function (err) {
                        $log.error(
                          'RecipeListService > showPublicDialog > editRecipe ERROR: ',
                          err
                        );
                      }
                    );
                  },
                  function (e) {
                    $log.error(
                      'RecipeListService > showPublicDialog > openConfirm ERROR: ',
                      err
                    );
                  }
                );
            }
          }
        };

        var setOrderFilters = function (criteria, pageQuery) {
          var searchParamsObj = [];
          var sortBy = $routeParams.sortBy;
          if (!sortBy) {
            sortBy = 'recent';
          }

          var query = setPageQuery(sortBy, 'sortBy');
          if (query) searchParamsObj.push(query);
          criteria = setCriteria(criteria, sortBy, 'sortBy');

          var hasVideo = $routeParams.hasVideo;
          var query = setPageQuery(hasVideo, 'hasVideo');
          if (query) searchParamsObj.push(query);
          criteria = setCriteria(criteria, hasVideo, 'hasVideo');

          var user = $routeParams.user;
          criteria = setCriteria(criteria, user, 'user');

          var verified = $routeParams.verified;
          var query = setPageQuery(verified, 'verified');
          if (query) searchParamsObj.push(query);
          criteria = setCriteria(criteria, verified, 'verified');

          var queryString = Object.keys(searchParamsObj)
            .map(function (key) {
              return (
                searchParamsObj[key]['key'] +
                '=' +
                searchParamsObj[key]['value']
              );
            })
            .join('&');

          if (queryString.length > 0) queryString = '?' + queryString;

          var orderInfo = {
            pageQuery: queryString,
            criteria: criteria,
          };

          return orderInfo;
        };

        function setPageQuery(param, paramName) {
          if (!toolsService.isEmpty(param)) {
            var query = {};
            query['key'] = paramName;
            query['value'] = param;
            return query;
          } else return null;
        }

        function setCriteria(criteria, param, paramName) {
          if (!toolsService.isEmpty(param)) {
            criteria[paramName] = param;
          }
          return criteria;
        }

        return {
          showDeleteDialog: showDeleteDialog,
          showPublishDialog: showPublishDialog,
          setOrderFilters: setOrderFilters,
        };
      }
    );
})();
