(function () {
    angular.module('mainApp')

        .factory('forgotPasswordFactory', ['$http', '$q', 'appConfig', 'toolsService', 'saRouter', function ($http, $q, appConfig, toolsService, saRouter) {

            var _forgotPasswordFactory = {};

            _forgotPasswordFactory.sendChangePasswordRequest = function (userEmail) { //User identificator may be userNiceName or user email

                deferredResponse = $q.defer();

                var urlDestino = appConfig.mycookTouchDomain + '/recoverPassword/' + userEmail;

                route = saRouter.getRoute('recoverPassword');
                url = saRouter.replaceUrlParams(route.url, {user: '-', token: '-'});
                url = url.replace("/-/-", "");

                var data = {
                    "redirectUrl": url
                };

                $http({
                    method: 'post',
                    data: data,
                    url: urlDestino
                }).then(function (res) {
                    deferredResponse.resolve(true);
                }, function errorCallback(response) {
                    deferredResponse.resolve(false);
                });

                return deferredResponse.promise;
            }

            _forgotPasswordFactory.checkToken = function (token) {

                deferredResponse = $q.defer();

                var urlDestino = appConfig.mycookTouchDomain + '/checkToken/' + token;
                $http({
                    method: 'get',
                    url: urlDestino
                }).then(function (res) {
                    deferredResponse.resolve(true);
                }, function errorCallback(response) {
                    deferredResponse.resolve(false);
                });

                return deferredResponse.promise;
            }

            _forgotPasswordFactory.changePassword = function (userNiceName, newPassword, token) {

                deferredResponse = $q.defer();

                var urlDestino = appConfig.mycookTouchDomain + '/user/resetPassword/' + userNiceName + '/' + token;
                var params = {
                    "password": newPassword
                };

                $http({
                    method: 'post',
                    url: urlDestino,
                    data: params
                }).then(function (res) {
                    deferredResponse.resolve(true);
                }, function errorCallback(response) {
                    deferredResponse.resolve(false);
                });

                return deferredResponse.promise;
            }

            _forgotPasswordFactory.changePasswordAuthenticated = function (userNiceName, newPassword, oldPassword) {

                deferredResponse = $q.defer();

                var urlDestino = appConfig.mycookTouchDomain + '/user/' + userNiceName + '/password';

                var params = {
                    "oldPassword": oldPassword,
                    "newPassword": newPassword
                };

                $http({
                    method: 'PUT',
                    url: urlDestino,
                    data: params
                }).then(function (res) {
                    deferredResponse.resolve(true);
                }, function errorCallback(response) {
                    deferredResponse.resolve(false);
                });

                return deferredResponse.promise;
            }

            return _forgotPasswordFactory;
        }])

        .controller('RecoverPasswordController', [function ($scope, $rootScope, $window, $route, $routeParams, forgotPasswordFactory) {
            var userNiceName = $routeParams.user;
            var token = $routeParams.token;

            $scope.recoverPassword = {
                recoverPassword: ""
            };

            forgotPasswordFactory.checkToken(token).then(function (response) {
                if (response == false) { //redirigir a la home
                    var urlToRedirect = $rootScope.url('home');
                    $window.location.href = urlToRedirect;
                }
            });

            $scope.submitForm = function (newPassword) {

                forgotPasswordFactory.changePassword(userNiceName, newPassword, token).then(function (response) {
                    if (response) {
                        $scope.successChangePassword = true;
                    } else {
                        $scope.successChangePassword = false;
                    }
                });
            }
        }])

        .controller('ForgotPasswordController', [function ($scope, $rootScope, $window, $route, $routeParams, forgotPasswordFactory) {
            $scope.submitForgotPassword = function (email) {
                $scope.error = false;

                forgotPasswordFactory.sendChangePasswordRequest(email).then(function (response) {
                    if (response) { //el usuario se ha encontrado en la base de datos i se ha enviado el correo para recuperar el password
                        $scope.success = true;
                        $scope.error = false;
                    } else { //el usuario no se ha encontrado en la base de datos
                        $scope.success = false;
                        $scope.error = true;
                    }
                });
            }
        }]);
})();
