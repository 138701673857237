(function () {

  'use strict';

  angular.module('mainApp')

    .factory('affiliatesUsersManager', ['$http', '$log', '$q', 'appConfig', 'sessionService', 'webStorage',
      function ($http, $log, $q, appConfig, sessionService, webStorage) {
        var _affiliatesUsersFactory = {};

        _affiliatesUsersFactory.resendMail = function (userName) {

          return $http({
            method: 'post',
            data: { niceName: userName },
            url: appConfig.localApiBasePath + '/affiliates/legalterms/resend/mail'
          }).then(function (res) {
            return res.data.response;
          });

        }

        _affiliatesUsersFactory.loginUser = function (userName, userPassword) {
          var urlDestino = appConfig.localApiBasePath + '/affiliates/login';

          var data = {
            login: userName,
            password: userPassword
          };

          return $http({
            method: 'post',
            data: data,
            url: urlDestino
          }).then(function (res) {
            var userInfo = res.data;
            if (userInfo.legalTerms) {
              webStorage.set('userNiceNameAffiliates', userInfo.niceName);
              sessionService.setUserToken(userInfo.token, true);
            }
            return userInfo;
          });
        };

        _affiliatesUsersFactory.logoutUser = function () {
          sessionService.setUserToken('', true);
          webStorage.remove('userNiceNameAffiliates');
        };

        _affiliatesUsersFactory.checkToken = function (token) {
          var urlDestino = appConfig.localApiBasePath + '/affiliates/checkToken/' + token;
          return $http({
            'method': 'get',
            url: urlDestino
          });
        };

        _affiliatesUsersFactory.getAffiliateInfo = function (affiliate, profile) {
          var defer = $q.defer();
          var url = appConfig.localApiBasePath + '/affiliate/' + affiliate;

          if (profile) {
            url += '?profile=' + profile
          }

          $http({
            method: 'GET',
            url: url,
            headers: {
              'Authorization': 'Bearer ' + sessionService.getUserToken(true)
            }
          }).then(function (res) {
            var data = res.data;
            defer.resolve(data);
          }).then(function errorCallBack(err) {
            var error = err;
            defer.reject(error);
          });

          return defer.promise;
        };

        _affiliatesUsersFactory.updateAffiliateInfo = function (affiliate, info) {
          var defer = $q.defer();
          var url = appConfig.localApiBasePath + '/affiliate/' + affiliate;

          $http({
            method: 'PUT',
            url: url,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + sessionService.getUserToken(true)
            },
            data: info
          }).then(function (res) {
            var data = res.data;
            defer.resolve(true);
          }, function errorCallBack(err) {
            var error = err;
            defer.resolve(error);
          });

          return defer.promise;
        };

        return _affiliatesUsersFactory;
      }]);
})();