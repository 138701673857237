(function () {
  angular
    .module('mainApp')
    .filter('multidimensionalFilter', [
      function () {
        return function (input, subSection) {
          return subSection
            ? input.filter(function (item) {
              if (Array.isArray(item.product)) {
                return item.product.find(function (product) {
                  return product.name === subSection;
                });
              } else {
                return item.product === subSection;
              }
            })
            : input;
        };
      },
    ])
    .controller('AffiliatesResourcesController', [
      '$scope',
      '$rootScope',
      'serviceApiAffiliates',
      'webStorage',
      'toolsService',
      function ($scope, $rootScope, serviceApiAffiliates, webStorage, toolsService) {
        var userNiceName = webStorage.get('userNiceNameAffiliates');

        $scope.updateMachineFilter = function (machine) {
          if (machine == 'Todas') machine = undefined;
          $scope.currentMachine = machine;
        };

        $scope.updateMachineFilterPhotos = function (machine) {
          if (machine == 'Todas') machine = undefined;
          $scope.currentMachinePhoto = machine;
        };

        $scope.updateMachineFilterVideos = function (machine) {
          if (machine == 'Todas') machine = undefined;
          $scope.currentMachineVideo = machine;
        };

        serviceApiAffiliates
          .getBanners(userNiceName, 'all')
          .then(function (data) {
            var listMachines = new Array();

            angular.forEach(data, function (value, key) {
              if (value.type == 'banner') {
                if (!listMachines.includes(value.machine))
                  listMachines.push(value.machine);
              }
            });

            listMachines.unshift('Todas');

            $scope.listMachines = listMachines;
            $scope.currentSize;
            $scope.banners = data;
          });

        serviceApiAffiliates.getResources('all').then(function (data) {
          serviceApiAffiliates
            .getAffiliateProducts(userNiceName)
            .then(function (prods) {
              var listMachinesPhotos = new Array();
              var listMachinesVideos = new Array();

              if (data) {
                data.photos = data.photos.filter(function (resource) {
                  if (resource.product) {
                    if (Array.isArray(resource.product)) {
                      return resource.product.find(function (prod) {
                        return prods.includes(prod.niceName);
                      });
                    } else {
                      return prods.includes(resource.product.niceName);
                    }
                  } else {
                    return false;
                  }
                });

                data.videos = data.videos.filter(function (resource) {
                  if (resource.product) {
                    if (Array.isArray(resource.product)) {
                      return resource.product.find(function (prod) {
                        return prods.includes(prod.niceName);
                      });
                    } else {
                      return prods.includes(resource.product.niceName);
                    }
                  } else {
                    return false;
                  }
                });

                angular.forEach(data.videos, function (vid, key) {

                  vid.iframe_url = toolsService.getEmbedVideo(vid.url, {
                    rel: 0,
                    showinfo: 0,
                    autohide: 1
                  });

                  vid.iframe_copy =
                    '<iframe width="560" height="315" src="'
                    + toolsService.getEmbedVideo(vid.url)
                  '" frameborder="0" allowfullscreen></iframe>';
                });

                angular.forEach(data.photos, function (value, key) {
                  if (value.product) {
                    if (Array.isArray(value.product)) {
                      angular.forEach(value.product, function (value, key) {
                        if (
                          !listMachinesPhotos.includes(value.name) &&
                          prods.includes(value.niceName)
                        )
                          listMachinesPhotos.push(value.name);
                      });
                    } else {
                      if (!listMachinesPhotos.includes(value.product.name))
                        listMachinesPhotos.push(value.product.name);
                    }
                  }
                });

                angular.forEach(data.videos, function (value, key) {
                  if (value.product) {
                    if (Array.isArray(value.product)) {
                      angular.forEach(value.product, function (value, key) {
                        if (
                          !listMachinesVideos.includes(value.name) &&
                          prods.includes(value.niceName)
                        )
                          listMachinesVideos.push(value.name);
                      });
                    } else {
                      if (!listMachinesVideos.includes(value.product.name))
                        listMachinesVideos.push(value.product.name);
                    }
                  }
                });
              }

              listMachinesPhotos.unshift('Todas');
              listMachinesVideos.unshift('Todas');
              $scope.listMachinesPhotos = listMachinesPhotos;
              $scope.listMachinesVideos = listMachinesVideos;

              $scope.currentResource = 'link_id';
              $scope.resources = data;
            });
        });

        serviceApiAffiliates.getAffiliateCode().then(function (code) {
          $scope.voucherCode = code;
        });
      },
    ]);
})();
