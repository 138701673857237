(function () {

  'use strict';

  angular.module('mainApp')

    .service("seoService", function ($rootScope, $location, $filter) {
      var pageTranslation = 'page';

      this.searchSeoAtTranslations = function () {
        var pageInfo = {};

        if ($rootScope.translations.seo[$rootScope.pageName]) {
          pageInfo = $rootScope.translations.seo[$rootScope.pageName];
        } else if ($rootScope.translations.shop.seo[$rootScope.pageName]) { // shop
          pageInfo = $rootScope.translations.shop.seo[$rootScope.pageName];
        } else { // affiliates
          pageInfo = $rootScope.translations.affiliates.seo[$rootScope.pageName];
        }

        return pageInfo;
      };

      this.searchSeoAtTranslationsLandings = function () {
        var pageInfo = {};
        var found = $filter('filter')($rootScope.translations.seo.others, {name: $rootScope.pageName}, true);

        if (found.length) {
          pageInfo = found[0];
        }

        return pageInfo;
      };

      this.setTitle = function(title, currentPage) {
        if (currentPage > 1) {
          title += ' ' + $rootScope.translations.seo.separator + ' ' + $rootScope.translations.common.page + ' ' + currentPage;
        }

        title += ' ' + $rootScope.translations.seo.separator + ' ' + $rootScope.translations.seo.website_name;
        $rootScope.pageTitle = title;
      };

      this.setDescription = function(description, currentPage) {
        if(currentPage > 1) {
          description += ' ' + $rootScope.translations.common.page + ' ' + currentPage;
        }

        $rootScope.pageDescription = description;
      };

      this.setRobots = function(index, follow) {
        var robots = '';
        if(index) { robots += 'index'; } else { robots += 'noindex'; }
        if(follow) { robots += ',follow'; } else { robots += ',nofollow'; }
        $rootScope.seo_robots = robots;
      };

      this.setPrevPage = function(currentPageNum, pageQuery, firstPageNum, currentPath) {
        var path = '';
        var firstPage = (firstPageNum) ? firstPageNum : 1;
        currentPageNum = parseInt(currentPageNum);

        if (currentPageNum > firstPage) {
          path = getPath(currentPath);
          if(currentPageNum - 1 > 1) {
            path += '/' + pageTranslation + '/' + (currentPageNum - 1);
          }

          if(pageQuery) {
            path += pageQuery;
          }
        }
        $rootScope.prevPage = path;

      };

      this.setNextPage = function(currentPageNum, pageQuery, lastPageNum, currentPath) {
        var path = '';
        currentPageNum = parseInt(currentPageNum);

        if (currentPageNum < lastPageNum) {
          path = getPath(currentPath);
          path += '/' + pageTranslation + '/' + (currentPageNum + 1);

          if(pageQuery) {
            path += pageQuery;
          }
        }

        $rootScope.nextPage = path; //$location.protocol() + '://' + $location.host() + $scope.pagePath + '/page/' + (currentPageNum + 1) + $scope.pageQuery;
      };

      this.setImage = function(image) {
        image = (image) ? image : $location.protocol() + '://' + $location.host() + $rootScope.translations.config.social_media.facebook.image;
        $rootScope.pageImage = image;
      };

      function getPath(currentPath) {
        var path = (currentPath) ? currentPath : $location.path();

        var hasPage = path.indexOf("/" + pageTranslation);
        if (hasPage > -1) {
          path = path.substring(0, hasPage);
        }

        var locationServer = $location.protocol() + '://' + $location.host();

        if($location.host().indexOf('localhost') > -1) { // Development URL (add port number)
          locationServer += ':' +  $location.port();
        }

        path = locationServer + path;

        return path;
      }
    });
})();
