(function () {
  'use strict';

  angular.module('mainApp')

    .service('legalTerms', function ($http, $q, $log, _, appConfig, toolsService, sessionService) {

      var _legalTerms = {};

      _legalTerms.getLegalList = function () {
        var defer = $q.defer();

        var legalListBundle = sessionService.getValue('legalList');

        if (!toolsService.isEmpty(legalListBundle)) {
          $log.debug("API > reaprovecho legalListBundle de sessionService: ", legalListBundle);
          defer.resolve(legalListBundle);
        } else {
          var urlDestino = appConfig.mycookTouchDomain + '/legalList';
          $log.debug("API > Cargando Legal list", urlDestino);

          $http({
            method: 'GET',
            url: urlDestino
          }).then(function(legal) {
            $log.debug("API > legal list loaded", legal);
            legalListBundle = legal.data;
            sessionService.setValue('legalList', legalListBundle);
            defer.resolve(legalListBundle);
          }, function (err) {
            console.error(err);
            defer.reject(err);
          });
        }

        return defer.promise;
      }

      function returnLegal(data, type) {
        if(type) {
          var newData = {};
          type.forEach(function(item) {
            newData[item] = data.legalterms[item];
          });
          return newData;
        } else {
          return data;
        }
      }

      _legalTerms.getLegalTerms = function (type) {
        var defer = $q.defer();

        var legalBundle = sessionService.getValue('legal');

        if (!toolsService.isEmpty(legalBundle)) {
          $log.debug("API > reaprovecho legalBundle de sessionService: ", legalBundle);
          defer.resolve(returnLegal(legalBundle, type));
        } else {
          var urlDestino = appConfig.mycookTouchDomain + '/legal/all';
          $log.debug("API > Cargando Legal terms", urlDestino);

          $http({
            method: 'GET',
            url: urlDestino
          }).then(function(legal) {
            $log.debug("API > legal loaded", legal);
            legalBundle = legal.data;
            sessionService.setValue('legal', legalBundle);
            defer.resolve(returnLegal(legalBundle, type));
          }, function (err) {
            defer.reject(err);
          });
        }

        return defer.promise;
      }

      _legalTerms.checkLegalTerms = function (userLegalData) {

        var defer = $q.defer();
        var userLegalData = userLegalData.data || {};
        var isAffiliate = userLegalData.affiliate;
        var promises = [];
        promises.push(this.getLegalTerms()); // returns array of objects<LegalTerm> with info about every terms
        promises.push(this.getLegalList()); // return object with active legalterms

        $q.all(promises).then(function(results) {
          var legal = results[0];
          var listLegal = results[1];
          var checkElements = {};

          if(!isAffiliate) {
            listLegal.forEach(function (element) {
              var result = checkData(legal.legalterms, userLegalData, element, legal.version);
              if(result) {
                checkElements[element] = userLegalData[element] || {};
              }
            });
          }
          defer.resolve(checkElements);
        }, function(err) {
          defer.reject(err);
        });

        return defer.promise;
      }

      function checkData(legalTerms, userLegalData, legalCondition, version) {
        var check = false;

        $log.debug('checkData -> ', legalCondition, legalTerms[legalCondition]);
        $log.debug('checkData USER -> ', userLegalData[legalCondition]);

        if(
          legalTerms[legalCondition]['enabled'] &&
          (
            (toolsService.isEmpty(userLegalData[legalCondition])) ||
            (
              legalTerms[legalCondition]['forceValidation'] &&
              !toolsService.isEmpty(userLegalData[legalCondition]) &&
                (
                  !userLegalData[legalCondition]['agree']
                )
              )
            )
          ) {
          check = true;
        }

        $log.debug('checkData result -> '+legalCondition, check);

        return check;
      }

      _legalTerms.acceptLegalTerms = function (type, multiArray, anonymousBody) { // type legalConditions

        var defer = $q.defer();
        var urlDestino = appConfig.mycookTouchDomain + '/legal/acceptLegal/' + type;
        multiArray = multiArray || [];

        this.getLegalList().then(function(result) {
          multiArray = _.intersection(result, multiArray);

          var query = {
            conditions: multiArray
          };

          if(anonymousBody) {
            query.email_address = anonymousBody;
            urlDestino = appConfig.mycookTouchDomain + '/legal/acceptAnonymousLegal/' + type;
          }

          $http({
            method: 'POST',
            url: urlDestino,
            headers: {
              'Authorization': 'Bearer ' + sessionService.getUserToken()
            },
            data: query
          }).then(function (res) {
            defer.resolve(res);
          }, function(err) {
            defer.reject(err);
          });
        }).catch(console.error);

        return defer.promise;
      }

      _legalTerms.updateMailContact = function(email_address){
        var defer = $q.defer();
        var urlDestino = appConfig.mycookTouchDomain + '/updateMailchimpContact';
        var query = {email_address:email_address};
        $http({
          method: 'PUT',
          url: urlDestino,
          headers: {
            'Authorization': 'Bearer ' + sessionService.getUserToken()
          },
          data: query
        }).then(function (res) {
          defer.resolve(res);
          $log.debug("updateMailContact > update mailchimp contact result: ",res)
        }, function(err) {
          defer.reject(err);
          $log.debug("updateMailContact > update mailchimp contact result: ", err)
        });
      }

      return _legalTerms;
    })

    .directive('userContract', function ($templateCache) {
      return {
        template: function(element, attrs) {
          return $templateCache.get('template/user-contract.html');
        },
        link: function (scope, element, attrs) {}
      };
    })

    .controller('LegalTermsController', [function ($scope, $rootScope, /*$route, */$log, toolsService, usersManager, legalTerms, _, affiliatesUsersManager, sessionService, webStorage) {
      $scope.signature_contract = 'deactivated';
      $scope.userLegalData = {};

      $rootScope.$on('legalTerms', function (event, data) {

        var minimumLegal = ['memberConditions', 'communityConditions', 'internationalConditions'];

        legalTerms.checkLegalTerms(data).then(function(checkedElements) {
          checkedElements = _.pick(checkedElements, minimumLegal);

          if(_.size(checkedElements) > 0) {
            $scope.userLegal = checkedElements;

            legalTerms.getLegalTerms(minimumLegal).then(function(legal) {
              $scope.legal = legal;
              $scope.signature_contract = 'activated';
            });
          }
        });
      });

      $scope.cancelContract = function (userLegalData) {
        affiliatesUsersManager.logoutUser();
        usersManager.logoutUser();
        $rootScope.$emit('SESSION_EXPIRED');
        $rootScope.$emit('SESSION_EXPIRED_AFFILIATES');
        sessionService.logoutUser(500, true);
        $scope.signature_contract = 'deactivated';
      };

      $scope.acceptContract = function (validForm, userLegalData) {
        $scope.signature_contract = 'loading';
        var sendToApi = new Array();

        for(var k in userLegalData) {
          if(userLegalData[k]) {
            sendToApi.push(k);
          }
        }

        legalTerms.acceptLegalTerms('multi', sendToApi).then(function(result) {
          $scope.signature_contract = 'deactivated';
        });

      };
    }])

    .controller('LegalBoxController', [function ($scope, $rootScope, legalTerms, $location, $injector) {
      $rootScope.$on('changeLegalBox', function (event, data) {
        legalTerms.getLegalTerms([data.legal]).then(function(legal) {
          $scope.legal = legal[data.legal];
          $scope.type = data.legal;

          setTimeout(function() {
            if(data.position && $('#' + data.position).length > 0) {
              if (data.legal === 'affiliateContactConditions') {
                $('.site-content').animate({scrollTop: $('#' + data.position).offset().top}, "slow");
              } else {
                $('.ngdialog').animate({scrollTop: $('#' + data.position).offset().top}, "slow");
              }
            }
          }, 0);
        });
      });

      var route = $injector.get('$route');
      
      console.log("Route name: ", route.current.$$route.name);
      
      if (route.current.$$route.name === "privacyPolicy") {
        
        legalTerms.getLegalTerms(['memberConditions']).then(function(legal) {
          $scope.legal = legal['memberConditions'];
          $scope.type = 'memberConditions';
        });

      } else if(route.current.$$route.name === "privacyNewsletter") {
        
        legalTerms.getLegalTerms(['newsletterConditions']).then(function(legal) {
          $scope.legal = legal['newsletterConditions'];
          $scope.type = 'newsletterConditions';
        });

      }

    }])

    .controller('PrivacyCookiesController', [function ($scope, $rootScope, $log) {
      $rootScope.seo_robots = "noindex, nofollow";

      var legal = {
        'title': $rootScope.translations.privacy_policy.title,
        'text': $rootScope.translations.privacy_policy.copy
      }

      $scope.legal = legal;
    }])

    .controller('NewsletterContractController', [function ($scope, $rootScope, $timeout, $location, $routeParams, $log, appConfig, toolsService, legalTerms) {
      var emailAddress = $routeParams.emailAddress;
      $scope.userLegalData = {};
      $scope.anonymousUserData = true;
      legalTerms.getLegalTerms(['newsletterConditions']).then(function(legal) {
        legal.newsletterConditions.forceValidationNewsletter = true;
        $scope.legal = legal;
        $scope.signature_contract = 'activated';
      });

      $scope.cancelContract = function (userLegalData) {
        $scope.signature_contract = 'deactivated';
        $scope.contract_not_accepted = true;
      };

      $scope.acceptContract = function (validForm, userLegalData) {
        $scope.contract_not_accepted = false;
        $scope.signature_contract = 'loading';

        var conditionsArray = [];
        for(var k in userLegalData) {
          if(userLegalData[k]) {
            conditionsArray.push(k);
          }
        }

        legalTerms.acceptLegalTerms('multi', conditionsArray, emailAddress).then(function(result) {
          legalTerms.updateMailContact(emailAddress).then(function(result) {
            $scope.signature_contract = 'deactivated';
            $scope.contract_accepted = true;

            $timeout(function () {
              $location.path($rootScope.url('home'));
            }, 1500);
          }).catch(function(error) {
            $log.error('NewsletterContractController -> Error updating mailchimp contact', error);
          });
        }).catch(function(error) {
          $log.error('NewsletterContractController -> Error accepting legalTerms', error);
        });
      };
    }]);

})();
