(function () {
  angular
    .module('mainApp')

    .run([
      '$rootScope',
      '$anchorScroll',
      '$timeout',
      '$location',
      'appConfig',
      'toolsService',
      'sessionService',
      'amMoment',
      'storeFactory',
      'webStorage',
      function (
        $rootScope,
        $anchorScroll,
        $timeout,
        $location,
        appConfig,
        toolsService,
        sessionService,
        amMoment,
        storeFactory,
        webStorage
      ) {
        amMoment.changeLocale(appConfig.momentJsLanguage);

        $rootScope.$on('SESSION_EXPIRED', function (event, data) {
          event.stopPropagation();
          sessionService.logoutUser(3000);
        });

        $rootScope.$on('SESSION_EXPIRED_AFFILIATES', function (event, data) {
          event.stopPropagation();
          sessionService.logoutUser(3000);
        });

        toolsService.newRandomQuery();

        // load Store configuration
        if (appConfig.webSettings.shop) {
          storeFactory.getConfiguration();
        }

        $anchorScroll.yOffset = 70;

        $rootScope.$on('$routeChangeSuccess', function (newRoute, oldRoute) {
          $timeout(function () {
            if (!toolsService.isEmpty($location.hash())) {
              $anchorScroll($location.hash());
            }
          }, 5000);
        });
      },
    ])

    .directive('isActiveNav', [
      '$rootScope',
      '$route',
      function ($rootScope, $route) {
        return {
          restrict: 'A',
          link: function (scope, elem, attrs) {
            $rootScope.$on(
              '$routeChangeSuccess',
              function (event, $currentRoute, $previousRoute) {
                if (attrs.values) {
                  if ($rootScope.isActive(attrs.values)) {
                    elem.addClass('active');
                  } else {
                    elem.removeClass('active');
                  }
                }
              }
            );
          },
        };
      },
    ])

    .controller('mainController', [
      function (
        $scope,
        $log,
        $location,
        $window,
        $rootScope,
        $filter,
        $cookies,
        $templateCache,
        appConfig,
        configService,
        toolsService,
        sessionService,
        usersManager,
        recipesFactory,
        deviceDetector,
        ngDialog,
        appTranslations,
        serviceBadges,
        webStorage,
        saRouter,
        affiliatesUsersManager,
        legalTerms,
        seoService,
        $timeout,
        $http,
        AffiliatesFactory
      ) {
        if (!webStorage.isSupported) {
          ngDialog.open({
            template: 'activate-cookies.html',
            className: 'cookies-alert',
            scope: $scope,
          });
        }

        var showLogs = toolsService.showLogs();
        configService.debugEnabled(showLogs);

        var temperatureUnit  = localStorage.getItem('temperatureUnit');
        var weightUnit = localStorage.getItem('weightUnit');
        $scope.selectUnit = {
          weight:'gr',
          temperature:'C'
        }
        
        $scope.setUnits = function (data) {
          $scope.openSelectLanguage = '';
          localStorage.setItem('temperatureUnit',$scope.selectUnit.temperature);
          localStorage.setItem('weightUnit',$scope.selectUnit.weight);
        }
        $rootScope.translatetemperature =[{F:98,C:37 },{F:100,C:38 },{F:102,C:39 },{F:104,C:40 },{F:106,C:41 },{F:108,C:42 },{F:110,C:43 },{F:112,C:44 },{F:114,C:45 },{F:116,C:46 },{F:118,C:47 },{F:120,C:48 },{F:121,C:49 },{F:122,C:50 },{F:124,C:51 },{F:126,C:52 },{F:128,C:53 },{F:130,C:54 },{F:132,C:55 },{F:134,C:56 },{F:136,C:57 },{F:138,C:58 },{F:139,C:59 },{F:140,C:60 },{F:142,C:61 },{F:144,C:62 },{F:146,C:63 },{F:148,C:64 },{F:150,C:65 },{F:152,C:66 },{F:154,C:67 },{F:156,C:68 },{F:157,C:69 },{F:158,C:70 },{F:160,C:71 },{F:162,C:72 },{F:164,C:73 },{F:166,C:74 },{F:168,C:75 },{F:170,C:76 },{F:172,C:77 },{F:174,C:78 },{F:175,C:79 },{F:176,C:80 },{F:178,C:81 },{F:180,C:82 },{F:182,C:83 },{F:184,C:84 },{F:186,C:85 },{F:188,C:86 },{F:190,C:87 },{F:192,C:88 },{F:193,C:89 },{F:194,C:90 },{F:196,C:91 },{F:198,C:92 },{F:200,C:93 },{F:202,C:94 },{F:204,C:95 },{F:206,C:96 },{F:208,C:97 },{F:210,C:98 },{F:211,C:99 },{F:212,C:100 },{F:214,C:101 },{F:216,C:102 },{F:218,C:103 },{F:220,C:104 },{F:222,C:105 },{F:224,C:106 },{F:226,C:107 },{F:228,C:108 },{F:229,C:109 },{F:230,C:110 },{F:232,C:111 },{F:234,C:112 },{F:236,C:113 },{F:238,C:114 },{F:240,C:115 },{F:242,C:116 },{F:244,C:117 },{F:246,C:118 },{F:247,C:119 },{F:248,C:120 },{F:250,C:121 },{F:252,C:122 },{F:254,C:123 },{F:256,C:124 },{F:258,C:125 },{F:260,C:126 },{F:262,C:127 },{F:264,C:128 },{F:256,C:129 },{F:266,C:130 },{F:268,C:131 },{F:270,C:132 },{F:272,C:133 },{F:274,C:134 },{F:276,C:135 },{F:278,C:136 },{F:280,C:137 },{F:282,C:138 },{F:283,C:139 },{F:284,C:140 }];
        $rootScope.cToF = function(celsius) {
          var convertedValue='';
          $rootScope.translatetemperature.forEach(function(element){
            if(element.C==celsius) {
              convertedValue = element.F;
            }
          })
          return convertedValue;
        }
        $rootScope.fToC = function (fahrenheit) {
          var convertedValue='';
          $rootScope.translatetemperature.forEach(function(element){
            if(element.F==fahrenheit) {
              convertedValue = element.C;
            }
          })
          return convertedValue;
        }
        $rootScope.converWeight = function(inputGrams,converTo) {
          if(converTo == 'lb') {
            return (inputGrams * 0.00220462262).toFixed(3);
          } else if(converTo == 'oz'){
            return (inputGrams * 0.035274).toFixed(3);
          }else{
            return inputGrams;
          }
        }

        $rootScope.convertToGrams = function (value, convertFrom) {
          if(convertFrom == 'lb') {
            return (value / 0.00220462262).toFixed(3);
          } else if(convertFrom == 'oz'){
            return (value / 0.035274).toFixed(3);
          }else{
            return value;
          }
        }

        $rootScope.roundToFive = function (inputVal) {
          return Math.round(inputVal/5) * 5;
        }

        $rootScope.translations = appTranslations;
        if((!temperatureUnit || !weightUnit) && $rootScope.translations.config.unitSelector.active){
          $scope.openSelectLanguage = 'activated';
        }
        $rootScope.gtm = appConfig.gtm || '';

        $rootScope.mycookTouchDomain = appConfig.mycookTouchDomain;

        seoService.setRobots();

        $rootScope.pinterestID = appConfig.pinterestID;

        serviceBadges.getBadges().then(function (response) {
          $rootScope.translations.badges = response;
        });

        legalTerms.getLegalTerms().then(function (response) {
          $rootScope.translations.legalTerms = response.legalterms;

          $scope.counterLegal = 0;
          if (
            $rootScope.translations.legalTerms.memberConditions &&
            $rootScope.translations.legalTerms.memberConditions.enabled
          )
            $scope.counterLegal++;
          if (
            $rootScope.translations.legalTerms.internationalConditions &&
            $rootScope.translations.legalTerms.internationalConditions.enabled
          )
            $scope.counterLegal++;
          if (
            $rootScope.translations.legalTerms.newsletterConditions &&
            $rootScope.translations.legalTerms.newsletterConditions.enabled
          )
            $scope.counterLegal++;
          if (
            $rootScope.translations.legalTerms.contactConditions &&
            $rootScope.translations.legalTerms.contactConditions.enabled
          )
            $scope.counterLegal++;
          if (
            $rootScope.translations.legalTerms.ebookConditions &&
            $rootScope.translations.legalTerms.ebookConditions.enabled
          )
            $scope.counterLegal++;
          if (
            $rootScope.translations.legalTerms.telesalesConditions &&
            $rootScope.translations.legalTerms.telesalesConditions.enabled
          )
            $scope.counterLegal++;
          if (
            appConfig.webSettings.shop &&
            $rootScope.translations.legalTerms.ecommerceGuestConditions &&
            $rootScope.translations.legalTerms.ecommerceGuestConditions.enabled
          )
            $scope.counterLegal++;
        });

        // Url Home
        $scope.homeUrl = $location.protocol() + '://' + $location.host();

        if ($location.host().indexOf('localhost') > -1) {
          // Development URL (add port number)
          $scope.homeUrl += ':' + $location.port();
        }

        $scope.showLogs = showLogs;

        $scope.deviceDetector = deviceDetector;

        //set media domain, for the moment is the same that the api
        $scope.mediaDomain = appConfig.mycookTouchDomain + '/image';

        //Path from project root
        $scope.absolut = '/';

        //set the adminRoles from the config
        $scope.adminRoles = appConfig.adminRoles;

        //set Webpage configuration (menu - mycook - mycooktouch - blog - etc)
        $scope.webSettings = appConfig.webSettings;

        // Check if nutritional is enabled
        $scope.nutritionalEnabled = false;

        // recipesCounter
        recipesFactory.countRecipes().then(
          function (count) {
            var roundTo = count >= 1000 ? 1000 : 100;

            $rootScope.translations.recipesCounter = (
              count -
              (count % roundTo)
            ).toString();
          },
          function (err) {
            console.error(err);
          }
        );

        //$rootScope.translations.recipesCounter = appConfig.recipesCounter || '8000';

        // WeChat
        if ($rootScope.translations.config.share.wechat) {
          var url =
            'https://api.weixin.qq.com/cgi-bin/token?grant_type=client_credential&appid=wx973c0217822fb65a&secret=7f33b18286ba6a5a4fc83c3346d1c544';

          var headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET',
            'Content-Type': 'application/json',
            Accept: 'application/json',
          };

          /*$http({
          method: "GET",
          headers: headers,
          url: url
        }).success(function(result) {
            console.log("Auth.signin.success!")
            console.log(result);
        }).error(function(data, status, headers, config) {
            console.log("Auth.signin.error!")
            console.log(data);
            console.log(status);
            console.log(headers);
            console.log(config);
        });

        /*$http.jsonp(url, {jsonpCallbackParam: 'callback'}).success(function(data){
          console.log(data);
        });*/

          /*$http({
          method: 'GET',
          //method: 'JSONP',
          url: url,
          headers: {
            'Content-Type': 'application/json'
          },
        }).then(function (res) {
          $scope.wechatAccessToken = res.access_token;
        }, function errorCallBack(err) {
          console.log('wechat', err);
        });*/
        }

        /* Aqui nunca habrá token ... */
        $scope.shareWechat = function (url) {
          //$window.open(url, '_blank');
          var urlDestino =
            'https://api.weixin.qq.com/cgi-bin/wxaapp/createwxaqrcode?access_token=' +
            $scope.wechatAccessToken;

          $http({
            method: 'POST',
            url: urlDestino,
            data: {
              path: url,
            },
          }).then(
            function (res) {
              $log.info(res);
            },
            function errorCallBack(err) {
              $log.error('wechat', err);
            }
          );
        };

        recipesFactory.getIngredientsSettings().then(
          function (settings) {
            if (settings) {
              $scope.nutritionalEnabled = settings;
            }
          },
          function (err) {
            $log.error('RecipeController > getIngredientsSettings: ', err);
          }
        );

        // Check if Website is viewed from mobile or desktop (show share Whatsapp)
        $scope.isMobile = false;
        (function (a, b) {
          if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
              a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
              a.substr(0, 4)
            )
          )
            $scope.isMobile = true;
        })(navigator.userAgent || navigator.vendor || window.opera);

        var counter_footer_modules = 1;

        if ($rootScope.translations.config.social_media.footer_module)
          counter_footer_modules++;
        if (
          $rootScope.translations.footer.communities.communities_list &&
          $rootScope.translations.footer.communities.communities_list.length > 0
        )
          counter_footer_modules++;
        if (
          $rootScope.translations.config.download_app &&
          ($rootScope.translations.config.download_app.google_play !== '' ||
            $rootScope.translations.config.download_app.app_store !== '')
        )
          counter_footer_modules++;
        if (appConfig.webSettings.newsletter) counter_footer_modules++;

        $scope.footer_modules = 'column-' + counter_footer_modules;

        $scope.logout = function (affiliates) {
          if (affiliates && affiliates == true) {
            affiliatesUsersManager.logoutUser();
            $scope.currentUserAffiliates = '';
          } else {
            usersManager.logoutUser();
            $rootScope.currentUser = $scope.currentUser = '';
            $scope.currentUserInfo = '';
          }

          sessionService.logoutUser(1000); // redirect in case private page
        };

        $rootScope.$on('userAffiliatesLoggedIn', function (event, data) {
          $scope.currentUserAffiliates = data;

          affiliatesUsersManager.getAffiliateInfo(data.niceName);
        });

        $rootScope.$on('userLoggedIn', function (event, data) {
          getCurrentUserInfo(data.niceName);
        });

        function getCurrentUserInfo(niceName) {
          usersManager.getCurrentUserInfo(niceName).then(function (user) {
            $rootScope.currentUser = $scope.currentUser = user;

            var currentUserMachine = webStorage.get('userMachine');

            if (!toolsService.isEmpty(currentUserMachine)) {
              $log.debug(
                'Main get currentUserInfo > reaprovecho currentUserMachine de sessionService: ',
                currentUserMachine
              );
              $scope.userMachine = currentUserMachine;
            } else {
              //SANTI: getSupportedClouds!!
              usersManager.getSupportedClouds().then(function (bundleData) {
                $log.debug(
                  'AppController > userMachine > data: ',
                  user,
                  bundleData
                );
                var userMachine = appConfig.webSettings.mycook ? 'S1' : 'S1.1'; //REVISAR
                // así podemos personalizar la información que se muestra
                // Devuelve máquinas QUE PUEDE USAR
                if (bundleData.length > 0) {
                  userMachine = bundleData[0]; //'mycooktouch';
                }

                $scope.userMachine = userMachine;
                webStorage.set('userMachine', userMachine);
                console.log('BUNDLE: ', bundleData);
              }, $log.error);
            }
          });
        }

        //update seo parameters on route change, checks if user can see a private page based on his user token and page privacy
        $rootScope.$on(
          '$routeChangeSuccess',
          function (event, $currentRoute, $previousRoute) {
            // Collapse menus
            $timeout($scope.closeUserNavigation());

            getTags();

            seoService.setPrevPage();
            seoService.setNextPage();

            $rootScope.currentLocation = $location.path();
            $rootScope.canonical = $location.$$absUrl;
            $rootScope.ogUrl = $location.$$absUrl;

            var dataLayer = (window.dataLayer = window.dataLayer || []);

            if (
              !toolsService.isEmpty($currentRoute) &&
              !toolsService.isEmpty($currentRoute.$$route)
            ) {
              $rootScope.currentRouteName = $currentRoute.$$route.routeName;

              var pageInfo = $currentRoute.$$route;
              $rootScope.pageType = pageInfo.pageType;
              $rootScope.pageName = pageInfo.name;

              if ($rootScope.pageType == 'affiliates') {
                seoService.setRobots(false, false);
              } else {
                seoService.setRobots(pageInfo.index, pageInfo.follow);
              }

              if (!toolsService.isEmpty(pageInfo)) {
                $rootScope.pageCssClass = !toolsService.isEmpty(
                  pageInfo.pageCssClass
                )
                  ? pageInfo.pageCssClass
                  : '';

                var seoObj = seoService.searchSeoAtTranslations();

                seoService.setImage(); // set default image

                if (!toolsService.isEmpty(seoObj)) {
                  var title = !toolsService.isEmpty(seoObj.title)
                    ? seoObj.title
                    : '';
                  var description = !toolsService.isEmpty(seoObj.description)
                    ? seoObj.description
                    : '';
                  seoService.setTitle(title);
                  seoService.setDescription(description);
                }

                var mitoken;

                if ($rootScope.pageType == 'affiliates') {
                  mitoken = sessionService.getUserToken(true);
                } else {
                  mitoken = sessionService.getUserToken();
                }

                // si hay token o estoy en una página privada, compruebo token, SI NO ESTOY PIDIENDO UN TEMPLATE
                if (
                  !toolsService.isEmpty(mitoken) ||
                  (!toolsService.isEmpty(pageInfo.private) && pageInfo.private)
                ) {
                  checkToken(mitoken);
                }
              }

              dataLayer.push({
                contentType: pageInfo.contentType || 'otros',
              });
            }

            if ($window.sessionStorage.getItem('EXTERNAL_CAMPAIGN')) {
              dataLayer.push({
                event: 'externalCampaignCounter',
              });
            }

            dataLayer.push({
              event: 'ngRouteChange',
              attributes: {
                route: $location.url(),
              },
            });
          }
        );

        // LISTEN WHEN RECIPES ARE LOADED - getRecipesByCriteria
        $scope.$on('LOAD', function () {
          $scope.loading = true;
          $log.debug('AppController load: ' + $scope.loading);
        });
        $scope.$on('UNLOAD', function () {
          $scope.loading = false;
          $log.debug('AppController unload: ' + $scope.loading);
        });
        // END LISTEN WHEN RECIPES ARE LOADED

        function checkToken(token) {
          if ($scope.pageType == 'affiliates') {
            affiliatesUsersManager
              .checkToken(sessionService.getUserToken(true))
              .success(function (tokenInfo) {
                var userNiceName = tokenInfo.niceName;
                if (userNiceName !== '') {
                  webStorage.set('userNiceNameAffiliates', userNiceName);
                  $rootScope.$emit('userAffiliatesLoggedIn', tokenInfo);
                  $rootScope.$emit('legalTerms', {
                    data: tokenInfo,
                  });
                }
              })
              .error(function (error) {
                $rootScope.$emit('SESSION_EXPIRED_AFFILIATES');
              });
          } else {
            usersManager
              .checkToken(token)
              .success(function (tokenInfo) {
                var userNiceName = tokenInfo.niceName;

                if (userNiceName !== '') {
                  $rootScope.$emit('userLoggedIn', {
                    niceName: userNiceName,
                  });

                  $rootScope.$emit('legalTerms', {
                    data: tokenInfo,
                  });
                }
              })
              .error(function (error) {
                $rootScope.$emit('SESSION_EXPIRED');
              });
          }
        }

        $scope.getTagUrl = function (tag) {
          // Laura: ñapa para aside diets - xk el array de tags está guardado con :
          tag = tag.replace(appConfig.itemSeparator, appConfig.decorator);

          var tag_array = tag.split(appConfig.decorator);
          var route;
          var url;

          angular.forEach(tag_array, function (value, key) {
            value = $filter('slugify')(value);
            tag_array[key] = value;
          });

          switch (tag_array.length) {
            case 2:
              route = saRouter.getRoute('tagsRecipeList2Params');
              url = saRouter.replaceUrlParams(route.url, {
                tipoDieta: tag_array[0],
                tipoDietaParam2: tag_array[1],
              });
              break;

            case 3:
              route = saRouter.getRoute('tagsRecipeList3Params');
              url = saRouter.replaceUrlParams(route.url, {
                tipoDieta: tag_array[0],
                tipoDietaParam2: tag_array[1],
                tipoDietaParam3: tag_array[2],
              });
              break;

            default:
              route = saRouter.getRoute('tagsRecipeList');
              url = saRouter.replaceUrlParams(route.url, {
                tipoDieta: tag_array[0],
              });
              break;
          }
          return url;
        };

        function getTags() {
          recipesFactory.getTags().then(function (tagsBundle) {
            $scope.tags = tagsBundle.tags;
            $scope.tagRank = tagsBundle.tagsRank;
            $scope.tagsDietas = tagsBundle.tagsDietas;
          });
        }

        // open popup policyPrivacy
        $scope.openPrivacyPolicy = function (legalType, position) {
          var legalType = legalType || 'memberConditions';

          if (legalType === 'cookies') {
            ngDialog.open({
              template: $templateCache.get('template/privacy-cookies.html'),
              plain: true,
              className: 'ngdialog-theme-default',
            });
          } else if (legalType === 'affiliateContactConditions') {
            ngDialog.open({
              template: $templateCache.get('template/privacy-policy.html'),
              plain: true,
              className: 'ngdialog-theme-plain',
              showClose: false,
              closeByEscape: true,
              onOpenCallback: function () {
                $rootScope.$emit('changeLegalBox', {
                  legal: legalType,
                  position: position,
                });
              },
            });
          } else {
            ngDialog.open({
              template: $templateCache.get('template/privacy-policy.html'),
              plain: true,
              className: 'ngdialog-theme-default',
              onOpenCallback: function () {
                $rootScope.$emit('changeLegalBox', {
                  legal: legalType,
                  position: position,
                });
              },
            });
          }
        };

        $scope.toggleFooterPolices = function () {
          $scope.footerPolices = !$scope.footerPolices;
        };

        // MENU NAV
        $scope.toggleUserNavigation = function () {
          if ($scope.userNavigation) {
            // Activo, desactivamos hamburguesa
            $scope.buttonHamburguer = false;
          } else $scope.buttonHamburguer = true;
          $scope.userNavigation = !$scope.userNavigation;
          $scope.mobileNavigation = false;
        };

        $scope.closeUserNavigation = function (event) {
          $scope.userNavigation = false;
          $scope.buttonHamburguer = false;
          $scope.submenuIsCollapsed = false;
          $scope.mobileNavigation = false;

          if (
            event &&
            angular.element(event.target).attr('id') !== 'footerPolices'
          ) {
            $scope.footerPolices = false;
          }
        };

        $scope.toggleHamburger = function () {
          if ($scope.buttonHamburguer) {
            // Activo, desactivamos y quitamos menus
            $scope.closeUserNavigation();
            $scope.buttonHamburguer = false;
            $scope.mobileNavigation = false;
          } else {
            // Desactivo, activamos el menú
            $scope.buttonHamburguer = !$scope.buttonHamburguer;
            $scope.mobileNavigation = true;
          }
        };

        $scope.toggleSubmenu = function () {
          $scope.submenuIsCollapsed = !$scope.submenuIsCollapsed;
        };

        ///// get tag class (Used in advanced search & edit recipe)
        $scope.getClassTag = function (value) {
          value = slugify(value);

          var tags = appConfig['tags-separator'];
          var returnValue = '';

          Object.keys(tags).map(function (objectKey) {
            if (tags[objectKey] === value) {
              returnValue = objectKey;
            }
          });

          return returnValue;
        };

        angular.element($window).bind('scroll', function (e) {
          if (angular.element($window).scrollTop() > 1) {
            //  >= (angular.element('#header').height() - angular.element('.module-pending-drafts').height())
            angular.element('.module-pending-drafts').addClass('fixed');
          } else {
            angular.element('.module-pending-drafts').removeClass('fixed');
          }
        });

        // footer
        $scope.currentYear = new Date().getFullYear();
        $scope.counterLegal = 0;

        //COOKIES
        $scope.acceptCookies = function () {
          $scope.showCookiesPolicy = false;

          var now = new Date();
          var expireDate = new Date(
            now.getFullYear(),
            now.getMonth() + 6,
            now.getDate()
          );
          $cookies.put('POLICY_COOKIES', true, {
            expires: expireDate,
          });
        };

        var showCookies = true;
        if ($cookies.get('POLICY_COOKIES')) showCookies = false;
        $scope.showCookiesPolicy = showCookies;

        /* DoubleTrader */

        var params = $location.search();

        if (params.af1) {
          $http({
            method: 'get',
            url: appConfig.localApiBasePath + '/affiliate/valid/' + params.af1,
          }).then(
            function (valid) {
              if (valid.data.response) {
                AffiliatesFactory.setActive(params.af1).then(
                  function () {
                    if (params.af2) {
                      return AffiliatesFactory.setExternal(params.af2);
                    } else {
                      if (AffiliatesFactory.getExternal())
                        AffiliatesFactory.removeExternal();
                    }
                  },
                  function (err) {
                    $log.error('Error getting affiliate cookies config', err);
                  }
                );
              } else {
                $log.error('Invalid affiliate ' + params.af1);
              }
            },
            function errorCallBack(err) {
              $log.error('Error: ', err);
            }
          );
        }

        /* END DoubleTrader */

        /* Campaings */

        if (
          params.utm_source === 'programmatic_estandar' &&
          params.utm_campaign === 'pros'
        ) {
          if (!$window.sessionStorage.getItem('EXTERNAL_CAMPAIGN'))
            $window.sessionStorage.setItem('EXTERNAL_CAMPAIGN', true);
        }

        /* END Campains */

        /* Allowed Compats */

        appConfig.allowedCompats = appConfig.allowedCompats || ['S1'];
        $rootScope.allowedCompats = appConfig.allowedCompats;

        appConfig.allowedCompatsAdmin = appConfig.allowedCompatsAdmin || [
          'S1',
          'S2',
        ];
        $rootScope.allowedCompatsAdmin = appConfig.allowedCompatsAdmin;

        /* END Allowed Compats */
      },
    ]);
})();
