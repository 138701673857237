(function () {
  angular.module('mainApp')

    .directive('ngConfirmClick', [
      function () {
        return {
          link: function (scope, element, attr) {
            var msg = attr.ngConfirmClick || "¿ Estas seguro ?";
            var clickAction = attr.confirmedClick;
            element.bind('click', function (event) {
              if (window.confirm(msg)) {
                scope.$eval(clickAction)
              }
            });
          }
        };
    }])
    .directive('viewAffiliatesContract', function ($templateCache) {
      return {
        template: function(element, attrs) {
          return $templateCache.get('template/view-affiliates-contract.html');
        },
        link: function (scope, element, attrs) {}
      };
    })

    .controller('AffiliatesProfileController', [function ($scope, $rootScope, affiliatesUsersManager, webStorage, appConfig, $http, $window, sessionService, ngDialog) {
      var affiliateNiceName = webStorage.get('userNiceNameAffiliates');
      var profile;
      $scope.viewContract = 'deactivated';

      affiliatesUsersManager.getAffiliateInfo(affiliateNiceName, profile).then(function (data) {
        $scope.affiliateData = data;
        $scope.bankData = {
          "bank_address": "",
          "bank_name": "",
          "owner": "",
          "bank_account": "",
          "ccc_account": "",
          "key_account": ""
        }
      });

      var getLegalTerms = function() {
        var urlDestino = appConfig.localApiBasePath + '/affiliates/legalterms'

          $http({
              method: 'get',
              url: urlDestino
            }).then(function (res) {
              $scope.introductionTitle = res.data.introductionTitle;
              $scope.legalText = res.data.legalText;
              $scope.validationText = res.data.validationText;
              $scope.preChecked = res.data.preChecked;
            }, function errorCallback (response) {
              sessionService.setUserToken("TOKEN", true);
              var urlToRedirect = $rootScope.url('affiliates-home');
              $window.location.href = urlToRedirect;
            })
      }
      getLegalTerms();

      $scope.submitForm = function (valid, affiliateData, bankData) {

        if (valid) {
          if (
            bankData.bank_name != undefined &&
            bankData.bank_address != undefined &&
            bankData.owner != undefined &&
            bankData.ccc_account != undefined &&
            bankData.key_account != undefined) {
            if (bankData.ccc_account.length == 20 && bankData.key_account.length == 4) {
              $scope.successIBAN = undefined;
              sendBankData();
              affiliateData.bank_data.send_data = true;
              affiliatesUsersManager.updateAffiliateInfo(affiliateNiceName, affiliateData).then(function (data) {
                if (data === true) {
                  $scope.success = true;
                } else {
                  $scope.success = false;
                }
              });
            } else {
              $scope.successIBAN = false;
            }
          } else {
            affiliatesUsersManager.updateAffiliateInfo(affiliateNiceName, affiliateData).then(function (data) {
              if (data === true) {
                $scope.success = true;
              } else {
                $scope.success = false;
              }
            });
          }
        }
      }

      $scope.viewContractDialog = function() {
        $scope.viewContract = 'activated';
      }

      $scope.closeContractDialog = function() {
        $scope.viewContract = 'deactivated';
      }

      $scope.dialogRemoveAffiliate = function () {

        ngDialog.openConfirm({
          template: 'remove-affiliate.html',
          className: 'new-recipe',
          scope: $scope
        }).then(function(){
          removeAffiliate();
        })
      }

      $scope.removeAffiliate = function() {
        var urlDestino = appConfig.localApiBasePath + '/affiliates/remove'
        var data = {
          'user': $scope.affiliateData.niceName,
        }
        $http({
          method: 'post',
          data: data,
          url: urlDestino,
          headers: {
            'Authorization': 'Bearer ' + sessionService.getUserToken(true)
          }
        }).then(function (res) {
          if (res.status == 200) {
            $scope.successContact = true;
            webStorage.set('userNiceNameAffiliates', "");
            sessionService.setUserToken("", true);
            setTimeout(function () {
              var urlToRedirect = $rootScope.url('affiliates-home');
              $window.location.href = urlToRedirect;
            }, 1000);
          } else {
            $scope.successContact = false;
          }
        }, function errorCallback(response) {
          var urlToRedirect = $rootScope.url('affiliates-home');
          $window.location.href = urlToRedirect;
        })        
      }

      $scope.changeBankData = function () {
        $scope.affiliateData.bank_data.send_data = false;
      }

      var sendBankData = function () {
        var urlDestino = appConfig.localApiBasePath + '/affiliate-bank'

        $scope.bankData.bank_account = "IBAN" + $scope.bankData.key_account + $scope.bankData.ccc_account

        var data = {
          'user': $scope.affiliateData.niceName,
          'name': $scope.affiliateData.name,
          'lastName': $scope.affiliateData.lastName,
          'email': $scope.affiliateData.email,
          'bank_account': $scope.bankData.bank_account,
          "bank_name": $scope.bankData.bank_name,
          "owner": $scope.bankData.owner,
          "bank_address": $scope.bankData.bank_address,
        }

        $http({
          method: 'post',
          data: data,
          url: urlDestino,
        }).then(function (res) {
          console.log("status")
          console.log(res.status)
          if (res.status == 200) {
            $scope.successBank = true;
          } else {
            $scope.successBank = false;
          }
          $scope.bankData = {};
        }, function errorCallback(response) {
          var urlToRedirect = $rootScope.url('affiliates-home');
          $window.location.href = urlToRedirect;
        })
      }

    }]);

})();
