(function () {
  angular
    .module('mainApp')

    .filter('byCategory', function () {
      return function (items, search) {
        if (!search) {
          return items;
        }

        if (search == 'all') {
          return items;
        }

        var itemsByProduct = new Array();

        items.forEach(function (el) {
          el.category.forEach(function (category) {
            if (category.niceName == search) {
              itemsByProduct.push(el);
            }
          });
        });

        return itemsByProduct;
      };
    })

    .controller('StoreHomeController', [
      '$rootScope',
      '$scope',
      '$filter',
      '$timeout',
      'saRouter',
      'storeFactory',
      'ngCart',
      'serviceStoreAnalytics',
      'sessionService',
      'bannerShop',
      'AffiliateProductsFactory',
      '$timeout',
      '$location',
      'AffiliatesFactory',
      function (
        $rootScope,
        $scope,
        $filter,
        $timeout,
        saRouter,
        storeFactory,
        ngCart,
        serviceStoreAnalytics,
        sessionService,
        bannerShop,
        AffiliateProductsFactory,
        $timeout,
        $location,
        AffiliatesFactory
      ) {
        var ap = [];
        var isAffiliate = AffiliatesFactory.getActive();

        $scope.extraContact =
          $rootScope.translations.help.contact.telesales.enabled_store;

        AffiliateProductsFactory.get().then(function (prods) {
          ap = prods;
        });

        $scope.getItemPrice = function (item) {
          return isAffiliate && ap.includes(item.niceName)
            ? item.affiliatePrice
              ? item.affiliatePrice
              : item.price
            : item.price;
        };

        $scope.isAffiliatePrice = function (itemNiceName) {
          return isAffiliate && ap.includes(itemNiceName);
        };

        function getProductsAndCategories() {
          return Promise.all([
            storeFactory.getProducts(),
            storeFactory.getOrderedCategories(),
          ]);
        }

        sessionService.getUserIP().then(function (result) {
          $scope.countryCode = result.extra
            ? result.extra.country.isoCode
            : result.countryCode;
          
          $scope.userIP = result.ip;
        });

        var timerImpressions;
        var arrImpressions = new Array();
        var listProductViews = new Array();

        function getProductUrl(niceName) {
          var route = saRouter.getRoute('store-product');
          return saRouter.replaceUrlParams(route.url, { niceName: niceName });
        }

        storeFactory.getConfiguration().then(function (config) {
          $scope.currency = config.displayCurrency;
        });

        getProductsAndCategories().then(function (data) {
          var products = data[0].result;
          var categories = data[1].result;

          var listCategories = categories.filter(function (cat) {
            return products.find(function (p) {
              return p.category.find(function (c) {
                return c.niceName == cat.niceName;
              });
            });
          });

          var all_must_show = $rootScope.translations.shop.home.all_must_show;

          if (all_must_show) {
            listCategories.unshift({
              niceName: 'all',
              text: $rootScope.translations.shop.home.all,
              image: $rootScope.translations.shop.home.all_image,
            });
          }

          for (var i = 0; i < products.length; i++) {
            products[i].url = getProductUrl(products[i].cms.url.slug);
          }

          var initCategory;
          var hash = $location.hash();

          if (hash) {
            var ct = listCategories.find(function (cat) {
              return cat.cms ? cat.cms.url.slug === hash : false;
            });

            if (ct) initCategory = ct.niceName;
          }

          $scope.listCategories = listCategories;
          $scope.products = products;
          $scope.currentCategory = initCategory
            ? initCategory
            : listCategories[0]
            ? listCategories[0].niceName
            : 'none';
        });

        $scope.getOtherCategories = function () {
          return $scope.listCategories
            ? $scope.listCategories.filter(function (cat) {
                return (
                  cat.niceName !== $scope.currentCategory &&
                  cat.niceName != 'all'
                );
              })
            : [];
        };

        $scope.updateCategoryFilter = function (category, direct) {
          sendImpressions();

          $scope.currentCategory = category;
          var new_hash = $scope.listCategories.find(function (cat) {
            return cat.niceName === category;
          }).cms.url.slug;

          $location.hash(new_hash);

          if (!direct) {
            angular.element('html, body').animate(
              {
                scrollTop:
                  document.getElementById('top-list').offsetTop -
                  document.getElementById('body-wrap').offsetTop -
                  15,
              },
              600,
              function () {
                // $scope.currentCategory = category;
              }
            );
          }

          // listProductViews = [];
          // arrImpressions = [];

          var productsByCategory = $filter('byCategory')(
            $scope.products,
            category
          );
        };

        $scope.ngCartAddItem = function (
          niceName,
          name,
          price,
          oprice,
          quantity,
          data
        ) {
          ngCart.addItem(niceName, name, price, oprice, quantity, data);
        };

        $scope.productView = function (element, inview, position) {
          var el = element;
          el.positionImpression = position;

          if (inview && listProductViews.indexOf(el.niceName) === -1) {
            $timeout.cancel(timerImpressions);
            listProductViews.push(el.niceName);

            arrImpressions.push(el);

            timerImpressions = $timeout(function () {
              sendImpressions();

              arrImpressions = [];
            }, 5000);
          }

          return false;
        };

        function sendImpressions() {
          $timeout.cancel(timerImpressions);
          var category =
            $scope.currentCategory == 'all' ? 'home' : 'home-list-by-category';

          serviceStoreAnalytics.addProductImpression(arrImpressions, category);
        }

        bannerShop.getBannerShop().then(function (o) {
          $scope.featuredBannerShop = o;

          // if ($cookies.get('AFFILIATE_MYCOOK')) {
          // 	$scope.affiliatePrices = true;
          // } else {
          // 	$scope.affiliatePrices = false;
          // }
        });
      },
    ]);
})();
