/**
 *  - recipesFactory
 *  - RecipeController
 *  - AsideController
 */

(function () {
  'use strict';

  angular
    .module('mainApp')
    .directive('starRating', [
      function () {
        return {
          restrict: 'EA',
          replace: true,
          template:
            '<div ng-class="className" class="svg-element">' +
            '<div class="scaling-svg-container" ng-attr-style="padding-bottom: {{ 100 * 16 / (stars.length * 16) }}%;">' +
            '<svg role="img" class="scaling-svg star-review" viewBox="{{ \'0 0 \' + (stars.length * 16) + \' 16\' }}">' +
            '<use ng-repeat="star in stars" ng-class="star.filled" ng-click="toggle($index)" xlink:href="#star" ng-attr-x="{{ 16 * $index }}" y="0" />' +
            '</svg>' +
            '</div>' +
            '</div>',
          /*
            viewBox="{{ x + \' \' + y + \' \' + w + \' \' + h }}"
            */
          scope: {
            ratingValue: '=ngModel',
            max: '=?', // optional (default is 5)
            readonly: '=?',
            /*x: '=?',
            y: '=?',
            w: '=?',
            h: '=?',*/
            className: '=?',
          },
          link: function (scope, element, attributes) {
            if (scope.max == undefined) scope.max = 5;
            /*if (scope.x == undefined) scope.x = 0;
            if (scope.y == undefined) scope.y = 0;
            if (scope.w == undefined) scope.w = 16 * scope.max;
            if (scope.h == undefined) scope.h = 16;*/

            function updateStars() {
              scope.stars = [];
              for (var i = 0; i < scope.max; i++) {
                var stateClass =
                  i + 1 <= scope.ratingValue ? 'star--full' : 'star--empty';

                // has another half rating, add half star
                if (
                  i + 1 - scope.ratingValue < 1 &&
                  i + 1 - scope.ratingValue > 0
                ) {
                  // mitjos
                  stateClass = 'star--half';
                }
                scope.stars.push({
                  filled: stateClass,
                });
              }
            }

            scope.toggle = function (index) {
              if (scope.readonly == undefined || scope.readonly === false) {
                scope.ratingValue = index + 1;
              }
            };

            scope.$watch('ratingValue', function (oldValue, newValue) {
              if (newValue || newValue === 0 || newValue === undefined) {
                updateStars();
              }
            });
          },
        };
      },
    ])

    .directive('commentAuthor', [
      '$compile',
      '$filter',
      'toolsService',
      'serviceRanks',
      'saRouter',
      function ($compile, $filter, toolsService, serviceRanks, saRouter) {
        return {
          restrict: 'E',
          scope: {
            modelo: '=',
          },
          replace: true,
          link: function (scope, elem, attrs) {
            var route = saRouter.getRoute('authorRecipeList');
            var url = saRouter.replaceUrlParams(route.url, {
              user: scope.modelo.niceName,
            });

            var htmlCode = '<strong>{{modelo.displayName}}</strong>';
            if (!toolsService.isEmpty(scope.modelo.niceName)) {
              htmlCode =
                '<a href="' + url + '" rel="nofollow">' + htmlCode + '</a>';
            }

            serviceRanks.getRanks().then(function (ranks) {
              if (scope.modelo.rank) {
                var found = $filter('filter')(
                  ranks,
                  { niceName: scope.modelo.rank },
                  true
                );
                if (found.length > 0) {
                  scope.modelo.rank = found[0].name;
                }
              } else {
                scope.modelo.rank = '';
              }
            });

            htmlCode += '<div class="comment-info">';
            htmlCode += '<strong>{{modelo.rank}}</strong>';
            htmlCode +=
              '<time datetime="{{modelo.date | date: \'yyyy-MM-dd HH:mm:ss+0200\'}}" am-time-ago="modelo.date"></time>';
            htmlCode += '</div>';
            elem.html(htmlCode);
            $compile(elem.contents())(scope);
          },
        };
      },
    ])

    //Really appreciated: https://medium.com/@Snowflakes91/angularjs-and-recursive-directives-32657fbaa543 :D
    .factory('RecursionHelper', [
      '$compile',
      function ($compile) {
        return {
          compile: function (element, link) {
            // Normalize the link parameter
            if (angular.isFunction(link)) {
              link = { post: link };
            }

            // Break the recursion loop by removing the contents
            var contents = element.contents().remove();
            var compiledContents;
            return {
              pre: link && link.pre ? link.pre : null,
              /**
               * Compiles and re-adds the contents
               */
              post: function (scope, element) {
                // Compile the contents
                if (!compiledContents) {
                  compiledContents = $compile(contents);
                }
                // Re-add the compiled contents to the element
                compiledContents(scope, function (clone) {
                  element.append(clone);
                });

                // Call the post-linking function, if any
                if (link && link.post) {
                  link.post.apply(null, arguments);
                }
              },
            };
          },
        };
      },
    ])

    .factory('CommentFactory', [
      'recipesFactory',
      '$filter',
      '$log',
      function (recipesFactory, $filter, $log) {
        function stripTags(isAdmin, txt) {
          // TO-IMPROVE: validar que solo haya enlaces a urls internas, por ahora se filtran todos los casos que no sean admins
          if (!isAdmin) {
            return $filter('stripTags')(txt);
          } else return txt;
        }

        return {
          sendCommentOrReply: function (
            comment,
            isAdmin,
            recipeNiceName,
            position,
            type
          ) {
            comment.msg = stripTags(isAdmin, comment.msg);

            return recipesFactory
              .sendMsg(recipeNiceName, comment.msg, position, type)
              .then(
                function (msgResponse) {
                  var promise;

                  if (comment.image.file) {
                    if (comment.image.file === 'deleted') {
                      promise = recipesFactory.deleteChildImage(
                        recipeNiceName,
                        msgResponse.data.response || comment._id
                      );
                    } else {
                      if (comment.image.rights) {
                        promise = recipesFactory.uploadChildImage(
                          recipeNiceName,
                          msgResponse.data.response || comment._id,
                          comment.image.file[0]
                        );
                      } else {
                        promise = Promise.reject();
                      }
                    }
                  } else {
                    promise = Promise.resolve();
                  }

                  return promise;
                },
                function (error) {
                  $log.error(
                    'RecipeController > sendComment >  ERROR: ',
                    error
                  );
                }
              );
          },
        };
      },
    ])

    .directive(
      'comments',
      function (timestamp, $timeout, CommentFactory, $rootScope, $log) {
        return {
          restrict: 'E',
          templateUrl: 'template/recipe/comments.html?ts=' + timestamp,
          link: function ($scope, element, attrs) {
            $timeout(function () {
              $scope.inputFile = element[0].querySelector(
                'input#root-comment-imageFile'
              );
            });
          },
          controller: function ($scope) {
            $scope.ignoreClick = false;
            $scope.mainResponse = {
              msg: '',
              image: {
                file: null,
                rights: true, //inicialitzo el check a true, si ho demanen hauria de ser false
                url: '',
              },
            };

            $scope.postNewComment = function (form) {
              if (!$scope.ignoreClick) {
                $scope.ignoreClick = true;
                CommentFactory.sendCommentOrReply(
                  $scope.mainResponse,
                  $scope.adminRoles.includes($scope.currentUser.role),
                  $scope.recipeContent.niceName,
                  [],
                  'new'
                )
                  .then(
                    function (imgResponse) {
                      $scope.mainResponse = {
                        msg: '',
                        image: {
                          file: null,
                          rights: true, //inicialitzo el check a true, si ho demanen hauria de ser false
                          url: '',
                        },
                      };

                      form.$clicked = false;

                      $rootScope.$emit(
                        'recipe-subdoc-updated',
                        '0',
                        'comment',
                        'outter'
                      );
                    },
                    function (error) {
                      $scope.responseMsg =
                        $rootScope.translations.single_recipe.rating.rate_message.error;

                      $log.error(
                        'RecipeController > sendComment > uploadChildImage > ERROR: ',
                        error
                      );
                    }
                  )
                  .finally(function () {
                    $scope.ignoreClick = false;
                  });
              }
            };

            $scope.setVirtualUrl = function (event) {
              if ($scope.mainResponse.image.file) {
                if ($scope.mainResponse.image.url)
                  URL.revokeObjectURL($scope.mainResponse.image.url);

                $scope.mainResponse.image.url = URL.createObjectURL(
                  $scope.mainResponse.image.file[0]
                );
              }
            };

            $scope.discardImage = function () {
              if ($scope.mainResponse.image.url) {
                URL.revokeObjectURL($scope.mainResponse.image.url);
                $scope.mainResponse.image.url = '';
              }

              $scope.mainResponse.image.file = null;
              $scope.inputFile.value = '';
            };
          },
        };
      }
    )

    .directive('commentsList', function (timestamp, $rootScope) {
      return {
        restrict: 'E',
        templateUrl: 'template/recipe/comments-list.html?ts=' + timestamp,
        scope: {
          comments: '=',
          currentUser: '=',
          isAdmin: '=',
          mycookTouchDomain: '=',
          recipeNiceName: '=',
          depth: '=',
          parentPos: '=?',
          limitComments: '=?',
        },
        link: function ($scope, element, attrs) {},
        controller: function ($scope) {
          $scope.translations = $rootScope.translations;

          $scope.showAll = function () {
            $scope.limitComments = $scope.comments.length;
          };

          $scope.getIndex = function (iter) {
            return $scope.depth ? iter : $scope.comments.length - 1 - iter;
          };
        },
      };
    })

    .directive(
      'comment',
      function (
        timestamp,
        $rootScope,
        RecursionHelper,
        CommentFactory,
        $timeout,
        $log
      ) {
        return {
          restrict: 'E',
          replace: true,
          scope: {
            comment: '=',
            currentUser: '=',
            isAdmin: '=',
            mycookTouchDomain: '=',
            recipeNiceName: '=',
            depth: '=',
            position: '=',
            isLast: '=',
          },
          templateUrl: 'template/recipe/comment.html?ts=' + timestamp,
          compile: function (element) {
            return RecursionHelper.compile(
              element,
              function ($scope, iElement, iAttrs, controller, transcludeFn) {
                // Define your normal link function here.
                // Alternative: instead of passing a function,
                // you can also pass an object with
                // a 'pre'- and 'post'-link function.
                $timeout(function () {
                  $scope.inputFileEdit = iElement[0].querySelector(
                    'input#commentImageFileEdit-' + $scope.prunedPos()
                  );
                  $scope.inputFileResponse = iElement[0].querySelector(
                    'input#commentImageFileResponse-' + $scope.prunedPos()
                  );
                  $scope.imageBucket = iElement[0].querySelector(
                    'div.comment-msg'
                  );
                });
              }
            );
          },
          controller: function ($scope) {
            $scope.ignoreClick = false;
            $scope.translations = $rootScope.translations;
            $scope.mediaDomain = $scope.mycookTouchDomain + '/image';
            $scope.mode = 'view';
            $scope.comment.msg = '';
            $scope.comment.image = {
              file: null,
              rights: true, //inicialitzo el check a true, si ho demanen hauria de ser false
              url: '',
            };

            $scope.comment.response = {
              msg: '',
              image: {
                file: null,
                rights: true, //inicialitzo el check a true, si ho demanen hauria de ser false
                url: '',
              },
            };

            $scope.rstr = Math.random().toString(36).substring(7);

            $scope.getImageWidth = function () {
              return screen.width <= 640 ? $scope.imageBucket.offsetWidth : 185;
            };

            $scope.editComment = function () {
              if ($scope.comment.image.url)
                URL.revokeObjectURL($scope.comment.image.url);

              $scope.comment.msg = $scope.comment.text;
              $scope.comment.image = {
                url: $scope.comment.haveImage
                  ? $scope.mediaDomain +
                    '/recipe/' +
                    $scope.recipeNiceName +
                    '/185x134/' +
                    $scope.comment._id +
                    '?ver=' +
                    $scope.rstr
                  : '',
                file: null,
                rights: true,
              };

              $scope.mode = 'edit';
              $rootScope.$emit(
                'recipe-subdoc-opening-input',
                $scope.comment._id
              );
            };

            $scope.responseComment = function () {
              if ($scope.comment.response.image.url)
                URL.revokeObjectURL($scope.comment.response.image.url);

              $scope.comment.response.msg = '';
              $scope.comment.response.image = {
                file: null,
                rights: true, //inicialitzo el check a true, si ho demanen hauria de ser false
                url: '',
              };

              $scope.mode = 'reply';
              $rootScope.$emit(
                'recipe-subdoc-opening-input',
                $scope.comment._id
              );
            };

            $scope.prunedPos = function () {
              if ($scope.position)
                return $scope.position.toString().replace('.', '_');
            };

            $scope.cancelResponseOrEdit = function () {
              var dest =
                $scope.mode === 'edit'
                  ? $scope.comment
                  : $scope.comment.response;

              if (dest.image.url) URL.revokeObjectURL(dest.image.url);

              dest.msg = '';
              dest.image = {
                file: null,
                rights: true,
                url: '',
              };

              $scope.mode = 'view';
            };

            $scope.setVirtualUrl = function (event) {
              var dest =
                $scope.mode === 'edit'
                  ? $scope.comment.image
                  : $scope.comment.response.image;

              if (dest.file) {
                if (dest.url) URL.revokeObjectURL(dest.url);

                dest.url = URL.createObjectURL(dest.file[0]);
              }
            };

            $scope.discardImage = function () {
              var dest =
                $scope.mode === 'edit'
                  ? $scope.comment.image
                  : $scope.comment.response.image;

              var input =
                $scope.mode === 'edit'
                  ? $scope.inputFileEdit
                  : $scope.inputFileResponse;

              if (dest.url) {
                URL.revokeObjectURL(dest.url);
                dest.url = '';
              }

              dest.file =
                $scope.mode === 'edit'
                  ? $scope.comment.haveImage
                    ? 'deleted'
                    : null
                  : null;

              input.value = '';
            };

            function sendComment(type) {
              if (!$scope.ignoreClick) {
                $scope.ignoreClick = true;

                CommentFactory.sendCommentOrReply(
                  type === 'edit' ? $scope.comment : $scope.comment.response,
                  $scope.isAdmin,
                  $scope.recipeNiceName,
                  type === 'edit'
                    ? $scope.position.split('.')
                    : [$scope.position.split('.')[0]],
                  type
                )
                  .then(
                    function (imgResponse) {
                      $rootScope.$emit(
                        'recipe-subdoc-updated',
                        $scope.position,
                        'comment',
                        $scope.mode
                      );

                      $scope.rstr = Math.random().toString(36).substring(7);
                    },
                    function (error) {
                      $scope.responseMsg =
                        $rootScope.translations.single_recipe.rating.rate_message.error;

                      $log.error(
                        'RecipeController > sendComment > uploadChildImage > ERROR: ',
                        error
                      );
                    }
                  )
                  .finally(function () {
                    $scope.ignoreClick = false;
                  });
              }
            }

            $scope.sendCommentResponse = function () {
              sendComment('new');
            };

            $scope.sendCommentEdit = function () {
              sendComment('edit');
            };

            $scope.amplifyImage = function () {
              $rootScope.$emit(
                'recipe-subdoc-expand-image',
                $scope.comment._id,
                $scope.translations.single_recipe.comment,
                $scope.comment.displayName
              );
            };

            var unregister = $rootScope.$on(
              'recipe-subdoc-opening-input',
              function (event, id) {
                if (id !== $scope.comment._id && $scope.mode !== 'view')
                  $scope.cancelResponseOrEdit();
              }
            );

            $scope.$on('$destroy', function () {
              unregister();
            });
          },
        };
      }
    )

    .factory('RatingFactory', [
      'recipesFactory',
      '$filter',
      '$log',
      function (recipesFactory, $filter, $log) {
        function stripTags(isAdmin, txt) {
          // TO-IMPROVE: validar que solo haya enlaces a urls internas, por ahora se filtran todos los casos que no sean admins
          if (!isAdmin) {
            return $filter('stripTags')(txt);
          } else return txt;
        }

        return {
          sendRatingOrReply: function (
            rating,
            currentUser,
            isAdmin,
            recipeNiceName,
            position,
            type
          ) {
            rating.msg = stripTags(isAdmin, rating.msg);

            return recipesFactory
              .sendRate(
                recipeNiceName,
                rating.rate,
                rating.msg,
                position,
                type,
                currentUser,
                rating._id
              )
              .then(
                function (msgResponse) {
                  var promise;

                  if (rating.image.file) {
                    if (rating.image.file === 'deleted') {
                      promise = recipesFactory.deleteChildImage(
                        recipeNiceName,
                        msgResponse.data.response || rating._id
                      );
                    } else {
                      if (rating.image.rights) {
                        promise = recipesFactory.uploadChildImage(
                          recipeNiceName,
                          msgResponse.data.response || rating._id,
                          rating.image.file[0]
                        );
                      } else {
                        promise = Promise.reject();
                      }
                    }
                  } else {
                    promise = Promise.resolve();
                  }

                  return promise;
                },
                function (error) {
                  $log.error('RecipeController > sendRate >  ERROR: ', error);
                }
              );
          },
        };
      },
    ])

    .directive(
      'ratings',
      function ($rootScope, RatingFactory, $timeout, $log, timestamp) {
        return {
          restrict: 'E',
          templateUrl: 'template/recipe/ratings.html?ts=' + timestamp,
          link: function ($scope, element, attrs) {
            $timeout(function () {
              $scope.inputFile = element[0].querySelector(
                'input#root-imageFile'
              );
            });
          },
          controller: function ($scope) {
            $scope.ignoreClick = false;
            $scope.reply = {
              msg: '',
              rate: 0,
              image: {
                file: null,
                rights: true, //inicialitzo el check a true, si ho demanen hauria de ser false
              },
            };

            $scope.discardImage = function () {
              $scope.reply.image = {
                file: null,
                rights: false,
                url: '',
              };

              $scope.inputFile.value = '';
            };

            $scope.sendRating = function (form) {
              if (
                $scope.reply.rate &&
                $scope.currentUser &&
                !$scope.ignoreClick
              ) {
                $scope.ignoreClick = true;
                RatingFactory.sendRatingOrReply(
                  $scope.reply,
                  $scope.currentUser,
                  $scope.adminRoles.includes($scope.currentUser.role),
                  $scope.recipeContent.niceName,
                  [],
                  'new'
                )
                  .then(
                    function (imgResponse) {
                      $scope.reply = {
                        msg: '',
                        rate: 0,
                        image: {
                          file: null,
                          rights: true, //inicialitzo el check a true, si ho demanen hauria de ser false
                        },
                      };

                      form.$clicked = false;

                      $rootScope.$emit(
                        'recipe-subdoc-updated',
                        '0',
                        'rating',
                        'outter'
                      );
                    },
                    function (error) {
                      $scope.responseMsg =
                        $rootScope.translations.single_recipe.rating.rate_message.error;

                      $log.error(
                        'RecipeController > sendRate > uploadChildImage > ERROR: ',
                        error
                      );
                    }
                  )
                  .finally(function () {
                    $scope.ignoreClick = false;
                  });
              }
            };

            $scope.onlyOneRated = function () {
              return (
                $scope.recipeContent.ratings.length == 1 &&
                $scope.recipeContent.status.rated
              );
            };
          },
        };
      }
    )

    .filter('ratingsLimit', [
      function () {
        return function (ratings, max, user, depth) {
          return depth || !max
            ? ratings
            : user
            ? ratings
                .filter(function (rating) {
                  return rating.niceName !== user;
                })
                .filter(function (rating, idx) {
                  return idx < max;
                })
            : ratings.filter(function (rating, idx) {
                return idx < max;
              });
        };
      },
    ])

    .directive('ratingsList', function (timestamp, $rootScope) {
      return {
        restrict: 'E',
        scope: {
          ratings: '=',
          currentUser: '=',
          isAdmin: '=',
          mycookTouchDomain: '=',
          recipeNiceName: '=',
          recipeOwner: '=',
          depth: '=',
          parentOwner: '=?',
          parentPos: '=?',
          limitRatings: '=?',
        },
        templateUrl: 'template/recipe/ratings-list.html?ts=' + timestamp,
        link: function ($scope, element, attrs) {},
        controller: function ($scope) {
          $scope.translations = $rootScope.translations;

          $scope.showAll = function () {
            $scope.limitRatings = $scope.ratings.length;
          };

          $scope.getIndex = function (current, iter) {
            return $scope.depth
              ? iter
              : $scope.ratings.findIndex(function (rating) {
                  return rating._id === current._id;
                });
          };
        },
      };
    })

    .directive(
      'rating',
      function (
        timestamp,
        $timeout,
        $log,
        $rootScope,
        RecursionHelper,
        RatingFactory
      ) {
        return {
          restrict: 'E',
          replace: true,
          scope: {
            rating: '=',
            currentUser: '=',
            isAdmin: '=',
            mycookTouchDomain: '=',
            recipeNiceName: '=',
            recipeOwner: '=',
            depth: '=',
            parentOwner: '=?',
            position: '=',
            isLast: '=',
          },
          templateUrl: 'template/recipe/rating.html?ts=' + timestamp,
          compile: function (element) {
            return RecursionHelper.compile(
              element,
              function ($scope, iElement, iAttrs, controller, transcludeFn) {
                // Define your normal link function here.
                // Alternative: instead of passing a function,
                // you can also pass an object with
                // a 'pre'- and 'post'-link function.
                $timeout(function () {
                  $scope.inputFileEdit = iElement[0].querySelector(
                    'input#imageFileEdit-' + $scope.prunedPos()
                  );
                  $scope.inputFileReply = iElement[0].querySelector(
                    'input#imageFileReply-' + $scope.prunedPos()
                  );
                  $scope.imageBucket = iElement[0].querySelector(
                    'div.comment-msg'
                  );
                });
              }
            );
          },
          controller: function ($scope) {
            var rateBackup;
            $scope.ignoreClick = false;
            $scope.translations = $rootScope.translations;
            $scope.mediaDomain = $scope.mycookTouchDomain + '/image';
            $scope.rating.image = {
              file: null,
              rights: true, //inicialitzo el check a true, si ho demanen hauria de ser false
            };

            $scope.rating.reply = {
              msg: '',
              image: {
                file: null,
                rights: true, //inicialitzo el check a true, si ho demanen hauria de ser false
              },
            };

            $scope.mode = 'view';
            $scope.rstr = Math.random().toString(36).substring(7);

            $scope.getImageWidth = function () {
              return screen.width <= 640 ? $scope.imageBucket.offsetWidth : 185;
            };

            $scope.prunedPos = function () {
              if ($scope.position)
                return $scope.position.toString().replace('.', '_');
            };

            $scope.setVirtualUrl = function (event) {
              if ($scope.rating.image.file && $scope.mode === 'edit') {
                if ($scope.rating.image.url)
                  URL.revokeObjectURL($scope.rating.image.url);

                $scope.rating.image.url = URL.createObjectURL(
                  $scope.rating.image.file[0]
                );
              }
            };

            $scope.editRating = function () {
              $scope.rating.msg = $scope.rating.text;
              $scope.rating.image = {
                url: $scope.rating.haveImage
                  ? $scope.mediaDomain +
                    '/recipe/' +
                    $scope.recipeNiceName +
                    '/185x134/' +
                    $scope.rating._id +
                    '?ver=' +
                    $scope.rstr
                  : '',
                file: null,
                rights: true,
              };

              $scope.mode = 'edit';
              rateBackup = $scope.rating.rate;
              $rootScope.$emit(
                'recipe-subdoc-opening-input',
                $scope.rating._id
              );
            };

            $scope.createReply = function () {
              $scope.rating.reply.msg = '';
              $scope.rating.reply.image = {
                file: null,
                rights: true, //inicialitzo el check a true, si ho demanen hauria de ser false
              };

              if ($scope.rating.image.url) {
                URL.revokeObjectURL($scope.rating.image.url);
                $scope.rating.image.url = '';
              }

              $scope.mode = 'reply';
              $rootScope.$emit(
                'recipe-subdoc-opening-input',
                $scope.rating._id
              );
            };

            $scope.removeImageEdit = function () {
              if ($scope.rating.image.url) {
                URL.revokeObjectURL($scope.rating.image.url);
                $scope.rating.image.url = '';
              }

              $scope.rating.image.file = $scope.rating.haveImage
                ? 'deleted'
                : null;

              $scope.inputFileEdit.value = '';
            };

            $scope.cancelReplyOrEdit = function () {
              var dest =
                $scope.mode === 'edit' ? $scope.rating : $scope.rating.reply;

              if (dest.image.url) URL.revokeObjectURL(dest.image.url);

              dest.image = {
                file: null,
                rights: true,
                url: '',
              };

              dest.msg = '';

              $scope.rating.rate =
                $scope.mode === 'edit' ? rateBackup : $scope.rating.rate;

              $scope.mode = 'view';

              return false;
            };

            $scope.discardImage = function (idBox) {
              $scope.rating.reply.image.file = null;
              $scope.inputFileReply.value = '';
            };

            $scope.sendRate = function () {
              if ($scope.currentUser && !$scope.ignoreClick) {
                $scope.ignoreClick = true;

                RatingFactory.sendRatingOrReply(
                  $scope.mode === 'reply' ? $scope.rating.reply : $scope.rating,
                  $scope.currentUser,
                  $scope.isAdmin,
                  $scope.recipeNiceName,
                  $scope.position.split('.'),
                  $scope.mode === 'reply' ? 'new' : 'edit'
                )
                  .then(
                    function (imgResponse) {
                      $rootScope.$emit(
                        'recipe-subdoc-updated',
                        $scope.position,
                        'rating',
                        $scope.mode
                      );

                      $scope.rstr = Math.random().toString(36).substring(7);
                    },
                    function (error) {
                      $scope.responseMsg =
                        $rootScope.translations.single_recipe.rating.rate_message.error;

                      $log.error(
                        'RecipeController > sendRate > uploadChildImage > ERROR: ',
                        error
                      );
                    }
                  )
                  .finally(function () {
                    $scope.ignoreClick = false;
                  });
              }
            };

            $scope.amplifyImage = function () {
              $rootScope.$emit(
                'recipe-subdoc-expand-image',
                $scope.rating._id,
                $scope.translations.single_recipe.rating.single_name,
                $scope.rating.displayName
              );
            };

            var unregister = $rootScope.$on(
              'recipe-subdoc-opening-input',
              function (event, id) {
                if (id !== $scope.rating._id && $scope.mode !== 'view')
                  $scope.cancelReplyOrEdit();
              }
            );

            $scope.$on('$destroy', function () {
              unregister();
            });
          },
        };
      }
    );
})();
